import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import { PROGRAM_AVAILABILITY, PROGRAM_AVAILABILITY_FORM_VALIDATIONS, PROGRAM_AVAILABILITY_PROGRAM_MAXIMUM, PROGRAM_AVAILABILITY_STEPS, PROGRAM_AVAILABILITY_PROGRESS_BAR, PROGRAM_AVAILABILITY_BUTTON_TEXT, STEP_2_FILTER_TYPES, PROGRAM_AVAILABILITY_GNVIEW_LAST_UPDATED } from '../../../constants/ProgramAvailability';
import ProgramAvailabilitySearchStep1 from "./ProgramAvailabilitySearchStep1";
import ProgramAvailabilitySearchStep2 from "./ProgramAvailabilitySearchStep2";
import GNCard from '../../../components/common/gnCard/GNCard';
import StepCount from '../../../components/common/gnProgramCard/StepCount';
import { checkProgramExists, formatProgramObject, getLocalGNVIEWIngestedLastupdated } from '../../../utils/ProgramUtils';
import { formatProgramWithSeasonsAndEpisodes, filterEpisodesBySeason } from '../../../utils/SeasonUtils';
import { numberWithCommas } from '../../../utils/GeneralUtils';
import { Button } from 'react-bootstrap';
import {
    gnviewCreateSavedSearch,
    gnviewGetProgramById,
    gnviewGetProgramDetails,
    gnviewGetSavedSearch,
    gnviewGetStreamingVideoCatalogs,
    gnviewGetProgramAvailabilitiesLastUpdated,
    gnviewGetProgramSeasonsAndEpisodes,
    gnviewProgramAvailAddProgramIds,
    gnviewProgramAvailAddQueryObj,
    gnviewProgramAvailAddCatalogIds
} from '../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import { ROUTES } from '../../../config/Routes.js';
import "./ProgramAvailabilitySearch.scss";
import { convertCountryCodes } from '../../../utils/GeneralUtils';
import PropTypes from 'prop-types';
import GNProgressBar from '../../../components/common/gnProgressBar/GNProgressBar';
import ReactRouterPropTypes from 'react-router-prop-types';
import ClassNames from 'classnames';
import { NO_SEASON, PROGRAM_GNPROGTYPES, PROGRAM_TYPES, PROGRAM_SUBTYPES } from "../../../constants/Program";
import SuccessMessageModal from '../../../components/common/successMessageModal/SuccessMessageModal';
import { MY_SAVED_ITEMS_TYPES } from '../../../constants/MySavedItems';
import { gvauthSelEmail } from '../../../reducers/GNVAuthReducer';
import queryString from 'query-string';
import MySavedItemsButton from '../../../components/common/saveButton/MySavedItemsButton';

export class ProgramAvailabilitySearchView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Step 1 variables
            stepOneCollapsed: false,
            selectedPrograms: [],
            tmsId: '',
            disableProgramSearch: false,
            // Step 2 variables
            stepTwoCollapsed: true,
            allStreamingCatalogs: [],
            filterTypes: {},
            countriesList: [],
            selectedFilter: STEP_2_FILTER_TYPES.all,
            selectedCountry: null,
            // Save search
            existingSaveSearchTitle: null,
            isDataFromSavedSearch: false,
            // global variables
            errorMessages: {},
            lastUpdated: ''
        }
    }

    componentDidMount() {
        this.getLastUpdated();
        this.props.gnviewGetStreamingVideoCatalogs().then((catalogsResponse) => {
            if (catalogsResponse.result) {
                const params = queryString.parse(this.props.location?.search);
                if (params.search_id) {
                    this.props.gnviewGetSavedSearch(params.search_id).then((savedSearchResponse) => {
                        this.setState({ existingSaveSearchTitle: savedSearchResponse?.result?.title });
                        const allStreamingCatalogs = catalogsResponse.result.map(cat => {
                            const selected = savedSearchResponse?.result?.query_obj?.catalog_ids?.includes(cat.id);
                            return { ...cat, selected, show: true };
                        });
                        const programIds = savedSearchResponse.result.query_obj.program_ids.map(program => program.parent_tms_id);
                        const selectedEpisodes = savedSearchResponse.result.query_obj.program_ids.map(program => program?.episode_tms_ids).flat();
                        this.props.gnviewGetProgramById(programIds).then((programsResponse) => {
                            if (programsResponse.result) {
                                this.handleChangeProgram(programsResponse.result, selectedEpisodes);
                                this.setState({ allStreamingCatalogs, isDataFromSavedSearch: true, stepTwoCollapsed: false }, () => this.setFiltersAndCountries());
                            }
                        }).catch((error) => {
                            this.props.gnviewSendLogMessage(`gnviewGetProgramById error: ${error.message}`, error);
                        });
                    }).catch((error) => {
                        this.props.gnviewSendLogMessage(`gnviewGetSavedSearch error: ${error.message}`, error);
                    });
                } else {
                    const allStreamingCatalogs = catalogsResponse.result.map(cat => ({...cat, selected: false, show: true}));
                    this.setState({ allStreamingCatalogs }, () => this.setFiltersAndCountries());
                }
            }
        }).catch((error) => {
            this.props.gnviewSendLogMessage(`gnviewGetStreamingVideoCatalogs error: ${error.message}`, error);
        });
    }

    getLastUpdated() {
        this.props
            .gnviewGetProgramAvailabilitiesLastUpdated()
            .then((response) => {
                if (response?.result?.length > 0) {
                    const lastUpdated = getLocalGNVIEWIngestedLastupdated(response.result);
                    this.setState({ lastUpdated });
                }
            })
            .catch((error) => {
                this.props.gnviewSendLogMessage(`gnviewGetProgramAvailabilitiesLastUpdated error: ${error.message}`, error);
            });
    }

    // Step 1
    toggleStepOne = () => {
        this.setState((prevState) => ({stepOneCollapsed: !prevState.stepOneCollapsed, stepTwoCollapsed: prevState.stepOneCollapsed}));
    }

    checkProgramsLimit = (programs) => {
        return programs.length >= PROGRAM_AVAILABILITY_PROGRAM_MAXIMUM;
    }

    handleChangeProgram = (prog, selectedEpisodes = []) => {
        if (prog.length > 1) {
            const programIds = prog.map(program => program.tms_id);
            const seasonsAndEpisodesRequests = programIds.map(tmsid => this.props.gnviewGetProgramSeasonsAndEpisodes(tmsid).catch(() => null));
            Promise.all(seasonsAndEpisodesRequests).then(seasonsAndEpisodesData => {
                const programsFormatted = seasonsAndEpisodesData.map((seasonsAndEpisodes, idx) => formatProgramWithSeasonsAndEpisodes(prog[idx], seasonsAndEpisodes.result, selectedEpisodes));
                this.setState({ selectedPrograms: programsFormatted, disableProgramSearch: this.checkProgramsLimit(programsFormatted) }, () => this.checkInvalidForm());
            }).catch((error) => {
                this.props.gnviewSendLogMessage(`Promise.all gnviewGetProgramSeasonsAndEpisodes error: ${error.message}`, error, {programIds});
            });
        } else {
            const program = prog?.length > 0 ? prog[0] : null;
            const programId = program?.tmsid
            const programFound = this.state.selectedPrograms.find((p) => p.tmsid === programId);
            this.checkInvalidForm(null, programId);
            if (this.state.errorMessages?.program === PROGRAM_AVAILABILITY_FORM_VALIDATIONS.PROGRAM_MAXIMUM) {
                return;
            }
            if (program && programId && !programFound) {
                const programsCopy = [...this.state.selectedPrograms];
                this.props.gnviewGetProgramSeasonsAndEpisodes(programId).then(
                    (episodesResponse) => {
                        const programFormatted = formatProgramWithSeasonsAndEpisodes(program, episodesResponse.result, selectedEpisodes);
                        programsCopy.push(programFormatted);
                        this.setState({ selectedPrograms: programsCopy, disableProgramSearch: this.checkProgramsLimit(programsCopy) }, () => this.checkInvalidForm());
                    }, (error) => {
                        this.props.gnviewSendLogMessage(`gnviewGetProgramSeasonsAndEpisodes error: ${error.message}`, error, {programId});
                    }
                )
            }
        }
    }

    handleProgramRemove = (tmsid) => {
        const programIndex = this.state.selectedPrograms.findIndex((program) => program.tmsid === tmsid);
        if (programIndex !== -1) {
            const programsCopy = [...this.state.selectedPrograms];
            programsCopy.splice(programIndex, 1);
            this.setState({ selectedPrograms: programsCopy, disableProgramSearch: this.checkProgramsLimit(programsCopy) }, () => this.checkInvalidForm());
        }
    }

    // TMS ID input
    handleTmsIdChange = (e) => {
        const str = e.target.value;
        if (typeof str === "string") {
            this.setState({tmsId: str});
        }
    }

    // TMS ID add program button
    handleTMSIdButtonClick = (e, tmsId) => {
        // Prevent page refresh
        e.preventDefault();
        if (tmsId) {
            this.checkInvalidForm(null, tmsId);
            if (this.state.errorMessages?.program === PROGRAM_AVAILABILITY_FORM_VALIDATIONS.PROGRAM_MAXIMUM || checkProgramExists(this.state.selectedPrograms, tmsId)) {
                return;
            }
            this.props.gnviewGetProgramDetails(tmsId, false, false, true).then((response) => {
                if (response.result) {
                    const programsCopy = [...this.state.selectedPrograms];
                    const program = formatProgramObject(response);
                    const programFormatted = formatProgramWithSeasonsAndEpisodes(program, program?.allEpisodes);
                    programsCopy.push(programFormatted);
                    this.setState({ selectedPrograms: programsCopy, disableProgramSearch: this.checkProgramsLimit(programsCopy) }, () => this.checkInvalidForm());
                }
            }).catch((error) => {
                this.props.gnviewSendLogMessage(`gnviewGetProgramDetails error: ${error.message}`, error, {tmsId});
                if (error?.response?.status === 404) {
                    this.checkInvalidForm(error.response.status, tmsId);
                    this.setState({ tmsId: ''})
                } else if (error?.response?.status === 403) {
                    this.checkInvalidForm(error.response.status, tmsId);
                    this.setState({ tmsId: ''})
                }
            });
        }
    }

    seriesCheckboxHandler = (e, program) => {
        const seriesIndex = this.state.selectedPrograms.findIndex((prog) => prog.tmsid === program.tmsid);
        if (seriesIndex !== -1 && e?.target) {
            const selectedProgramsCopy = [...this.state.selectedPrograms];
            const programCopy = selectedProgramsCopy[seriesIndex];
            if (programCopy?.uniqueSeasons?.length > 0) {
                programCopy.uniqueSeasons.forEach((season) => {
                    programCopy.seasonsAndEpisodes[season] = programCopy.seasonsAndEpisodes[season].map((ep) => ({...ep, isSelected: e.target.checked }));
                    const seasonCheckbox = document.getElementsByClassName(`${programCopy.tmsid}-select-season-${season}`)[0];
                    if (seasonCheckbox) {
                        const cb = seasonCheckbox.querySelector('input');
                        if (cb.type === 'checkbox') {
                            cb.checked = e.target.checked;
                        }
                    }
                    // Visually uncheck the boxes, we've already set the isSelected. We could trigger updates on all the child components
                    // but visually it flickers and not a good experience. Better to just update the checkboxes manually then remount the entire table.
                    const epCheckboxWrapper = Array.from(document.getElementsByClassName(programCopy.tmsid + '-season-' + season));
                    epCheckboxWrapper.forEach((wrapper) => {
                        const cb = wrapper.querySelector('input');
                        if (cb.type === 'checkbox') {
                            cb.checked = e.target.checked;
                        }
                    });
                });
            }
            this.setState({selectedPrograms: selectedProgramsCopy});
        }
    }

    seasonCheckboxHandler = (e, program, seasonNum) => {
        const seriesIndex = this.state.selectedPrograms.findIndex((prog) => prog.tmsid === program.tmsid);
        const seasonCbxWrapper = document.getElementsByClassName(`${program.tmsid}-select-season-${seasonNum}`)[0];
        if (seasonCbxWrapper) {
            const seasonCbx = seasonCbxWrapper.querySelector('input');
            const cbValue = seasonCbx?.data === 'indeterminate' ? false : seasonCbx.checked;
            if (seasonNum && seriesIndex !== -1 && e?.target) {
                const seasonCopy = [...this.state.selectedPrograms[seriesIndex]?.seasonsAndEpisodes[seasonNum]].map((ep) => ({...ep, isSelected: cbValue }));
                const selectedProgramsCopy = [...this.state.selectedPrograms];
                selectedProgramsCopy[seriesIndex].seasonsAndEpisodes[seasonNum] = seasonCopy;
                this.setState({selectedPrograms: selectedProgramsCopy});

                // Visually uncheck the boxes, we've already set the isSelected. We could trigger updates on all the child components
                // but visually it flickers and not a good experience. Better to just update the checkboxes manually then remount the entire table.
                const epCheckboxWrapper = Array.from(document.getElementsByClassName(program.tmsid + '-season-' + seasonNum));
                epCheckboxWrapper.forEach((wrapper) => {
                    const cb = wrapper.querySelector('input');
                    if (cb.type === 'checkbox') {
                        cb.checked = cbValue;
                    }
                });
            }
        }
    }

    episodeCheckboxHandler = (e, row) => {
        const seasonNum = row?.original?.season_number || NO_SEASON;
        const seriesTmsId = row?.original?.series_tms_id;
        const epTmsId = row?.original?.tms_id;
        const seriesIndex = this.state.selectedPrograms.findIndex((program) => program.tmsid === seriesTmsId);
        if (seasonNum && seriesTmsId && epTmsId && seriesIndex !== -1) {
            const seasonCopy = [...this.state.selectedPrograms[seriesIndex]?.seasonsAndEpisodes[seasonNum]];
            const episodeIdx = seasonCopy.findIndex((ep) => ep.tms_id === epTmsId);
            if (episodeIdx !== -1) {
                seasonCopy[episodeIdx].isSelected = !seasonCopy[episodeIdx].isSelected;
                const selectedProgramsCopy = [...this.state.selectedPrograms];
                selectedProgramsCopy[seriesIndex].seasonsAndEpisodes[seasonNum] = seasonCopy;
                this.setState({selectedPrograms: selectedProgramsCopy});
            }
        }
    }

    getSelectedProgramIds = () => {
        const nonseriesTmsIds = this.state.selectedPrograms.filter(program => program?.gn_progtype !== PROGRAM_GNPROGTYPES.SERIES).map(program => program.tmsid);
        const episodeTmsIds = this.state.selectedPrograms.filter(program => program?.seasonsAndEpisodes && program?.uniqueSeasons?.length > 0).flatMap(program =>
            program.uniqueSeasons.flatMap(season => {
                let episodes = program.seasonsAndEpisodes[season];
                if ((!episodes) && program.seasonsAndEpisodes[NO_SEASON]) {
                    episodes = filterEpisodesBySeason(season, program.seasonsAndEpisodes[NO_SEASON]);
                }
                return episodes.filter(ep => ep.isSelected).map(ep => ep.tms_id)
            })
        );
        return nonseriesTmsIds.concat(episodeTmsIds);
    }

    handleStep1NextButton = () => {
        // Validate form and only let the user continue if there are no errors in step 1
        if (this.checkInvalidForm(null, null, true)) {
            if (this.state.errorMessages?.program) {
                return;
            }
        }
        const selectedPrograms = this.getSelectedProgramIds();
        if (selectedPrograms?.length > 0) {
            this.props.gnviewProgramAvailAddProgramIds(selectedPrograms);
            this.setState({stepOneCollapsed: true, stepTwoCollapsed: false});
        }
    }

    checkInvalidForm = (errorCode, tmsId, searching = false, seriesTMSID) => {
        const errorMessages = {
            ...(searching && this.state.selectedPrograms.length <= 0 && { program: PROGRAM_AVAILABILITY_FORM_VALIDATIONS.PROGRAM_MINIMUM }),
            ...(this.state.selectedPrograms.length === PROGRAM_AVAILABILITY_PROGRAM_MAXIMUM && { program: PROGRAM_AVAILABILITY_FORM_VALIDATIONS.PROGRAM_MAXIMUM }),
            ...(errorCode === 404 && tmsId && { program: tmsId + PROGRAM_AVAILABILITY_FORM_VALIDATIONS.PROGRAM_INVALID_TMSID }),
            ...(errorCode === 403 && tmsId && { program: PROGRAM_AVAILABILITY_FORM_VALIDATIONS.PROGRAM_UNAUTHORIZED + tmsId }),
            ...(checkProgramExists(this.state.selectedPrograms, tmsId) && { programDuplicate: PROGRAM_AVAILABILITY_FORM_VALIDATIONS.PROGRAM_DUPLICATE }),
            ...(tmsId && seriesTMSID && { episodeTMSIDSearch: PROGRAM_AVAILABILITY_FORM_VALIDATIONS.EPISODE_TMSID_SEARCH + seriesTMSID }),
            ...(this.getSelectedCatalogs().length <= 0 && { selectedCatalogs: PROGRAM_AVAILABILITY_FORM_VALIDATIONS.STREAMING_CATALOGS_MINIMUM })
        };
        this.setState({errorMessages});
        const errorsArray = Object.keys(errorMessages);
        // Edge case: we want to return false when ONLY the PROGRAM_MAXIMUM error occurs to allow users to still run and save searches (buttons are normally disabled when checkInvalidForm() returns true)
        if (errorsArray.length === 1 && errorMessages.program === PROGRAM_AVAILABILITY_FORM_VALIDATIONS.PROGRAM_MAXIMUM) {
            return false;
        } else {
            return errorsArray.length !== 0;
        }
    }

    // Step 2
    toggleStepTwo = () => {
        this.setState((prevState) => ({stepOneCollapsed: prevState.stepTwoCollapsed, stepTwoCollapsed: !prevState.stepTwoCollapsed}));
    }

    handleSelectAllCatalogs = (event) => {
        const checked = event.target.checked;
        const allCatalogsCopy = this.state.allStreamingCatalogs.map(catalog => ({...catalog, selected: checked}));
        this.setState({ allStreamingCatalogs: allCatalogsCopy }, () => this.checkInvalidForm());
    }

    handleSelectOneCatalog = (event, catalog) => {
        const catalogIndex = this.state.allStreamingCatalogs.findIndex((cat) => cat.id === catalog.id);
        if (catalogIndex !== -1) {
            const allCatalogsCopy = [...this.state.allStreamingCatalogs];
            allCatalogsCopy[catalogIndex].selected = event.target.checked;
            this.setState({ allStreamingCatalogs: allCatalogsCopy }, () => this.checkInvalidForm());
        }
    }

    getSelectedCatalogs = () => {
        return this.state.allStreamingCatalogs.filter(cat => cat.selected && cat.show);
    }

    handleStep2NextButton = () => {
        if (this.checkInvalidForm(null, null, true)) {
            return;
        }
        const catalogIds = this.getSelectedCatalogs().map((cat) => cat.id);
        if (catalogIds.length > 0) {
            this.props.gnviewProgramAvailAddCatalogIds(catalogIds);
        }
        const selectedPrograms = this.getSelectedProgramIds();
        if (selectedPrograms?.length > 0) {
            const queryObj = this.createSavedItemQueryObj();
            this.props.gnviewProgramAvailAddQueryObj(queryObj);
            this.props.gnviewProgramAvailAddProgramIds(selectedPrograms);
        }
        const searchId = queryString.parse(this.props.location?.search)['search_id'];
        const route = searchId ? `${ROUTES.PROGRAM_AVAILABILITIES}/result?search_id=${searchId}` : `${ROUTES.PROGRAM_AVAILABILITIES}/result`;
        this.props.history.push(route);
    }

    setFiltersAndCountries = () => {
        const filterTypes = this.createFilterTypes();
        const countriesList = this.createCountriesList();
        this.setState({ filterTypes, countriesList });
    }

    createFilterTypes = () => {
        const filterTypes = { all: 'All' };
        let idx = 0;
        while ((Object.keys(filterTypes).length < Object.keys(STEP_2_FILTER_TYPES).length) && (idx < this.state.allStreamingCatalogs.length)) {
            const purchaseTypes = this.state.allStreamingCatalogs[idx]?.purchase_types;
            purchaseTypes.forEach(type => {
                if (!Object.keys(filterTypes).includes(type)) {
                    filterTypes[type] = STEP_2_FILTER_TYPES[type]
                }
            })
            idx++
        }
        return filterTypes
    }

    createCountriesList = () => {
        const allCountries = this.state.allStreamingCatalogs.map(cat => cat.country_codes[0]);
        const filteredCountries = allCountries.filter((item, index) => allCountries.indexOf(item) === index);
        return convertCountryCodes(filteredCountries);
    }

    onSelectFilterTypes = (filterType) => {
        if (filterType === this.state.filterTypes.all) {
            this.setCatalogsFilter(true, ['all']);
        } else {
            const purchaseTypes = this.getPurchaseTypes(filterType);
            this.setCatalogsFilter(false, purchaseTypes);
        }
    }

    handleCountryChange = (countryName) => {
        const selectedCountry = this.state.countriesList.find(country => country.name === countryName);
        if (this.state.selectedFilter === (this.state.filterTypes.all)) {
            this.setState({ selectedCountry }, () => this.setCatalogsFilter(true, ['all']))
        } else {
            const purchaseTypes = this.getPurchaseTypes(this.state.selectedFilter);
            this.setState({ selectedCountry }, () => this.setCatalogsFilter(false, purchaseTypes));
        }
    }

    toggleCatalogShow = (catalog, toggle) => {
        catalog.show = toggle;
        return catalog;
    }

    setCatalogsFilter = (isAll, purchaseTypes) => {
        if (this.state.selectedCountry) {
            const streamingCatalogsCopy = this.state.allStreamingCatalogs.map(cat => {
                const toggle = (isAll && cat.country_codes.includes(this.state.selectedCountry.code)) || (cat.purchase_types.some(type => purchaseTypes.includes(type)) && cat.country_codes.includes(this.state.selectedCountry.code));
                return this.toggleCatalogShow(cat, toggle);
            });
            this.setState({ allStreamingCatalogs: streamingCatalogsCopy, selectedFilter: this.state.filterTypes[purchaseTypes[0]] });
        } else {
            const streamingCatalogsCopy = this.state.allStreamingCatalogs.map(cat => {
                const toggle = isAll || cat.purchase_types.some(type => purchaseTypes.includes(type));
                return this.toggleCatalogShow(cat, toggle);
            });
            this.setState({ allStreamingCatalogs: streamingCatalogsCopy, selectedFilter: this.state.filterTypes[purchaseTypes[0]] });
        }
    }

    getPurchaseTypes = (filterType) => {
        return filterType === 'TVOD' ? ['purchase', 'rental'] : [Object.keys(this.state.filterTypes).find(key => this.state.filterTypes[key] === filterType)];
    }

    getSelectedEpisodeIds = (seasonsAndEpisodes) => {
        if (seasonsAndEpisodes) {
            const seasonsAndEpisodesCopy = JSON.parse(JSON.stringify(seasonsAndEpisodes));
            const episodeIds = Object.values(seasonsAndEpisodesCopy).flat().map(episode => (episode.isSelected ? episode.tms_id : null)).filter(item => item !== null);
            return episodeIds;
        }
        // this is the case where a program is identified as a series, but the seasonsAndEpisodes are undefined
        return [];
    }

    createSavedItemQueryObj = () => {
        const selectedCatalogIds = this.state.allStreamingCatalogs.filter(cat => cat.selected).map(cat => cat.id);
        const selectedProgramIds = this.state.selectedPrograms.map(program => ({
            title: program?.title,
            parent_tms_id: program?.tms_id,
            ...(program.type === PROGRAM_TYPES.SERIES && program.subtype !== PROGRAM_SUBTYPES.EPISODE && {episode_tms_ids: this.getSelectedEpisodeIds(program.seasonsAndEpisodes)})
        }));
        return {
            program_ids: selectedProgramIds,
            catalog_ids: selectedCatalogIds
        };
    }

    createSavedItemBody = () => {
        const body = {
            title: this.state.existingSaveSearchTitle,
            search_type: MY_SAVED_ITEMS_TYPES.PROGRAM_AVAILABILITY,
            username: this.props.email,
            query_obj: this.createSavedItemQueryObj()
        };
        return body;
    }

    render() {
        const searchId = queryString.parse(this.props.location?.search)['search_id'];
        const savedItemBody = this.createSavedItemBody();
        return (
            <ErrorBoundary>
                <div className="gnview-program-availability-content">
                    <div className="filter-bar-container program-availability-search-container">
                        <div className='header-section'>
                            <div className="gnview-header-title">{PROGRAM_AVAILABILITY}</div>
                        </div>
                        <div className="gnview-last-updated">
                            <span className="gnview-last-updated__label">{PROGRAM_AVAILABILITY_GNVIEW_LAST_UPDATED}</span>
                            <span className="gnview-last-updated__datetime">{this.state.lastUpdated}</span>
                        </div>
                        <div className="progress-container">
                            <GNProgressBar label={PROGRAM_AVAILABILITY_PROGRESS_BAR.STEP_1} active={true} />
                            <GNProgressBar label={PROGRAM_AVAILABILITY_PROGRESS_BAR.STEP_2} active={!this.state.stepTwoCollapsed} />
                        </div>
                        <GNCard
                            title={PROGRAM_AVAILABILITY_STEPS.STEP_1}
                            color="black"
                            handleToggle={this.toggleStepOne}
                            isCollapsed={this.state.stepOneCollapsed}
                            subtitle={this.getSelectedProgramIds().length > 0 ? <StepCount count={numberWithCommas(this.getSelectedProgramIds().length)} text={this.getSelectedProgramIds().length > 1 ? "programs" : "program"} /> : null}>
                            <div className="step-1-container">
                                <ProgramAvailabilitySearchStep1
                                    handleChangeProgram={this.handleChangeProgram}
                                    handleProgramRemove={this.handleProgramRemove}
                                    isDataFromSavedSearch={this.state.isDataFromSavedSearch}
                                    tmsId={this.state.tmsId}
                                    handleTmsIdChange={this.handleTmsIdChange}
                                    handleTMSIdButtonClick={this.handleTMSIdButtonClick}
                                    disabled={this.state.disableProgramSearch}
                                    checkInvalidForm={this.checkInvalidForm}
                                    errorMessages={this.state.errorMessages}
                                    selectedPrograms={this.state.selectedPrograms}
                                    seriesCheckboxHandler={this.seriesCheckboxHandler}
                                    seasonCheckboxHandler={this.seasonCheckboxHandler}
                                    episodeCheckboxHandler={this.episodeCheckboxHandler}/>
                                <div className="button-container">
                                    <Button className={ClassNames("step-button", {"disabled": this.getSelectedProgramIds().length <= 0})} onClick={this.handleStep1NextButton}>{PROGRAM_AVAILABILITY_BUTTON_TEXT.STEP_1}</Button>
                                </div>
                            </div>
                        </GNCard>
                        <GNCard
                            title={PROGRAM_AVAILABILITY_STEPS.STEP_2}
                            color="black"
                            handleToggle={this.toggleStepTwo}
                            isCollapsed={this.state.stepTwoCollapsed}
                            subtitle={this.getSelectedCatalogs().length > 0 ? <StepCount count={this.getSelectedCatalogs().length.toString()} text={this.getSelectedCatalogs().length > 1 ? "catalogs" : "catalog"} /> : null} >
                            <div className="step-2-container">
                                <ProgramAvailabilitySearchStep2
                                    allStreamingCatalogs={this.state.allStreamingCatalogs}
                                    handleSelectAllCatalogs={this.handleSelectAllCatalogs}
                                    handleSelectOneCatalog={this.handleSelectOneCatalog}
                                    onSelectFilterTypes={this.onSelectFilterTypes}
                                    filterTypes={this.state.filterTypes}
                                    countriesList={this.state.countriesList}
                                    handleCountryChange={this.handleCountryChange} />
                                {this.state.errorMessages?.selectedCatalogs && <div className="gnview-error-msg">{this.state.errorMessages.selectedCatalogs}</div>}
                                <StepCount count={this.getSelectedCatalogs().length} text={`${this.getSelectedCatalogs().length !== 1 ? "catalogs" : "catalog"} selected`} />
                                <div className="button-container">
                                    <Button className="step-button" onClick={this.toggleStepTwo}>{PROGRAM_AVAILABILITY_BUTTON_TEXT.STEP_2_PREV}</Button>
                                    <Button className={ClassNames("step-button", {"disabled": this.getSelectedCatalogs().length <= 0})} onClick={this.handleStep2NextButton}>{PROGRAM_AVAILABILITY_BUTTON_TEXT.STEP_2_NEXT}</Button>
                                    <MySavedItemsButton
                                        body={savedItemBody}
                                        searchId={searchId}
                                        selectedPrograms={this.state.selectedPrograms}
                                        validationCallback={() => false}
                                    />
                                </div>
                            </div>
                        </GNCard>
                    </div>
                    {this.state.successModalShow && <SuccessMessageModal animation={false} message='Save Search Successful'/>}
                </div>
            </ErrorBoundary>
        )
    }
}

ProgramAvailabilitySearchView.propTypes = {
    email: PropTypes.string,
    gnviewCreateSavedSearch: PropTypes.func,
    gnviewGetProgramAvailabilitiesLastUpdated: PropTypes.func,
    gnviewGetProgramById: PropTypes.func,
    gnviewGetProgramDetails: PropTypes.func,
    gnviewGetProgramSeasonsAndEpisodes: PropTypes.func,
    gnviewGetSavedSearch: PropTypes.func,
    gnviewGetStreamingVideoCatalogs: PropTypes.func,
    gnviewProgramAvailAddProgramIds: PropTypes.func,
    gnviewProgramAvailAddQueryObj: PropTypes.func,
    gnviewProgramAvailAddCatalogIds: PropTypes.func,
    gnviewSendLogMessage: PropTypes.func,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
};

const mapStateToProps = state => {
    return {
        email: gvauthSelEmail(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewCreateSavedSearch,
        gnviewGetProgramAvailabilitiesLastUpdated,
        gnviewGetProgramById,
        gnviewGetProgramDetails,
        gnviewGetProgramSeasonsAndEpisodes,
        gnviewGetSavedSearch,
        gnviewGetStreamingVideoCatalogs,
        gnviewProgramAvailAddProgramIds,
        gnviewProgramAvailAddQueryObj,
        gnviewProgramAvailAddCatalogIds,
        gnviewSendLogMessage
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramAvailabilitySearchView);