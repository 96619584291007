import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { gvauthHasVideoDescriptorsEntitlements } from '../../../../reducers/GNVAuthReducer';
import GNCard from '../../../../components/common/gnCard/GNCard';
import {
    VIDEO_DESCRIPTORS_DESCRIPTION,
    VIDEO_DESCRIPTORS_NO_ENTITLEMENTS_LOCK_MSG,
    VIDEO_DESCRIPTORS_NO_ENTITLEMENTS_HEADER,
    VIDEO_DESCRIPTORS_NO_ENTITLEMENTS_MESSAGE,
    VIDEO_DESCRIPTORS_TYPES_ORDER,
    NO_VIDEO_DESCRIPTORS_MESSAGE,
    VIDEO_DESCRIPTORS,
    UNENTITLED_VIDEO_DESCRIPTOR_TYPES,
    UNENTITLED_VIDEO_DESCRIPTOR_SIZES,
    BLANK_VIDEO_DESCRIPTORS_COUNT,
    LEARN_MORE_LINK,
    CONTACT_US_LINK,
    ERROR
} from '../../../../constants/VideoDescriptors';
import "./VideoDescriptorsTab.scss";
import PropTypes from "prop-types";
import { gnviewGetProgramAnnotations } from '../../../../actions/GNViewActions';
import { gnviewSelProgramDetails } from '../../../../reducers/ProgramDetailsReducer';
import { gnviewSendLogMessage } from '../../../../services/GeneralService';
import GNListGroup from '../../../../components/common/gnListGroup/GNListGroup';
import groupBy from 'lodash.groupby';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Popover, Button } from 'react-bootstrap';
import ClassNames from 'classnames';
import sample from 'lodash.sample';
import range from 'lodash.range';
import teaserImage from '../../../../images/empire-ui.png';

export class VideoDescriptorsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            videoDescriptors: [],
            videoDescriptorsTypes: [],
            noData: false
        }
    }

    componentDidMount() {
        const body = [this.props?.programDetails?.tmsid];
        this.props.gnviewGetProgramAnnotations(body).then((response) => {
            const noData = response.result.length <= 0;
            const videoDescriptors = (this.props.hasVideoDescriptorsEntitlements && response.result) || this.addBlankDescriptors(response.result);
            const videoDescriptorsTypes = VIDEO_DESCRIPTORS_TYPES_ORDER.filter(type => response.result.some(vidDesc => (vidDesc.type?.name === type.name)) || response.result.some(vidDesc => type.name.includes(ERROR) && !vidDesc.type && !vidDesc.name));
            this.setState({ isLoading: false, videoDescriptors, videoDescriptorsTypes, noData });
        }).catch((error) => {
            this.props.gnviewSendLogMessage(`gnviewGetProgramAnnotations error: ${error.message}`, error, { tmsid: this.props?.programDetails?.tmsid });
            this.setState({ isLoading: false });
        });
    }

    addBlankDescriptors = (vidDescList) => {
        const groupedList = groupBy(vidDescList, (i) => i.type.name);
        const vidDescListCopy = Object.values(groupedList).map(list => {
            const nullDescriptor = this.createNullDescriptorObj(list[0]);
            if (list.length < 2) {
                list.unshift(nullDescriptor);
            } else {
                list[0] = nullDescriptor;
            }
            return list;
        }).flat();
        range(BLANK_VIDEO_DESCRIPTORS_COUNT).forEach(() => {
            const nullDescriptor = this.createNullDescriptorObj();
            vidDescListCopy.push(nullDescriptor);
        });
        return vidDescListCopy;
    }

    createNullDescriptorObj = (vidDesc) => {
        const type = vidDesc ? vidDesc.type : { name: sample(UNENTITLED_VIDEO_DESCRIPTOR_TYPES) };
        const size = sample(UNENTITLED_VIDEO_DESCRIPTOR_SIZES);
        return { id: null, type, size };
    }

    createListGroupProps = () => {
        const listGroupFields = this.state.videoDescriptorsTypes.map(type => ({ prop: type.name, label: this.renderVideoDescriptorType(type) }));
        const listGroupData = groupBy(this.state.videoDescriptors, (i) => (i.type?.name || VIDEO_DESCRIPTORS_TYPES_ORDER.find(type => type.name.includes(ERROR)).name));
        return { fields: listGroupFields, data: listGroupData };
    }

    sortVideoDescriptors = (videoDescriptors) => {
        return videoDescriptors.sort((a, b) => {
            if (a.name > b.name && a.weight === b.weight) {
                return 1;
            } else if (a.name < b.name && a.weight === b.weight) {
                return -1;
            } else {
                return 0;
            }
        });
    }

    renderCardHeader = () => {
        return (
            <div className='video-descriptors-card-header'>{VIDEO_DESCRIPTORS} {VIDEO_DESCRIPTORS_DESCRIPTION}</div>
        )
    }

    createTypePopover = (type) => (
        <Popover>
            <Popover.Content>
                <div className='popover-content-main-container'>
                    <div className='popover-definition'>DEFINITION</div>
                    <div className='popover-vid-desc-header'>{type.name}</div>
                    <div className='popover-content-body'>{type.description}</div>
                </div>
            </Popover.Content>
        </Popover>
    )

    renderVideoDescriptorType = (type) => {
        const popover = this.createTypePopover(type);
        return (
            <div>
                <OverlayTrigger trigger='hover' placement='top' overlay={popover} delay={500} >
                    <i className="fas fa-info-circle" />
                </OverlayTrigger>
                <span className='vid-desc-list-type'>{type.name}</span>
            </div>
        );
    }

    createDescriptorPopover = (vidDesc) => {
        const parentHierarchy = vidDesc?.parents?.reverse();
        return (
            <Popover>
                <Popover.Content>
                    <div className='popover-content-main-container'>
                        {vidDesc.definition && <div className='popover-definition'>DEFINITION</div>}
                        <div className='popover-vid-desc-header'>{vidDesc.name}</div>
                        <div className='popover-vid-desc-definition-body'>{vidDesc.definition}</div>
                        <div className='popover-description-tree-container'>
                            <div className='popover-description-tree-header'>DESCRIPTOR TREE</div>
                            <div className='parent-hierarchy'>
                                {parentHierarchy?.map(parent => {
                                    return (
                                        <div className='description-tree-item' key={parent.id}>
                                            <span className='parent-desc'>{parent.name}</span>
                                            <i className="fas fa-greater-than" />
                                        </div>
                                    );
                                })}
                                <div className={ClassNames('child-desc', `${this.props.programDetails.type}`)}>{vidDesc.name}</div>
                            </div>
                        </div>
                    </div>
                </Popover.Content>
            </Popover>
        );
    }

    renderVideoDescriptorItem = (item) => {
        if (item.id) {
            const popover = this.createDescriptorPopover(item);
            return (
                <div key={item.id} className={ClassNames('video-descriptor-container', `${this.props.programDetails.type}`)}>
                    {item.name || item.id}
                    <span className={ClassNames('video-descriptor-weight', `${this.props.programDetails.type}`)}>{item.weight}</span>
                    <OverlayTrigger trigger='hover' placement='top' overlay={popover} delay={500}><i className="fas fa-ellipsis-vertical" /></OverlayTrigger>
                </div>
            )
        } else {
            return (
                <div key={item.id} className={ClassNames('blank-video-descriptor-container', `${item.size}`)}>
                    <i className='fas fa-lock' />
                    <span className='restricted-desc'>Restricted</span>
                </div>
            )
        }
    }


    render() {
        return (
            <div className="gnview-video-descriptors-tab-content">
                <GNCard title={this.renderCardHeader()} headerOutsideOfCard={true}>
                    {!this.state.isLoading && !this.props.hasVideoDescriptorsEntitlements && <div className="unentitled-message">
                        <div className='teaser-content-body'>
                            <div className='lock-msg'><i className='fas fa-lock' />{VIDEO_DESCRIPTORS_NO_ENTITLEMENTS_LOCK_MSG}</div>
                            <div className='header'>{VIDEO_DESCRIPTORS_NO_ENTITLEMENTS_HEADER}</div>
                            <div className='message'>{VIDEO_DESCRIPTORS_NO_ENTITLEMENTS_MESSAGE}</div>
                            <div className='button-section'>
                                <Button href={LEARN_MORE_LINK} target='_blank' className='learn-more'>Learn More</Button>
                                <Button href={CONTACT_US_LINK} target='_blank' className='contact-us'>Contact Us</Button>
                            </div>
                        </div>
                        <img src={teaserImage} alt='Empire Hero' className='teaser-hero-img' />
                    </div>}
                    {!this.state.isLoading && !this.state.noData && <GNListGroup fields={this.createListGroupProps().fields} data={this.createListGroupProps().data} renderListItem={this.renderVideoDescriptorItem} sortListItems={this.sortVideoDescriptors} />}
                    {!this.state.isLoading && (!this.state.videoDescriptors.length || this.state.noData) && this.props.hasVideoDescriptorsEntitlements && <div className="no-descriptors-message">{NO_VIDEO_DESCRIPTORS_MESSAGE}</div>}
                </GNCard>
            </div>
        )
    }
}

VideoDescriptorsTab.propTypes = {
    hasVideoDescriptorsEntitlements: PropTypes.bool,
    programDetails: PropTypes.object,
    gnviewGetProgramAnnotations: PropTypes.func,
    gnviewSendLogMessage: PropTypes.func
};

const mapStateToProps = state => {
    return {
        hasVideoDescriptorsEntitlements: gvauthHasVideoDescriptorsEntitlements(state),
        programDetails: gnviewSelProgramDetails(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewGetProgramAnnotations,
        gnviewSendLogMessage
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoDescriptorsTab);