import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../config/Routes';
import { gnviewGetMarket } from '../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import GNClientTable from '../../../components/common/gnClientTable/GNClientTable';
import { MARKET_DETAILS, MARKET_COLUMN_HEADERS, MARKET_SUMMARY_FIELDS } from '../../../constants/Markets';
import './MarketsDetails.scss';
import LoadingSpinner from '../../../components/common/loadingSpinner/LoadingSpinner';
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import GNCard from '../../../components/common/gnCard/GNCard';
import GNListGroup from '../../../components/common/gnListGroup/GNListGroup';

export class MarketsDetailsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMarket: {},
            selectedMarketDetails: [],
            isLoading: true
        }
    }

    componentDidMount() {
        const marketId = this.props.match ? this.props.match.params.marketId : null;
        if (marketId) {
            this.props.gnviewGetMarket(marketId).then(
                (response) => {
                    const market = {
                        id: response.result.id,
                        name: response.result.name,
                        rank: response.result.rank,
                        year: response.result.year,
                        householdCount: response.result.household_count,
                        householdPercentage: response.result.household_percentage
                    };
                    this.setState({selectedMarket: market, selectedMarketDetails: response.result.stations, isLoading: false});
                    this.getMarketCancelToken = response.cancelToken;
                }, (error) => {
                    this.props.gnviewSendLogMessage(`gnviewGetMarket error: ${error.message}`, error, {marketId});
                    if (error?.response?.status === 404) {
                        // Replace the current bad URL with /not-found so when user navigates back it will be /markets not /markets/123
                        this.props.history.replace(ROUTES.NOT_FOUND);
                    }
                });
        }
    }

    componentWillUnmount() {
        if (this.cancelToken) {
            this.cancelToken.cancel();
        }
    }
    render() {
        return (
            <ErrorBoundary>
                <div className="market-details-main-container">
                    {!this.state.isLoading && <div className="filter-bar-container">
                        <div className="markets-top-section">
                            <div className="gnview-breadcrumbs">
                                <Link to={ROUTES.MARKET_DETAILS}>{MARKET_DETAILS}</Link>
                                <i className="fas fa-angle-right gnview-breadcrumbs-separator" />
                                <span>{this.state.selectedMarket.name}</span>
                            </div>
                            <span className="market-title">{this.state.selectedMarket.name} ({this.state.selectedMarket.rank})</span>
                        </div>
                    </div>}
                    {!this.state.isLoading && this.state.selectedMarketDetails && <div className="market-details-content">
                        <GNCard title='Summary'><GNListGroup fields={MARKET_SUMMARY_FIELDS} data={this.state.selectedMarket} /></GNCard>
                        <GNClientTable columns={MARKET_COLUMN_HEADERS} data={this.state.selectedMarketDetails} isLoading={this.state.isSearchMarketsLoading}/>
                    </div>}
                    {this.state.isLoading && <LoadingSpinner />}
                </div>
            </ErrorBoundary>
        )
    }
}

MarketsDetailsView.propTypes = {
    gnviewGetMarket: PropTypes.func,
    gnviewSendLogMessage: PropTypes.func,
    history: ReactRouterPropTypes.history,
    match: ReactRouterPropTypes.match.isRequired
};

const mapStateToProps = () => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewGetMarket,
        gnviewSendLogMessage
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketsDetailsView);
