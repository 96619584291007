import { ROUTES } from "./Routes";

export const MODULES = [
    {
        title: 'DETAILS',
        views: [
            {
                title: 'Programs',
                path: ROUTES.PROGRAM_DETAILS,
                icon: 'fas fa-tv'
            },
            {
                title: 'Sports',
                path: ROUTES.SPORTS,
                icon: 'fas fa-football',
                indented: true
            },
            {
                title: 'Advanced Search',
                path: ROUTES.ADVANCED_SEARCH,
                icon: 'fas fa-magnifying-glass',
                indented: true
            },
            {
                title: 'Channels',
                path: ROUTES.STATION_DETAILS,
                icon: 'fas fa-video'
            },
            {
                title: 'US Markets',
                path: ROUTES.MARKET_DETAILS,
                icon: 'fas fa-location-dot'
            }
        ]
    },
    {
        title: 'LINEAR DATA',
        views: [
            {
                title: 'Program Airings',
                path: ROUTES.PROGRAM_AIRINGS,
                icon: 'fas fa-clipboard-list'
            },
            {
                title: 'Schedules',
                path: ROUTES.SCHEDULES,
                icon: 'fas fa-clock'
            }
        ]
    },
    {
        title: 'STREAMING VIDEO',
        views: [
            {
                title: 'Program Availability',
                path: ROUTES.PROGRAM_AVAILABILITIES,
                icon: 'fas fa-house-laptop'
            }
        ]
    },
    {
        topBorder: true,
        views: [
            {
                title: 'My Saved Items',
                path: ROUTES.MY_SAVED_ITEMS,
                icon: 'fas fa-bookmark'
            },
            {
                title: 'Data Alerts',
                path: ROUTES.DATA_ALERTS,
                icon: 'fas fa-regular fa-bell bell-badge',
                notificationCounter: true
            }
        ]
    }
];