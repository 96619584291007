import React, { Component } from 'react';
import './ErrorBoundary.scss';
import PropTypes from "prop-types";
import EnvironmentService from "../../../services/EnvironmentService.js";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
            errorName: null,
            errorMessage: null
        };
        /* eslint-disable no-undef */
        this.airbrake = new EnvironmentService().getAirbrakeClient();
        /* eslint-enable no-undef */
    }

    componentDidCatch(error, errorInfo) {
        console.error('Error Boundary caught an error', error, JSON.stringify(errorInfo));
        this.setState({ hasError: true, errorName: error.name, errorMessage: error.message});
        this.airbrake.notify({
            error,
            params: {info: errorInfo, name: error.name, message: error.message}
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className='global-error-message'>
                    <h3 className='error-code'>Error: {this.state.errorName}</h3>
                    <h4 className='error-message'>{this.state.errorMessage}</h4>
                </div>
            )
        }
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node
};

export default ErrorBoundary;