import React, { useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MY_SAVED_ITEMS_SUBMENU_OPTIONS } from '../../../constants/MySavedItems';
import './MySavedItemsSubMenu.scss';
import PropTypes from 'prop-types';

export const MySavedItemsSubMenu = ({handleModal, savedItem}) => {
    const [showSubMenu, setShowSubMenu] = useState(false);
    const target = useRef(null);

    return (
        <div className='my-saved-items-submenu-container'>
            <div className='my-saved-items-submenu-button' onClick={() => setShowSubMenu(true)} ref={target}>
                <FontAwesomeIcon icon='ellipsis-h' />
                <Overlay
                    onHide={() => setShowSubMenu(false)}
                    placement='bottom'
                    rootClose={true}
                    show={showSubMenu}
                    target={target.current}
                    transition={false} >
                    <div className='sub-menu-body'>
                        {Object.values(MY_SAVED_ITEMS_SUBMENU_OPTIONS).map((option, idx) => {
                            const isUpdate = option.name === MY_SAVED_ITEMS_SUBMENU_OPTIONS.RENAME.name;
                            return (
                                <div className='submenu-option-item' key={idx} onClick={() => handleModal(true, savedItem, isUpdate)}>
                                    <FontAwesomeIcon className='submenu-icon' icon={option.icon} />
                                    <span className='submenu-label'>{option.name}</span>
                                </div>
                            )
                        })}
                    </div>
                </Overlay>
            </div>
        </div>
    )
};

MySavedItemsSubMenu.propTypes = {
    handleModal: PropTypes.func,
    savedItem: PropTypes.object
};

export default MySavedItemsSubMenu;