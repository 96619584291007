import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import get from 'lodash.get';
import GNInput from '../../common/gnInput/GNInput';
import GNDropdown from '../../common/gnDropdown/GNDropdown';
import isEqual from 'lodash.isequal';
import isEmpty from 'lodash.isempty';
import { Formik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../config/Routes';
import { Button, Form } from 'react-bootstrap';
import {
    gnviewSelAdvancedSearchSeriesIdFields,
    gnviewSelAdvancedSearchRootIdFields
} from '../../../reducers/AdvancedSearchReducer';
import { gvauthGetEntitledProgramLanguages } from '../../../reducers/GNVAuthReducer';
import { convertLanguageFromCode } from '../../../utils/GeneralUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
    SERIES_ID_INITIAL_TOUCHED,
    SERIES_ID_VALIDATION_SCHEMA,
    SERIES_ID_INITIAL_VALUES,
    ROOT_ID_INITIAL_TOUCHED,
    ROOT_ID_INITIAL_VALUES,
    ROOT_ID_VALIDATION_SCHEMA
} from '../../../constants/AdvancedSearchValidation';
import {
    REGULAR_EXPRESSION_DIGITS_LETTERS,
    ID_BUTTONS,
    ID_DEFAULT_PLACEHOLDERS,
    ID_LANGUAGE_SECTION,
    SERIES_ID_ID_SECTION,
    ROOT_ID_ID_SECTION
} from '../../../constants/AdvancedSearch';
import {
    gnviewAdvancedSearchFieldsClear,
    gnviewAdvancedSearchRootIdFieldsUpdate,
    gnviewAdvancedSearchSeriesIdFieldsUpdate,
    gnviewAdvancedSearchUpdateResultTableReset
} from '../../../actions/GNViewActions';
import './IDSearch.scss';

const IDSearch = ({ searchFromHeader = false, isRootId }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const searchRootIdFields = useSelector(gnviewSelAdvancedSearchRootIdFields);
    const searchSeriesIdFields = useSelector(gnviewSelAdvancedSearchSeriesIdFields);
    const searchFields = isRootId ? searchRootIdFields : searchSeriesIdFields;
    const ID_INITIAL_TOUCHED = isRootId ? ROOT_ID_INITIAL_TOUCHED : SERIES_ID_INITIAL_TOUCHED;
    const ID_VALIDATION_SCHEMA = isRootId ? ROOT_ID_VALIDATION_SCHEMA : SERIES_ID_VALIDATION_SCHEMA;
    const ID_INITIAL_VALUES = isRootId ? ROOT_ID_INITIAL_VALUES : SERIES_ID_INITIAL_VALUES;
    const ID_SECTION = isRootId ? ROOT_ID_ID_SECTION : SERIES_ID_ID_SECTION;
    const [selectedTitleLanguage, setSelectedTitleLanguage] = useState('');
    const [selectedDescriptionLanguage, setSelectedDescriptionLanguage] = useState('');

    const entitledLanguages = [
        ID_DEFAULT_PLACEHOLDERS.LANGUAGE,
        ...useSelector(gvauthGetEntitledProgramLanguages)
            .sort((a, b) => (a > b ? 1 : -1))
            .map((langCode) => ({ name: convertLanguageFromCode(langCode), value: langCode }))
    ];

    const handleSearchBtn = (values) => {
        if (isRootId) {
            dispatch(gnviewAdvancedSearchRootIdFieldsUpdate(values));
        } else {
            dispatch(gnviewAdvancedSearchSeriesIdFieldsUpdate(values));
        }

        if (location?.pathname !== ROUTES.ADVANCED_SEARCH_RESULT) {
            history.push(ROUTES.ADVANCED_SEARCH_RESULT);
        }

        dispatch(gnviewAdvancedSearchUpdateResultTableReset(true));
    };

    return (
        <Formik
            initialValues={searchFields}
            initialTouched={ID_INITIAL_TOUCHED}
            validationSchema={ID_VALIDATION_SCHEMA}
        >
            {({
                errors,
                handleSubmit,
                resetForm,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
                touched,
                values = ID_INITIAL_VALUES
            }) => {
                const validateForm = (formName) =>
                    isSubmitting || get(touched, formName) ? !get(errors, formName) : true;

                const handleFormChange = (formName, value) => {
                    if (!touched[formName]) {
                        setFieldTouched(formName, true);
                    }

                    setFieldValue(formName, value);
                };

                const handleResetBtn = () => {
                    dispatch(gnviewAdvancedSearchFieldsClear());
                    resetForm({ values: ID_INITIAL_VALUES });
                };

                const isIdEntered = () => {
                    const key = ID_SECTION.VALIDATION_LABEL;
                    return !isEqual(ID_INITIAL_VALUES[key], values[key]) && isEmpty(errors[key]);
                };

                if (selectedTitleLanguage !== values[ID_LANGUAGE_SECTION.TITLE_LANG.VALIDATION_LABEL]) {
                    setSelectedTitleLanguage(values[ID_LANGUAGE_SECTION.TITLE_LANG.VALIDATION_LABEL]);
                }

                if (selectedDescriptionLanguage !== values[ID_LANGUAGE_SECTION.DESCRIPTION_LANG.VALIDATION_LABEL]) {
                    setSelectedDescriptionLanguage(values[ID_LANGUAGE_SECTION.DESCRIPTION_LANG.VALIDATION_LABEL]);
                }

                return (
                    <Form noValidate onSubmit={handleSubmit} className="idSection_form">
                        {/* ID */}
                        <div className='idSections'>
                            <div className='idSections__section'>{ID_SECTION.LABEL}</div>
                            <div className='idSections__section'>
                                <GNInput
                                    handleChange={(item) => {
                                        if (REGULAR_EXPRESSION_DIGITS_LETTERS.test(item)) {
                                            handleFormChange(ID_SECTION.VALIDATION_LABEL, item);
                                        }
                                    }}
                                    label={ID_SECTION.LABEL}
                                    placeholder={ID_SECTION.PLACEHOLDER}
                                    validateInput={() => validateForm(ID_SECTION.VALIDATION_LABEL)}
                                    validationText={errors[ID_SECTION.VALIDATION_LABEL]}
                                    value={values[ID_SECTION.VALIDATION_LABEL]}
                                />
                            </div>
                        </div>

                        {/* Languages */}
                        {entitledLanguages?.length > 1 && (
                            <div className='idSections'>
                                <div className='idSections__section'>{ID_LANGUAGE_SECTION.NAME}</div>
                                <div className='idSections__section-dropdown'>
                                    <GNDropdown
                                        label={ID_LANGUAGE_SECTION.TITLE_LANG.LABEL}
                                        placeholder={ID_LANGUAGE_SECTION.TITLE_LANG.PLACEHOLDER}
                                        options={entitledLanguages}
                                        value={values[ID_LANGUAGE_SECTION.TITLE_LANG.VALIDATION_LABEL]}
                                        handleChange={(item) => {
                                            setSelectedTitleLanguage(item);
                                            handleFormChange(ID_LANGUAGE_SECTION.TITLE_LANG.VALIDATION_LABEL, item);
                                        }}
                                    />
                                    <GNDropdown
                                        label={ID_LANGUAGE_SECTION.DESCRIPTION_LANG.LABEL}
                                        placeholder={ID_LANGUAGE_SECTION.DESCRIPTION_LANG.PLACEHOLDER}
                                        options={entitledLanguages}
                                        value={values[ID_LANGUAGE_SECTION.DESCRIPTION_LANG.VALIDATION_LABEL]}
                                        handleChange={(item) => {
                                            setSelectedDescriptionLanguage(item);
                                            handleFormChange(
                                                ID_LANGUAGE_SECTION.DESCRIPTION_LANG.VALIDATION_LABEL,
                                                item
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        {/* Footer */}
                        <div
                            className={ClassNames(
                                { idSectionFooter: !searchFromHeader },
                                { idSectionFooterResultView: searchFromHeader }
                            )}
                        >
                            <div className='idSectionFooter__btnContainer'>
                                <Button onClick={handleResetBtn}>{ID_BUTTONS.RESET}</Button>
                                <Button onClick={() => handleSearchBtn(values)} disabled={!isIdEntered()}>
                                    {!searchFromHeader ? ID_BUTTONS.SEARCH : ID_BUTTONS.UPDATE}
                                </Button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

IDSearch.propTypes = {
    isRootId: PropTypes.bool.isRequired,
    searchFromHeader: PropTypes.bool
};

export default IDSearch;
