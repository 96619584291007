import { API_ENDPOINTS, getSportsScheduleResultsURL } from '../config/Api';
import apiService from '../services/ApiService';
import { SPORTS_API_LANGUAGE } from '../constants/Sports';
import {
    GNVIEW_SPORTS_SET_LEAGUE_DATA,
    GNVIEW_SPORTS_SET_LEAGUE_ID,
    GNVIEW_SPORTS_SET_MATCH_DATA,
    GNVIEW_SPORTS_SET_SPORT_ID,
    GNVIEW_SPORTS_SET_START_DATE,
    GNVIEW_SPORTS_SET_TEAM_ID
} from './ActionTypes';

export const sportsSetLeagueData = (leagueData) => ({
    type: GNVIEW_SPORTS_SET_LEAGUE_DATA,
    payload: { leagueData }
});

export const sportsSetLeagueId = (leagueId) => ({
    type: GNVIEW_SPORTS_SET_LEAGUE_ID,
    payload: { leagueId }
});

export const sportsSetMatchData = (matchData) => ({
    type: GNVIEW_SPORTS_SET_MATCH_DATA,
    payload: { matchData }
});

export const sportsSetSportId = (sportId) => ({
    type: GNVIEW_SPORTS_SET_SPORT_ID,
    payload: { sportId }
});

export const sportsSetStartDate = (startDate) => ({
    type: GNVIEW_SPORTS_SET_START_DATE,
    payload: { startDate }
});

export const sportsSetTeamId = (teamId) => ({
    type: GNVIEW_SPORTS_SET_TEAM_ID,
    payload: { teamId }
});

export const sportsGetRelatedPrograms = (matchId) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.SPORTS_CONNECTED_API_BASE}/${API_ENDPOINTS.SPORTS_ENTITIES}/${matchId}/${API_ENDPOINTS.SPORTS_RELATED_PROGRAMS}`, {}, 'get');
    }
};

export const sportsGetScheduleResults = (timeFrom, timeTo, sportId, leagueId, teamId) => {
    return () => {
        const queryParams = {
            timeFrom,
            timeTo,
            language: SPORTS_API_LANGUAGE
        };
        return apiService.makeApiCall(getSportsScheduleResultsURL(sportId, leagueId, teamId), queryParams, 'get');
    }
};

export const sportsGetSportsOptions = () => {
    const queryParams = {
        language: SPORTS_API_LANGUAGE
    };
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.SPORTS_API_BASE}/sports`, queryParams, 'get');
    }
};

export const sportsGetLeaguesOptions = (sportsId) => {
    return () => {
        const queryParams = {
            language: SPORTS_API_LANGUAGE
        };
        return apiService.makeApiCall(`${API_ENDPOINTS.SPORTS_API_BASE}/sports/${sportsId}/leagues`, queryParams, 'get');
    };
};

export const sportsGetTeamsOptions = (leagueId) => {
    return () => {
        const queryParams = {
            language: SPORTS_API_LANGUAGE
        };
        return apiService.makeApiCall(`${API_ENDPOINTS.SPORTS_API_BASE}/leagues/${leagueId}/teams`, queryParams, 'get');
    };
};
