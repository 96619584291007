import {
    SPORTS_PLACEHOLDER,
    SPORTS_RELATED_SPORTS_EVENT_TYPES,
    ALL_LANGUAGES_OPTION,
    DROPDOWN_LABEL,
    SPORTS_SEARCH_DROPDOWN_OPTIONS
} from '../constants/Sports';
import { convertLanguageFromCode } from './GeneralUtils';
import groupBy from 'lodash.groupby';
import sortby from 'lodash.sortby';
import uniq from 'lodash.uniq';
import moment from 'moment-timezone';

export const displayTextWithPlaceholder = (text) => {
    return text || SPORTS_PLACEHOLDER;
};

/**
 * The GDS /schedule-results response has sports, leagues, leagueSeasons, overalls, phases and matches.
 * Inside a match object, it has `relatedSportsEvents` which contains it's linkage to leagues, leagueSeasons etc.
 * First, we loop through and map each of the match's league, sport and league season and attach it to the match itself
 * First we group matches by league, then sort by Sport type.
 * @param {[object]} matches - array of matches
 * @param {[object]} sports - array of sports
 * @param {[object]} leagues - array of leagues
 * @param {[object]} leagueSeasons - array of league seasons
 * @returns {object}
 * Example response:
{
    [Sport ID: {
        [League ID]: [
            {
                ...match
                league: {
                    ...
                },
                leagueSeason: {
                    ...
                },
                sport: {
                   ...
                }
            }
        ]
    }
}
 */
export const organizeBySportsAndLeague = (matches, sports, leagues, leagueSeasons) => {
    const result = {};
    if (!matches || !sports || !leagues || !leagueSeasons) {
        return result;
    }
    const matchesCopy = [...matches].map((m) => {
        const { sportId, ...match } = m;
        const leagueId = m?.relatedSportsEvents?.find((e) => e.type === SPORTS_RELATED_SPORTS_EVENT_TYPES.LEAGUE)?.id
        const leagueSeasonId = m?.relatedSportsEvents?.find((e) => e.type === SPORTS_RELATED_SPORTS_EVENT_TYPES.LEAGUE_SEASON)?.id
        return {
            ...match,
            league: leagues?.find((l) => l.id === leagueId),
            leagueSeason: leagueSeasons?.find((ls) => ls.id === leagueSeasonId),
            sport: sports.find((s) => s.id === sportId)
        }
    });
    const groupedByLeague = groupBy(matchesCopy, (m) => m.league.id);
    Object.keys(groupedByLeague).forEach((league) => {
        const matchesForThisLeague = groupedByLeague[league]
        const leagueInfo = leagues.find((l) => l.id === league);
        if (result[leagueInfo.sportId]) {
            result[leagueInfo.sportId][leagueInfo.id] = matchesForThisLeague
        } else {
            result[leagueInfo.sportId] = {
                [leagueInfo.id]: matchesForThisLeague
            }
        }
    });
    return result;
}

/**
 * Format dropdown options and add all selection option
 * @param {Array.<Object>} options - Sport options, League Options from API
 * @param {string} dropdownType - Sport, League
 * @returns {Array.<Object>}
 */
export const formatDropdownOptions = (options, dropdownType) => {
    const result = [];
    if (!options) {
        return result;
    }
    const formattedArray = options.map((option) => ({ name: option.names.default, value: option.id }));
    const sortedArray = sortby(formattedArray, (s) => s.name);

    if (dropdownType === DROPDOWN_LABEL.SPORT) {
        result.push(SPORTS_SEARCH_DROPDOWN_OPTIONS.SPORT);
    } else if (dropdownType === DROPDOWN_LABEL.LEAGUE) {
        result.push(SPORTS_SEARCH_DROPDOWN_OPTIONS.LEAGUE);
    } else if (dropdownType === DROPDOWN_LABEL.TEAM) {
        result.push(SPORTS_SEARCH_DROPDOWN_OPTIONS.TEAM)
    }

    return [...result, ...sortedArray]
};

export const filterRelatedPrograms = (programs) => {
    if (!programs || programs.length === 0) {
        return [];
    }
    return programs.filter(p => (p.program.type === 'root' && p.program.supplier === 'tms'));
};

// Return deduped, alphabetical array of {name, value} objects based on user's entitled languages and title languages available in array of programs
// For example, if user is not entitled to Spanish (but there are Spanish title language programs), do not list Spanish as an option.
// If there are no programs with title language of Dutch (but user is entitled to Dutch language programs), do not list Dutch as an option.
export const formatSportsTitleLanguageOptions = (entitledLanguages, programData) => {
    if (entitledLanguages.length === 0) {
        return [ALL_LANGUAGES_OPTION];
    }
    const programTitleLanguages = uniq(programData
        .map(p => p.titles[0].language)
        .filter(l => entitledLanguages.includes(l)))
        .map((langCode) => ({ name: convertLanguageFromCode(langCode), value: langCode }));
    const sortedProgramTitleLanguages = sortby(programTitleLanguages, l => l.name);
    return [ALL_LANGUAGES_OPTION, ...sortedProgramTitleLanguages];
};

// Return deduped, alphabetical array of {name, value} objects based on user's entitled languages and description languages available in array of programs
// For example, if user is not entitled to Spanish (but there are Spanish description language programs), do not list Spanish as an option.
// If there are no programs with description language of Dutch (but user is entitled to Dutch language programs), do not list Dutch as an option.
export const formatSportsDescriptionLanguageOptions = (entitledLanguages, programData) => {
    if (entitledLanguages.length === 0) {
        return [ALL_LANGUAGES_OPTION];
    }
    const programDescriptionLanguages = uniq(programData
        .map(p => p.description_language)
        .filter(l => entitledLanguages.includes(l)))
        .map((langCode) => ({ name: convertLanguageFromCode(langCode), value: langCode }));
    const sortedProgramDescriptionLanguages = sortby(programDescriptionLanguages, l => l.name);
    return [ALL_LANGUAGES_OPTION, ...sortedProgramDescriptionLanguages];
};

// If description language is selected (but not title language), filter only programs with matching description language.
// If title language is selected (but not description language), filter only programs with matching title language.
// If both description language and title language are selected, filter only programs with matching description language AND title language.
export const filterProgramsByLanguage = (selectedDescriptionLanguage, selectedTitleLanguage, programs) => {
    if (selectedDescriptionLanguage.length > 0 && selectedTitleLanguage.length === 0) {
        return programs.filter(p => p.description_language === selectedDescriptionLanguage);
    } else if (selectedTitleLanguage.length > 0 && selectedDescriptionLanguage.length === 0) {
        return programs.filter(p => p.titles[0].language === selectedTitleLanguage);
    } else if (selectedDescriptionLanguage.length > 0 && selectedTitleLanguage.length > 0) {
        return programs.filter(p => p.description_language === selectedDescriptionLanguage && p.titles[0].language === selectedTitleLanguage);
    }
    return programs;
};


export const formatDateOptions = (firstDate) => {
    return [
        moment(firstDate),
        moment(firstDate).add(1, 'days'),
        moment(firstDate).add(2, 'days'),
        moment(firstDate).add(3, 'days'),
        moment(firstDate).add(4, 'days'),
        moment(firstDate).add(5, 'days'),
        moment(firstDate).add(6, 'days')
    ];
};

export const formatLeagueName = (league) => {
    return league?.names?.default;
}

export const formatSportName = (match) => {
    return match?.sport?.names?.default;
}
