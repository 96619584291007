/* eslint react/prop-types: 0 */
/* eslint react/display-name: 0 */
import React from 'react';
import {ROUTES} from '../config/Routes';
import {Link} from 'react-router-dom';
import { array, object, string } from 'yup';

export const CHANNEL_LABEL = 'Channels:';
export const CHANNEL_NAME = 'Channel Name';
export const CHANNEL_CALL_SIGN = 'Channel Call Sign';
export const CHANNEL_ID = 'Channel ID';
export const CHANNEL_DETAILS = 'Channel Details';
export const CHANNEL_SEARCH_TYPES_LABEL = 'Find a Channel by';
export const CHANNEL_SEARCH_TYPES = {
    channelName: CHANNEL_NAME,
    channelCallSign: CHANNEL_CALL_SIGN,
    channelId: CHANNEL_ID
};
export const SEARCH_CHANNELS_CALL_SIGN = 'call_sign';
export const RECENT_SEARCHES_HEADER = 'Recently Viewed Channels';
export const UNSUBSCRIBE_MODAL = {
    TITLE: 'Unsubscribe from this channel?',
    TEXT: 'You will no longer receive data alerts from this channel.',
    UNSUBSCRIBE: 'UNSUBSCRIBE',
    CANCEL: 'CANCEL'
};
export const INPUT_PLACEHOLDER_OPTIONS = {
    channelName: 'Enter Channel Name',
    channelCallSign: 'Enter Channel Call Sign',
    channelId: 'Enter Channel ID'
};

export const STATION = {
    id: 'Station ID',
    name: 'Station Name',
    call_sign: 'Call sign',
    affiliation: 'Station Affiliation',
    type: 'Type',
    dma: 'DMA',
    license_location: 'License Location',
    website: 'Website',
    status: 'Status'
};

export const STATION_RELATIONSHIPS_COLUMN_HEADERS = [
    { accessor: 'id', Header: 'Channel ID', Cell: ({row}) => {
        const url = `${ROUTES.STATION_DETAILS}/${row.values.id}`;
        return <span><Link to={url}>{row.values.id}</Link></span>
    }},
    { accessor: 'type', Header: 'Relationship Type'}
];

export const STATION_AFFILIATIONS_COLUMN_HEADERS = [
    { accessor: 'id', Header: 'Channel ID', Cell: ({row}) => {
        const url = `${ROUTES.STATION_DETAILS}/${row.values.id}`;
        return <span><Link to={url}>{row.values.id}</Link></span>
    }},
    { accessor: 'name', Header: 'Channel Name'}
];

export const STATION_SUMMARY_FIELDS = [
    { prop: 'name', label: 'Channel Name' },
    { prop: 'id', label: 'Channel ID' },
    { prop: 'number', label: 'Number' },
    { prop: 'call_sign', label: 'Call Sign' },
    { prop: 'type', label: 'Type'},
    { prop: 'dma', label: 'DMA' },
    { prop: 'license_location', label: 'License Location' },
    { prop: 'website', label: 'Website'},
    { prop: 'status', label: 'Status' },
    { prop: 'timezone', label: 'Timezone' },
    { prop: 'timezone_name', label: 'Timezone Name' },
    { prop: 'country_codes', label: 'Countries of Coverage'},
    { prop: 'signal_type', label: 'Video Quality'},
    { prop: 'attributes', label: 'Attributes'},
    { prop: 'edLanguagesConverted', label: 'Ed Languages'},
    { prop: 'broadcastLanguagesConverted', label: 'Broadcast Languages'},
    { prop: 'location', label: 'Location'}
];

export const UNENTITLED_STATION_SUMMARY_FIELDS = [
    { prop: 'name', label: 'Channel Name' },
    { prop: 'id', label: 'Channel ID' },
    { prop: 'country_codes', label: 'Countries of Coverage'}
];

export const STATION_TYPES = [
    'Cable Only',
    'Digital Broadcast Network',
    'Full Power Broadcast',
    'Low Power Broadcast',
    'Network',
    'Satellite',
    'Streaming'
];

export const CHANNEL_DETAILS_TABS = {
    overview: 'Overview',
    images: 'Images'
};

export const SUBSCRIBE_TO_STATION_MODAL = {
    MODAL_TITLE: 'Subscribe to Channel Alerts'
}

export const SUBSCRIBE_TO_ALERTS_LABELS = {
    CHANNEL_NAME: 'Channel Name',
    SOURCE_TYPE: 'Source of Channel',
    DISTRIBUTION_PLATFORMS: 'Distribution Platforms',
    DISTRIBUTION_PLATFORMS_PLACEHOLDER: 'Type the platforms this channel will be visible on E.g. Roku, YouTube TV, Dish',
    LABELS: 'Labels',
    LABELS_PLACEHOLDER: 'Type channel category for easier filtering of your channels E.g. UK, FAST, International, etc.',
    ORIGINATOR: 'Originator',
    LICENSEE: 'Licensee',
    UNSUBSCRIBE_MODAL_TITLE: 'Unsubscribe to this channel?',
    UNSUBSCRIBE_MODAL_BODY: 'You will no longer receive data alerts for this channel.',
    CANCEL: 'CANCEL',
    UNSUBSCRIBE: 'UNSUBSCRIBE',
    UNSUBSCRIPTION_ERROR: 'An error occurred while trying to unsubscribe to this channel. Please try again.',
    SUBSCRIPTION_ERROR: 'An error occurred while trying to subscribe to this channel. Please try again.'
};

export const SUBSCRIBE_TO_CHANNEL_FORM_FIELDS = {
    CHANNEL_NAME: 'prgSvcID',
    SOURCE_TYPE: 'sourceType',
    DISTRIBUTION_PLATFORMS: 'distributionPlatforms',
    LABELS: 'labels'
};

export const getSubscribeToChannelFormInitialValues = () => ({
    [SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.SOURCE_TYPE]: SUBSCRIBE_TO_ALERTS_LABELS.ORIGINATOR,
    [SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.DISTRIBUTION_PLATFORMS]: [],
    [SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.LABELS]: []
});

export const SOURCE_TYPES_MAPPING = {
    [SUBSCRIBE_TO_ALERTS_LABELS.ORIGINATOR]: 1,
    [SUBSCRIBE_TO_ALERTS_LABELS.LICENSEE]: 2
}

export const SUBSCRIBE_TO_CHANNEL_FORM_INITIAL_TOUCHED = {
    [SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.SOURCE_TYPE]: false,
    [SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.DISTRIBUTION_PLATFORMS]: false,
    [SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.LABELS]: false
};

export const SUBSCRIBE_TO_CHANNEL_FORM_VALIDATION_SCHEMA = object({
    [SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.SOURCE_TYPE]: string().oneOf([SUBSCRIBE_TO_ALERTS_LABELS.ORIGINATOR, SUBSCRIBE_TO_ALERTS_LABELS.LICENSEE]),
    [SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.DISTRIBUTION_PLATFORMS]: array().of(string()),
    [SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.LABELS]: array().of(string())
        .test(SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.LABELS, SUBSCRIPTIONS_LABELS_LIMIT_ERROR, function(labels, {createError, path}) {
            if (labels?.length > 5) {
                return createError({message: SUBSCRIPTIONS_LABELS_LIMIT_ERROR, path})
            }
            return true
        })
});

export const SUBSCRIPTIONS_LIMIT = 10;
export const SUBSCRIPTIONS_LIMIT_TOOLTIP = `Maximum of ${SUBSCRIPTIONS_LIMIT} channels can be tracked. Unsubscribe from an existing channel to add a new one.`;
export const SUBSCRIPTIONS_TURN_ON_TOOLTIP = 'Turn on to receive data alerts for this channel.';
export const SUBSCRIPTIONS_LABELS_LIMIT_ERROR = 'You have reached the maximum limit of 5 labels. To add a new label, please delete an existing one.'
