import React, {Component} from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./GNModal.scss";

export class GNModal extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Modal {...this.props} show={this.props.show} aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="gnview-modal">
                <FontAwesomeIcon icon='xmark' className={'close-icon'} onClick={this.props.onHide}/>
                {this.props.title && <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>}
                {this.props.children}
            </Modal>
        );
    }
}

GNModal.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
    onHide: PropTypes.func,
    children: PropTypes.node
};

export default GNModal;