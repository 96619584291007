import React from 'react';
import "./StepCount.scss";
import PropTypes from 'prop-types';

export const StepCount = (props) => {
    return (
        <span className="gnview-step-count">
            {props.count} <span className="gnview-step-text">{props.text}</span>
        </span>
    )
}

StepCount.propTypes = {
    count: PropTypes.string,
    text: PropTypes.string
};

export default StepCount;