import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Formik } from 'formik';
import {
    APPLIES_TO,
    PROGRAM_ATTRIBUTES_BUTTONS,
    PROGRAM_ATTRIBUTES_DEFAULT_PLACEHOLDERS,
    PROGRAM_ATTRIBUTES_GENRES_SECTION,
    PROGRAM_ATTRIBUTES_PRODUCTION_COMPANIES_SECTION,
    PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION,
    PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION,
    PROGRAM_ATTRIBUTES_RELEASE_YEAR_SECTION,
    PROGRAM_ATTRIBUTES_SECTION_HEADERS,
    PROGRAM_ATTRIBUTES_SPORTS,
    PROGRAM_ATTRIBUTES_TYPES,
    PROGRAM_ATTRIBUTES_TITLE_SECTION
} from '../../../constants/AdvancedSearch';
import './ProgramAttributes.scss';
import {
    PROGRAM_ATTRIBUTES_INITIAL_TOUCHED,
    PROGRAM_ATTRIBUTES_INITIAL_VALUES,
    PROGRAM_ATTRIBUTES_VALIDATION_SCHEMA
} from '../../../constants/AdvancedSearchValidation';
import get from 'lodash.get';
import {
    gnviewGetSportProgramFieldValues,
    gnviewGetProgramFieldValues,
    gnviewAdvancedSearchFieldsUpdate,
    gnviewAdvancedSearchFieldsClear,
    gnviewAdvancedSearchUpdateResultTableReset
} from '../../../actions/GNViewActions';
import LoadingSpinner from '../../common/loadingSpinner/LoadingSpinner';
import GNInput from '../../common/gnInput/GNInput';
import TypeaheadFilter from '../../common/typeaheadFilter/TypeaheadFilter';
import AnyOrAllToggle from './anyOrAllToggle/AnyOrAllToggle';
import Languages from './universalCriteria/Languages';
import GNBadge from '../../common/gnBadge/GNBadge';
import GNDropdown from '../../common/gnDropdown/GNDropdown';
import Types from './programTypeCriteria/Types';
import NetworksFilter from './programTypeCriteria/NetworksFilter';
import ProductionCompanyFilter from './programTypeCriteria/ProductionCompanyFilter';
import { gnviewSelAdvancedSearchProgramAttributesFields } from '../../../reducers/AdvancedSearchReducer';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../config/Routes';
import isEqual from 'lodash.isequal';
import { parseUrlToParams } from '../../../utils/ProgramUtils';
import { gvauthSelFeatureFlags } from '../../../reducers/GNVAuthReducer';
import { FEATURE_FLAGS } from '../../../constants/App';

const ProgramAttributes = ({searchFromHeader}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [genresList, setGenresList] = useState([]);
    const [sportsList, setSportsList] = useState([]);
    const [isProgramFieldValuesLoading, setProgramFieldValuesLoading] = useState(true);
    const searchFields = useSelector(gnviewSelAdvancedSearchProgramAttributesFields);
    const featureFlags = useSelector(gvauthSelFeatureFlags);
    const userSelectedMappingFFEnabled = featureFlags?.includes(FEATURE_FLAGS.USER_SELECTED_MAPPING);

    useEffect(() => {
        if (!userSelectedMappingFFEnabled || !location.search) {
            return;
        }
        const parsedSearchParams = parseUrlToParams(location.search)
        handleSearch(searchFields, parsedSearchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(gnviewGetProgramFieldValues(PROGRAM_ATTRIBUTES_GENRES_SECTION.PROGRAM_FIELD_VALUES)).then(
            (response) => {
                if (response?.result?.length > 0) {
                    const genres = response?.result?.map((genre) => genre.value);
                    setGenresList([...genres]);
                }
            }
        );
        dispatch(
            gnviewGetSportProgramFieldValues(
                PROGRAM_ATTRIBUTES_SPORTS.PROGRAM_FIELD,
                PROGRAM_ATTRIBUTES_SPORTS.PROGRAM_TYPES
            )
        ).then((response) => {
            if (response?.result?.length > 0) {
                const sports = response?.result?.map((sport) => {
                    return {
                        name: sport.value,
                        value: sport.value
                    };
                });
                setSportsList([PROGRAM_ATTRIBUTES_DEFAULT_PLACEHOLDERS.SPORT, ...sports]);
            }
            setProgramFieldValuesLoading(false);
        });
    }, [dispatch, setGenresList]);

    const handleSearch = useCallback((values, urlSearchParams = {}) => {
        dispatch(gnviewAdvancedSearchFieldsUpdate({...values, ...(userSelectedMappingFFEnabled && urlSearchParams ? {...urlSearchParams} : {})}));
        // If not on the result page, reroute there
        if (location?.pathname !== ROUTES.ADVANCED_SEARCH_RESULT) {
            history.push(`${ROUTES.ADVANCED_SEARCH_RESULT}${userSelectedMappingFFEnabled && urlSearchParams ? `?${queryString.stringify(urlSearchParams)}` : ''}`);
        }
        // Tell table to reset
        dispatch(gnviewAdvancedSearchUpdateResultTableReset(true));
    }, [dispatch, history, location?.pathname, userSelectedMappingFFEnabled]);

    return isProgramFieldValuesLoading ? (
        <LoadingSpinner />
    ) : (
        <Formik
            initialValues={searchFields}
            initialTouched={PROGRAM_ATTRIBUTES_INITIAL_TOUCHED}
            validationSchema={PROGRAM_ATTRIBUTES_VALIDATION_SCHEMA}
        >
            {({ errors, handleSubmit, resetForm, isSubmitting, setFieldTouched, setFieldValue, touched, values = PROGRAM_ATTRIBUTES_INITIAL_VALUES }) => {
                const validateForm = (formName) =>
                    isSubmitting || get(touched, formName) ? !get(errors, formName) : true;

                const handleFormChange = (formName, value) => {
                    if (!touched[formName]) {
                        setFieldTouched(formName, true);
                    }

                    setFieldValue(formName, value);
                };

                const handleResetBtn = () => {
                    dispatch(gnviewAdvancedSearchFieldsClear());
                    resetForm({values: PROGRAM_ATTRIBUTES_INITIAL_VALUES});
                };

                const hasCompletedFields = () => {
                    let completedField = false;
                    for (const [key, val] of Object.entries(values)) {
                        if (
                            key === PROGRAM_ATTRIBUTES_GENRES_SECTION.IS_ANY_SELECTED ||
                            key === PROGRAM_ATTRIBUTES_PRODUCTION_COMPANIES_SECTION.IS_ANY_SELECTED ||
                            key === PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION.VALIDATION_LABEL_RADIO ||
                            key === PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION.VALIDATION_LABEL_RADIO
                        ) {
                            continue;
                        } else if (!isEqual(PROGRAM_ATTRIBUTES_INITIAL_VALUES[key], val)) { // Compare to initial values
                            completedField = true;
                            break;
                        }
                    }
                    return completedField;
                };

                const enableSportsTypeMenu = () => {
                    const showSportsType = PROGRAM_ATTRIBUTES_SPORTS.PROGRAM_TYPES.includes(
                        values[PROGRAM_ATTRIBUTES_TYPES.PROGRAM_TYPES.VALIDATION_LABEL]
                    );

                    if (!showSportsType && get(touched, PROGRAM_ATTRIBUTES_SPORTS.SPORTS_TYPE.VALIDATION_LABEL)) {
                        setFieldTouched(PROGRAM_ATTRIBUTES_SPORTS.SPORTS_TYPE.VALIDATION_LABEL, false);
                        setFieldValue(
                            PROGRAM_ATTRIBUTES_SPORTS.SPORTS_TYPE.VALIDATION_LABEL,
                            PROGRAM_ATTRIBUTES_DEFAULT_PLACEHOLDERS.SPORT.value
                        );
                    }

                    return showSportsType;
                };

                return (
                    <Form noValidate onSubmit={handleSubmit} className="programAttributes_form">
                        {/* Universal Criteria */}
                        <div className='programAttributesSectionHeader'>
                            <div className='programAttributesSectionHeader__title'>
                                {PROGRAM_ATTRIBUTES_SECTION_HEADERS.UNIVERSAL.title}
                            </div>
                            <div className='programAttributesSectionHeader__description'>
                                {PROGRAM_ATTRIBUTES_SECTION_HEADERS.UNIVERSAL.description}
                            </div>
                            <div className='programAttributesSections'>
                                <div className='programAttributesSections__section'>{PROGRAM_ATTRIBUTES_TITLE_SECTION.NAME}</div>
                                <div className='programAttributesSections__section'>
                                    <GNInput
                                        label={PROGRAM_ATTRIBUTES_TITLE_SECTION.LABEL}
                                        placeholder={PROGRAM_ATTRIBUTES_TITLE_SECTION.PLACEHOLDER}
                                        handleChange={(item) => handleFormChange(PROGRAM_ATTRIBUTES_TITLE_SECTION.VALIDATION_LABEL, item)}
                                        validateInput={() => validateForm(PROGRAM_ATTRIBUTES_TITLE_SECTION.VALIDATION_LABEL)}
                                        validationText={errors[PROGRAM_ATTRIBUTES_TITLE_SECTION.VALIDATION_LABEL]}
                                        value={values[PROGRAM_ATTRIBUTES_TITLE_SECTION.VALIDATION_LABEL]}
                                    />
                                </div>
                            </div>
                            <Languages handleFormChange={handleFormChange} values={values} />
                            <div className='programAttributesSections'>
                                <div className='programAttributesSections__section'>{PROGRAM_ATTRIBUTES_GENRES_SECTION.NAME}</div>
                                <div className='programAttributesSections__section programAttributesSections__section-genres'>
                                    <div className='programAttributesSections__section-genre'>
                                        <TypeaheadFilter
                                            addLabelKey={PROGRAM_ATTRIBUTES_GENRES_SECTION.PLACEHOLDER}
                                            allowNew={false}
                                            filterLabel={PROGRAM_ATTRIBUTES_GENRES_SECTION.NAME}
                                            handleChange={(item) =>
                                                handleFormChange(PROGRAM_ATTRIBUTES_GENRES_SECTION.VALIDATION_LABEL, item)
                                            }
                                            list={genresList}
                                            multiple={true}
                                            placeholder={PROGRAM_ATTRIBUTES_GENRES_SECTION.PLACEHOLDER}
                                            selected={values[PROGRAM_ATTRIBUTES_GENRES_SECTION.VALIDATION_LABEL]}
                                            validationText={errors[PROGRAM_ATTRIBUTES_GENRES_SECTION.VALIDATION_LABEL]}
                                            validateTypeahead={() => validateForm(PROGRAM_ATTRIBUTES_GENRES_SECTION.VALIDATION_LABEL)}
                                        />
                                    </div>
                                    <AnyOrAllToggle
                                        section={PROGRAM_ATTRIBUTES_GENRES_SECTION}
                                        handleFormChange={handleFormChange}
                                        values={values}
                                    />
                                </div>
                            </div>
                            <div className='programAttributesSections'>
                                <div className='programAttributesSections__section'>{PROGRAM_ATTRIBUTES_RELEASE_YEAR_SECTION.NAME}</div>
                                <div className='programAttributesSections__section-releaseYear'>
                                    <GNInput
                                        label={PROGRAM_ATTRIBUTES_RELEASE_YEAR_SECTION.LABEL}
                                        placeholder={PROGRAM_ATTRIBUTES_RELEASE_YEAR_SECTION.PLACEHOLDER}
                                        handleChange={(item) => handleFormChange(PROGRAM_ATTRIBUTES_RELEASE_YEAR_SECTION.VALIDATION_LABEL, item)}
                                        validateInput={() => validateForm(PROGRAM_ATTRIBUTES_RELEASE_YEAR_SECTION.VALIDATION_LABEL)}
                                        validationText={errors[PROGRAM_ATTRIBUTES_RELEASE_YEAR_SECTION.VALIDATION_LABEL]}
                                        value={values[PROGRAM_ATTRIBUTES_RELEASE_YEAR_SECTION.VALIDATION_LABEL]}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Program Type Criteria */}
                        <div className='programAttributesSectionHeader'>
                            <div className='programAttributesSectionHeader__title'>
                                {PROGRAM_ATTRIBUTES_SECTION_HEADERS.PROGRAM_TYPE.title}
                            </div>
                            <div className='programAttributesSectionHeader__description'>
                                {PROGRAM_ATTRIBUTES_SECTION_HEADERS.PROGRAM_TYPE.description}
                            </div>
                            <Types handleFormChange={handleFormChange} values={values} />
                            <NetworksFilter handleFormChange={handleFormChange} values={values} />
                            <ProductionCompanyFilter handleFormChange={handleFormChange} values={values} />
                            <div className='programAttributesSections lastSection'>
                                <div className='programAttributesSections__section-header'>
                                    <div className='programAttributesSections__section'>{PROGRAM_ATTRIBUTES_SPORTS.NAME}</div>
                                    <div className='programAttributesSections__section-header-applies'>{APPLIES_TO}</div>
                                    <div className='programAttributesSections__section-header-badges'>
                                        <GNBadge type={PROGRAM_ATTRIBUTES_SPORTS.BADGE_TYPE} subtype={PROGRAM_ATTRIBUTES_SPORTS.NAME} />
                                    </div>
                                </div>
                                {sportsList?.length > 1 && <div className='programAttributesSections__section-dropdown'>
                                    {!enableSportsTypeMenu() ? (
                                        <OverlayTrigger
                                            overlay={<Tooltip>{PROGRAM_ATTRIBUTES_SPORTS.TOOLTIP}</Tooltip>}
                                            placement='right'
                                        >
                                            <div>
                                                <GNDropdown
                                                    disabled={true}
                                                    label={PROGRAM_ATTRIBUTES_SPORTS.SPORTS_TYPE.LABEL}
                                                    placeholder={PROGRAM_ATTRIBUTES_SPORTS.SPORTS_TYPE.PLACEHOLDER}
                                                    options={sportsList}
                                                    value={
                                                        values[PROGRAM_ATTRIBUTES_SPORTS.SPORTS_TYPE.VALIDATION_LABEL]
                                                    }
                                                    handleChange={(item) =>
                                                        handleFormChange(
                                                            PROGRAM_ATTRIBUTES_SPORTS.SPORTS_TYPE.VALIDATION_LABEL,
                                                            item
                                                        )
                                                    }
                                                />
                                            </div>
                                        </OverlayTrigger>
                                    ) : (
                                        <GNDropdown
                                            label={PROGRAM_ATTRIBUTES_SPORTS.SPORTS_TYPE.LABEL}
                                            placeholder={PROGRAM_ATTRIBUTES_SPORTS.SPORTS_TYPE.PLACEHOLDER}
                                            options={sportsList}
                                            value={values[PROGRAM_ATTRIBUTES_SPORTS.SPORTS_TYPE.VALIDATION_LABEL]}
                                            handleChange={(item) =>
                                                handleFormChange(
                                                    PROGRAM_ATTRIBUTES_SPORTS.SPORTS_TYPE.VALIDATION_LABEL,
                                                    item
                                                )
                                            }
                                        />
                                    )}
                                </div>}
                            </div>
                        </div>

                        {/* Footer */}
                        <div className='programAttributesSectionFooter'>
                            <div className='programAttributesSectionFooter__btnContainer'>
                                <Button onClick={handleResetBtn}>{PROGRAM_ATTRIBUTES_BUTTONS.RESET}</Button>
                                <Button
                                    onClick={() => handleSearch(values, {})}
                                    disabled={!hasCompletedFields()}>
                                    {!searchFromHeader
                                        ? PROGRAM_ATTRIBUTES_BUTTONS.SEARCH
                                        : PROGRAM_ATTRIBUTES_BUTTONS.UPDATE}
                                </Button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

ProgramAttributes.defaultProps = {
    searchFromHeader: false
};

ProgramAttributes.propTypes = {
    searchFromHeader: PropTypes.bool
};

export default ProgramAttributes;
