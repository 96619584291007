export const API_URL = (window.location.port === '8082') ? 'http://127.0.0.1:80/api/' : '/api/';

export const API_ENDPOINTS = {
    SEARCH_MARKETS: 'search/market-identifiers',
    SEARCH_PROGRAMS: 'search/program-identifiers',
    SEARCH_PROGRAM_INFO: 'search/program-info',
    SEARCH_STATIONS: 'search/station-identifiers',
    SEARCH_STATION_COUNTRIES: 'search/station-countries',
    SEARCH_PROGRAM_FIELD_VALUES: 'search/program-field-values',
    SEARCH_PRODUCTION_COMPANY_IDENTIFIERS: 'search/production-company-identifiers',

    GET_STREAMING_VIDEO_CATALOGS: "non-live/catalogs",
    GET_MARKET: 'non-live/markets',
    GET_PROGRAM_AIRINGS: 'non-live/program-airings',
    GET_PROGRAM_AIRINGS_LAST_UPDATED: 'non-live/program-airings/last-ingested-time',
    GET_PROGRAM_ANNOTATIONS: 'non-live/program-annotations',
    GET_PROGRAM_AVAILABILITIES: 'non-live/program-availabilities',
    GET_PROGRAM_AVAILABILITIES_LAST_UPDATED: 'non-live/program-availabilities/last-ingested-time',

    ADVANCED_SEARCH_PROGRAMS: 'non-live/advanced-search/program-identifiers',

    GET_PERSON_DETAILS: 'live/celebrities',
    GET_PROGRAM_DETAILS: 'live/programs',
    GET_PROGRAM_DETAILS_UNRESTRICTED: 'live/unrestricted-programs',
    GET_STATION: 'live/stations',
    GET_SCHEDULE: 'live/schedules',

    GET_SAVED_SEARCHES: 'saved-searches',

    EXPORT_SCHEDULES: 'exports/schedules',
    EXPORT_SEASON_EPISODES: 'exports/programs',
    EXPORT_PROGRAM_AIRINGS: 'exports/program-airings',
    EXPORT_PROGRAM_AVAILABILITIES: 'exports/get-program-availabilities',

    RAW_PROGRAMS_XML: 'raw/programs',

    REPORT_AN_ISSUE: 'salesforce/case',
    UPLOAD_ATTACHMENT: 'salesforce/attachment',
    GET_CASES: 'salesforce/cases',

    SPORTS_API_BASE: 'sports-api',
    SPORTS_CONNECTED_API_BASE: 'sports-connected-api',
    SPORTS_ENTITIES: 'entities',
    SPORTS_RELATED_PROGRAMS: 'relatedprograms',
    SUBSCRIPTIONS: 'subscriptions',
    ALERTS: 'alerts',
    REVIEWED_ALERTS: 'reviewed-alerts',
    ALERTS_COUNT: 'subscriptions/alerts/count',
    LOOKUP: 'user/lookup',
    LOOKUP_RESET: 'user/lookup-reset'
};

export const API_CURRENT_VERSION = '4';
export const API_NEXT_VERSION = '5';

export const getSportsScheduleResultsURL = (sportId, leagueId, teamId) => {
    const SCHEDULE_RESULTS = 'schedule-results';
    let URL = `${API_ENDPOINTS.SPORTS_API_BASE}/`;

    if (sportId) {
        URL += `sports/${sportId}/${SCHEDULE_RESULTS}`;
    } else if (leagueId) {
        URL += `leagues/${leagueId}/${SCHEDULE_RESULTS}`;
    } else if (teamId) {
        URL += `teams/${teamId}/${SCHEDULE_RESULTS}`;
    } else {
        URL += `${SCHEDULE_RESULTS}`;
    }

    return URL;
};
