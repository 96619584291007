import {
    GNVIEW_DATA_ALERTS_SET_ALERTS,
    GNVIEW_DATA_ALERTS_SET_SUBSCRIPTIONS,
    GNVIEW_DATA_ALERTS_SET_PRG_SVC_IDS,
    GNVIEW_DATA_ALERTS_SET_ALERTS_COUNTS,
    GNVIEW_DATA_ALERTS_SET_SELECTED_WINDOW,
    GNVIEW_DATA_ALERTS_SET_ALERTS_COUNTS_THIS_WEEK
} from '../actions/ActionTypes';
import { DATA_ALERTS_WINDOWS } from '../constants/DataAlerts';

export const DATA_ALERTS_STORE = 'dataAlerts';

/**
 * Initial State
 */
export const initialState = {
    alerts: [],
    prgSvcIDs: [],
    subscriptions: [],
    alertsCounts: {
        'this-week': 0,
        'next-week': 0,
        'three-weeks-out': 0
    },
    selectedWindow: DATA_ALERTS_WINDOWS?.[0]?.week
};

/**
 * Reducer
 */
export const DataAlertsReducer = (state = initialState, action) => {
    switch (action.type) {
    case GNVIEW_DATA_ALERTS_SET_ALERTS:
        return { ...state, alerts: action.payload.alerts };
    case GNVIEW_DATA_ALERTS_SET_SUBSCRIPTIONS:
        return { ...state, subscriptions: action.payload.subscriptions };
    case GNVIEW_DATA_ALERTS_SET_PRG_SVC_IDS:
        return { ...state, prgSvcIDs: action.payload.prgSvcIDs };
    case GNVIEW_DATA_ALERTS_SET_ALERTS_COUNTS:
        return { ...state, alertsCounts: action.payload.alertsCounts };
    case GNVIEW_DATA_ALERTS_SET_ALERTS_COUNTS_THIS_WEEK:
        return { ...state,
            alertsCounts: {
                ...state.alertsCounts,
                'this-week': action.payload.alertsCount
            }
        };
    case GNVIEW_DATA_ALERTS_SET_SELECTED_WINDOW:
        return { ...state, selectedWindow: action.payload.window };
    default:
        return state;
    }
};

/**
 * Selectors
 */
export const gnviewSelDataAlertsAlerts = (state) => state[DATA_ALERTS_STORE].alerts;
export const gnviewSelDataAlertsSubscriptions = (state) => state[DATA_ALERTS_STORE].subscriptions;
export const gnviewSelDataAlertsPrgSvcIDs = (state) => state[DATA_ALERTS_STORE].prgSvcIDs;
export const gnviewSelDataAlertsAlertsCounts = (state) => state[DATA_ALERTS_STORE].alertsCounts;
export const gnviewSelDataAlertsSelectedWindow = (state) => state[DATA_ALERTS_STORE].selectedWindow;

export default DataAlertsReducer;