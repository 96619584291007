/* eslint react/prop-types: 0 */
import React from 'react';
import find from 'lodash.find';
import moment from 'moment-timezone';
import {ROUTES} from '../config/Routes';
import {Link} from "react-router-dom";

export const RELATED_PROGRAMS_TAB_NAMES = {
    alternateAudio: "Alternate Audio",
    audioVersion: "Audio Version",
    crossover: "Crossover",
    customVersion: "Custom Version",
    derivative: "Derivative",
    franchise: "Franchise",
    recommendation: "Recommendation",
    remake: "Remake",
    repackage: "Repackage",
    revival: "Revival",
    seriesSpecial: "Series Special",
    spinoff: "Spinoff",
    superSeries: "Super Series",
    topic: "Topic"
};

export const RELATED_PROGRAMS_TABLE_COLUMN_HEADERS = [
    { accessor: 'tms_id', Header: 'TMS ID', Cell: ({value}) => {
        const url = `${ROUTES.PROGRAM_DETAILS}/${value}`;
        return <span><Link to={url}>{value}</Link></span>
    }, disableSortBy: true},
    { accessor: 'titles', Header: 'Title', Cell: ({row}) => {
        if (row.original?.titles?.length > 0) {
            const mainTitle = find(row.original.titles, { type: 'full', sub_type: 'Main', size: 120 })?.text || row.original.titles[0]?.text || '';
            return mainTitle;
        }
        return '';
    }, disableSortBy: true},
    { accessor: 'original_air_date', Header: 'Original Air Date', Cell: ({value}) => {
        return value ? moment(value).format('MM/DD/YYYY') : '';
    }, disableSortBy: true}
];
