import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { gnviewGetProgramAvailabilities, gnviewGetStreamingVideoCatalogs } from '../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import { gnviewSelEntitledCatalogs, gnviewSelEntitledCatalogsLoading } from '../../../reducers/GNVCatalogsReducer';
import { gnviewSelProgramAvailCatalogIds } from '../../../reducers/ProgramAvailabilityReducer';
import GNClientTable from '../../../components/common/gnClientTable/GNClientTable';
import { PROGRAM_AVAILABILITY_TABLE_COLUMN_HEADERS, TABLE_PAGE_SIZE } from '../../../constants/ProgramAvailability';
import './ProgramAvailabilityTable.scss';
import moment from 'moment-timezone';
import LoadingSpinner from '../../../components/common/loadingSpinner/LoadingSpinner';
import { numberWithCommas } from '../../../utils/GeneralUtils';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

export class ProgramAvailabilityTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            hasError: false,
            programAvailabilities: null,
            entitled: true,
            totalCount: null,
            currentCount: TABLE_PAGE_SIZE,
            pageIndex: 0,
            pageSize: TABLE_PAGE_SIZE,
            nextTMSId: null,
            nextCatalogName: null,
            nextAvailabilityId: null,
            prevTMSIds: [],
            prevCatalogNames: [],
            prevAvailabilityIds: []
        }
    }

    componentDidMount() {
        if (this.props.entitledCatalogs.length && !this.props.entitledCatalogsIsLoading) {
            this.fetchProgramAvailabilities(this.props.entitledCatalogs);
        } else {
            this.props.gnviewGetStreamingVideoCatalogs(true).then((response) => {
                this.fetchProgramAvailabilities(response.result);
            }).catch((error) => {
                this.setState({ isLoading: false, hasError: true });
                this.props.gnviewSendLogMessage(`gnview_gnviewGetStreamingVideoCatalogs error: ${error.message}`, error, {});
            });
        }
    }

    fetchProgramAvailabilities = (entitledCatalogs) => {
        const body = {
            tms_ids: this.props.tmsid,
            catalog_ids: this.props.selectedStreamingCatalogIds,
            size: this.state.pageSize,
            after_tms_id: this.state.nextTMSId,
            after_catalog_name: this.state.nextCatalogName,
            after_availability_id: this.state.nextAvailabilityId
        };

        this.props.gnviewGetProgramAvailabilities(body).then((response) => {
            const totalCount = response.headers?.total_count;
            const nextTMSId = response.headers?.next_after_tms_id;
            const nextCatalogName = response.headers?.next_after_catalog_name;
            const nextAvailabilityId = response.headers?.next_after_availability_id;
            if (response.result.length) {
                const programAvailabilities = response.result.map(programAvailability => {
                    const catalogId = programAvailability.catalog.id;
                    const catalog = entitledCatalogs.find(c => c.id === catalogId);
                    programAvailability.catalog.images = catalog.images;
                    programAvailability.catalog.country_codes = catalog.country_codes;
                    programAvailability.formatted_dates = {
                        ...programAvailability.start_date_time && { start_date: moment.parseZone(programAvailability.start_date_time).format('MMMM D, YYYY h:mma') },
                        ...programAvailability.end_date_time && { end_date: moment.parseZone(programAvailability.end_date_time).format('MMMM D, YYYY h:mma') }
                    };
                    return programAvailability;
                });
                const nextDataStore = this.storeNextData();
                this.setState({
                    isLoading: false,
                    programAvailabilities,
                    nextTMSId, nextCatalogName,
                    nextAvailabilityId,
                    totalCount,
                    prevTMSIds: nextDataStore?.tmsIds,
                    prevCatalogNames: nextDataStore?.catalogNames,
                    prevAvailabilityIds: nextDataStore?.availabilityIds
                });
            } else {
                this.setState({ isLoading: false, entitled: false });
            }
        }).catch((error) => {
            this.setState({ isLoading: false, hasError: true });
            const tmsid = this.props.tmsid;
            this.props.gnviewSendLogMessage(`gnview_getProgramAvailabilties error: ${error.message}`, error, { tmsid });
        });
    }

    storeNextData = () => {
        if (this.state.nextCatalogName && this.nextAvailabilityId && this.state.nextTMSId && this.state.pageIndex > 0) {
            const prevTMSIdsCopy = this.state.prevTMSIds;
            const prevCatalogNamesCopy = this.state.prevCatalogNames;
            const prevAvailabilityIdsCopy = this.state.prevAvailabilityIds;

            return {
                tmsIds: prevTMSIdsCopy.push(this.state.nextTMSId),
                catalogNames: prevCatalogNamesCopy.push(this.state.nextCatalogName),
                availabilityIds: prevAvailabilityIdsCopy.push(this.nextAvailabilityId)
            };
        } else {
            return {};
        }
    }

    previousPage = () => {
        if (this.state.pageIndex <= 1) {
            this.setState((prevState) => ({nextCatalogName: null, nextAvailabilityId: null, nextTMSId: null, prevCatalogNames: [], prevAvailabilityIds: [], prevTMSIds: null, pageIndex: prevState.pageIndex - 1, currentCount: prevState.currentCount - TABLE_PAGE_SIZE}), () => {
                this.fetchProgramAvailabilities(this.props.entitledCatalogs);
            });
        } else {
            this.setState((prevState) => ({nextCatalogName: prevState.prevCatalogNames.splice(-2)[0], nextAvailabilityId: prevState.prevAvailabilityIds.splice(-2)[0], nextTMSId: prevState.prevTMSIds.splice(-2)[0], pageIndex: prevState.pageIndex - 1, currentCount: prevState.currentCount - TABLE_PAGE_SIZE}), () => {
                this.fetchProgramAvailabilities(this.props.entitledCatalogs)
            });
        }
    }

    nextPage = () => {
        this.setState((prevState) => ({pageIndex: prevState.pageIndex + 1, currentCount: prevState.currentCount + TABLE_PAGE_SIZE}), () => {
            this.fetchProgramAvailabilities(this.props.entitledCatalogs)
        });
    }

    showPagination = () => {
        return !(this.state.programAvailabilities?.length < this.state.pageSize && this.state.pageIndex === 0);
    }

    renderTotalCountPagination = () => {
        const startPaginationCount = (this.state.pageIndex * TABLE_PAGE_SIZE) + 1;
        const maxRange = (this.state.pageIndex * TABLE_PAGE_SIZE) + TABLE_PAGE_SIZE;
        const endPaginationCount = maxRange >= this.state.totalCount ? this.state.totalCount : maxRange;
        return `${numberWithCommas(startPaginationCount)} - ${numberWithCommas(endPaginationCount)} of ${numberWithCommas(this.state.totalCount)}`;
    }

    render() {
        return (
            <div className='gnview-program-availability-table'>
                {this.state.isLoading && <LoadingSpinner />}
                {!this.state.isLoading && !this.state.hasError && this.state.entitled && <div>
                    <GNClientTable columns={PROGRAM_AVAILABILITY_TABLE_COLUMN_HEADERS} data={this.state.programAvailabilities} isLoading={false} pagination={false} sorting={false} />
                    {this.showPagination() && <div className="table-pagination-container">
                        <Button variant="light" onClick={() => this.previousPage()} disabled={this.state.pageIndex === 0}>
                            <i className="fas fa-angle-left" />
                        </Button>
                        <Button variant="light" onClick={() => this.nextPage()} disabled={this.state.currentCount >= this.state.totalCount}>
                            <i className="fas fa-angle-right" />
                        </Button>
                        {this.showPagination() && <span className='total-count-pagination'>{this.renderTotalCountPagination()}</span>}
                    </div>}
                </div>}
                {!this.state.entitled && <div className='unentitled-message'>No availability data</div>}
            </div>
        )
    }
}

ProgramAvailabilityTable.propTypes = {
    entitledCatalogs: PropTypes.array.isRequired,
    entitledCatalogsIsLoading: PropTypes.bool.isRequired,
    gnviewGetProgramAvailabilities: PropTypes.func.isRequired,
    gnviewGetStreamingVideoCatalogs: PropTypes.func.isRequired,
    gnviewSendLogMessage: PropTypes.func,
    selectedStreamingCatalogIds: PropTypes.array.isRequired,
    tmsid: PropTypes.string.isRequired
}

const mapStateToProps = state => {
    return {
        entitledCatalogs: gnviewSelEntitledCatalogs(state),
        entitledCatalogsIsLoading: gnviewSelEntitledCatalogsLoading(state),
        selectedStreamingCatalogIds: gnviewSelProgramAvailCatalogIds(state)
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewGetProgramAvailabilities,
        gnviewGetStreamingVideoCatalogs,
        gnviewSendLogMessage
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramAvailabilityTable);