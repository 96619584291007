import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { gnviewGetStation } from '../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import LazyLoad from 'react-lazyload';
import GNImage from '../gnImage/GNImage';
import { getStationImage } from '../../../utils/StationUtils';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { getImageURL } from '../../../utils/ImageUtils';
import './StationInfo.scss';
import PropTypes from "prop-types";

export class StationInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stationInfo: {},
            stationInfoLoading: true,
            hasError: false
        };
    }

    componentDidMount() {
        if (this.props.stationId) {
            this.props.gnview_getStation(this.props.stationId).then(
                (stationInfo) => {
                    this.setState({stationInfo: stationInfo.result, stationInfoLoading: false});
                    this.cancelToken = stationInfo.cancelToken;
                }, (error) => {
                    this.props.gnviewSendLogMessage(`StationInfo gnview_getStation error: ${error.message}`, error, {"stationId": this.props.stationId});
                    this.setState({stationInfoLoading: false, hasError: true});
                });
        }
    }

    componentWillUnmount() {
        if (this.cancelToken) {
            this.cancelToken.cancel();
        }
    }

    render() {
        const imageURL = getImageURL(getStationImage(this.state.stationInfo));
        return (
            <span className="station-info-content">
                {!this.state.stationInfoLoading && !this.state.hasError && <span>
                    {imageURL && <LazyLoad overflow={true} once><GNImage className={'station-image'} url={imageURL} text={this.state.stationInfo.name} /></LazyLoad>}
                    <span>{this.state.stationInfo.call_sign} ({this.state.stationInfo.id})</span>
                </span>}
                {!this.state.stationInfoLoading && this.state.hasError && <span>{`${this.props.extra?.['original_network_call_sign'] || this.props.extra?.['last_original_network_call_sign'] || ''}`}</span>}
                {this.state.stationInfoLoading && <LoadingSpinner />}
            </span>
        )
    }
}

StationInfo.propTypes = {
    stationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    gnview_getStation: PropTypes.func.isRequired,
    gnviewSendLogMessage: PropTypes.func.isRequired,
    extra: PropTypes.object
};

const mapStateToProps = () => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnview_getStation: gnviewGetStation,
        gnviewSendLogMessage
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(StationInfo);
