import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { ProgramDetailsReducer, PROGRAM_DETAILS_STORE } from './ProgramDetailsReducer';
import { GNVAuthReducer, GNVAUTH_STORE_NAME } from './GNVAuthReducer';
import { GNVCatalogsReducer, GNVIEW_STREAMING_CATALOGS } from './GNVCatalogsReducer';
import { ProgramAvailabilityReducer, PROGRAM_AVAILABILITY_STORE } from './ProgramAvailabilityReducer';
import { ProgramAiringsReducer, PROGRAM_AIRINGS_STORE } from './ProgramAiringsReducer';
import { StationDetailsReducer, STATION_DETAILS_STORE } from './StationDetailsReducer';
import { AdvancedSearchReducer, ADVANCED_SEARCH_STORE } from './AdvancedSearchReducer';
import { DataAlertsReducer, DATA_ALERTS_STORE } from './DataAlertsReducer';
import { SportsReducer, SPORTS_STORE } from './SportsReducer';

const createRootReducer = (history) => {
    return combineReducers({
        router: connectRouter(history),
        [ADVANCED_SEARCH_STORE]: AdvancedSearchReducer,
        [DATA_ALERTS_STORE]: DataAlertsReducer,
        [GNVAUTH_STORE_NAME]: GNVAuthReducer,
        [GNVIEW_STREAMING_CATALOGS]: GNVCatalogsReducer,
        [PROGRAM_AIRINGS_STORE]: ProgramAiringsReducer,
        [PROGRAM_AVAILABILITY_STORE]: ProgramAvailabilityReducer,
        [PROGRAM_DETAILS_STORE]: ProgramDetailsReducer,
        [SPORTS_STORE]: SportsReducer,
        [STATION_DETAILS_STORE]: StationDetailsReducer
    })
};

export default createRootReducer;