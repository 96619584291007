import generalIcon from '../images/allotherprograms.png';
import filmIcon from '../images/film.png';
import sportsIcon from '../images/sports.png';
import stationIcon from '../images/station.png';
import { PROGRAM_TYPES } from './Program';

export const RECENT_SEARCH_PLACEHOLDER_IMAGES = {
    [PROGRAM_TYPES.FEATURE_FILM]: filmIcon,
    [PROGRAM_TYPES.SERIES]: generalIcon,
    [PROGRAM_TYPES.SHORT_FILM]: filmIcon,
    [PROGRAM_TYPES.MINISERIES]: generalIcon,
    [PROGRAM_TYPES.PAID_PROGRAMMING]: generalIcon,
    [PROGRAM_TYPES.SPECIAL]: generalIcon,
    [PROGRAM_TYPES.SPORTS_EVENT]: sportsIcon,
    [PROGRAM_TYPES.SPORTS_NON_EVENT]: sportsIcon,
    [PROGRAM_TYPES.TV_MOVIE]: generalIcon,
    [PROGRAM_TYPES.THEATRE_EVENT]: generalIcon,
    [PROGRAM_TYPES.MUSIC_VIDEO]: generalIcon,
    [PROGRAM_TYPES.PREVIEW]: generalIcon,
    STATION: stationIcon
};