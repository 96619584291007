import React, { useEffect, useState } from 'react';
import notFound404 from '../images/404.png';
import { LEARN_MORE_LINK } from '../constants/ContactUs';
import "./NotFound404.scss";
import { useSelector } from 'react-redux';
import { gnviewSelProgramDetails } from '../reducers/ProgramDetailsReducer';

export const NotFound404View = () => {
    const programDetailsReducer = useSelector(gnviewSelProgramDetails);
    const [errorReason, setErrorReason] = useState(null)

    useEffect(() => {
        if (programDetailsReducer?.message?.length > 0) {
            setErrorReason(programDetailsReducer.message);
        }
    }, [programDetailsReducer]);

    return (
        <div className="gnview-not-found-view">
            <img src={notFound404} alt="404-not-found" className="not-found-image" />
            <div className="oops-title">Oops!</div>
            <div className="message-section">
                {errorReason?.length && (
                    <div className='message-section__error'>{errorReason}</div>
                )}
                <div>Something went wrong.</div>
                <div>You can go back or contact <a href={LEARN_MORE_LINK} target='_blank' rel='noreferrer'>Support</a>.</div>
            </div>
        </div>
    )
}

export default NotFound404View;
