import React from 'react';
import { PROGRAM_COLORS } from '../../../constants/Program';
import ClassNames from 'classnames';
import './ScheduleLegend.scss';
import invertBy from 'lodash.invertby';

export const ScheduleLegend = () => {
    const colorObj = invertBy(PROGRAM_COLORS.gnview);

    return (
        <div className='gnview-schedule-legend'>
            <span className='legend-title'>Legend:</span>
            {Object.keys(colorObj).map((color, idx) => (
                <span className='legend-content' key={idx}>
                    <span className={ClassNames('legend-color', color)} />
                    <span className='legend-text'>{colorObj[color].join(', ')}</span>
                </span>
            ))}
        </div>
    )
};

export default ScheduleLegend;