/* eslint react/prop-types: 0 */
/* eslint react/display-name: 0 */
import React from 'react';
import { Link } from "react-router-dom";
import { ROUTES } from '../config/Routes';

export const MARKET_DETAILS = 'US Market Details';
export const MARKET_AREA_NAME = 'US Market Area Name:';

export const MARKET_COLUMN_HEADERS = [
    { accessor: 'name', Header: 'Station Name', Cell: ({row}) => {
        const url = `${ROUTES.STATION_DETAILS}/${row.original.id}`;
        return <span><Link target="_blank" rel="noreferrer" to={url}>{row.values.name}</Link></span>
    }},
    { accessor: 'call_sign', Header: 'Call Sign'},
    { accessor: 'affiliation', Header: 'Station Affiliation'},
    { accessor: 'type', Header: 'Type'},
    { accessor: 'status', Header: 'Status'}
];

export const MARKET_RANK_MINIMUM = 1;
export const MARKET_RANK_MAXIMUM = 210;

export const MARKET_SUMMARY_FIELDS = [
    { prop: 'rank', label: 'Rank' },
    { prop: 'id', label: 'DMA Market ID' },
    { prop: 'year', label: 'Year' },
    { prop: 'householdCount', label: 'Household Count' },
    { prop: 'householdPercentage', label: 'Household Percentage' }
];