import React from 'react';
import SaveButton from "../components/common/saveButton/SaveButton";
import {EXACT_DATE} from "./Schedule";
import {ROUTES} from '../config/Routes';
import {RECENT_SEARCH_PLACEHOLDER_IMAGES} from './RecentSearchItem';
import {PROGRAM_TYPES} from './Program';

export const MY_SAVED_ITEMS = 'My Saved Items';
export const MY_SAVED_ITEMS_TYPES = {
    PROGRAM_AIRINGS: "Program Airings",
    PROGRAM_AVAILABILITY: "Program Availability",
    PROGRAM_LISTS: "Program Lists",
    SCHEDULES: "Schedules"
};

export const MY_SAVED_ITEMS_TABS = {
    [MY_SAVED_ITEMS_TYPES.PROGRAM_AIRINGS]: {name: MY_SAVED_ITEMS_TYPES.PROGRAM_AIRINGS, icon: 'clipboard-list'},
    [MY_SAVED_ITEMS_TYPES.PROGRAM_LISTS]: {name: MY_SAVED_ITEMS_TYPES.PROGRAM_LISTS, icon: 'tv'},
    [MY_SAVED_ITEMS_TYPES.SCHEDULES]: {name: MY_SAVED_ITEMS_TYPES.SCHEDULES, icon: 'clock'},
    [MY_SAVED_ITEMS_TYPES.PROGRAM_AVAILABILITY]: {name: MY_SAVED_ITEMS_TYPES.PROGRAM_AVAILABILITY, icon: 'laptop-house'}
};

export const MY_SAVED_ITEMS_EMPTY_MESSAGE = {
    [MY_SAVED_ITEMS_TYPES.PROGRAM_AIRINGS]: <div className="empty-msg">Click the <SaveButton /> button on a Program Airings search to save the search. Saved Program Airings searches will appear here.</div>,
    [MY_SAVED_ITEMS_TYPES.PROGRAM_AVAILABILITY]: <div className="empty-msg">Click the <SaveButton /> button on a Program Availability search to save the search. Saved Program Availability searches will appear here.</div>,
    [MY_SAVED_ITEMS_TYPES.SCHEDULES]: <div className="empty-msg">Click the <SaveButton /> button on a Schedule search to save the search. Saved Schedule searches will appear here.</div>,
    [MY_SAVED_ITEMS_TYPES.PROGRAM_LISTS]: (
        <div className='empty-msg'>
            Click the <SaveButton /> button on a Program Details page to create lists and save the program to a list.
            Lists will appear here.
        </div>
    )
};

// Modal Constants
export const DELETE_SAVED_SEARCH_MODAL_MESSAGE = "Are you sure you want to delete this saved item? This action cannot be undone.";
export const DELETE_SAVED_SEARCH_MODAL_HEADER = "Delete Saved Item";
export const DELETE_SAVED_SEARCH_MODAL_FIRST_BUTTON = 'Cancel';
export const DELETE_SAVED_SEARCH_MODAL_SECOND_BUTTON = 'Confirm';
export const DELETE_SAVED_SEARCH_SUCCESS = (title) => {
    return `Saved Item deleted: "${title}"`
};
export const RENAME_SAVED_SEARCH_MODAL_HEADER = 'Rename';
export const RENAME_SAVED_SEARCH_ERROR_MESSAGE = 'Please create a new Saved Item name';
export const RENAME_SAVED_SEARCH_SUCCESS = (title) => {
    return `Saved Item renamed: "${title}"`
};

export const scheduleSavedItemBodyFrom = (
    username,
    title,
    stationIds,
    dateType,
    exactStartDate,
    exactEndDate,
    dateObj
) => {
    return {
        title,
        search_type: MY_SAVED_ITEMS_TYPES.SCHEDULES,
        username,
        query_obj: {
            station_ids: stationIds,
            date_type: dateType,
            start_date: dateType === EXACT_DATE ? exactStartDate : dateObj.startDate,
            end_date: dateType === EXACT_DATE ? exactEndDate : dateObj.endDate
        }
    }
}

export const programAiringsSavedItemBodyFrom = (
    username,
    title,
    countryCodes,
    marketIds,
    programIds,
    stationIds,
    stationTypes,
    dateType,
    searchType,
    exactStartDate,
    exactEndDate
) => {
    return {
        title,
        search_type: MY_SAVED_ITEMS_TYPES.PROGRAM_AIRINGS,
        username,
        query_obj: {
            country_codes: countryCodes,
            market_ids: marketIds,
            program_ids: programIds,
            station_ids: stationIds,
            station_types: stationTypes,
            date_type: dateType,
            search_type: searchType,
            start_date: dateType === EXACT_DATE ? exactStartDate : null,
            end_date: dateType === EXACT_DATE ? exactEndDate : null
        }
    }
}

export const programAvailabilitySavedItemBodyFrom = (
    username,
    title,
    catalogIds,
    programIds
) => {
    return {
        title,
        search_type: MY_SAVED_ITEMS_TYPES.PROGRAM_AVAILABILITY,
        username,
        query_obj: {
            catalog_ids: catalogIds,
            program_ids: programIds
        }
    }
}

export const SUCCESS_MODAL_TYPES = {
    SAVE: 'Save',
    UPDATE: 'Update'
};

export const sortSavedItemsByDate = (savedItems) => {
    if (savedItems?.length > 0) {
        return savedItems.sort((a, b) => {
            if (a.updated_at < b.updated_at) {
                return 1;
            } else if (a.updated_at > b.updated_at) {
                return -1;
            } else {
                return 0;
            }
        })
    }
    return null;
};

export const MY_SAVED_ITEMS_SUBMENU_OPTIONS = {
    RENAME: { name: 'Rename', icon: 'pencil' },
    DELETE: { name: 'Delete', icon: 'trash-can' }
};


export const PROGRAM_DETAILS_SAVED_ITEMS_LIMIT = 4;
export const RECENT_SAVED_SEARCHES_LIMIT = 4;
export const RECENT_SAVED_ITEMS_TYPES = {
    [MY_SAVED_ITEMS_TYPES.PROGRAM_AIRINGS]: {
        route: ROUTES.PROGRAM_AIRINGS,
        icon: RECENT_SEARCH_PLACEHOLDER_IMAGES[PROGRAM_TYPES.SERIES]
    },
    [MY_SAVED_ITEMS_TYPES.SCHEDULES]: {
        route: ROUTES.SCHEDULES,
        icon: RECENT_SEARCH_PLACEHOLDER_IMAGES.STATION
    }
};

export const viewAllOrViewLessButton = (isExpanded) => isExpanded ? 'View Less' : 'View All';