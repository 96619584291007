import React from 'react';
import { Row, Col } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import GNImage from '../gnImage/GNImage';
import './GNImagesTabImages.scss';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const GNImagesTabImages = (props) => (
    <div className={ClassNames('image-content-container', {'background-gray': props.backgroundColor})}>
        <Row className="image-section">
            {props.selectedImages.length > 0 && props.selectedImages.map((img) => (
                <Col key={img.url} className="image-container" >
                    <LazyLoad offset={100}>
                        <div className="asset-image-wrapper">
                            {!img?.expired_date && <a href={img.url} target='_blank' rel="noreferrer">
                                <FontAwesomeIcon icon="file-download"/>
                                <GNImage url={img.url} text={img.image} className="asset-image"/>
                            </a>}
                            {img?.expired_date && <GNImage url={img.url} text={img.image} className="asset-image"/>}
                        </div>
                        {props.isSeasonSpecific && <div><strong>{img.image}</strong></div>}
                        <div><strong>{img.dimensions}</strong></div>
                        <div>{img.ratio}</div>
                        {img?.identifiers?.length > 0 && img.identifiers.map((iden, idx) => (
                            <div className="identifier" key={idx}>{iden.type}: {iden.id}</div>
                        ))}
                        {img?.expired_date && <span className='expired-message'>Expired: {moment(img.expired_date).format('MMMM D, YYYY')}</span>}
                        {img?.provider && <div className="identifier">Provider: {img.provider}</div>}
                        {img?.last_modified && <div className="last-modified">Last Updated {moment(img.last_modified).format('M/DD/YYYY')}</div>}
                    </LazyLoad>
                </Col>
            ))}
            {(!props.selectedImages || props.selectedImages?.length === 0) &&
            <div className="no-info-message">No images available</div>}
        </Row>
    </div>
);

GNImagesTabImages.propTypes = {
    selectedImages: PropTypes.array.isRequired,
    isSeasonSpecific: PropTypes.bool,
    backgroundColor: PropTypes.bool
};

export default GNImagesTabImages;