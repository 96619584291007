import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class TypeaheadSingleToken extends Component {
    render() {
        return (
            <div className="filter-bar-col">
                <div className="filter-label">{this.props.label}</div>
                <div className="gnview-single-selected-token">
                    {this.props.children}
                    <span className="close-icon" onClick={this.props.onClearToken}>×</span>
                </div>
            </div>
        );
    }
}

TypeaheadSingleToken.propTypes = {
    label: PropTypes.string,
    onClearToken: PropTypes.func,
    children: PropTypes.node
};

export default TypeaheadSingleToken;