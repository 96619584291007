import React from 'react';
import { generateTimeAxis } from '../../../utils/DateUtils';
import "./ScheduleTimeAxis.scss";
import PropTypes from 'prop-types';

export const ScheduleTimeAxis = ({gridStartHour}) => {
    const timeAxis = generateTimeAxis(gridStartHour);
    return (
        <div className="schedule-time-axis">
            <table>
                <tbody>
                    {timeAxis.map((time, index) => (<tr key={index} id={time}><td>{time}</td></tr>))}
                </tbody>
            </table>
        </div>
    );
}

ScheduleTimeAxis.propTypes = {
    gridStartHour: PropTypes.number
};

export default ScheduleTimeAxis;