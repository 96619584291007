import { GNVIEW_PROGRAM_AVAIL_ADD_PROGRAM_IDS, GNVIEW_PROGRAM_AVAIL_ADD_QUERY_OBJ, GNVIEW_PROGRAM_AVAIL_ADD_STREAMING_CATALOG_IDS } from '../actions/ActionTypes';

export const PROGRAM_AVAILABILITY_STORE = 'programAvailability';

/**
 * Initial State
 */
export const initialState = {
    selectedProgramIds: [],
    selectedStreamingCatalogIds: [],
    queryObj: {}
};

/**
 * Reducer
 */
export const ProgramAvailabilityReducer = (state = initialState, action) => {
    switch (action.type) {
    case GNVIEW_PROGRAM_AVAIL_ADD_PROGRAM_IDS:
        return { ...state, selectedProgramIds: action.payload.programIds };
    case GNVIEW_PROGRAM_AVAIL_ADD_STREAMING_CATALOG_IDS:
        return { ...state, selectedStreamingCatalogIds: action.payload.catalogIds };
    case GNVIEW_PROGRAM_AVAIL_ADD_QUERY_OBJ:
        return { ...state, queryObj: action.payload.queryObj };
    default:
        return state;
    }
};


/**
 * Selectors
 */

export const gnviewSelProgramAvailProgramIds = state => state[PROGRAM_AVAILABILITY_STORE].selectedProgramIds;
export const gnviewSelProgramAvailCatalogIds = state => state[PROGRAM_AVAILABILITY_STORE].selectedStreamingCatalogIds;
export const gnviewSelProgramAvailQueryObj = state => state[PROGRAM_AVAILABILITY_STORE].queryObj;

/**
 * Default export
 */
export default ProgramAvailabilityReducer;