import React from 'react';
import find from 'lodash.find';
import {
    TAB_NAMES,
    PROGRAM_COLORS,
    PROGRAM_GNPROGTYPES,
    PROGRAM_HORIZONTAL_IMAGE_PREFS,
    PROGRAM_VERTICAL_IMAGE_PREFS,
    PROGRAM_SEASON_EPISODE_COLUMN_HEADERS_WITH_LINK,
    PROGRAM_SEASON_EPISODE_COLUMN_HEADERS_WITH_NO_LINK,
    EPISODIC_PROGRAM_SUBTYPES,
    PROGRAM_ALERT_TYPES,
    PROGRAM_TYPES
} from '../constants/Program';
import { RELATED_PROGRAMS_TAB_NAMES } from '../constants/RelatedPrograms';
import moment from 'moment';
import {PROGRAM_RELEASES_COLUMN_HEADERS, PROGRAM_SUMMARY_FIELDS} from '../components/common/programTabs/programTabs';
import isEmpty from 'lodash.isempty';
import { filterRestrictedImages } from './ImageUtils';

export const getProgramTabs = (program) => {
    // Always show the "Overview" tab, since there are many subtabs and there is always data here
    const tabs = [TAB_NAMES.OVERVIEW];
    if (program?.seasons?.length > 0 || program?.allSeasons?.length > 0) {
        tabs.push(TAB_NAMES.SEASONS_AND_EPISODES);
    }
    if (program?.cast?.length > 0 || program?.crew?.length > 0) {
        tabs.push(TAB_NAMES.CAST_AND_CREW);
    }
    if (program?.images?.length > 0) {
        tabs.push(TAB_NAMES.IMAGES);
    }
    // Always push "Other Versions" since we need to call a separate API
    tabs.push(TAB_NAMES.OTHER_VERSIONS);
    if (program?.relationships?.length > 0) {
        tabs.push(TAB_NAMES.RELATED_PROGRAMS);
    }
    if (program?.episode_info?.alternate_episodes?.length > 0) {
        tabs.push(TAB_NAMES.ALTERNATE_EPISODES);
    }
    // Always push "Video Descriptors", need to show teaser whether or not there's data
    tabs.push(TAB_NAMES.VIDEO_DESCRIPTORS);
    return tabs;
};

export const getOverviewSubMenuTabs = (program) => {
    // Always show "Summary" tab, there's usually data
    const tabs = [TAB_NAMES.SUMMARY];
    if (program?.sports_info?.teams?.length > 0) {
        tabs.push(TAB_NAMES.TEAMS);
    }
    // Always push "Titles" and "Descriptions", since we want to show if there's missing data
    tabs.push(TAB_NAMES.TITLES);
    tabs.push(TAB_NAMES.DESCRIPTIONS);
    if (program?.releases?.length > 0) {
        tabs.push(TAB_NAMES.RELEASES);
    }
    if (program?.ratings?.length > 0) {
        tabs.push(TAB_NAMES.RATINGS);
    }
    if (program?.awards?.length > 0) {
        tabs.push(TAB_NAMES.AWARDS);
    }
    return tabs;
};

export const getProgramTitle = (program, parentTitleOnly = false, textOnly = false) => {
    if (program?.titles?.length > 0) {
        const mainTitle = find(program.titles, { type: 'full', sub_type: 'Main', size: 120 })?.text || program.titles[0]?.text || '';
        if (!parentTitleOnly && textOnly && program.episode_info?.title) {
            return program.episode_info.title;
        } else if (!parentTitleOnly && program.episode_info?.title) {
            return <span>{mainTitle}<i className="fas fa-angle-right gnview-breadcrumbs-separator" />{program.episode_info.title}</span>;
        } else if (!parentTitleOnly && textOnly && program.sports_info?.title) {
            return program.sports_info.title;
        } else if (program.sports_info?.title) {
            return <span>{mainTitle}<i className="fas fa-angle-right gnview-breadcrumbs-separator" />{program.sports_info.title}</span>;
        } else {
            return mainTitle;
        }
    }
    return '';
};

export const getProgramTitleAltText = (program) => {
    if (program?.titles?.length > 0) {
        const mainTitle = find(program.titles, { type: 'full', sub_type: 'Main', size: 120 })?.text || program.titles[0]?.text || '';
        if (program.gn_progtype === PROGRAM_GNPROGTYPES.EPISODE && program.episode_info?.title) {
            return program.episode_info.title;
        } else if ((program.gn_progtype === PROGRAM_GNPROGTYPES.SPORTS || program.gn_progtype === PROGRAM_GNPROGTYPES.SPORTS_SUMMARY) && program.sports_info?.title) {
            return program.sports_info.title;
        } else {
            return mainTitle;
        }
    }
    return '';
};

export const getProgramDescription = (program) => {
    if (program?.descriptions?.length > 0) {
        return find(program.descriptions, { size: 250 })?.text || program.descriptions[0]?.text || '';
    }
    return '';
};

export const getProgAlerts = (program) => {
    const alerts = [];
    if (program.type === 'TBA' || program.subtype === 'TBA') {
        alerts.push(PROGRAM_ALERT_TYPES.TBA);
    }
    if (program?.images?.length === 0) {
        alerts.push(PROGRAM_ALERT_TYPES.NO_IMAGES);
    }
    if (program?.images?.length > 0 && program?.images?.filter(i => i.category === 'Staple')?.length > 0) {
        alerts.push(PROGRAM_ALERT_TYPES.STAPLE_IMAGES);
    }
    if (!program.descriptions || program.descriptions.length === 0) {
        alerts.push(PROGRAM_ALERT_TYPES.MISSING_DESCRIPTION);
    }
    if (program.type === PROGRAM_TYPES.SERIES && program.subtype === PROGRAM_TYPES.SERIES) {
        alerts.push(PROGRAM_ALERT_TYPES.NO_EPISODE_DATA);
    }
    return alerts;
};

export const containsUnexpiredImages = program => program?.images?.filter(img => !img.expired_date)?.length > 0;

export const getUnexpiredImagesProp = program => {
    if (program?.images?.filter(img => !img.expired_date)?.length > 0) {
        return 'images';
    } else if (program?.parent_season_images?.filter(img => !img.expired_date)?.length > 0) {
        return 'parent_season_images';
    } else if (program?.parent_program_images?.filter(img => !img.expired_date)?.length > 0) {
        return 'parent_program_images';
    } else {
        return '';
    }
};

export const getProgramImageHorizontal = (program) => {
    if (containsUnexpiredImages(program) && program.gn_progtype) {
        const imageType = getUnexpiredImagesProp(program);
        const unexpiredImageProp = `unexpired_${imageType}`;
        program[unexpiredImageProp] = filterRestrictedImages(program?.[imageType] || [])?.filter(img => !img.expired_date);
        program[unexpiredImageProp].sort(function(a, b) {
            return new Date(b.last_modified) - new Date(a.last_modified);
        });
        const defaultImage = program[unexpiredImageProp]?.[0]?.image;
        const preferredCategories = PROGRAM_HORIZONTAL_IMAGE_PREFS[program.gn_progtype]?.preferredCategories || ['Banner-L1', ''];
        const preferredRatios = PROGRAM_HORIZONTAL_IMAGE_PREFS[program.gn_progtype]?.preferredRatios || ['2:1', '16:9', '4:3', '9:5'];
        for (let i = 0; i <= preferredCategories.length; i++) {
            for (let j = 0; j <= preferredRatios.length; j++) {
                let image;
                if (preferredCategories[i]) {
                    image = find(program[unexpiredImageProp], { ratio: preferredRatios[j], category: preferredCategories[i] })?.image;
                } else {
                    image = find(program[unexpiredImageProp], { ratio: preferredRatios[j] })?.image;
                }
                if (image) {
                    return image
                }
            }
        }
        return defaultImage || '';
    }
    return '';
};

export const getProgramImageVertical = (program) => {
    if (containsUnexpiredImages(program) && program.gn_progtype) {
        const imageType = getUnexpiredImagesProp(program);
        const unexpiredImageProp = `unexpired_${imageType}`;
        program[unexpiredImageProp] = filterRestrictedImages(program?.[imageType] || [])?.filter(img => !img.expired_date);
        program[unexpiredImageProp].sort(function(a, b) {
            return new Date(b.last_modified) - new Date(a.last_modified);
        });
        const defaultImage = program[unexpiredImageProp][0]?.image;
        const preferredCategories = PROGRAM_VERTICAL_IMAGE_PREFS[program.gn_progtype]?.preferredCategories || [''];
        const preferredRatios = PROGRAM_VERTICAL_IMAGE_PREFS[program.gn_progtype]?.preferredRatios || ['2:3', '3:4'];
        for (let i = 0; i <= preferredCategories.length; i++) {
            for (let j = 0; j <= preferredRatios.length; j++) {
                let image;
                if (preferredCategories[i]) {
                    image = find(program[unexpiredImageProp], { ratio: preferredRatios[j], category: preferredCategories[i] })?.image;
                } else {
                    image = find(program[unexpiredImageProp], { ratio: preferredRatios[j] })?.image;
                }
                if (image) {
                    return image
                }
            }
        }
        return defaultImage || '';
    }
    return '';
};

export const getProgramImageByRatio = (program, ratio) => {
    if (containsUnexpiredImages(program) && program.gn_progtype) {
        const imageType = getUnexpiredImagesProp(program);
        const unexpiredImageProp = `unexpired_${imageType}`;
        // Filter restricted images - currently saved items is using non-live view, which does not provide identifiers, so only
        // images with 'restricted' in the proivider are filtered when this function is called from SavedItem.
        program[unexpiredImageProp] = filterRestrictedImages(program?.[imageType] || [])?.filter(img => !img.expired_date);
        // Newest images first
        program[unexpiredImageProp].sort(function(a, b) {
            return new Date(b.last_modified) - new Date(a.last_modified);
        });
        const defaultImage = program[unexpiredImageProp][0]?.image;
        if (program.gn_progtype === PROGRAM_GNPROGTYPES.MOVIE) {
            return find(program[unexpiredImageProp], { ratio, category: 'VOD Art'})?.image
                || find(program[unexpiredImageProp], { ratio, category: 'Poster Art' })?.image
                || find(program[unexpiredImageProp], { ratio })?.image
                || defaultImage
                || '';
        } else if (program.gn_progtype === PROGRAM_GNPROGTYPES.SERIES) {
            return find(program[unexpiredImageProp], { ratio, category: 'Banner-L1' })?.image
                || find(program[unexpiredImageProp], { ratio, category: 'Iconic' })?.image
                || find(program[unexpiredImageProp], { ratio })?.image
                || defaultImage
                || '';
        } else {
            return find(program[unexpiredImageProp], { ratio })?.image
                || defaultImage
                || '';
        }
    }
    return '';
};

export const getProgramRating = (program) => {
    if (!isEmpty(program) && program?.ratings?.length > 0 && program.subtype) {
        const match = {};

        if (program.gn_progtype === PROGRAM_GNPROGTYPES.MOVIE) {
            match.ratings_body = 'Motion Picture Association of America';
        } else if (program.gn_progtype === PROGRAM_GNPROGTYPES.SERIES || program.gn_progtype === PROGRAM_GNPROGTYPES.TRIMMED_SERIES) {
            match.ratings_body = 'USA Parental Rating';
        }
        const rating = find(program?.ratings, match)?.code?.toUpperCase();
        return rating || program.ratings[0]?.code || 'None';
    }
    return '';
};

export const getProgramSummaryFields = (program) => {
    if (program?.gn_progtype) {
        switch (program.gn_progtype) {
        case PROGRAM_GNPROGTYPES.MOVIE:
            return PROGRAM_SUMMARY_FIELDS.FEATURE_FILM;
        case PROGRAM_GNPROGTYPES.SERIES:
        case PROGRAM_GNPROGTYPES.TRIMMED_SERIES:
            return PROGRAM_SUMMARY_FIELDS.SERIES;
        case PROGRAM_GNPROGTYPES.EPISODE:
            return PROGRAM_SUMMARY_FIELDS.EPISODE;
        case PROGRAM_GNPROGTYPES.SPORTS_SUMMARY:
            return PROGRAM_SUMMARY_FIELDS.SPORTS_EVENT;
        case PROGRAM_GNPROGTYPES.SPORTS:
            return PROGRAM_SUMMARY_FIELDS.SPORTS_NON_EVENT;
        case PROGRAM_GNPROGTYPES.PREVIEW:
            return PROGRAM_SUMMARY_FIELDS.PREVIEW;
        default:
            return [];
        }
    }
    return [];
};

export const getProgramReleaseHeaders = (program) => {
    if (program?.gn_progtype) {
        switch (program.gn_progtype) {
        case PROGRAM_GNPROGTYPES.MOVIE:
        case PROGRAM_GNPROGTYPES.TRIMMED_SERIES:
        case PROGRAM_GNPROGTYPES.SPORTS_SUMMARY:
        case PROGRAM_GNPROGTYPES.SPORTS:
            return PROGRAM_RELEASES_COLUMN_HEADERS.FEATURE_FILM;
        case PROGRAM_GNPROGTYPES.SERIES:
        case PROGRAM_GNPROGTYPES.EPISODE:
            return PROGRAM_RELEASES_COLUMN_HEADERS.SERIES_EPISODE;
        default:
            return [];
        }
    }
    return [];
};

export const getProgramSeasonAndEpisodeHeaders = (hasLink = false) => {
    return hasLink ? PROGRAM_SEASON_EPISODE_COLUMN_HEADERS_WITH_LINK : PROGRAM_SEASON_EPISODE_COLUMN_HEADERS_WITH_NO_LINK;
}

export const getProgramColor = (programType, gnview = true) => {
    return gnview ? PROGRAM_COLORS?.gnview[programType] : PROGRAM_COLORS?.mobius[programType];
};

export const getReleaseYear = (program) => {
    if (program?.release_year) {
        return Number(program.release_year);
    } else if (program?.original_air_date &&
      moment(program.original_air_date, 'YYYY-MM-DD', true).isValid()) {
        return moment(program.original_air_date).year();
    } else {
        return null
    }
};

export const getTeamInfo = teamsArr => teamsArr?.length > 0 ? teamsArr.map(teamObj => ({venue: teamObj.is_home ? 'Home' : 'Away', ...teamObj})) : [];

export const checkProgramExists = (list, tmsId) => {
    return list.some(program => program.tmsid === tmsId);
}

// Used to format the program details response to look like the typeahead program response
export const formatProgramObject = (program) => {
    return {
        tmsid: program.result.tmsid,
        tms_id: program.result.tms_id,
        title: getProgramTitle(program.result),
        gn_progtype: program.result.gn_progtype,
        type: program.result.type,
        subtype: program.result.subtype,
        duration: program.result.duration,
        origin_countries: program.result.countries_of_origin,
        primary_image: { image: getProgramImageVertical(program.result)},
        top_cast: program.result.cast.map((cast) => cast.name),
        release_year: getReleaseYear(program.result),
        images: program.result.images,
        allEpisodes: program.result?.allEpisodes
    };
}

export const isProgramAnEpisode = (program) => {
    if (program?.tms_id && program?.parent_tms_id) {
        return program?.tms_id !== program?.parent_tms_id;
    }
    return EPISODIC_PROGRAM_SUBTYPES.includes(program?.subtype);
};

/**
 *
 * @param {[object]} lastIngestedTime
 * @returns {string}
 */
export const getLocalGNVIEWIngestedLastupdated = (lastIngestedTime) => {
    if (isEmpty(lastIngestedTime) || isEmpty(lastIngestedTime[0]) || isEmpty(lastIngestedTime[0].last_ingested_time)) {
        return '';
    }
    // eslint-disable-next-line babel/camelcase
    const { last_ingested_time } = lastIngestedTime[0];
    const currentTimezone = moment.tz.guess(true);
    const lastUpdated = `${moment(last_ingested_time).tz(currentTimezone).format('MM/DD/YYYY h:mmA')} (${moment()
        .tz(currentTimezone)
        .zoneAbbr()})`;
    return lastUpdated;
};

export const getRelatedProgramsSubTabs = (categorizedRelatedPrograms) => {
    return Object.entries(categorizedRelatedPrograms)
        .filter(i => i[1].length > 0)
        .map(j => RELATED_PROGRAMS_TAB_NAMES[j[0]]);
};

export const formatRelatedPrograms = (programDetails) => ({
    alternateAudio: programDetails.relationships?.filter((item => item.type === RELATED_PROGRAMS_TAB_NAMES.alternateAudio)) || [],
    audioVersion: programDetails.relationships?.filter((item => item.type === RELATED_PROGRAMS_TAB_NAMES.audioVersion)) || [],
    crossover: programDetails.relationships?.filter((item => item.type === RELATED_PROGRAMS_TAB_NAMES.crossover)) || [],
    customVersion: programDetails.relationships?.filter((item => item.type === RELATED_PROGRAMS_TAB_NAMES.customVersion)) || [],
    derivative: programDetails.relationships?.filter((item => item.type === RELATED_PROGRAMS_TAB_NAMES.derivative)) || [],
    franchise: programDetails.relationships?.filter((item => item.type === RELATED_PROGRAMS_TAB_NAMES.franchise)) || [],
    recommendation: programDetails.relationships?.filter((item => item.type === RELATED_PROGRAMS_TAB_NAMES.recommendation)) || [],
    remake: programDetails.relationships?.filter((item => item.type === RELATED_PROGRAMS_TAB_NAMES.remake)) || [],
    repackage: programDetails.relationships?.filter((item => item.type === RELATED_PROGRAMS_TAB_NAMES.repackage)) || [],
    revival: programDetails.relationships?.filter((item => item.type === RELATED_PROGRAMS_TAB_NAMES.revival)) || [],
    seriesSpecial: programDetails.relationships?.filter((item => item.type === RELATED_PROGRAMS_TAB_NAMES.seriesSpecial)) || [],
    spinoff: programDetails.relationships?.filter((item => item.type === RELATED_PROGRAMS_TAB_NAMES.spinoff)) || [],
    topic: programDetails.relationships?.filter((item => item.type === RELATED_PROGRAMS_TAB_NAMES.topic)) || [],
    superSeries: programDetails.relationships?.filter((item => item.type === RELATED_PROGRAMS_TAB_NAMES.superSeries)) || []
});

/**
 * Parses a URL search string and returns an object with specific query parameters.
 * @param {string} search - The URL search string (e.g., '?key1=value1&key2=value2').
 * @returns {Object} An object with parsed query parameters.
 */
export const parseUrlToParams = (search) => {
    // Ensure the search string starts with '?' for URLSearchParams
    const searchString = search.startsWith('?') ? search : `?${search}`;
    const params = new URLSearchParams(searchString);

    // Helper function to conditionally include properties
    const addParam = (key, value) => {
        if (value === 'undefined' || value === null) {
            return {};
        }
        return { [key]: value.includes(',') ? value.split(',') : value };
    };

    // Build the result object using object spreading
    return {
        ...addParam('subTypes', params.get('programSubtypes')),
        ...addParam('programTypes', params.get('programTypes')),
        ...addParam('releaseYear', params.get('releaseYear')),
        ...addParam('title', params.get('title')),
        ...addParam('titleLanguage', params.get('titleLanguage'))
    };
};