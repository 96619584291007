import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { MODULES } from '../../../config/Modules';
import ClassNames from 'classnames';
import './Menu.scss';
import { ROUTES } from '../../../config/Routes';
import { MODULES_PERMITTED } from '../../../constants/PrivateRoute';
import { SUPPORT, DATA_ALERTS } from '../../../constants/Menu';
import {
    gvauthSelEmail,
    gvauthSelFeatureFlags,
    gvauthSelInternalUser,
    gvauthSelModulesPermitted
} from '../../../reducers/GNVAuthReducer';

import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { gnviewAdvancedSearchFieldsClear } from '../../../actions/GNViewActions';
import { gnviewSetLastURLVisited } from '../../../actions/GNViewAuthActions';
import { Badge } from 'react-bootstrap';
import { FEATURE_FLAGS } from '../../../constants/App';
import { gnviewSelDataAlertsAlertsCounts } from '../../../reducers/DataAlertsReducer';
import { DATA_ALERTS_WINDOWS } from '../../../constants/DataAlerts';
import { gnviewGetAlertsCount } from '../../../actions/SubscriptionActions';
import { gnviewDataAlertsSetAlertsCounts } from '../../../actions/DataAlertsActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';

export class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuItems: [...MODULES].map((group) => {
                return {
                    ...group
                };
            }),
            currentGroup: null,
            currentItem: null,
            notificationCount: props.alertsCounts?.[DATA_ALERTS_WINDOWS?.[0]?.rangeValue] || 0,
            email: props.email
        };
    }

    // Make sure on refresh that we are resetting the proper menu items to active
    setCurrentPathAsActive = () => {
        const path = this.props.location.pathname;
        if (path) {
            this.state.menuItems.map((group) => {
                const item = group.views.filter((view) => path.includes(view.path));
                if (item.length > 0) {
                    this.setState({ currentGroup: group.title, currentItem: item[0].title });
                }
            });
        }
    };

    componentDidMount() {
        this.setCurrentPathAsActive();
        gnviewGetAlertsCount(this.props.email, 'this-week').then((response) => {
            const count = {'this-week': response?.result?.alert_count}
            this.props.gnviewDataAlertsSetAlertsCounts(count);
        }).catch(error => {
            gnviewSendLogMessage(error)
        })
    }

    componentDidUpdate(prevProps) {
        const path = this.props.location.pathname;
        if (prevProps?.location?.pathname !== path) {
            this.setCurrentPathAsActive();
        }
        const latestNotificationCount = this.props.alertsCounts?.[DATA_ALERTS_WINDOWS[0]?.rangeValue] || 0;
        const prevNotificationCount = prevProps.alertsCounts?.[DATA_ALERTS_WINDOWS[0]?.rangeValue] || 0;
        if (prevNotificationCount !== latestNotificationCount) {
            this.setState({notificationCount: latestNotificationCount});
        }
    }

    onMenuItemClick = (group, item) => {
        if (item.path === ROUTES.ADVANCED_SEARCH) {
            this.props.gnviewAdvancedSearchFieldsClear();
        }
        if (!item.newTab) {
            this.setState({ currentGroup: group.title, currentItem: item.title });
        }
    };

    canShowDataAlertsTab() {
        return this.props.featureFlags?.includes(FEATURE_FLAGS.CHANNEL_ALERTS)
    }

    render() {
        const renderLinkMenuItem = (group, item) => (<Link to={item.path} onClick={() => this.onMenuItemClick(group, item)} className={ClassNames('menu-group-items-content', { 'selected': item.title === this.state.currentItem })} key={item.path}>
            <span className={ClassNames('menu-group-item-link', { 'selected': item.title === this.state.currentItem })}>
                <span className={ClassNames('menu-item-icon', { 'indented': item.indented })}><i className={item.icon} /></span>
                {item.title}
                { item.notificationCounter && this.state.notificationCount > 0 && <Badge pill variant='primary' data-testid='notifications-count'> {this.state.notificationCount} </Badge> }
            </span>
        </Link>);
        return (
            <div className='menu-bar'>
                {this.state.menuItems.map((group, index) => {
                    const shouldShowGroup =
                        (group.title === SUPPORT &&
                            this.props.modulesPermitted?.includes(MODULES_PERMITTED.REPORT_AN_ISSUE) &&
                            this.props.modulesPermitted?.includes(MODULES_PERMITTED.SUBMIT_FEEDBACK) &&
                            !this.props.internalUser) ||
                        (group.title === SUPPORT &&
                            !this.props.modulesPermitted &&
                            !this.props.internalUser) ||
                        (group.title !== SUPPORT);
                    return shouldShowGroup ? (
                        <div className='menu-group' key={index}>
                            {group?.topBorder && <div className="top-border" />}
                            {group?.title && <div className={ClassNames('menu-title', { 'selected': group.title === this.state.currentGroup })}>
                                {group?.title}
                            </div>}
                            <div className='menu-group-items'>
                                {group.views.map((item) => {
                                    if (!item?.newTab) {
                                        if (item.title === DATA_ALERTS && !this.canShowDataAlertsTab()) {
                                            return null;
                                        } else {
                                            return renderLinkMenuItem(group, item);
                                        }
                                    } else {
                                        return (
                                            <a href={item.path} target="_blank" rel="noreferrer" onClick={() => this.onMenuItemClick(group, item)} className={ClassNames('menu-group-items-content', { 'selected': item.title === this.state.currentItem })} key={item.path}>
                                                <span className={ClassNames('menu-group-item-link', { 'selected': item.title === this.state.currentItem })}>
                                                    <span className={ClassNames('menu-item-icon', { 'indented': item.indented })}><i className={item.icon} /></span>
                                                    {item.title}
                                                </span>
                                            </a>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    ) : null;
                })}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        internalUser: gvauthSelInternalUser(state),
        modulesPermitted: gvauthSelModulesPermitted(state),
        featureFlags: gvauthSelFeatureFlags(state),
        alertsCounts: gnviewSelDataAlertsAlertsCounts(state),
        email: gvauthSelEmail(state)
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewSetLastURLVisited,
        gnviewAdvancedSearchFieldsClear,
        gnviewDataAlertsSetAlertsCounts
    }, dispatch)
};

Menu.propTypes = {
    internalUser: PropTypes.bool,
    gnviewAdvancedSearchFieldsClear: PropTypes.func,
    gnviewSetLastURLVisited: PropTypes.func,
    gnviewSelDataAlertsAlertsCounts: PropTypes.func,
    gnviewDataAlertsSetAlertsCounts: PropTypes.func,
    gnviewGetAlertsCount: PropTypes.func,
    location: PropTypes.object.isRequired,
    modulesPermitted: PropTypes.array,
    alertsCounts: PropTypes.any,
    featureFlags: PropTypes.array,
    email: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));