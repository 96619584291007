import React, { useState } from 'react';
import './GNViewTeaser.scss';
import ClassNames from 'classnames';
import {
    WHAT_YOU_GET_HEADER,
    WHAT_YOU_GET_DESC,
    WHAT_YOU_GET_TYPES,
    WHAT_YOU_GET_IMAGES,
    LANDING_CONTACT_SALES
} from '../constants/GNViewTeaser';
import ErrorBoundary from '../components/common/errorBoundary/ErrorBoundary';
import { Button } from 'react-bootstrap';

export const GNViewTeaser = () => {
    const [image, setImage] = useState(WHAT_YOU_GET_IMAGES[WHAT_YOU_GET_TYPES.PROGRAM_DETAILS].img);

    return (
        <ErrorBoundary>
            <div className='gnview-teaser-main-container'>
                <div className='what-you-get-section-container'>
                    <div className='what-you-get-header'>{WHAT_YOU_GET_HEADER}</div>
                    <div className='what-you-get-desc'>{WHAT_YOU_GET_DESC}</div>
                    <a href={LANDING_CONTACT_SALES.url} className='contact-sales-button' id='request-btn'>
                        <Button variant='bright-blue' className='request-demo-button'>{LANDING_CONTACT_SALES.buttonText}</Button>
                    </a>
                    <div className='what-you-get-selector'>
                        {Object.values(WHAT_YOU_GET_TYPES).map((type, idx) => {
                            const selected = image === WHAT_YOU_GET_IMAGES[type].img;
                            return (
                                <div className='selector-container' key={idx}>
                                    <div
                                        className={ClassNames('selector-type', { [type]: true, selected })}
                                        onClick={() => setImage(WHAT_YOU_GET_IMAGES[type].img)}>
                                        {type}
                                    </div>
                                    {selected && <div className='selector-description'>{WHAT_YOU_GET_IMAGES[type].description}</div>}
                                </div>
                            )
                        })}
                    </div>
                    <img src={image} className='what-you-get-image' alt='What You Get Cover Photo' />
                </div>
            </div>
        </ErrorBoundary>
    )
};

export default GNViewTeaser;