import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

export const AnyOrAllToggle = ({section, handleFormChange, values}) => {
    const defaultChecked = values[section.IS_ANY_SELECTED];
    return (
        <div className='any-or-all-main-container'>
            <Form.Check
                custom
                inline
                name={section.VALIDATION_LABEL}
                id={`custom-radio-${section.ANY_SELECTED}`}
                type='radio'
                label={section.ANY_SELECTED}
                onClick={() =>
                    handleFormChange(
                        section.IS_ANY_SELECTED,
                        true
                    )
                }
                defaultChecked={defaultChecked}
                checked={values[section.IS_ANY_SELECTED]}
            />
            <Form.Check
                custom
                inline
                name={section.VALIDATION_LABEL}
                id={`custom-radio-${section.ALL_SELECTED}`}
                type='radio'
                label={section.ALL_SELECTED}
                onClick={() =>
                    handleFormChange(
                        section.IS_ANY_SELECTED,
                        false
                    )
                }
                defaultChecked={!defaultChecked}
                checked={!values[section.IS_ANY_SELECTED]}
            />
        </div>
    )
};

AnyOrAllToggle.propTypes = {
    section: PropTypes.object.isRequired,
    handleFormChange: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired
};

export default AnyOrAllToggle;