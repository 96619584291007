import countries from "i18n-iso-countries";
import { LANGUAGES } from '../constants/Languages';
import isEmpty from 'lodash.isempty';
import emojiRegex from 'emoji-regex';

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

// Replace null item in provided array with replacement appended at end
export const replaceNull = (arr, replacement) => {
    if (arr?.length > 0 && (arr.includes(null) || arr.includes('null'))) {
        const idx = arr.indexOf(null) !== -1 ? arr.indexOf(null) : arr.indexOf('null');
        arr.splice(idx, 1);
        if (replacement) {
            arr.push(replacement);
        }
    }
};

// Finds objects in array using provided prop and category value for comparison, compares with replacement if prop is null
export const getObjsFind = (arr, prop, category, replacement) => {
    return arr?.length > 0 ? arr.find(obj => {
        if (category === replacement) {
            return obj[prop] === null;
        } else {
            return obj[prop] === category;
        }
    }) : {};
};

// Filters objects in array using provided prop and category value for comparison, compares with replacement if prop is null
export const getObjsFilter = (arr, prop, category, replacement) => {
    return arr?.length > 0 ? arr.filter(obj => {
        if (category === replacement) {
            return obj[prop] === null;
        } else {
            return obj[prop] === category;
        }
    }) : [];
};

// Returns first element of provided array if non-empty, null or provided default if provided argument is non-array or empty array
export const getFirstElement = (arr, def = null) => arr?.length > 0 ? arr[0] : def;

// Converts an array of country codes ['USA'] to an array with code and name [{code: 'USA', name: 'United States of America'}]
export const convertCountryCodes = (countryCodes) => {
    const countryNameAndCodes = countryCodes?.reduce((result, code) => {
        // Only add the country if we can convert the country code to name
        if (countries.getName(code, 'en')) {
            result.push({ code, name: countries.getName(code, 'en') });
        }
        return result;
    }, []);

    // Sort alphabetically a-z
    return countryNameAndCodes?.sort((a, b) => a.name > b.name ? 1 : -1);
};

export const formatExportFileName = (response) => (
    response.headers['content-disposition']?.split("filename=")[1].slice(1, -1)
)

export const downloadExportFile = (data, dataType, fileName) => {
    // For IE 10+ and Edge.
    if (window.navigator.msSaveBlob) {
        const blob = new Blob([data], {type: dataType});
        window.navigator.msSaveBlob(blob, fileName);
    } else {
        const downloadLink = document.createElement('a');
        downloadLink.download = fileName;
        // For newer browsers
        if (window.URL.createObjectURL) {
            const blob = new Blob([data], {type: dataType});
            downloadLink.href = window.URL.createObjectURL(blob);
        } else {
            // For older browsers
            downloadLink.href = `data:${dataType}, ${encodeURIComponent(data)}`;
        }
        // Force click event to trigger download
        if (document.createEvent) {
            const event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            downloadLink.dispatchEvent(event);
        } else {
            downloadLink.click();
        }
    }
};

export const numberWithCommas = (num) => {
    return !isNaN(num) ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0';
};

export const convertLanguageFromCode = (code) => {
    return LANGUAGES[code] || code || '';
};

export const convertLanguageCodeList = languageCodes => languageCodes?.length > 0 ? languageCodes.map(code => convertLanguageFromCode(code)) : [];

export const sortByTitle = (a, b) => {
    const titleA = a?.title?.toUpperCase();
    const titleB = b?.title?.toUpperCase();
    if (titleA > titleB) {
        return 1;
    } else if (titleA < titleB) {
        return -1;
    } else {
        return 0;
    }
};

export const getIdFromURL = (url, route) => {
    return url?.split('/').filter((str) => !isEmpty(str) && !route.includes(str))[0] || "";
}

export const hasEmojiText = (text) => {
    const regex = emojiRegex();
    const matches = [...text.matchAll(regex)];
    return matches.length > 0;
}

export const convertFileToBase64 = (file) => new Promise((resolve, reject) => {
    if (file instanceof File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = (error) => reject(error);
    } else {
        reject('No file given')
    }
});

// given size in bytes, return 10MB etc
export const formatFileSize = (size) => {
    if (isNaN(size)) {
        size = 0;
    }
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return Math.round(size / Math.pow(1024, i)) + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

/**
 * Objects in an array can be filtered by key and value
 * @param {[object]} arr - Object of Array
 * @param {string} key - Key of Object in an array that you would like to find
 * @param {string} value - Value of Object in an array that you would like to find
 * @param {boolean} remove - whether to filter the object out
 * @returns {[object]} - Object of Array
 */
export const filterObjsWithKeyValueInArr = (arr, key, value, keepObj = false) => {
    return arr?.length > 0
        ? arr.filter((obj) => {
            const hasValue = obj[key]?.toLowerCase()?.includes(value?.toLowerCase());
            return keepObj ? hasValue : !hasValue;
        })
        : [];
};

export const replaceNextLineCharWithSalesforceNextLineChar = (inputString) => {
    if (typeof (inputString) === 'string' && inputString.length !== 0) {
        return inputString?.replace(/\n/g, '\r\n') || inputString;
    }
    return null
}
