import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './ContactUs.scss';
import { CONTACT_US_HEADER, CONTACT_US_SUBHEADER, LEARN_MORE_LINK, UPGRADE_YOUR_ACCOUNT } from '../constants/ContactUs';
import ErrorBoundary from '../components/common/errorBoundary/ErrorBoundary';
import PropTypes from "prop-types";
import lockIcon from '../images/403_Icon.png';
import { Button } from 'react-bootstrap';


export class ContactUsView extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ErrorBoundary>
                <div className='gnview-contact-us-main-container'>
                    <img src={lockIcon} alt="Access Denied" className='lock-icon'/>
                    <div className='contact-us-header'>{CONTACT_US_HEADER}</div>
                    <div className='subheader'>{CONTACT_US_SUBHEADER}</div>
                    <Button className='upgrade-account-btn'><a href={LEARN_MORE_LINK} target='_blank' rel='noreferrer'>{UPGRADE_YOUR_ACCOUNT}</a></Button>
                </div>
            </ErrorBoundary>
        )
    }
}

ContactUsView.propTypes = {
    hasSchedulesEntitlement: PropTypes.func.isRequired
};

const mapStateToProps = () => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsView);