import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PROGRAM_ATTRIBUTES_DEFAULT_PLACEHOLDERS, PROGRAM_ATTRIBUTES_LANGUAGE_SECTION } from '../../../../constants/AdvancedSearch';
import { useSelector } from 'react-redux';
import { gvauthGetEntitledProgramLanguages } from '../../../../reducers/GNVAuthReducer';
import { convertLanguageFromCode } from '../../../../utils/GeneralUtils';
import GNDropdown from '../../../common/gnDropdown/GNDropdown';

const Languages = ({ handleFormChange, values }) => {
    const [selectedTitleLanguage, setSelectedTitleLanguage] = useState(
        values[PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.TITLE_LANG.VALIDATION_LABEL]
    );

    const [selectedDescriptionLanguage, setSelectedDescriptionLanguage] = useState(
        values[PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.DESCRIPTION_LANG.VALIDATION_LABEL]
    );

    const [selectedAudioLanguage, setSelectedAudioLanguage] = useState(
        values[PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.AUDIO_LANG.VALIDATION_LABEL]
    );

    const entitledLanguages = [
        PROGRAM_ATTRIBUTES_DEFAULT_PLACEHOLDERS.LANGUAGE,
        ...useSelector(gvauthGetEntitledProgramLanguages)
            ?.sort((a, b) => (a > b ? 1 : -1))
            ?.map((langCode) => ({ name: convertLanguageFromCode(langCode), value: langCode }))
    ];

    useEffect(() => {
        if (selectedTitleLanguage !== values[PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.TITLE_LANG.VALIDATION_LABEL]) {
            setSelectedTitleLanguage(values[PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.TITLE_LANG.VALIDATION_LABEL]);
        }
        if (
            selectedDescriptionLanguage !==
            values[PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.DESCRIPTION_LANG.VALIDATION_LABEL]
        ) {
            setSelectedDescriptionLanguage(
                values[PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.DESCRIPTION_LANG.VALIDATION_LABEL]
            );
        }
        if (selectedAudioLanguage !== values[PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.AUDIO_LANG.VALIDATION_LABEL]) {
            setSelectedAudioLanguage(values[PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.AUDIO_LANG.VALIDATION_LABEL]);
        }
    }, [selectedTitleLanguage, selectedDescriptionLanguage, selectedAudioLanguage, values]);

    return (
        <div className='programAttributesSections'>
            <div className='programAttributesSections__section'>{PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.NAME}</div>
            {/* Entitled languages has more than just the default one in the list */}
            {entitledLanguages?.length > 1 && <div className='programAttributesSections__section-dropdown'>
                <GNDropdown
                    label={PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.TITLE_LANG.LABEL}
                    placeholder={PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.TITLE_LANG.PLACEHOLDER}
                    options={entitledLanguages}
                    value={selectedTitleLanguage}
                    handleChange={(item) => {
                        setSelectedTitleLanguage(item);
                        handleFormChange(PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.TITLE_LANG.VALIDATION_LABEL, item);
                    }}
                />
                <GNDropdown
                    label={PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.DESCRIPTION_LANG.LABEL}
                    placeholder={PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.DESCRIPTION_LANG.PLACEHOLDER}
                    options={entitledLanguages}
                    value={selectedDescriptionLanguage}
                    handleChange={(item) => {
                        setSelectedDescriptionLanguage(item);
                        handleFormChange(PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.DESCRIPTION_LANG.VALIDATION_LABEL, item);
                    }}
                />
                <GNDropdown
                    label={PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.AUDIO_LANG.LABEL}
                    placeholder={PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.AUDIO_LANG.PLACEHOLDER}
                    options={entitledLanguages}
                    value={selectedAudioLanguage}
                    handleChange={(item) => {
                        setSelectedAudioLanguage(item);
                        handleFormChange(PROGRAM_ATTRIBUTES_LANGUAGE_SECTION.AUDIO_LANG.VALIDATION_LABEL, item);
                    }}
                />
            </div>}
        </div>
    );
};

Languages.propTypes = {
    handleFormChange: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired
};

export default Languages;
