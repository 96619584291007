import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../config/Routes';
import { gnviewGetStation, gnviewStationDetailsAddRecentStation, gnviewAddSelectedStation } from '../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import "./StationsDetails.scss";
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import { CHANNEL_DETAILS, CHANNEL_DETAILS_TABS, SUBSCRIPTIONS_LIMIT, SUBSCRIPTIONS_LIMIT_TOOLTIP, SUBSCRIPTIONS_TURN_ON_TOOLTIP, UNSUBSCRIBE_MODAL, SUBSCRIBE_TO_ALERTS_LABELS } from "../../../constants/Station";
import { gnviewSelUserSettings, gvauthHasChannelAlerts, gvauthSelEmail, gvauthSelEntitlements } from '../../../reducers/GNVAuthReducer';
import { Container, Button } from "react-bootstrap";
import GNRadioGroup from '../../../components/common/gnRadioGroup/GNRadioGroup';
import StationsOverviewTab from './tabs/StationsOverviewTab';
import StationsImagesTab from './tabs/StationsImagesTab';
import ReactRouterPropTypes from "react-router-prop-types";
import { GNToggleButton } from '../../../components/common/gnToggleButton/GNToggleButton';
import { SubscribeModal } from './Subscribe/SubscribeModal';
import GNConfirmationModal from '../../../components/common/gnConfirmationModal/GNConfirmationModal';
import GNNotification from '../../../components/common/gnNotification/GNNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteSubscription } from '../../../actions/SubscriptionActions';
import { gnviewGetUserSubscriptions, gnviewDataAlertsSetSubscriptions } from '../../../actions/DataAlertsActions';
import { gnviewDataAlertsSetAlertsCounts } from '../../../actions/DataAlertsActions';
import { gnviewGetAlertsCount } from '../../../actions/SubscriptionActions';

export class StationsDetailsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStation: {},
            selectedTab: CHANNEL_DETAILS_TABS.overview,
            isLoading: true,
            subscriptionsLoading: true,
            unentitled: false,
            serverError: false,
            invalidIDError: false,
            currentSubscription: {},
            alreadySubscribed: false,
            showSubscribeModal: false,
            showConfirmUnsubscribeModal: false,
            subscriptions: [],
            showNotification: false,
            notificationMessage: '',
            isUnsubscriptionInProgress: false
        }
    }

    noError = () => {
        return !(this.state.invalidIDError || this.state.serverError);
    }

    handleGetStationError(error) {
        if (error?.response?.status === 403) {
            this.setState({ selectedStation: { name: error?.response?.data?.name, id: error?.response?.data?.id, country_codes: error?.response?.data?.country_codes }, unentitled: true, isLoading: false });
        } else if (error?.response?.status === 404) {
            this.setState({ isLoading: false, invalidIDError: true });
            // Replace the current bad URL with /not-found so when user navigates back it will be /stations not /stations/123
            this.props.history.replace(ROUTES.NOT_FOUND);
        } else {
            this.setState({ isLoading: false, serverError: true });
        }
    }

    componentDidMount() {
        const stationId = this.props.match ? this.props.match.params.stationId : null;
        if (stationId) {
            this.props.gnviewGetStation(stationId).then((response) => {
                this.props.gnviewStationDetailsAddRecentStation(stationId);
                this.setState({ selectedStation: response.result, isLoading: false });
                this.cancelToken = response.cancelToken;
            }
            ).catch((error) => {
                this.handleGetStationError(error);
                this.props.gnviewSendLogMessage(`gnviewGetStation error: ${error.message}`, "ERROR", error, { stationId });
            });
        }

        const username = this.props.userSettings?.username;
        if (username) {
            this.props.gnviewGetUserSubscriptions(username)
                .then(response => {
                    const subscriptions = response || [];
                    this.setState({
                        subscriptions,
                        subscriptionsLoading: false
                    });
                    const currentSubscription = subscriptions.find(s => s.station_id.toString() === this.state.selectedStation.id.toString());
                    this.setState({ alreadySubscribed: Boolean(currentSubscription), currentSubscription });
                    this.cancelToken = response.cancelToken;
                })
                .catch(error => {
                    this.props.gnviewSendLogMessage(`gnviewGetUserSubscriptions error: ${error.message}`, "ERROR", error, { username });
                })
                .finally(() => {
                    this.setState({ subscriptionsLoading: false });
                });
        }
    }

    componentDidUpdate(prevProps) {
        const stationId = this.props.match?.params.stationId;
        if (prevProps.match.params.stationId !== stationId) {
            const alreadySubscribed = Boolean(this.statesubscriptions?.find(s => s.station_id === stationId))
            this.props.gnviewGetStation(stationId).then((response) => {
                this.setState({ selectedStation: response.result, isLoading: false, alreadySubscribed });
                this.cancelToken = response.cancelToken;
            }
            ).catch((error) => {
                this.handleGetStationError(error);
                this.props.gnviewSendLogMessage(`gnviewGetStation error: ${error.message}`, "ERROR", error, { stationId });
            });
        }
    }

    componentWillUnmount() {
        if (this.cancelToken) {
            this.cancelToken.cancel();
        }
    }

    onTabSelect = (tab) => {
        this.setState({ selectedTab: tab });
    }

    onSchedulesButtonClick = () => {
        this.props.gnviewAddSelectedStation(this.state.selectedStation);
        window.open(`${window.location.origin}${ROUTES.SCHEDULES}`, "_blank");
    }

    onToggleClick = (event) => {
        if (!this.state.isLoading && !this.state.subscriptionsLoading) {
            if (event.target.checked) {
                this.setState({ showSubscribeModal: event.target.checked });
            } else if (!event.target.checked) {
                this.setState({ showConfirmUnsubscribeModal: !event.target.checked })
            }
        }
    }

    isSubscriptionToggleDisabled = () => {
        return this.state.subscriptions?.length >= SUBSCRIPTIONS_LIMIT;
    }

    shouldShowSubscribeModal = () => {
        return this.props.isChannelAlertsFFOn && !this.state.isLoading && !this.state.alreadySubscribed && this.state.showSubscribeModal;
    }

    getToggleButtonTooltip = () => {
        if (this.isSubscriptionToggleDisabled()) {
            return SUBSCRIPTIONS_LIMIT_TOOLTIP;
        } else if (!this.state.alreadySubscribed) {
            return SUBSCRIPTIONS_TURN_ON_TOOLTIP;
        }

        return '';
    }

    handleUnsubscription = async () => {
        const subscriptionId = this.state.currentSubscription?.subscription_id;
        try {
            this.setState({ isUnsubscriptionInProgress: true })
            await this.props.deleteSubscription(subscriptionId);
            this.setState({ alreadySubscribed: false });
        } catch (error) {
            this.props.gnviewSendLogMessage(`deleteSubscription error: ${error.message}`, "ERROR", error, { subscriptionId });
            this.setState({ showNotification: true, notificationMessage: SUBSCRIBE_TO_ALERTS_LABELS.UNSUBSCRIPTION_ERROR })
        } finally {
            this.setState({ showConfirmUnsubscribeModal: false, isUnsubscriptionInProgress: false })
            gnviewGetAlertsCount(this.props.email, 'this-week').then((response) => {
                const count = {'this-week': response?.result?.alert_count}
                this.props.gnviewDataAlertsSetAlertsCounts(count)
            });
        }
    }

    handleCancelUnsubscription = () => {
        this.setState({ showConfirmUnsubscribeModal: false });
    }

    handleAfterSubscription = (newSubscription) => {
        const updatedSubscriptions = this.props.subscriptions ? this.props.subscriptions.map((sub) => {
            if (sub.subscription_id === this.state.currentSubscription?.subscription_id) {
                sub.distributionPlatforms = newSubscription.distributionPlatforms;
                sub.labels = newSubscription.labels;
                sub.source_of_channel = newSubscription.source_of_channel;
                sub.station_id = newSubscription.station_id;
                sub.station_name = newSubscription.station_name;
                sub.subscription_id = newSubscription.subscription_id;
                sub.station_image = newSubscription.station_image || '';
            }
            return sub;
        }) : [];
        gnviewGetAlertsCount(this.props.email, 'this-week').then((response) => {
            const count = {'this-week': response?.result?.alert_count}
            this.props.gnviewDataAlertsSetAlertsCounts(count)
        });
        this.props.gnviewDataAlertsSetSubscriptions(updatedSubscriptions);
        this.setState({ subscriptions: updatedSubscriptions, alreadySubscribed: true, showSubscribeModal: false, currentSubscription: newSubscription });
    }

    handleErrorSubscription = () => {
        this.setState({ showSubscribeModal: false, notificationMessage: SUBSCRIBE_TO_ALERTS_LABELS.SUBSCRIPTION_ERROR, showNotification: true })
    }

    render() {
        return (
            <ErrorBoundary>
                <div className="gnview-station-details-content">
                    {!this.state.serverError && !this.state.invalidIDError && !this.state.isLoading && <div className="filter-bar-container">
                        <div className="stations-top-section">
                            <div className="gnview-breadcrumbs">
                                <Link to={ROUTES.STATION_DETAILS}>{CHANNEL_DETAILS}</Link>
                                <i className="fas fa-angle-right gnview-breadcrumbs-separator" />
                                <span>{this.state.selectedStation.id}</span>
                                <div className="station-title">
                                    <span>{this.state.selectedStation.name}</span>
                                    <span className="dd-sub-title"> ({this.state.selectedStation.id})</span>
                                </div>
                                {this.state.unentitled && <span className='unentitled-message'>Access Denied - You are not entitled to view this Station</span>}
                            </div>
                            <div className='right-section'>
                                {this.props.isChannelAlertsFFOn && !this.state.subscriptionsLoading && (
                                    this.state.isUnsubscriptionInProgress ? <FontAwesomeIcon icon='spinner' /> : <GNToggleButton
                                        onClick={this.onToggleClick}
                                        checked={Boolean(this.state.alreadySubscribed)}
                                        defaultChecked={Boolean(this.state.alreadySubscribed)}
                                        tooltipMsg={this.getToggleButtonTooltip()}
                                        isDisabled={this.isSubscriptionToggleDisabled()} />)
                                }
                                <Button className='schedules-button' variant='primary' onClick={this.onSchedulesButtonClick}><i className='fas fa-clock' />View Channel Schedule</Button>
                            </div>
                        </div>
                    </div>}
                </div>
                <Container fluid>
                    {!this.state.isLoading && !this.state.unentitled && <GNRadioGroup list={Object.values(CHANNEL_DETAILS_TABS)} style="flat" onSelect={this.onTabSelect} default={CHANNEL_DETAILS_TABS.overview} />}
                    {!this.state.isLoading && this.state.selectedTab === CHANNEL_DETAILS_TABS.overview && <StationsOverviewTab
                        selectedStation={this.state.selectedStation}
                        isLoading={this.state.isLoading}
                        noError={this.noError}
                        unentitled={this.state.unentitled}
                        invalidIDError={this.state.invalidIDError}
                        serverError={this.state.serverError} />}
                    {!this.state.isLoading && this.state.selectedTab === CHANNEL_DETAILS_TABS.images && <StationsImagesTab
                        selectedStation={this.state.selectedStation}
                        noError={this.noError}
                        unentitled={this.state.unentitled} />}
                    {this.shouldShowSubscribeModal() &&
                        <SubscribeModal
                            show={this.state.showSubscribeModal}
                            handleModal={() => this.setState({ showSubscribeModal: !this.state.showSubscribeModal })}
                            selectedStation={this.state.selectedStation}
                            username={this.props.userSettings?.username}
                            handleAfterSubscription={this.handleAfterSubscription}
                            handleErrorSubscription={this.handleErrorSubscription}
                        />}
                    {this.state.showConfirmUnsubscribeModal && <GNConfirmationModal
                        cancelButtonText={UNSUBSCRIBE_MODAL.CANCEL}
                        message={UNSUBSCRIBE_MODAL.TEXT}
                        show={this.state.showConfirmUnsubscribeModal}
                        cancelButtonHandler={this.handleCancelUnsubscription}
                        submitButtonHandler={this.handleUnsubscription}
                        submitButtonText={UNSUBSCRIBE_MODAL.UNSUBSCRIBE}
                        title={UNSUBSCRIBE_MODAL.TITLE}
                    />}
                    {this.props.isChannelAlertsFFOn && !this.state.isLoading && this.state.showNotification &&
                        <GNNotification
                            show={this.state.showNotification}
                            milliseconds={5000}
                            success={false}
                            handleShow={(show) => this.setState({ showNotification: show })}
                            message={this.state.notificationMessage} />}
                </Container>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        entitlements: gvauthSelEntitlements(state),
        userSettings: gnviewSelUserSettings(state),
        isChannelAlertsFFOn: gvauthHasChannelAlerts(state),
        email: gvauthSelEmail(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewDataAlertsSetSubscriptions,
        gnviewGetStation,
        deleteSubscription,
        gnviewGetUserSubscriptions,
        gnviewSendLogMessage,
        gnviewStationDetailsAddRecentStation,
        gnviewAddSelectedStation,
        gnviewDataAlertsSetAlertsCounts
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(StationsDetailsView);

StationsDetailsView.propTypes = {
    gnviewSendLogMessage: PropTypes.func,
    gnviewGetStation: PropTypes.func.isRequired,
    deleteSubscription: PropTypes.func,
    gnviewGetUserSubscriptions: PropTypes.func.isRequired,
    userSettings: PropTypes.any,
    isChannelAlertsFFOn: PropTypes.bool,
    history: ReactRouterPropTypes.history,
    match: ReactRouterPropTypes.match,
    gnviewStationDetailsAddRecentStation: PropTypes.func,
    gnviewAddSelectedStation: PropTypes.func,
    gnviewDataAlertsSetSubscriptions: PropTypes.func,
    subscriptions: PropTypes.array,
    email: PropTypes.string,
    gnviewDataAlertsSetAlertsCounts: PropTypes.func
}