import {
    GVAUTH_AUTH_UPDATE,
    GVAUTH_AUTH_CLEAR,
    GVAUTH_USER_SETTINGS_UPDATE,
    GVAUTH_SET_LAST_VISITED_URL,
    GVAUTH_LOOKUP_COUNT_UPDATE
} from './ActionTypes';
import { API_ENDPOINTS } from '../config/Api';
import apiService from '../services/ApiService';

export const gnviewAuthUpdate = (authState) => ({
    type: GVAUTH_AUTH_UPDATE,
    payload: authState
});

export const gnviewUserSettingsUpdate = (userSettings) => ({
    type: GVAUTH_USER_SETTINGS_UPDATE,
    payload: {
        userSettings
    }
});

/** Lookup **/
export const gnviewLookupCountUpdate = (lookupInfo) => ({
    type: GVAUTH_LOOKUP_COUNT_UPDATE,
    payload: {
        ...lookupInfo
    }
});

export const gnviewPostLookup = (body) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.LOOKUP}`, body, 'post');
    }
};

export const gnviewGetLookupReset = (email) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.LOOKUP_RESET}?email=${email}`, {}, 'get');
    }
};

export const gnviewClearState = () => ({
    type: GVAUTH_AUTH_CLEAR
});

export const gnviewSetLastURLVisited = (url) => ({
    type: GVAUTH_SET_LAST_VISITED_URL,
    payload: { url }
});