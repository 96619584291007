import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import "./SubscribeModal.scss";
import GNModal from '../../../../components/common/gnModal/GNModal';
import { SUBSCRIBE_TO_STATION_MODAL, SOURCE_TYPES_MAPPING } from '../../../../constants/Station';
import SubscribeForm from './SubscribeForm/SubscribeForm';
import LoadingSpinner from '../../../../components/common/loadingSpinner/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { gnviewSendLogMessage } from '../../../../services/GeneralService';
import isEmpty from 'lodash.isempty';
import { gvauthSelEmail } from '../../../../reducers/GNVAuthReducer';
import { gnviewCreateSubscription } from '../../../../actions/SubscriptionActions';

export const SubscribeModal = ({ show, handleModal, selectedStation, handleAfterSubscription, handleErrorSubscription, subscriptions, trackChannel = false }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [values, setValues] = useState({});
    const userEmail = useSelector(gvauthSelEmail);

    const dispatch = useDispatch();

    const handleFormSubmit = async () => {
        if (!values || isEmpty(values) || (!values.prgSvcID && !selectedStation?.id)) {
            return;
        }

        const body = {
            ...values,
            prgSvcID: selectedStation?.id || values.prgSvcID,
            sourceType: SOURCE_TYPES_MAPPING[values.sourceType],
            username: userEmail
        };

        setIsLoading(true);
        dispatch(gnviewCreateSubscription(body)).then((response) => {
            const newSubscription = {
                ...response.result,
                subscription_id: response.result?.subscriptionId
            }
            handleAfterSubscription(newSubscription);
            setIsLoading(false);
        }).catch((error) => {
            dispatch(gnviewSendLogMessage(`gnviewCreateSubscription error: ${error.message}`, "ERROR", error, { body }));
            handleModal(false);
            handleErrorSubscription();
            setIsLoading(false);
        })
    }

    return (
        <>
            <GNModal className='gnview-alerts-subscribe-modal' show={show} onHide={handleModal} title={SUBSCRIBE_TO_STATION_MODAL.MODAL_TITLE}>
                <Modal.Body>
                    <div className='gnview-alerts-subscribe-modal-main-container'>
                        <>
                            <div className='gnview-alerts-subscribe-modal-body'>
                                {isLoading && <LoadingSpinner data-testid='loading-spinner' />}
                                {!isLoading &&
                                    <SubscribeForm
                                        handleFormSubmit={handleFormSubmit}
                                        selectedStation={selectedStation || null}
                                        setIsFormValid={setIsFormValid}
                                        setValues={setValues}
                                        currentSubscriptions={subscriptions}
                                        trackChannel={trackChannel}
                                    />}
                            </div>
                        </>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='light' onClick={handleModal}>Cancel</Button>
                    <Button variant='primary' onClick={handleFormSubmit} disabled={isLoading || !isFormValid} type='submit' role='button' >Save</Button>
                </Modal.Footer>
            </GNModal>
        </>
    );
}

SubscribeModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleModal: PropTypes.func,
    selectedStation: PropTypes.any,
    handleAfterSubscription: PropTypes.func,
    handleErrorSubscription: PropTypes.func,
    subscriptions: PropTypes.array,
    trackChannel: PropTypes.bool
};

export default SubscribeModal;