import { date, object, string } from "yup";

export const DESCRIPTION_CHAR_LIMIT = 1000;
export const MAX_FILE_SIZE = (10 * 1024 * 1024);
export const STATION_ID_CHAR_LIMIT = 50;
export const TMS_ID_CHAR_LIMIT = 100;
export const VALID_FILE_TYPES = ".jpg, .jpeg, .png, .gif, .svg";

export const REPORT_AN_ISSUE_FIELDS = {
    CONFLICT_DATE: "conflictDate",
    DESCRIPTION: "description",
    FEEDBACK_ABOUT: "feedbackAbout",
    STATION_ID: "stationId",
    TMS_ID: "tmsId",
    URL: "url",
    USER_EMAIL: "userEmail"
};

export const REPORT_AN_ISSUE_INITIAL_VALUES = {
    [REPORT_AN_ISSUE_FIELDS.CONFLICT_DATE]: "",
    [REPORT_AN_ISSUE_FIELDS.DESCRIPTION]: "",
    [REPORT_AN_ISSUE_FIELDS.FEEDBACK_ABOUT]: "",
    [REPORT_AN_ISSUE_FIELDS.STATION_ID]: "",
    [REPORT_AN_ISSUE_FIELDS.TMS_ID]: "",
    [REPORT_AN_ISSUE_FIELDS.URL]: "",
    [REPORT_AN_ISSUE_FIELDS.USER_EMAIL]: ""
};

export const REPORT_AN_ISSUE_INITIAL_TOUCHED = {
    [REPORT_AN_ISSUE_FIELDS.CONFLICT_DATE]: false,
    [REPORT_AN_ISSUE_FIELDS.DESCRIPTION]: false,
    [REPORT_AN_ISSUE_FIELDS.FEEDBACK_ABOUT]: false,
    [REPORT_AN_ISSUE_FIELDS.STATION_ID]: false,
    [REPORT_AN_ISSUE_FIELDS.TMS_ID]: false,
    [REPORT_AN_ISSUE_FIELDS.URL]: false,
    [REPORT_AN_ISSUE_FIELDS.USER_EMAIL]: false
};

export const REPORT_AN_ISSUE_VALIDATION_SCHEMA = object({
    [REPORT_AN_ISSUE_FIELDS.CONFLICT_DATE]: date(),
    [REPORT_AN_ISSUE_FIELDS.DESCRIPTION]: string()
        .required(" ")
        .max(DESCRIPTION_CHAR_LIMIT, " "),
    [REPORT_AN_ISSUE_FIELDS.FEEDBACK_ABOUT]: string()
        .required("(Please select a category for this issue)"),
    [REPORT_AN_ISSUE_FIELDS.STATION_ID]: string().max(STATION_ID_CHAR_LIMIT, "(Maximum ${max} Characters)"),
    [REPORT_AN_ISSUE_FIELDS.TMS_ID]: string().max(TMS_ID_CHAR_LIMIT, "(Maximum ${max} Characters)"),
    [REPORT_AN_ISSUE_FIELDS.URL]: string()
        .required("(URL is a required field)"),
    [REPORT_AN_ISSUE_FIELDS.USER_EMAIL]: string()
});
