import React from 'react';
import { Table } from 'react-bootstrap';
import get from 'lodash.get';
import ClassNames from 'classnames';
import "./GNListGroup.scss";
import PropTypes from 'prop-types';

export const GNListGroup = (props) => {
    return (
        <React.Fragment>
            <div className={'gnlistgroup-wrapper'}>
                <Table size="sm" className={ClassNames('gnlistgroup', props.tableClassName)}>
                    <tbody>
                        {props.fields.map((item, index) => {
                            const value = get(props.data, item.prop, '');
                            const extra = Array.isArray(item.extra) ? item.extra.reduce(function(acc, cur) {
                                acc[cur] = get(props.data, cur, '');
                                return acc;
                            }, {}) : {};

                            if (item.render) {
                                return (
                                    <tr key={index}>
                                        <td className="list-label">{item.label}</td>
                                        <td>{value ? item.render(value, extra) : ''}</td>
                                    </tr>
                                )
                            }
                            return (
                                <tr key={index}>
                                    <td className="list-label">{item.label}</td>
                                    {props.renderListItem && props.sortListItems && <td className='list-item'>{props.sortListItems(value).map((i, idx) => <span key={idx}>{props.renderListItem(i)}</span>)}</td>}
                                    {props.renderListItem && !props.sortListItems && <td className='list-item'>{Array.isArray(value) ? value.map((i, idx) => <span key={idx}>{props.renderListItem(i)}</span>) : value}</td>}
                                    {!props.renderListItem && <td>{Array.isArray(value) ? value.join(', ') : value}</td>}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    )
};

GNListGroup.propTypes = {
    fields: PropTypes.array,
    data: PropTypes.object,
    tableClassName: PropTypes.object,
    renderListItem: PropTypes.func,
    sortListItems: PropTypes.func
};

export default GNListGroup;
