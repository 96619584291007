import React from 'react';
import { useSelector } from 'react-redux';
import "./ResultsHeader.scss";
import moment from 'moment-timezone';
import LazyLoad from 'react-lazyload';
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import { sportsSelLeagueData, sportsSelMatchData } from '../../../reducers/SportsReducer';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../config/Routes';
import { displayTextWithPlaceholder, formatLeagueName, formatSportName } from '../../../utils/SportUtils';

export const ResultsHeader = () => {
    const league = useSelector(sportsSelLeagueData);
    const match = useSelector(sportsSelMatchData);
    const time = moment(match?.dateAndTimeInfo?.scheduledStartTimeUTC);
    const dateFormatted = time.format('MM/DD/YYYY');
    const timeFormatted = time.format('hh:mma');

    return (
        <ErrorBoundary>
            <div className="results-header-top-section">
                <Link to={ROUTES.SPORTS} className="sports-link">Sports </Link>
                <span className="carrot">{">"}</span>
                <span className="programs-for">{"Programs for: "}</span>
                <span className="sport-name">{formatSportName(match)}</span>
                <div className="horizontal-line" />
                <div className="league-header">
                    {league?.images?.logo && <LazyLoad offset={500}><img src={league?.images?.logo} className="league-image" /></LazyLoad>}
                    {!league?.images?.logo && <span className="placeholder-league-image" />}
                    <span className="league-name">{formatLeagueName(league)}</span>
                </div>
                <div className="horizontal-line" />
                <div className="team-section-away">
                    {match?.awayParticipant?.images?.teamLogo && <LazyLoad offset={500}><img src={match?.awayParticipant?.images?.teamLogo} className="team-image" /></LazyLoad>}
                    {!match?.awayParticipant?.images?.teamLogo && <span className="placeholder-team-image" />}
                    <span className="team-name">{displayTextWithPlaceholder(match?.awayParticipant?.names?.default)}</span>
                </div>
                <div className="at">at</div>
                <div className="team-section-home">
                    {match?.homeParticipant?.images?.teamLogo && <LazyLoad offset={500}><img src={match?.homeParticipant?.images?.teamLogo} className="team-image" /></LazyLoad>}
                    {!match?.homeParticipant?.images?.teamLogo && <span className="placeholder-team-image" />}
                    <span className="team-name">{displayTextWithPlaceholder(match?.homeParticipant?.names?.default)}</span>
                </div>
                <div className="horizontal-line" />
                <span className="date">{dateFormatted}</span>
                <div className="horizontal-line" />
                <span className="time">{timeFormatted}</span>
            </div>
        </ErrorBoundary>
    )
}

export default ResultsHeader;
