import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import GNDateSelection from '../../../components/common/gnDateSelection/GNDateSelection';
import {
    PROGRAM_AIRINGS,
    PROGRAM_AIRINGS_DATE_SELECTION,
    PROGRAM_AIRINGS_DATE_OPTIONS,
    PROGRAM_AIRINGS_OPTIONAL_FILTERS,
    PROGRAM_AIRINGS_SEARCH_BY,
    PROGRAM_AIRINGS_FORM_VALIDATIONS,
    PROGRAM_AIRINGS_MARKET_RANGE_VALIDATIONS,
    PROGRAM_AIRINGS_PROGRAM_MAXIMUM,
    PROGRAM_AIRINGS_OPTIONAL_FILTER_TYPES,
    PROGRAM_AIRINGS_GNVIEW_LAST_UPDATED
} from '../../../constants/ProgramAirings';
import { MARKET_RANK_MINIMUM, MARKET_RANK_MAXIMUM } from '../../../constants/Markets';
import { getThisWeek, getDateSelectObj, getFormattedDate } from '../../../utils/DateUtils';
import moment from 'moment-timezone';
import GNProgramSelection from '../../../components/common/gnProgramSelection/GNProgramSelection';
import GNProgramCard from '../../../components/common/gnProgramCard/GNProgramCard';
import GNRadioGroup from '../../../components/common/gnRadioGroup/GNRadioGroup';
import GNStationTypeahead from '../../../components/common/gnStationTypeahead/GNStationTypeahead';
import {
    gnviewGetAllSavedSearches,
    gnviewCreateSavedSearch,
    gnviewGetMarket,
    gnviewGetProgramAiringsLastUpdated,
    gnviewGetProgramDetails,
    gnviewGetSavedSearch,
    gnviewGetStation,
    gnviewProgramAiringsClearSearch,
    gnviewProgramAiringsStoreSearch,
    gnviewSearchMarkets,
    gnviewUpdateSavedSearch
} from '../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import { checkProgramExists, formatProgramObject, getLocalGNVIEWIngestedLastupdated, isProgramAnEpisode } from '../../../utils/ProgramUtils';
import { Button } from 'react-bootstrap';
import './ProgramAiringsSearch.scss';
import TypeaheadFilter from '../../../components/common/typeaheadFilter/TypeaheadFilter';
import GNUSMarketTypeahead from '../../../components/common/gnUSMarketTypeahead/GNUSMarketTypeahead';
import { Col, Form } from 'react-bootstrap';
import { STATION_TYPES, INPUT_PLACEHOLDER_OPTIONS } from '../../../constants/Station';
import queryString from 'query-string';
import { convertCountryCodes } from '../../../utils/GeneralUtils';
import {
    gvauthSelEmail,
    gvauthSelEntitledCountries,
    gnviewSelUserSettings
} from '../../../reducers/GNVAuthReducer';
import { ROUTES } from '../../../config/Routes.js';
import { CHANNEL_SEARCH_TYPES_LABEL, CHANNEL_ID } from '../../../constants/Station';
import { SCHEDULE_SEARCH_TYPES } from '../../../constants/Schedule';
import { gnviewSelProgramAiringsQueryObj } from '../../../reducers/ProgramAiringsReducer';
import isEmpty from 'lodash.isempty';
import ReactRouterPropTypes from "react-router-prop-types";
import { checkStationExists } from '../../../utils/StationUtils';
import uniqBy from 'lodash.uniqby';
import {
    MY_SAVED_ITEMS,
    MY_SAVED_ITEMS_TYPES,
    RECENT_SAVED_SEARCHES_LIMIT,
    sortSavedItemsByDate,
    viewAllOrViewLessButton
} from '../../../constants/MySavedItems';
import MySavedItemsButton from "../../../components/common/saveButton/MySavedItemsButton";
import RecentSavedItem from '../../../components/common/recentSavedItem/RecentSavedItem';
import LazyLoad from 'react-lazyload';

export class ProgramAiringsSearchView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryList: [],
            dateType: PROGRAM_AIRINGS_DATE_OPTIONS.THIS_WEEK,
            date: getThisWeek(),
            disableProgramSearch: false,
            errorMessages: {},
            exactStartDate: moment().startOf('week'),
            exactEndDate: moment().endOf('week'),
            isRelative: true,
            lastUpdated: '',
            marketRangeErrorMessage: '',
            optionalSearchType: PROGRAM_AIRINGS_OPTIONAL_FILTER_TYPES.COUNTRY,
            programAiringsSavedSearches: [],
            savedSearchIsExpanded: false,
            savedSearchIsLoading: true,
            saveSearchModalShow: false,
            selectedCountries: [],
            selectedMarkets: [],
            selectedPrograms: [],
            selectedSearchTitle: null,
            selectedStations: [],
            stationTypes: STATION_TYPES.map((name) => ({name, selected: true})),
            stationSearchType: SCHEDULE_SEARCH_TYPES.channelName,
            stationPlaceholderText: INPUT_PLACEHOLDER_OPTIONS.channelName,
            stationId: null,
            successModalShow: false,
            successModalType: 'Save',
            tmsId: ''
        };
    }

    componentDidMount() {
        this.getLastUpdated();
        this.props.gnviewGetAllSavedSearches().then((allSavedSearchesResponse) => {
            const programAiringsSavedSearches = sortSavedItemsByDate(allSavedSearchesResponse?.result?.filter(savedSearch => savedSearch.search_type === MY_SAVED_ITEMS_TYPES.PROGRAM_AIRINGS)) || [];
            this.setState({programAiringsSavedSearches});
        }).catch((error) => {
            this.props.gnviewSendLogMessage(`gnviewGetAllSavedSearches error: ${error.message}`, error);
        });
        const params = queryString.parse(this.props.location?.search);
        const reduxQueryObj = this.props.queryObj;
        if (params.search_id) {
            this.props.gnviewGetSavedSearch(params.search_id).then(
                (response) => {
                    const selectedSearchTitle = response.result?.title;
                    const queryObj = response.result?.query_obj;
                    this.setQueryObj(queryObj, selectedSearchTitle);
                }).catch(() => {
                // Unauthorized
                this.validateForm('savedSearchError');
                this.setState({savedSearchIsLoading: false});
            });
        } else if (!isEmpty(reduxQueryObj)) {
            this.setQueryObj(reduxQueryObj);
        } else {
            this.setState({savedSearchIsLoading: false});
        }
        const countryNameAndCodes = convertCountryCodes(this.props.entitledCountries);
        const selectedCountries = params?.search_id ? [] : convertCountryCodes([this.props.defaultCountry]);
        this.setState({countryList: countryNameAndCodes, selectedCountries});
    }

    getLastUpdated() {
        this.props
            .gnviewGetProgramAiringsLastUpdated()
            .then((response) => {
                if (response?.result?.length > 0) {
                    const lastUpdated = getLocalGNVIEWIngestedLastupdated(response.result);
                    this.setState({ lastUpdated });
                }
            })
            .catch((error) => {
                this.props.gnviewSendLogMessage(`gnviewGetProgramAiringsLastUpdated error: ${error.message}`, error);
            });
    }

    setQueryObj = (queryObj, selectedSearchTitle) => {
        const exactStartDate = moment(queryObj.start_date).startOf('day');
        const exactEndDate = moment(queryObj.end_date).endOf('day');
        const dateType = queryObj.date_type;
        const optionalSearchType = queryObj.search_type === PROGRAM_AIRINGS_OPTIONAL_FILTER_TYPES.COUNTRY ? PROGRAM_AIRINGS_OPTIONAL_FILTER_TYPES.COUNTRY : PROGRAM_AIRINGS_OPTIONAL_FILTER_TYPES.CHANNEL;
        const stationTypes = STATION_TYPES.map((name) => ({name, selected: queryObj.station_types ? queryObj.station_types.indexOf(name) !== -1 : true}));
        this.getDetailsFromIds(queryObj);
        this.setDate(dateType, exactStartDate, exactEndDate);
        this.setState({savedSearchIsLoading: false, selectedSearchTitle, optionalSearchType, stationTypes});
    }

    checkProgramsLimit = (programs) => {
        return programs.length >= PROGRAM_AIRINGS_PROGRAM_MAXIMUM;
    }

    getDetailsFromIds = (queryObj) => {
        const programRequests = queryObj.program_ids?.map(tmsid => this.props.gnviewGetProgramDetails(tmsid)) || [];
        const marketRequests = queryObj.market_ids?.map(id => this.props.gnviewGetMarket(id)) || [];
        const stationRequests = queryObj.station_ids?.map(id => this.props.gnviewGetStation(id)) || [];
        const selectedCountries = queryObj.country_codes ? convertCountryCodes(queryObj.country_codes) : convertCountryCodes([this.props.defaultCountry]);
        Promise.all(programRequests).then((programData) => {
            const selectedPrograms = programData.map((program) => (formatProgramObject(program)));
            Promise.all(marketRequests).then((marketData) => {
                const selectedMarkets = marketData.map((market) => market.result);
                Promise.all(stationRequests).then((stationData) => {
                    const selectedStations = stationData.map((station) => station.result);
                    this.setState({selectedPrograms, selectedCountries, selectedMarkets, selectedStations, disableProgramSearch: this.checkProgramsLimit(selectedPrograms)});
                }).catch((error) => {
                    this.props.gnviewSendLogMessage(`gnviewGetStation error: ${error.message}`, error, queryObj);
                });
            }).catch((error) => {
                this.props.gnviewSendLogMessage(`gnviewGetMarket error: ${error.message}`, error, queryObj);
            });
        }).catch((error) => {
            this.props.gnviewSendLogMessage(`gnviewGetProgramDetails error: ${error.message}`, error, queryObj);
        });
    }

    setDate = (dateType, exactStartDate, exactEndDate) => {
        if (Object.values(PROGRAM_AIRINGS_DATE_OPTIONS).includes(dateType)) {
            if (dateType === 'Exact Date') {
                if (exactStartDate.isValid() && exactEndDate.isValid()) {
                    this.onDateTypeSelect(dateType, null, exactStartDate, exactEndDate);
                    this.setState({isRelative: false});
                }
            } else {
                this.onDateTypeSelect(dateType);
                this.setState({isRelative: true});
            }
        }
    }

    onDateTypeSelect = (dateType, event, exactStartDate, exactEndDate) => {
        const dateObj = getDateSelectObj(dateType, event, exactStartDate, exactEndDate);
        if (dateObj) {
            this.setState(dateObj);
        }
    }

    getProgramSelectionText = () => {
        if (!this.state.selectedPrograms?.length) {
            return 'Program Selection';
        }
        return this.state.selectedPrograms.length <= 1 ? this.state.selectedPrograms.length + ' Program Selected' : this.state.selectedPrograms.length + ' Programs Selected';
    }

    handleChangeProgram = (program) => {
        this.validateForm();
        if (this.state.errorMessages?.program === PROGRAM_AIRINGS_FORM_VALIDATIONS.PROGRAM_MAXIMUM) {
            return;
        }
        const programId = (program.length > 0 && program[0].tmsid) ? program[0].tmsid : null;
        const programFound = this.state.selectedPrograms.find((p) => p.tmsid === programId);
        if (programId && !programFound) {
            const programsCopy = [...this.state.selectedPrograms];
            programsCopy.push(program[0]);
            this.setState({ selectedPrograms: programsCopy, disableProgramSearch: this.checkProgramsLimit(programsCopy) }, () => this.validateForm());
        }
    }

    handleProgramRemove = (tmsid) => {
        const programIndex = this.state.selectedPrograms.findIndex((program) => program.tmsid === tmsid);
        if (programIndex !== -1) {
            const programsCopy = [...this.state.selectedPrograms];
            programsCopy.splice(programIndex, 1);
            this.setState({ selectedPrograms: programsCopy, disableProgramSearch: this.checkProgramsLimit(programsCopy) }, () => this.validateForm());
        }
    }

    // TMS ID input
    handleTmsIdChange = (e) => {
        const str = e.target.value;
        if (typeof str === "string") {
            this.setState({tmsId: str});
        }
    }

    // TMS ID add program button
    handleTMSIdButtonClick = (e, tmsId) => {
        // Prevent page refresh
        e.preventDefault();
        if (tmsId) {
            this.validateForm(null, tmsId);
            if (this.state.errorMessages?.program === PROGRAM_AIRINGS_FORM_VALIDATIONS.PROGRAM_MAXIMUM || checkProgramExists(this.state.selectedPrograms, tmsId)) {
                return;
            }
            this.props.gnviewGetProgramDetails(tmsId).then((response) => {
                if (response.result) {
                    const programsCopy = [...this.state.selectedPrograms];
                    const program = formatProgramObject(response);
                    programsCopy.push(program);
                    this.setState({ selectedPrograms: programsCopy }, () => this.validateForm());
                }
            }).catch((error) => {
                this.props.gnviewSendLogMessage(`gnviewGetProgramDetails error: ${error.message}`, error, {tmsId});
                if (error?.response?.status === 404) {
                    this.validateForm(error.response.status, tmsId);
                    this.setState({ tmsId: ''})
                } else if (error?.response?.status === 403) {
                    this.validateForm(error.response.status, tmsId);
                    this.setState({ tmsId: ''})
                }
            });
        }
    }

    onOptionalSearchTypeSelect = (type) => {
        this.setState({ optionalSearchType: type, stationSearchType: SCHEDULE_SEARCH_TYPES.channelName }, () => this.validateForm());
    }

    handleChangeStation = (stations) => {
        this.setState({ selectedStations: stations }, () => this.validateForm());
    }

    handleChangeCountry = (countries) => {
        this.setState({ selectedCountries: countries }, () => this.validateForm());
    }

    shouldShowUSMarketTypeahead = () => {
        return this.state.selectedCountries.findIndex((country) => country.code === 'USA') !== -1;
    }

    handleChangeMarket = (markets) => {
        this.setState({ selectedMarkets: markets }, () => this.validateForm());
    }

    addMarketRange = (start, end) => {
        if (this.validateMarketRange(start, end)) {
            this.props.gnviewSearchMarkets(null, start, end).then(
                (response) => {
                    if (response.result) {
                        const marketsCopy = [...this.state.selectedMarkets].concat(response.result);
                        const removeDuplicates = uniqBy(marketsCopy, 'id');
                        const marketsSortedByRank = removeDuplicates.sort((a, b) => a.rank > b.rank ? 1 : -1);
                        this.setState({selectedMarkets: marketsSortedByRank});
                    }
                }, (error) => {
                    this.props.gnviewSendLogMessage(`gnviewSearchMarkets error: ${error.message}`, error);
                }
            );
        }
    }

    validateMarketRange = (start, end) => {
        const startNum = parseInt(start);
        const endNum = parseInt(end);
        if ((!startNum && !endNum) || (endNum < startNum)) {
            this.setState({marketRangeErrorMessage: PROGRAM_AIRINGS_MARKET_RANGE_VALIDATIONS.START_AND_END_INVALID});
            return false;
        } else if (!startNum || isNaN(startNum) || startNum < MARKET_RANK_MINIMUM) {
            this.setState({marketRangeErrorMessage: PROGRAM_AIRINGS_MARKET_RANGE_VALIDATIONS.START_INVALID});
            return false;
        } else if (!endNum || isNaN(endNum) || endNum > MARKET_RANK_MAXIMUM) {
            this.setState({marketRangeErrorMessage: PROGRAM_AIRINGS_MARKET_RANGE_VALIDATIONS.END_INVALID});
            return false;
        }
        this.setState({marketRangeErrorMessage: ''});
        return true;
    }

    handleStationTypeCheckbox = (event, stationType) => {
        const typeIndex = this.state.stationTypes.findIndex((type) => type.name === stationType.name);
        if (typeIndex !== -1) {
            const typesCopy = [...this.state.stationTypes];
            typesCopy[typeIndex].selected = event.target.checked;
            this.setState({ stationTypes: typesCopy });
        }
    }

    validateForm = (errorCode, tmsId, searching = false) => {
        const errorMessages = {
            ...(searching && this.state.selectedPrograms.length <= 0 && { program: PROGRAM_AIRINGS_FORM_VALIDATIONS.PROGRAM_MINIMUM }),
            ...(this.state.selectedPrograms.length === PROGRAM_AIRINGS_PROGRAM_MAXIMUM && { program: PROGRAM_AIRINGS_FORM_VALIDATIONS.PROGRAM_MAXIMUM }),
            ...(errorCode === 404 && tmsId && { program: tmsId + PROGRAM_AIRINGS_FORM_VALIDATIONS.PROGRAM_INVALID_TMSID }),
            ...(errorCode === 403 && tmsId && { program: PROGRAM_AIRINGS_FORM_VALIDATIONS.PROGRAM_UNAUTHORIZED + tmsId }),
            ...(searching && this.state.optionalSearchType === PROGRAM_AIRINGS_OPTIONAL_FILTER_TYPES.COUNTRY && this.state.selectedCountries.length <= 0 && { country: PROGRAM_AIRINGS_FORM_VALIDATIONS.COUNTRY_MINIMUM }),
            ...(searching && this.state.optionalSearchType === PROGRAM_AIRINGS_OPTIONAL_FILTER_TYPES.CHANNEL && this.state.selectedStations.length <= 0 && { station: PROGRAM_AIRINGS_FORM_VALIDATIONS.STATION_MINIMUM }),
            ...(errorCode === 'savedSearchError' && { savedSearch: PROGRAM_AIRINGS_FORM_VALIDATIONS.SAVED_SEARCH_UNAUTHORIZED }),
            ...(!this.state.isRelative && !this.state.exactEndDate && { date: PROGRAM_AIRINGS_FORM_VALIDATIONS.NO_END_DATE_SELECTED }),
            ...(checkProgramExists(this.state.selectedPrograms, tmsId) && { programDuplicate: PROGRAM_AIRINGS_FORM_VALIDATIONS.PROGRAM_DUPLICATE }),
            ...(errorCode === 'invalidStationId' && { stationIdSearch: PROGRAM_AIRINGS_FORM_VALIDATIONS.INVALID_STATION_ID }),
            ...(errorCode === 'duplicateStationId' && { stationIdSearch: PROGRAM_AIRINGS_FORM_VALIDATIONS.DUPLICATE_STATION_ID })
        };
        this.setState({errorMessages});

        // Save query object into redux state since this is called when adding and removing search criteria
        const queryObj = this.createSavedSearchBody().query_obj;
        this.props.gnviewProgramAiringsStoreSearch(queryObj);

        const errorsArray = Object.keys(errorMessages);
        // Edge case: we want to return false when ONLY the PROGRAM_MAXIMUM error occurs to allow users to still run and save searches (buttons are normally disabled when validateForm() returns true)
        if (errorsArray.length === 1 && errorMessages.program === PROGRAM_AIRINGS_FORM_VALIDATIONS.PROGRAM_MAXIMUM) {
            return false;
        } else {
            return errorsArray.length !== 0;
        }
    }

    createSavedSearchBody = () => {
        // Convert date to YYYY-MM-DD for query_obj
        const convertedStartDate = this.state.isRelative ? moment(this.state.date.startDate).format('YYYY-MM-DD') : this.state.exactStartDate?.format('YYYY-MM-DD');
        const convertedEndDate = this.state.isRelative ? moment(this.state.date.endDate).format('YYYY-MM-DD') : this.state.exactEndDate?.format('YYYY-MM-DD');
        const queryObj = {
            program_ids: this.state.selectedPrograms.map(program => program.tmsid),
            date_type: this.state.dateType,
            start_date: convertedStartDate,
            end_date: convertedEndDate,
            search_type: this.state.optionalSearchType,
            // Optional type 'Country' selected
            ...(this.state.optionalSearchType === PROGRAM_AIRINGS_OPTIONAL_FILTER_TYPES.COUNTRY && { country_codes: this.state.selectedCountries.map((country) => country.code) }),
            ...(this.state.optionalSearchType === PROGRAM_AIRINGS_OPTIONAL_FILTER_TYPES.COUNTRY && this.shouldShowUSMarketTypeahead() && { market_ids: this.state.selectedMarkets.map((mkt) => mkt.id) }),
            ...(this.state.optionalSearchType === PROGRAM_AIRINGS_OPTIONAL_FILTER_TYPES.COUNTRY && { station_types: this.state.stationTypes.filter((type) => type.selected === true).map((type) => type.name) }),
            // Optional type 'Station' selected
            ...(this.state.optionalSearchType === PROGRAM_AIRINGS_OPTIONAL_FILTER_TYPES.CHANNEL && { station_ids: this.state.selectedStations.map((station) => station.id) })
        };
        return {
            title: this.state.selectedSearchTitle,
            search_type: MY_SAVED_ITEMS_TYPES.PROGRAM_AIRINGS,
            username: this.props.email,
            query_obj: queryObj
        };
    }

    handleSearchButtonClick = () => {
        if (this.validateForm(null, null, true)) {
            return;
        }
        // Storing queryObj in redux state
        const queryObj = {
            ...(this.createSavedSearchBody().query_obj),
            // Used for result page
            parent_tms_ids: this.state.selectedPrograms.filter((prog) => !isProgramAnEpisode(prog)).map((prog) => prog.tmsid),
            tms_ids: this.state.selectedPrograms.filter((prog) => isProgramAnEpisode(prog)).map((prog) => prog.tmsid)
        };
        this.props.gnviewProgramAiringsStoreSearch(queryObj);
        const searchId = queryString.parse(this.props.location?.search)['search_id'];
        const searchIdQueryParam = searchId ? `?search_id=${searchId}` : '';
        this.props.history.push(`${ROUTES.PROGRAM_AIRINGS}/result${searchIdQueryParam}`);
    }

    onStationSearchTypeSelect = (type) => {
        const placeholderText = this.handleStationPlaceholderText(type);
        this.setState({ stationSearchType: type, stationPlaceholderText: placeholderText }, () => this.validateForm());
    }

    handleStationPlaceholderText = (searchType) => {
        if (searchType === SCHEDULE_SEARCH_TYPES.channelName) {
            return INPUT_PLACEHOLDER_OPTIONS.channelName
        } else if (searchType === SCHEDULE_SEARCH_TYPES.channelCallSign) {
            return INPUT_PLACEHOLDER_OPTIONS.channelCallSign
        } else {
            return ' ';
        }
    }

    clearSearchData = () => {
        this.props.gnviewProgramAiringsClearSearch();
        this.setState({
            tmsId: '',
            selectedPrograms: [],
            selectedStations: [],
            selectedCountries: [],
            selectedMarkets: [],
            stationTypes: STATION_TYPES.map((name) => ({name, selected: true})),
            errorMessages: {},
            saveSearchModalShow: false,
            selectedSearchTitle: null,
            successModalShow: false,
            disableProgramSearch: false
        });
    }

    handleStationIdChange = (e) => {
        const str = e.target.value;
        this.setState({ stationId: str });
    }

    /**
     * preventing enter key press from refreshing page in station id field, this should
     * probably be changed to request handleStationIdButtonClick instead - bg
     **/
    handleStationIdKeyPress = (e) => {
        if (e.charCode === 13) {
            e.preventDefault();
        }
    }

    handleStationIdButtonClick = () => {
        if (this.state.stationId) {
            if (checkStationExists(this.state.selectedStations, this.state.stationId)) {
                this.validateForm('duplicateStationId', null);
            } else {
                this.validateForm();
                this.props.gnviewGetStation(this.state.stationId).then((response) => {
                    const station = response?.result;
                    const formattedStation = {
                        call_sign: station.call_sign,
                        country_codes: station.country_codes,
                        id: station.id,
                        name: station.name,
                        timezone: this.props.defaultTimezone || station.timezone,
                        type: station.type
                    };
                    const selectedStationsCopy = [...this.state.selectedStations, formattedStation];
                    this.setState({ selectedStations: selectedStationsCopy });
                }).catch((error) => {
                    this.validateForm('invalidStationId', null);
                    this.props.gnviewSendLogMessage(`gnviewGetStation error: ${error.message}`, error, {});
                });
            }
        }
    }
    render() {
        const searchId = queryString.parse(this.props.location?.search)['search_id'];
        const savedSearchesToShow = !this.state.savedSearchIsExpanded
            ? this.state.programAiringsSavedSearches.slice(0, RECENT_SAVED_SEARCHES_LIMIT)
            : this.state.programAiringsSavedSearches;
        return (
            <ErrorBoundary>
                <div className="gnview-program-airings-content">
                    <div className="filter-bar-container program-airings-search-container">
                        <div className="gnview-header-title">{PROGRAM_AIRINGS}</div>
                        <div className="gnview-last-updated">
                            <span className="gnview-last-updated__label">{PROGRAM_AIRINGS_GNVIEW_LAST_UPDATED}</span>
                            <span className="gnview-last-updated__datetime">{this.state.lastUpdated}</span>
                        </div>
                        <div className="gnview-section-title">{PROGRAM_AIRINGS_DATE_SELECTION}</div>
                        {!this.state.savedSearchIsLoading && <GNDateSelection
                            radioLabel={PROGRAM_AIRINGS_DATE_SELECTION}
                            radioList={Object.values(PROGRAM_AIRINGS_DATE_OPTIONS)}
                            onRadioSelect={this.onDateTypeSelect}
                            defaultRadio={this.state.dateType}
                            isRelative={this.state.isRelative}
                            dateTypeSelected={this.state.dateType}
                            relativeStartDate={this.state.date.startDate}
                            relativeEndDate={this.state.date.endDate}
                            exactDailyOrWeekly={false}
                            exactStartDate={this.state.exactStartDate}
                            exactEndDate={this.state.exactEndDate}
                            onExactDatesChange={({ startDate, endDate }) => this.setState({ exactStartDate: startDate, exactEndDate: endDate }) } />}
                        {this.state.errorMessages?.date && <div className="gnview-error-msg">{this.state.errorMessages.date}</div>}
                        {this.state.errorMessages?.savedSearch && <div className="gnview-error-msg">{this.state.errorMessages.savedSearch}</div>}
                        <div className="gnview-section-title">{this.getProgramSelectionText()}</div>
                        {this.state.selectedPrograms?.length > 0 && <div className="selected-programs-container">
                            {this.state.selectedPrograms.map((program, index) => (
                                <div className="program-tile" key={index}>
                                    <GNProgramCard program={program} handleProgramRemove={this.handleProgramRemove}/>
                                </div>
                            ))}
                        </div>}
                        <GNProgramSelection
                            handleChangeProgram={this.handleChangeProgram}
                            tmsId={this.state.tmsId}
                            handleTmsIdChange={this.handleTmsIdChange}
                            handleTMSIdButtonClick={this.handleTMSIdButtonClick}
                            validateForm={this.validateForm}
                            tmsIdButtonText="Add Program"
                            disabled={this.state.disableProgramSearch}
                            selectedProgramIds={this.state.selectedPrograms.map(prog => prog.tmsid)}
                            isMultiple={true} />
                        {this.state.errorMessages?.programDuplicate && <div className="gnview-error-msg">{this.state.errorMessages.programDuplicate}</div>}
                        {this.state.errorMessages?.program && <div className="gnview-error-msg">{this.state.errorMessages.program}</div>}
                        {this.state.errorMessages?.episodeTMSIDSearch && <div className="gnview-error-msg">{this.state.errorMessages.episodeTMSIDSearch}</div>}
                        <div className="gnview-section-title">{PROGRAM_AIRINGS_OPTIONAL_FILTERS}</div>
                        {!this.state.savedSearchIsLoading && <GNRadioGroup label={PROGRAM_AIRINGS_SEARCH_BY} list={Object.values(PROGRAM_AIRINGS_OPTIONAL_FILTER_TYPES)} onSelect={this.onOptionalSearchTypeSelect} default={this.state.optionalSearchType}/>}
                        {this.state.optionalSearchType === PROGRAM_AIRINGS_OPTIONAL_FILTER_TYPES.COUNTRY &&
                            <div className="country-filter-container">
                                <TypeaheadFilter
                                    filterLabel="Country:"
                                    handleChange={(item) => this.handleChangeCountry(item)}
                                    labelKey="name"
                                    list={this.state.countryList}
                                    multiple={true}
                                    placeholder=""
                                    selected={this.state.selectedCountries} />
                                {this.state.errorMessages?.country && <div className="gnview-error-msg">{this.state.errorMessages?.country}</div>}
                                {this.shouldShowUSMarketTypeahead() &&
                                    <GNUSMarketTypeahead
                                        multipleMarkets={true}
                                        selectedMarkets={this.state.selectedMarkets}
                                        handleChangeMarket={this.handleChangeMarket}
                                        showMarketRangeSelection={true}
                                        addMarketRange={this.addMarketRange}
                                        marketRangeErrorMessage={this.state.marketRangeErrorMessage} />}
                                <Form className="station-types-container">
                                    <div className="filter-label">Station Types:</div>
                                    <Form.Row>
                                        {this.state.stationTypes?.length > 0 && this.state.stationTypes.map((stationType) => (
                                            <Col xs="3" key={stationType.name}>
                                                {/* For custom checkboxes you need the prop 'custom', label, and a unique id */}
                                                <Form.Check custom id={stationType.name} type="checkbox" label={stationType.name} checked={stationType.selected} onChange={(e) => this.handleStationTypeCheckbox(e, stationType)}/>
                                            </Col>
                                        ))}
                                    </Form.Row>
                                </Form>
                            </div>
                        }
                        {this.state.optionalSearchType === PROGRAM_AIRINGS_OPTIONAL_FILTER_TYPES.CHANNEL && <div className='station-search-container'>
                            <GNRadioGroup label={CHANNEL_SEARCH_TYPES_LABEL} list={Object.values(SCHEDULE_SEARCH_TYPES)} onSelect={this.onStationSearchTypeSelect} default={SCHEDULE_SEARCH_TYPES.channelName}/>
                            <div className='station-search-inputs'>
                                {this.state.stationSearchType === SCHEDULE_SEARCH_TYPES.channelId && <div className="station-id-selection">
                                    <Form>
                                        <div className="filter-label">{CHANNEL_ID}:</div>
                                        <Form.Row>
                                            <Form.Control
                                                value={this.state.stationId}
                                                onChange={this.handleStationIdChange}
                                                onKeyPress={this.handleStationIdKeyPress}>
                                            </Form.Control>
                                            <Button onClick={this.handleStationIdButtonClick}>Add Channel</Button>
                                        </Form.Row>
                                    </Form>
                                </div>}
                                {this.state.errorMessages?.stationIdSearch && <div className="gnview-error-msg">{this.state.errorMessages?.stationIdSearch}</div>}
                                <GNStationTypeahead
                                    selectedStations={this.state.selectedStations}
                                    multipleStations={true}
                                    placeholder={this.state.stationPlaceholderText}
                                    handleChangeStation={this.handleChangeStation}
                                    isCallSignSearch={this.state.stationSearchType === SCHEDULE_SEARCH_TYPES.channelCallSign}
                                    showTypeAhead={this.state.stationSearchType !== SCHEDULE_SEARCH_TYPES.channelId} />
                                {this.state.stationSearchType === SCHEDULE_SEARCH_TYPES.channelId && !this.state.selectedStations.length > 0 && <div className='no-stations-selected-msg'>No channels selected</div>}
                            </div>
                        </div>}
                        {this.state.errorMessages?.station && <div className="gnview-error-msg">{this.state.errorMessages?.station}</div>}
                        <div className="filter-bar-col">
                            <Button variant="primary" className="search-button" onClick={this.handleSearchButtonClick}>Search</Button>
                            <MySavedItemsButton
                                body={this.createSavedSearchBody()}
                                searchId={searchId}
                                formattedDate={getFormattedDate(this.state.isRelative, this.state.dateType, this.state.date.startDate, this.state.date.endDate, this.state.exactStartDate, this.state.exactEndDate)}
                                selectedStations={this.state.selectedStations}
                                selectedCountries={this.state.selectedCountries}
                                selectedMarkets={this.state.selectedMarkets}
                                selectedPrograms={this.state.selectedPrograms}
                                shouldReloadPage={true}
                                validationCallback={() => this.validateForm(null, null, true)}
                            />
                            <Button variant="light" className='button-item' onClick={this.clearSearchData} >Clear Search</Button>
                        </div>
                    </div>
                </div>
                {this.state.programAiringsSavedSearches.length > 0 && (
                    <div className='my-saved-items-section'>
                        <div className='my-saved-item-header'>{MY_SAVED_ITEMS}</div>
                        <div className='my-saved-items-list'>
                            {savedSearchesToShow.map((savedSearch) => (
                                <LazyLoad overflow={true} scroll={true} key={savedSearch}>
                                    <RecentSavedItem savedSearch={savedSearch} />
                                </LazyLoad>
                            ))}
                        </div>
                        {this.state.programAiringsSavedSearches.length > RECENT_SAVED_SEARCHES_LIMIT && (
                            <div className='my-saved-items-view-btn-container'>
                                <span
                                    onClick={() =>
                                        this.setState({ savedSearchIsExpanded: !this.state.savedSearchIsExpanded })
                                    }
                                    className='my-saved-items-view-btn'
                                >
                                    {viewAllOrViewLessButton(this.state.savedSearchIsExpanded)}
                                </span>
                            </div>
                        )}
                    </div>
                )}
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        defaultCountry: gnviewSelUserSettings(state).station_country,
        defaultTimezone: gnviewSelUserSettings(state).timezone,
        email: gvauthSelEmail(state),
        entitledCountries: gvauthSelEntitledCountries(state),
        queryObj: gnviewSelProgramAiringsQueryObj(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewGetAllSavedSearches,
        gnviewCreateSavedSearch,
        gnviewGetMarket,
        gnviewGetProgramAiringsLastUpdated,
        gnviewGetProgramDetails,
        gnviewGetSavedSearch,
        gnviewGetStation,
        gnviewProgramAiringsClearSearch,
        gnviewProgramAiringsStoreSearch,
        gnviewSearchMarkets,
        gnviewSendLogMessage,
        gnviewUpdateSavedSearch
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramAiringsSearchView);

ProgramAiringsSearchView.propTypes = {
    defaultCountry: PropTypes.string,
    defaultTimezone: PropTypes.string,
    email: PropTypes.string,
    entitledCountries: PropTypes.array,
    gnviewCreateSavedSearch: PropTypes.func,
    gnviewGetAllSavedSearches: PropTypes.func,
    gnviewGetMarket: PropTypes.func,
    gnviewGetProgramAiringsLastUpdated: PropTypes.func,
    gnviewGetProgramDetails: PropTypes.func,
    gnviewGetSavedSearch: PropTypes.func,
    gnviewGetStation: PropTypes.func,
    gnviewProgramAiringsClearSearch: PropTypes.func,
    gnviewProgramAiringsStoreSearch: PropTypes.func,
    gnviewSearchMarkets: PropTypes.func,
    gnviewSendLogMessage: PropTypes.func,
    gnviewUpdateSavedSearch: PropTypes.func,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location,
    queryObj: PropTypes.object
}
