import React, { useEffect, useState } from 'react';
import './RecentSavedItem.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MY_SAVED_ITEMS_TYPES, RECENT_SAVED_ITEMS_TYPES, RECENT_SAVED_SEARCHES_LIMIT } from '../../../constants/MySavedItems';
import { gnviewGetStation } from '../../../actions/GNViewActions';
import { useDispatch } from 'react-redux';
import { getStationImage } from '../../../utils/StationUtils';
import { getImageURL } from '../../../utils/ImageUtils';
import { gnviewSendLogMessage } from '../../../services/GeneralService';

export const RecentSavedItem = ({savedSearch}) => {
    const recentSavedItemType = RECENT_SAVED_ITEMS_TYPES[savedSearch?.search_type];
    const dateType = savedSearch?.query_obj?.date_type;
    const dateString = savedSearch?.query_obj?.start_date === savedSearch?.query_obj?.end_date ? savedSearch?.query_obj?.start_date : `${savedSearch?.query_obj?.start_date} - ${savedSearch?.query_obj?.end_date}`;
    const title = savedSearch?.title;
    const numStations = savedSearch?.query_obj?.station_ids?.length;
    const stations = savedSearch?.query_obj?.station_ids;
    const [stationImages, setStationImages] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (stations?.length > 0) {
            stations.map((station) => {
                dispatch(gnviewGetStation(station)).then(
                    (res) => {
                        const imageURL = getImageURL(getStationImage(res?.result));
                        if (imageURL) {
                            setStationImages((prevStation) => [...prevStation, imageURL]);
                        }
                    },
                    (err) => {
                        dispatch(gnviewSendLogMessage(`gnviewGetStation error: ${err.message}`, err, station));
                    }
                );
            });
        }
    }, [dispatch, stations]);

    return (
        <Link className='gnview-recent-saved-item-main-container' to={`${recentSavedItemType.route}?search_id=${savedSearch.id}`} target='_blank' rel='noreferrer'>
            <div className='placeholder-image-container'>
                {stationImages.length > 0 && savedSearch.search_type === MY_SAVED_ITEMS_TYPES.SCHEDULES ? (
                    stationImages
                        .slice(0, RECENT_SAVED_SEARCHES_LIMIT)
                        .map((imageURL, idx) => <img className='station-image' key={idx} src={imageURL} />)
                ) : (
                    <img className='station-image' src={recentSavedItemType.icon}/>
                )}
            </div>
            <div className='recent-saved-item-body'>
                <div className='recent-saved-item-date-type'>{dateType}</div>
                <div className='recent-saved-item-date-string'><FontAwesomeIcon icon='calendar-alt' />{dateString}</div>
                <div className='recent-saved-item-title'>{title}{savedSearch.search_type === MY_SAVED_ITEMS_TYPES.SCHEDULES && <span className='recent-saved-item-num-stations'>{numStations}</span>}</div>
            </div>
        </Link>
    )
};

RecentSavedItem.propTypes = {
    savedSearch: PropTypes.object.isRequired
};

export default RecentSavedItem;