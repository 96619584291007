import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    PROGRAM_ATTRIBUTES_DEFAULT_PLACEHOLDERS,
    PROGRAM_ATTRIBUTES_TYPES,
    SUBTYPE_POPULATE_RULE
} from '../../../../constants/AdvancedSearch';
import GNDropdown from '../../../common/gnDropdown/GNDropdown';
import './Types.scss';
import { PROGRAM_TYPES, PROGRAM_TYPES_WITH_SUBTYPE } from '../../../../constants/Program';
import GNBadge from '../../../common/gnBadge/GNBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Types = ({ handleFormChange, values }) => {
    const [selectedProgramType, setSelectedProgramType] = useState(
        values[PROGRAM_ATTRIBUTES_TYPES.PROGRAM_TYPES.VALIDATION_LABEL]
    );

    const [selectedProgramSubtype, setSelectedProgramSubtype] = useState(
        values[PROGRAM_ATTRIBUTES_TYPES.SUBTYPES.VALIDATION_LABEL]
    );

    const defaultProgramType = PROGRAM_ATTRIBUTES_DEFAULT_PLACEHOLDERS.PROGRAM_TYPES;
    const defaultProgramSubtype = PROGRAM_ATTRIBUTES_DEFAULT_PLACEHOLDERS.PROGRAM_SUBTYPES;

    const PROGRAM_TYPES_WITH_BADGES = [
        defaultProgramType,
        ...Object.keys(PROGRAM_TYPES_WITH_SUBTYPE)
            .filter((t) => t !== PROGRAM_TYPES.THEATRE_EVENT && t !== PROGRAM_TYPES.PREVIEW)
            .map((type) => ({
                value: type,
                name: <GNBadge type={type} subtype={type} />
            }))
    ];
    const PROGRAM_SUBTYPES_WITH_BADGES = !selectedProgramType
        ? []
        : [
            defaultProgramSubtype,
            ...Object.values(PROGRAM_TYPES_WITH_SUBTYPE[selectedProgramType]).map((subtype) => ({
                value: subtype,
                name: <GNBadge type={selectedProgramType} subtype={subtype} />
            }))
        ];

    const canResetValue = (valueProps) => {
        if (
            (valueProps.placeholder === PROGRAM_ATTRIBUTES_TYPES.PROGRAM_TYPES.PLACEHOLDER &&
                valueProps?.ref?.current) ||
            (valueProps.placeholder === PROGRAM_ATTRIBUTES_TYPES.SUBTYPES.PLACEHOLDER &&
                valueProps?.ref?.current &&
                !selectedProgramSubtype)
        ) {
            return true;
        }
        return false;
    };

    const renderValue = (valueProps, ref, selectedValue) => {
        if (canResetValue(valueProps)) {
            valueProps.ref.current.value = '';
        }
        return <input {...valueProps} {...ref} className={selectedValue} />;
    };

    useEffect(() => {
        if (selectedProgramType !== values[PROGRAM_ATTRIBUTES_TYPES.PROGRAM_TYPES.VALIDATION_LABEL]) {
            setSelectedProgramType(values[PROGRAM_ATTRIBUTES_TYPES.PROGRAM_TYPES.VALIDATION_LABEL]);
        }
        if (selectedProgramSubtype !== values[PROGRAM_ATTRIBUTES_TYPES.SUBTYPES.VALIDATION_LABEL]) {
            setSelectedProgramSubtype('');
        }
    }, [selectedProgramType, selectedProgramSubtype, values]);

    return (
        <div className='programAttributesSections'>
            <div className='programAttributesSections__section'>{PROGRAM_ATTRIBUTES_TYPES.NAME}</div>
            <div className='programAttributesSections__section-dropdown types-section'>
                {selectedProgramType && (
                    <div className='programAttributesSections__section-dropdown__programTypeBadge'>
                        <GNBadge type={selectedProgramType} subtype={selectedProgramType} />
                    </div>
                )}
                {selectedProgramType && selectedProgramSubtype && (
                    <div className='programAttributesSections__section-dropdown__programSubTypeBadge'>
                        <GNBadge type={selectedProgramType} subtype={selectedProgramSubtype} />
                    </div>
                )}
                {PROGRAM_TYPES_WITH_BADGES?.length > 0 && <div className="primary-type">
                    <GNDropdown
                        className='select-search ProgramTypes'
                        label={PROGRAM_ATTRIBUTES_TYPES.PROGRAM_TYPES.LABEL}
                        options={PROGRAM_TYPES_WITH_BADGES}
                        placeholder={PROGRAM_ATTRIBUTES_TYPES.PROGRAM_TYPES.PLACEHOLDER}
                        value={selectedProgramType}
                        handleChange={(item) => {
                            setSelectedProgramType(item);
                            handleFormChange(PROGRAM_ATTRIBUTES_TYPES.PROGRAM_TYPES.VALIDATION_LABEL, item);
                            setSelectedProgramSubtype(item === PROGRAM_TYPES.SERIES ? PROGRAM_TYPES.SERIES : '');
                            handleFormChange(
                                PROGRAM_ATTRIBUTES_TYPES.SUBTYPES.VALIDATION_LABEL,
                                item === PROGRAM_TYPES.SERIES ? PROGRAM_TYPES.SERIES : ''
                            );
                        }}
                        renderValue={renderValue}
                    />
                </div>}
                <div className="sub-type">
                    <GNDropdown
                        label={PROGRAM_ATTRIBUTES_TYPES.SUBTYPES.LABEL}
                        options={PROGRAM_SUBTYPES_WITH_BADGES}
                        placeholder={PROGRAM_ATTRIBUTES_TYPES.SUBTYPES.PLACEHOLDER}
                        value={selectedProgramSubtype}
                        handleChange={(item) => {
                            setSelectedProgramSubtype(item);
                            handleFormChange(PROGRAM_ATTRIBUTES_TYPES.SUBTYPES.VALIDATION_LABEL, item);
                        }}
                        renderValue={renderValue}
                    />
                </div>
                {selectedProgramType === PROGRAM_TYPES.SERIES && selectedProgramSubtype === PROGRAM_TYPES.SERIES && (
                    <div className='programAttributesSections__section-dropdown__subtypeInfo'>
                        <FontAwesomeIcon icon='info-circle' />
                        <span className='subtypePopulateRule'>{SUBTYPE_POPULATE_RULE}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

Types.propTypes = {
    handleFormChange: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired
};

export default Types;
