import { CHANNEL_NAME, CHANNEL_CALL_SIGN, CHANNEL_ID } from './Station';

export const SCHEDULE_REPORT = 'Schedule Report';
export const SCHEDULE_UPDATES_REAL_TIME = 'Real time data from On (v3) API';
export const SCHEDULE_LAST_UPDATED = 'Last Updated';

export const SCHEDULE_DATE_SELECTION = 'Date Selection';
export const EXACT_DATE = 'Exact Date';
export const SCHEDULE_DATE_OPTIONS = ['Last week', 'This week', 'Next week', 'Today', EXACT_DATE];
export const SCHEDULE_EXACT_DATE_SELECTION = ['Daily', 'Weekly'];

export const CREATE_SAVED_SEARCH = 'Save Item';

export const DAILY_MAX_STATIONS = 7;

export const SCHEDULE_SEARCH_TYPES = { channelName: CHANNEL_NAME, channelCallSign: CHANNEL_CALL_SIGN, channelId: CHANNEL_ID };
