import React, { Component } from 'react';
import moment from 'moment-timezone';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DayPickerRangeController } from 'react-dates';
import { Button } from 'react-bootstrap';
import { SCHEDULE_EXACT_DATE_SELECTION } from '../../../constants/Schedule';
import GNRadioGroup from '../gnRadioGroup/GNRadioGroup';
import "./GNDatePicker.scss";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class GNDatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDayPicker: false,
            dateTypeSelected: null,
            focusedInput: 'startDate'
        };
    }

    componentDidMount() {
        if (this.props.startDate?.format('MMMM D, YYYY') === this.props.endDate?.format('MMMM D, YYYY')) {
            this.setState({dateTypeSelected: SCHEDULE_EXACT_DATE_SELECTION[0]});
        } else {
            this.setState({dateTypeSelected: SCHEDULE_EXACT_DATE_SELECTION[1]});
        }
    }

    // Force the focusedInput to always be truthy so that dates are always selectable
    onFocusChange = (focusedInput) => {
        this.setState({ focusedInput: !focusedInput ? 'startDate' : focusedInput });
    }

    showDayPicker = () => {
        this.setState((prevState) => ({showDayPicker: !prevState.showDayPicker}));
    }

    getFormattedDate = () => {
        const formattedStartDate = this.props.startDate?.format('MMMM D, YYYY') || '';
        const formattedEndDate = this.props.endDate?.format('MMMM D, YYYY') || '';
        if (formattedStartDate !== formattedEndDate && this.state.dateTypeSelected === SCHEDULE_EXACT_DATE_SELECTION[1]) {
            return formattedStartDate + ' - ' + formattedEndDate;
        } else {
            return formattedStartDate;
        }
    }

    onDateTypeSelect = (dateType) => {
        // Changed from weekly to daily need to convert dates
        if (dateType === SCHEDULE_EXACT_DATE_SELECTION[0]) {
            const startDate = moment(this.props.startDate).startOf('day');
            const endDate = moment(this.props.startDate).endOf('day');
            this.props.onDatesChange({startDate, endDate});
        } else if (dateType === SCHEDULE_EXACT_DATE_SELECTION[1]) {
            // Changed from daily to weekly need to convert dates
            const startDate = moment(this.props.startDate).startOf('week');
            const endDate = moment(this.props.endDate).endOf('week');
            this.props.onDatesChange({startDate, endDate});
        }
        this.setState({dateTypeSelected: dateType});
    }

    renderBottomSection = () => {
        return (
            <div className="bottom-section">
                <span className="date-type-label">Selection Type: </span>
                <GNRadioGroup list={SCHEDULE_EXACT_DATE_SELECTION} style="flat" onSelect={this.onDateTypeSelect} default={this.state.dateTypeSelected}/>
                <Button variant="light" onClick={this.showDayPicker}>Close</Button>
            </div>
        );
    }

    renderDayContents(day) {
        return (
            <div className="inner-calendar-day">
                {day.format('D')}
            </div>
        );
    }

    endDateOffset = (day) => {
        if (this.state.dateTypeSelected === SCHEDULE_EXACT_DATE_SELECTION[1]) {
            return day.add(6, 'days');
        }
        return day;
    }

    isOutsideRange = (date) => {
        const minDate = moment().subtract('6', 'months');
        const maxDate = moment().add('6', 'months');
        return date.isBefore(minDate.startOf('day')) || date.isAfter(maxDate.endOf('day'));
    }

    render() {
        return (
            <div className="gnview-date-picker">
                <div className={'filter-label'}>Date:</div>
                <Button variant="light" className="date-picker-button" onClick={this.showDayPicker}>
                    <FontAwesomeIcon icon="calendar-alt" />{this.state.dateTypeSelected && <span>{this.getFormattedDate()}</span>}
                </Button>
                {this.state.showDayPicker && <DayPickerRangeController
                    onDatesChange={this.props.onDatesChange}
                    onFocusChange={this.onFocusChange}
                    focusedInput={this.state.focusedInput}
                    startDate={this.props.startDate}
                    endDate={this.props.endDate}
                    hideKeyboardShortcutsPanel={true}
                    onOutsideClick={this.showDayPicker}
                    minimumNights={0}
                    enableOutsideDays={true}
                    isOutsideRange={(date) => this.isOutsideRange(date) }
                    renderDayContents={this.renderDayContents}
                    navPrev={<div className="nav-button prev"><FontAwesomeIcon icon="chevron-left" /></div>}
                    navNext={<div className="nav-button next"><FontAwesomeIcon icon="chevron-right" /></div>}
                    initialVisibleMonth={() => moment()}
                    {...this.props.dailyOrWeekly && { renderCalendarInfo: this.renderBottomSection } }
                    {...this.props.dailyOrWeekly && { endDateOffset: day => this.endDateOffset(day) }}
                />}
            </div>
        );
    }
}

GNDatePicker.propTypes = {
    dailyOrWeekly: PropTypes.bool.isRequired,
    // Moment object
    startDate: PropTypes.object.isRequired,
    // Moment object
    endDate: PropTypes.object.isRequired,
    onDatesChange: PropTypes.func.isRequired
};

export default GNDatePicker;