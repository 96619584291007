import { ROUTES } from '../config/Routes';

export const shouldRenderComponent = (entitlements, path, hasUSAEntitlements, modulesPermitted, internalUser) => {
    if (internalUser) {
        if (path.includes(ROUTES.REPORT_AN_ISSUE) || path.includes(ROUTES.STATUS)) {
            return false;
        }
        return true;
    } else if (!internalUser && !modulesPermitted) {
        if (path.includes(ROUTES.PROGRAM_AIRINGS)) {
            return (entitlements.programs?.include?.languages?.length > 0) && (entitlements.schedules?.include?.countries?.length > 0);
        } else if (path.includes(ROUTES.MARKET_DETAILS)) {
            return hasUSAEntitlements;
        } else if (path.includes(ROUTES.PROGRAM_AVAILABILITIES)) {
            return (entitlements.streamingVideos?.include?.catalogs?.length > 0) || (entitlements?.global?.include?.roles?.includes('admin'));
        } else if (path.includes(ROUTES.ADVANCED_SEARCH)) {
            return (entitlements?.programs?.include?.languages?.length > 0);
        } else if (path.includes(ROUTES.REPORT_AN_ISSUE) || path.includes(ROUTES.STATUS)) {
            return true;
        } else if (path.includes(ROUTES.TRIAL_FORM)) {
            return true;
        } else if (path.includes(ROUTES.SPORTS)) {
            return entitlements.programs?.include?.languages?.length > 0;
        } else {
            const pathEntitlementsOptions = {
                [ROUTES.PROGRAM_DETAILS]: 'programs',
                [ROUTES.PROGRAM_DETAILS + '/:tmsId']: 'programs',
                [ROUTES.STATION_DETAILS]: 'schedules',
                [ROUTES.STATION_DETAILS + '/:stationId']: 'schedules',
                [ROUTES.MARKET_DETAILS]: 'schedules',
                [ROUTES.MARKET_DETAILS + '/:marketId']: 'schedules',
                [ROUTES.SCHEDULES]: 'schedules',
                [ROUTES.SCHEDULES + '/result']: 'schedules',
                [ROUTES.MY_SAVED_ITEMS]: 'schedules',
                [ROUTES.DATA_ALERTS]: 'data-alerts'
            };
            const hasProgramsEntitlement = entitlements[pathEntitlementsOptions[path]]?.include?.languages?.length > 0;
            const hasSchedulesEntitlement = entitlements[pathEntitlementsOptions[path]]?.include?.countries?.length > 0;
            return (hasProgramsEntitlement || hasSchedulesEntitlement || path === ROUTES.CONTACT_US || path === ROUTES.LOGOUT || path === ROUTES.GNVIEW_TEASER);
        }
    } else {
        if (path.includes(ROUTES.PROGRAM_AIRINGS)) {
            return modulesPermitted?.includes(MODULES_PERMITTED.PROGRAM_AIRINGS) && ((entitlements.programs?.include?.languages?.length > 0) && (entitlements.schedules?.include?.countries?.length > 0));
        } else if (path.includes(ROUTES.MARKET_DETAILS)) {
            return modulesPermitted?.includes(MODULES_PERMITTED.MARKET_DETAILS) && hasUSAEntitlements;
        } else if (path.includes(ROUTES.PROGRAM_DETAILS)) {
            return modulesPermitted?.includes(MODULES_PERMITTED.PROGRAM_DETAILS) && entitlements.programs?.include?.languages?.length > 0;
        } else if (path.includes(ROUTES.PROGRAM_AVAILABILITIES)) {
            return (entitlements.streamingVideos?.include?.catalogs?.length > 0) || (entitlements?.global?.include?.roles?.includes('admin'));
        } else if (path.includes(ROUTES.ADVANCED_SEARCH)) {
            return modulesPermitted?.includes(MODULES_PERMITTED.ADVANCED_SEARCH) && (entitlements?.programs?.include?.languages?.length > 0);
        } else if (path.includes(ROUTES.REPORT_AN_ISSUE) || path.includes(ROUTES.STATUS)) {
            return modulesPermitted?.includes(MODULES_PERMITTED.REPORT_AN_ISSUE);
        } else if (path.includes(ROUTES.TRIAL_FORM)) {
            return true;
        } else if (path.includes(ROUTES.SPORTS)) {
            return modulesPermitted?.includes(MODULES_PERMITTED.SPORTS) && entitlements.programs?.include?.languages?.length > 0;
        } else if (path.includes(ROUTES.STATION_DETAILS)) {
            return modulesPermitted?.includes(MODULES_PERMITTED.STATION_DETAILS) && entitlements?.schedules?.include?.countries?.length > 0;
        } else if (path.includes(ROUTES.SCHEDULES)) {
            return modulesPermitted?.includes(MODULES_PERMITTED.SCHEDULES) && entitlements?.schedules?.include?.countries?.length > 0;
        } else if (path.includes(ROUTES.MY_SAVED_ITEMS)) {
            return modulesPermitted?.includes(MODULES_PERMITTED.MY_SAVED_ITEMS) && entitlements?.schedules?.include?.countries?.length > 0;
        } else if (path.includes(ROUTES.DATA_ALERTS)) {
            return modulesPermitted?.includes(MODULES_PERMITTED.DATA_ALERTS) && entitlements?.schedules?.include?.countries?.length > 0;
        }
        return (path === ROUTES.CONTACT_US || path === ROUTES.LOGOUT || path === ROUTES.GNVIEW_TEASER || path === ROUTES.ACCESS_DENIED || path === ROUTES.LOOKUP_MAX);
    }
};

export const shouldRenderGNViewTeaser = (entitlements, path) => {
    if (path.includes(ROUTES.SCHEDULES) || path.includes(ROUTES.GNVIEW_TEASER)) {
        return entitlements.programs?.include?.languages?.length === 0 && entitlements.schedules?.include?.countries?.length === 0 && entitlements.streamingVideos?.include?.catalogs?.length === 0 && entitlements?.mobius?.include?.access?.length > 0;
    }
    return false;
};

export const MODULES_PERMITTED = {
    PROGRAM_DETAILS: "program_details",
    SPORTS: "sports",
    ADVANCED_SEARCH: "advanced_search",
    STATION_DETAILS: "station_details",
    MARKET_DETAILS: "market_details",
    PROGRAM_AIRINGS: "program_airings",
    SCHEDULES: "schedules",
    MY_SAVED_ITEMS: "my_saved_items",
    REPORT_AN_ISSUE: "report_an_issue",
    SUBMIT_FEEDBACK: "submit_feedback",
    DATA_ALERTS: "data_alerts"
};