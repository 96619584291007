import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { gnviewSelProgramDetails } from '../../../../reducers/ProgramDetailsReducer';
import GNCard from '../../../../components/common/gnCard/GNCard';
import GNClientTable from '../../../../components/common/gnClientTable/GNClientTable';
import { PROGRAM_OTHER_VERSIONS_COLUMN_HEADERS } from '../../../../constants/Program';
import { gnviewGetProgramOtherVersions } from "../../../../actions/GNViewActions";
import { gnviewSendLogMessage } from '../../../../services/GeneralService';
import ErrorBoundary from '../../../../components/common/errorBoundary/ErrorBoundary';

export class OtherVersionsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            otherVersions: []
        }
    }

    componentDidMount() {
        const programRootId = this.props.programDetails?.rootid;
        if (programRootId) {
            this.props.gnviewGetProgramOtherVersions(programRootId).then(
                (response) => {
                    const formattedResponse = response?.result
                        ?.map((version) => ({
                            tmsid: version?.tmsid,
                            rootid: version?.rootid,
                            language: version?.description_language,
                            title: version?.episode_info?.title ? version?.episode_info?.title : version?.titles[0]?.text,
                            subtype: version?.titles[0]?.sub_type || '',
                            title_language: version?.titles[0]?.language
                        }))
                        .sort((a, b) => (a.tmsid > b.tmsid ? 1 : -1));
                    this.setState({ isLoading: false, otherVersions: formattedResponse });
                    this.cancelToken = response.cancelToken;
                },
                (error) => {
                    this.props.gnviewSendLogMessage(`gnviewGetProgramOtherVersions error: ${error.message}`, error, {
                        programRootId
                    });
                }
            );
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.cancelToken) {
            this.cancelToken.cancel();
        }
    }

    render() {
        return (
            <ErrorBoundary>
                <div>
                    <GNCard title="Other Versions" headerOutsideOfCard={true}>
                        {this.state.otherVersions?.length > 0 && <GNClientTable columns={PROGRAM_OTHER_VERSIONS_COLUMN_HEADERS} data={this.state.otherVersions} isLoading={false}/>}
                        {!this.state.isLoading && this.state.otherVersions?.length === 0 && <div className="no-other-versions-message">No other versions data available</div>}
                    </GNCard>
                </div>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = state => {
    return {
        programDetails: gnviewSelProgramDetails(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewGetProgramOtherVersions,
        gnviewSendLogMessage
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherVersionsTab);

OtherVersionsTab.propTypes = {
    gnviewSendLogMessage: PropTypes.func,
    gnviewGetProgramOtherVersions: PropTypes.func.isRequired,
    programDetails: PropTypes.object.isRequired
}