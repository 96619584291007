import React from 'react';
import { Modal } from 'react-bootstrap';
import "./SuccessMessageModal.scss";
import PropTypes from "prop-types";

export const SuccessMessageModal = (props) => {
    return (
        <Modal {...props} show={true} aria-labelledby="contained-modal-title-vcenter" centered className='success-message-container' backdrop="static">
            <Modal.Body>
                <div className='success-icon'>
                    <i className="fas fa-circle-check fa-7x"/>
                </div>
                <p className='success-message'>
                    {props.message}
                </p>
            </Modal.Body>
        </Modal>
    );
};

SuccessMessageModal.propTypes = {
    message: PropTypes.string.isRequired
};

export default SuccessMessageModal;