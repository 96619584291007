import get from 'lodash.get';
import { RESTRICTED_IMAGES } from '../constants/Program';
import { replaceNull } from './GeneralUtils';

const internalMediaURL = "https://coin.tmsimg.com/assets/";

// Returns image location with the correct URL or null, if there is none
export const getImageURLFromObj = (obj, imageProp, width = "?w=300", mediaURL = internalMediaURL) => {
    const imageName = get(obj, imageProp);
    return (imageName) ? mediaURL + imageName + width : null;
};

// Returns imageURL using provided image name and width, width defaults to 300
export const getImageURL = (imageName, width = "?w=300", mediaURL = internalMediaURL) => {
    return (imageName) ? mediaURL + imageName + width : null;
};

// Returns imageURL using provided image name and height, height defaults to 300
export const getImageURLH = (imageName, height = "?h=300", mediaURL = internalMediaURL) => {
    return (imageName) ? mediaURL + imageName + height : null;
};

/**
 * Returns image categories using provided image category.
 * If isAdminOrHasStreamId is true, Restricted is added to the end of the array
 * @param {[object]} imageObjs - Object of array
 * @param {boolean} isAdminOrHasStreamId - isAdmin or hasStreamId is used for Program Images Restricted. If it's true, restricted is added
 * @returns {[string]} - Strings (Categories) of array
 */
export const getImgCategories = (imageObjs, isAdminOrHasStreamId = false) => {
    const categoryCounts = imageObjs.reduce((acc, imgObj) => {
        const { category, provider } = imgObj;
        return {
            ...acc,
            [category]: {
                // A number of category
                images: acc?.[category]?.images + 1 || 1,
                // A number of restricted images in the category
                restricted: provider?.toLowerCase()?.includes(RESTRICTED_IMAGES.RESTRICTED.toLowerCase()) ? acc?.[category]?.restricted + 1 || 1 : acc?.[category]?.restricted || 0
            }
        };
    }, {});
    // Remove the category that has only restricted images
    Object.entries(categoryCounts).forEach(([key, val]) => {
        if (val?.images === val?.restricted) {
            delete categoryCounts[key];
        }
    });
    const uniqueCategories = Object.keys(categoryCounts).sort(
        // (All images - All restricted images) in a category
        (a, b) => (categoryCounts[b].images - categoryCounts[b].restricted) - (categoryCounts[a].images - categoryCounts[a].restricted)
    );
    replaceNull(uniqueCategories, 'Uncategorized');
    if (isAdminOrHasStreamId) {
        return [...uniqueCategories, RESTRICTED_IMAGES.RESTRICTED];
    }
    return uniqueCategories;
};

export const getImage = (img, mediaURL = internalMediaURL) => {
    return {
        ...img,
        aspectRatio: img.ratio && img.ratio.split(':').length === 2 ? img.ratio.split(':')[0] / img.ratio.split(':')[1] : 0,
        area: img.width * img.height,
        dimensions: `${img.width} x ${img.height}`,
        url: img.height ? getImageURLH(img.image, `?h=${img.height}`, mediaURL) : getImageURLH(img.image, null, mediaURL)
    }
};

export const sortImagesArray = (images) => {
    return images.sort((img1, img2) => img1.area && img2.area ? img2.area - img1.area : img2.aspectRatio - img1.aspectRatio);
};

/**
 * Filter out images that include the restricted image
 * @param {[object]} images
 * @returns {[object]}
 */
export const filterRestrictedImages = (images) => {
    if (images?.length > 0) {
        images = images.filter((image) => {
            return !image.provider?.toLowerCase().includes(RESTRICTED_IMAGES.RESTRICTED.toLowerCase()) && !(image.identifiers?.find((identifier) => (identifier.type === "prgSvcId" || identifier.type === 'market') && identifier.id !== null));
        });
    }
    return images;
};
