import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { gnviewGetProgramById, gnviewGetStation } from '../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import { getProgramImageByRatio, getProgramColor } from '../../../utils/ProgramUtils';
import { getImageURLH } from '../../../utils/ImageUtils';
import GNImage from '../gnImage/GNImage';
import './SavedItem.scss';
import PropTypes from 'prop-types';
import { getStationImage } from '../../../utils/StationUtils';
import ClassNames from 'classnames';
import { ROUTES } from '../../../config/Routes';
import { withRouter } from 'react-router-dom';
import { RECENT_SEARCH_PLACEHOLDER_IMAGES } from '../../../constants/RecentSearchItem';

export class SavedItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            imageURL: '',
            hasError: false,
            isLoading: true
        }
    }

    componentDidMount() {
        if (this.props.id) {
            const action = this.props.isProgramSearch ? this.props.gnviewGetProgramById : this.props.gnviewGetStation;
            action(this.props.id).then((response) => {
                const data = this.props.isProgramSearch ? response?.result[0] : response?.result;
                if (data) {
                    const getImage = this.props.isProgramSearch ? getProgramImageByRatio : getStationImage;
                    const height = this.props.isProgramSearch ? 150 : 100;
                    const imageURL = getImageURLH(getImage(data, '4:3'), `?h=${height}`) || '';
                    this.setState({ data, imageURL, isLoading: false });
                } else {
                    this.setState({ hasError: true, isLoading: false });
                }
            }).catch((error) => {
                this.setState({ hasError: true, isLoading: false });
                const errorMsg = this.props.isProgramSearch ? `gnviewGetProgramById error: ${error.message}` : `gnviewGetStation error: ${error.message}`;
                this.props.gnviewSendLogMessage(errorMsg, error, { id: this.props.id });
            });
        }
    }

    handleClick = () => {
        const path = this.props.isProgramSearch ? `${ROUTES.PROGRAM_DETAILS}/${this.props.id}` : `${ROUTES.STATION_DETAILS}/${this.props.id}`;
        this.props.history.push(path);
    }

    render() {
        const color = getProgramColor(this.state.data?.type);
        return (
            <div className={ClassNames('gnview-recent-search-item-container', {'station': !this.props.isProgramSearch})} onClick={this.handleClick}>
                {!this.state.isLoading && <div className={ClassNames('recent-search-img-container', {'station': !this.props.isProgramSearch, 'isPlaceholder': this.state.imageURL.length === 0})}>
                    {this.state.imageURL.length > 0 && <GNImage className={ClassNames('recent-search-image', {'station': !this.props.isProgramSearch})} url={this.state.imageURL} />}
                    {this.state.imageURL.length === 0 && <div className='placeholder-img-container'>
                        <GNImage className='recent-search-image' url={this.props.isProgramSearch ? RECENT_SEARCH_PLACEHOLDER_IMAGES[this.state.data?.type] : RECENT_SEARCH_PLACEHOLDER_IMAGES.STATION} />
                    </div>}
                </div>}
                {!this.state.hasError && this.props.isProgramSearch && <div className='recent-search-text-section'>
                    <div className={`recent-search-type ${color}`}>{this.state.data?.subtype === 'Episode' ? this.state.data?.subtype : this.state.data?.type}</div>
                    <div className='recent-search-title'>{this.state.data?.title}</div>
                    <div className='recent-search-year'>{this.state.data?.release_year}</div>
                </div>}
                {!this.state.hasError && !this.props.isProgramSearch && <div className='recent-search-text-section station'>
                    <div className={`recent-search-type color-special`}>{this.state.data?.type}</div>
                    <div className='recent-search-title'>{this.state.data?.name}</div>
                    <div className='recent-search-year'>{this.state.data?.id}</div>
                </div>}
                {this.state.hasError && <div className='recent-search-id'>{this.props.id}</div>}
            </div>
        )
    }
}

SavedItem.propTypes = {
    id: PropTypes.string.isRequired,
    isProgramSearch: PropTypes.bool.isRequired,
    gnviewGetProgramById: PropTypes.func,
    gnviewGetStation: PropTypes.func,
    gnviewSendLogMessage: PropTypes.func,
    history: PropTypes.object
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        gnviewGetProgramById,
        gnviewGetStation,
        gnviewSendLogMessage
    }, dispatch)
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SavedItem));