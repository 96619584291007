import React from 'react';
import { Button, OverlayTrigger} from 'react-bootstrap';
import ClassNames from 'classnames';
import './GNButton.scss';
import PropTypes from 'prop-types';

export const GNButton = ({disabled, onClick, hasWriteAccess, tooltip, tooltipPlacement, children, trigger, buttonClass, type}) => {
    return (
        <OverlayTrigger
            overlay={tooltip}
            placement={tooltipPlacement || "bottom"}
            trigger={trigger}
        >
            <div className={ClassNames('gn_button', {'cursor-pointer': hasWriteAccess && !disabled, 'not-allowed': !hasWriteAccess || disabled})}>
                <Button
                    className={ClassNames(buttonClass, 'gn_button', {'pointer-none': disabled})}
                    disabled={disabled}
                    onClick={onClick}
                    type={type}
                >
                    {children}
                </Button>
            </div>
        </OverlayTrigger>
    );
}

GNButton.defaultProps = {
    gnview: true,
    type: 'button'
};

GNButton.propTypes = {
    buttonClass: PropTypes.string,
    onClick: PropTypes.func,
    hasWriteAccess: PropTypes.bool,
    disabled: PropTypes.bool,
    trigger: PropTypes.any,
    tooltip: PropTypes.node,
    tooltipPlacement: PropTypes.string,
    type: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

export default GNButton;