import React from 'react';
import Badge from 'react-bootstrap/Badge';
import ClassNames from 'classnames';
import './StatusBadge.scss';
import PropTypes from 'prop-types';

export const StatusBadge = ({ value = 'New' }) => {
    const getColor = (statusValue) => {
        if (statusValue.includes('New')) {
            return 'blue';
        }
        if (statusValue.includes('In Progress')) {
            return 'purple';
        }
        if (statusValue.includes('Directed')) {
            return 'yellow';
        }
        return 'grey';
    };

    return (
        <Badge className={ClassNames(
            'status_badge',
            `color-${getColor(value)}`
        )}>
            {value && <div className='badge-text'>{value.toUpperCase()}</div>}
        </Badge>
    );
}

StatusBadge.propTypes = {
    value: PropTypes.string
};

export default StatusBadge;