import React, { useState } from 'react';
import { Formik } from 'formik';
import get from 'lodash.get';
import './SubscribeForm.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { CHANNEL_SEARCH_TYPES, SUBSCRIBE_TO_ALERTS_LABELS, SUBSCRIBE_TO_CHANNEL_FORM_FIELDS, SUBSCRIBE_TO_CHANNEL_FORM_INITIAL_TOUCHED, SUBSCRIBE_TO_CHANNEL_FORM_VALIDATION_SCHEMA, INPUT_PLACEHOLDER_OPTIONS, getSubscribeToChannelFormInitialValues } from '../../../../../constants/Station';
import { Button, Form } from 'react-bootstrap';
import { getSubscriptionStation } from '../../../../../actions/SubscriptionActions';
import TypeaheadFilter from '../../../../../components/common/typeaheadFilter/TypeaheadFilter';
import { GNRadioGroup } from '../../../../../components/common/gnRadioGroup/GNRadioGroup';
import { GNStationTypeahead } from '../../../../../components/common/gnStationTypeahead/GNStationTypeahead';
import { getUpdateValues } from '../../../../../constants/DataAlerts';

export const SubscribeForm = ({ handleFormSubmit, setIsFormValid, selectedStation, setValues, isUpdate = false, trackChannel = false, currentSubscriptions = [] }) => {
    const [distributionPlatformsRef, setDistributionPlatformsRef] = useState(null);
    const [distributionPlatformsValue, setDistributionPlatformsValue] = useState(null);
    const [selectedDistributionPlatforms, setSelectedDistributionPlatforms] = useState(null);
    const [searchType, setSearchType] = useState(CHANNEL_SEARCH_TYPES.channelName);
    const [placeholderText, setPlaceholderText] = useState(CHANNEL_SEARCH_TYPES.channelName);
    const [selectedStations, setSelectedStations] = useState([]);
    const [channelError, setChannelError] = useState(null);
    const [labelsRef, setLabelsRef] = useState(null);
    const [labelsValue, setLabelsValue] = useState(null);
    const [selectedLabels, setSelectedLabels] = useState(null);

    return (
        <div className="gnview-subscribe-alerts-form-content">
            <Formik
                initialValues={isUpdate ? getUpdateValues(selectedStation) : getSubscribeToChannelFormInitialValues()}
                initialTouched={SUBSCRIBE_TO_CHANNEL_FORM_INITIAL_TOUCHED}
                validationSchema={SUBSCRIBE_TO_CHANNEL_FORM_VALIDATION_SCHEMA}
                onSubmit={(values) => handleFormSubmit(values)}>
                {({ errors, handleSubmit, isSubmitting, isValid, setFieldTouched, setFieldValue, touched, values }) => {
                    const validateForm = (formName) =>
                        isSubmitting || get(touched, formName) ? !get(errors, formName) : true;

                    const handleFormChange = (formName, value) => {
                        if (!touched[formName]) {
                            setFieldTouched(formName, true);
                        }
                        setFieldValue(formName, value);
                        setValues(values);
                    };

                    const onSearchTypeSelect = (type) => {
                        const typePlaceholderText = handlePlaceholderText(type);
                        setPlaceholderText(typePlaceholderText);
                        setSearchType(type);
                    }

                    const handleChangeStation = (station) => {
                        const stationId = (station.length > 0 && station[0].id) ? station[0].id : null;
                        if (currentSubscriptions.some(sub => sub.station_id === stationId)) {
                            setChannelError('Already subscribed to this channel');
                            setIsFormValid(false);
                            return;
                        }
                        if (stationId) {
                            setSelectedStations(station);
                            setFieldValue('prgSvcID', String(stationId));
                        }
                        setChannelError(null);
                    }

                    const handlePlaceholderText = (thisSearchType) => {
                        if (thisSearchType === CHANNEL_SEARCH_TYPES.channelName) {
                            return INPUT_PLACEHOLDER_OPTIONS.channelName
                        } else if (thisSearchType === CHANNEL_SEARCH_TYPES.channelCallSign) {
                            return INPUT_PLACEHOLDER_OPTIONS.channelCallSign
                        } else {
                            return INPUT_PLACEHOLDER_OPTIONS.channelId;
                        }
                    }

                    const isCallSignSearch = () => {
                        return searchType === CHANNEL_SEARCH_TYPES.channelCallSign;
                    }

                    const selectedSourceType = values[SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.SOURCE_TYPE] === SUBSCRIBE_TO_ALERTS_LABELS.ORIGINATOR;

                    setIsFormValid(isValid)

                    setValues(values);

                    const handleOnBlurTypeaheads = (inputRef, inputValue, selectedItems, fieldName, setSelectedItems, setInputValue) => {
                        if (inputValue) {
                            let formattedItems = selectedItems === null ? [] : selectedItems;
                            formattedItems = [...formattedItems, inputValue];
                            handleFormChange(fieldName, formattedItems);
                            setSelectedItems(formattedItems);
                            setInputValue('');
                        }
                    }

                    const handleSearchButtonClick = () => {
                        setChannelError('');
                        if (selectedStation.id) {
                            getSubscriptionStation(selectedStation.id).then((station) => {
                                if (station?.id && station?.name) {
                                    handleChangeStation([station]);
                                    const searchTypeButtons = document.getElementsByClassName('searchTypeButtons')[0];
                                    searchTypeButtons.getElementsByTagName('label')[0].click();
                                } else {
                                    setChannelError('No station found with this ID');
                                }
                            });
                        }
                    }

                    // Station ID input
                    const handleStationIdChange = (e) => {
                        const stationId = e.target.value;
                        selectedStation = {id: stationId};
                    }

                    return (
                        <Form noValidate onSubmit={handleSubmit} role='form'>
                            {trackChannel && (
                                <div className = "track-channel">
                                    <Form.Label role='label'>
                                        {SUBSCRIBE_TO_ALERTS_LABELS.CHANNEL_NAME}
                                    </Form.Label>
                                    <div className="filter-bar-container">
                                        <GNRadioGroup className="searchTypeButtons" list={Object.values(CHANNEL_SEARCH_TYPES)} onSelect={onSearchTypeSelect} default={CHANNEL_SEARCH_TYPES.channelName}/>
                                        {(searchType !== CHANNEL_SEARCH_TYPES.channelId) && <div className="station-title-section">
                                            <GNStationTypeahead
                                                multipleStations={false}
                                                handleChangeStation={handleChangeStation}
                                                selectedStations={selectedStations}
                                                isCallSignSearch={isCallSignSearch()}
                                                placeholder={placeholderText} />
                                        </div>
                                        }
                                        {searchType === CHANNEL_SEARCH_TYPES.channelId && <div className="station-id-selection">
                                            <Form onSubmit={handleSearchButtonClick}>
                                                <Form.Row>
                                                    <Form.Control onChange={handleStationIdChange} placeholder={placeholderText}></Form.Control>
                                                    <Button value={selectedStation?.id} variant="primary" className="search-button" onClick={handleSearchButtonClick}>Search</Button>
                                                </Form.Row>
                                            </Form>
                                        </div>}
                                        {channelError && (
                                            <div className="channel-error">
                                                {channelError}
                                            </div>
                                        )}
                                    </div>
                                </div>)
                            }
                            {!trackChannel && (
                                <div className="channel-name">
                                    <Form.Label role='label'>
                                        {SUBSCRIBE_TO_ALERTS_LABELS.CHANNEL_NAME}
                                    </Form.Label>
                                    <div className='channel-name-input'>
                                        <Form.Control
                                            disabled={true}
                                            value={selectedStation?.name || selectedStation?.station_name}
                                            // disable Last Pass icon
                                            data-lpignore={true}></Form.Control>
                                        <FontAwesomeIcon icon='magnifying-glass' />
                                    </div>
                                    <Form.Text className='required-text'>*Required</Form.Text>
                                </div>)
                            }
                            <div className="source-type">
                                <Form.Label role='label'>
                                    {SUBSCRIBE_TO_ALERTS_LABELS.SOURCE_TYPE}
                                </Form.Label>
                                <div className="source-type-options">
                                    <Form.Check
                                        custom
                                        inline
                                        name={SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.SOURCE_TYPE}
                                        id={`custom-radio-${SUBSCRIBE_TO_ALERTS_LABELS.ORIGINATOR}`}
                                        type='radio'
                                        label={SUBSCRIBE_TO_ALERTS_LABELS.ORIGINATOR}
                                        onClick={() => handleFormChange(SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.SOURCE_TYPE, SUBSCRIBE_TO_ALERTS_LABELS.ORIGINATOR)}
                                        checked={selectedSourceType}
                                    />
                                    <Form.Check
                                        custom
                                        inline
                                        name={SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.SOURCE_TYPE}
                                        id={`custom-radio-${SUBSCRIBE_TO_ALERTS_LABELS.LICENSEE}`}
                                        type='radio'
                                        label={SUBSCRIBE_TO_ALERTS_LABELS.LICENSEE}
                                        onClick={() => handleFormChange(SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.SOURCE_TYPE, SUBSCRIBE_TO_ALERTS_LABELS.LICENSEE)}
                                        checked={!selectedSourceType}
                                    />
                                </div>
                            </div>
                            <Form.Text className='required-text'>*Required</Form.Text>
                            <TypeaheadFilter
                                allowNew={true}
                                labelKey = {"distributionPlatform"}
                                name={SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.DISTRIBUTION_PLATFORMS}
                                filterLabel={SUBSCRIBE_TO_ALERTS_LABELS.DISTRIBUTION_PLATFORMS}
                                domRef={distributionPlatformsRef}
                                onInputChange={(val) => {
                                    setDistributionPlatformsValue(val);
                                }}
                                handleChange={(item) => {
                                    const formattedDistributionPlatforms = item.map((platform) => platform?.distributionPlatform || platform);
                                    handleFormChange(SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.DISTRIBUTION_PLATFORMS, formattedDistributionPlatforms);

                                    setSelectedDistributionPlatforms(formattedDistributionPlatforms);
                                    setDistributionPlatformsValue('');
                                }}
                                list={[]}
                                handleBlur={() => {
                                    handleOnBlurTypeaheads(distributionPlatformsRef, distributionPlatformsValue, selectedDistributionPlatforms, SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.DISTRIBUTION_PLATFORMS, setSelectedDistributionPlatforms, setDistributionPlatformsValue);
                                }}
                                multiple={true}
                                ref={(ref) => {
                                    setDistributionPlatformsRef(ref);
                                }}
                                placeholder={SUBSCRIBE_TO_ALERTS_LABELS.DISTRIBUTION_PLATFORMS_PLACEHOLDER}
                                selected={values[SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.DISTRIBUTION_PLATFORMS]}
                                validationText={errors[SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.DISTRIBUTION_PLATFORMS]}
                                validateTypeahead={() => validateForm(SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.DISTRIBUTION_PLATFORMS)}
                            />
                            <TypeaheadFilter
                                allowNew={true}
                                name={SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.LABELS}
                                filterLabel={SUBSCRIBE_TO_ALERTS_LABELS.LABELS}
                                handleChange={(item) => {
                                    const formattedLables = item.map((label) => label?.label || label);
                                    handleFormChange(SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.LABELS, formattedLables);

                                    setSelectedLabels(formattedLables);
                                    setLabelsValue('');
                                }}
                                list={[]}
                                multiple={true}
                                domRef={labelsRef}
                                onInputChange={(val) => {
                                    setLabelsValue(val);
                                }}
                                handleBlur={() => {
                                    handleOnBlurTypeaheads(labelsRef, labelsValue, selectedLabels, SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.LABELS, setSelectedLabels, setLabelsValue);
                                }}
                                placeholder={SUBSCRIBE_TO_ALERTS_LABELS.LABELS_PLACEHOLDER}
                                ref={(ref) => {
                                    setLabelsRef(ref);
                                }}
                                selected={values[SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.LABELS]}
                                validationText={errors[SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.LABELS]}
                                validateTypeahead={() => validateForm(SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.LABELS)}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </div>
    )
};

SubscribeForm.propTypes = {
    handleFormSubmit: PropTypes.func,
    setIsFormValid: PropTypes.func,
    setValues: PropTypes.func,
    selectedStation: PropTypes.any,
    isUpdate: PropTypes.bool,
    trackChannel: PropTypes.bool,
    currentSubscriptions: PropTypes.array
};

SubscribeForm.defaultProps = {
    handleFormSubmit: () => {},
    setIsFormValid: () => {},
    selectedStation: null,
    setValues: () => {},
    isUpdate: false,
    trackChannel: false,
    currentSubscriptions: []
}

export default SubscribeForm;
