import React from 'react';
import SubscriptionNoIcon from '../images/subscription_no_icon.png';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getImageURL } from '../utils/ImageUtils';

export const SUBSCRIPTION_MODAL_UNSUBSCRIBE_TITLE = "Unsubscribe from this Channel?"
export const SUBSCRIPTION_MODAL_UNSUBSCRIBE_MESSAGE = "You will no longer receive data alerts for this channel."
export const SUBSCRIPTION_MODAL_EDIT_TITLE = "Edit Channel Subscription"
export const SUBSCRIPTION_MODAL_CANCEL = "Cancel"
export const SUBSCRIPTION_MODAL_UNSUBSCRIBE = "Unsubscribe"
export const SUBSCRIPTION_MODAL_SAVE = "Save"
export const SUBSCRIPTION_EDIT_BUTTON = "Edit"
export const SUBSCRIPTION_UNSUBSCRIBE_BUTTON = "Unsubscribe"

/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
export const SUBSCRIPTION_TABLE_HEADERS = [
    {
        accessor: 'station_name',
        Header: 'Channel Name',
        Cell: ({row, value}) => {
            return (
                <>
                    { row.original?.station_image ? (
                        <img src={getImageURL(row.original?.station_image)} alt={value} className="sub-icon" />
                    ) : (
                        <img src={SubscriptionNoIcon} alt={value} className="sub-icon" />
                    )}
                    <span>{value}</span>
                </>
            );
        }
    },
    {
        accessor: 'source_of_channel',
        Header: 'Source of Channel'
    },
    {
        accessor: 'distributionPlatforms',
        Header: 'Distribution Platforms',
        Cell: ({row}) => {
            return (
                /* eslint-disable react/jsx-key */
                <OverlayTrigger overlay={<Tooltip id="tooltip">{row.values?.distributionPlatforms.map((distributionPlatform) => (<p className="label-tooltip">{distributionPlatform.distributionPlatform}</p>))}</Tooltip>} placement="bottom">
                    <div>
                        {row.values?.distributionPlatforms.map((distributionPlatform, index) => (
                            <span key={index} >{distributionPlatform.distributionPlatform}{index !== row.values?.distributionPlatforms.length - 1 ? ', ' : ''}</span>
                        ))}
                    </div>
                </OverlayTrigger>
            )
        }
    },
    {
        accessor: 'labels',
        Header: 'Labels',
        Cell: ({row}) => {
            return (
                /* eslint-disable react/jsx-key */
                <OverlayTrigger overlay={<Tooltip id="tooltip">{row.values?.labels.map((label) => (<p className="label-tooltip">{label.label}</p>))}</Tooltip>} placement="bottom">
                    <div>
                        {row.values?.labels.map((label) => (
                            <span className="subscription-label">{label.label}</span>
                        ))}
                    </div>
                </OverlayTrigger>
            )
        }
    },
    {
        id: 'TablePopover',
        Header: '',
        disableSortBy: true
    }
];
/* eslint-enable react/display-name */
/* eslint-enable react/prop-types */