import React from 'react';
import Badge from 'react-bootstrap/Badge';
import ClassNames from 'classnames';
import './GNBadge.scss';
import { getProgramColor } from '../../../utils/ProgramUtils';
import PropTypes from 'prop-types';

export const GNBadge = (props) => {
    const color = getProgramColor(props.type, props.gnview) || props.color || 'color-default';

    return (
        <Badge className={ClassNames('gn_badge', `${color}`)} onClick={props.onClick ? props.onClick : null}>
            { props.subtype && <div className='badge-text'>{props.subtype}</div> }
            { !props.subtype && <div className='badge-text'>SUBTYPE</div> }
        </Badge>
    );
}

GNBadge.defaultProps = {
    gnview: true
};

GNBadge.propTypes = {
    color: PropTypes.string,
    gnview: PropTypes.bool,
    onClick: PropTypes.func,
    subtype: PropTypes.string,
    type: PropTypes.string
};

export default GNBadge;