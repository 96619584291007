import getStartedIntroImage from '../images/get_started/introductionimage.png';
import getStartedStepByStepImage from '../images/get_started/userguideimage.png';

export const GET_STARTED_TOP_SECTION_CONTENT = {
    INTRODUCTION: {
        title: 'Introduction to Gracenote View',
        image: getStartedIntroImage,
        url: null,
        icon: 'fas fa-play'
    },
    STEP_BY_STEP: {
        title: 'Step-by-Step User Guide',
        image: getStartedStepByStepImage,
        url: 'http://www.gracenote.com/documentation/gn-view/html/Content/view/Overview.htm',
        icon: 'fas fa-book'
    }
}

export const GET_STARTED_CARD_CONTENT = [
    {
        title: 'Advanced Search',
        button_id_prefix: 'advanced-search-',
        watch_links: [
            'How to locate all seasons for a series that changes title from season to season',
            'How to do a Root ID search',
            'How to search programs by program attributes such as genre'
        ],
        icon: 'fas fa-magnifying-glass'
    },
    {
        title: 'Basics',
        button_id_prefix: 'basics-',
        watch_links: [
            'Introduction to Gracenote and Gracenote View',
            'How to use Gracenote View for exploring Gracenote Data',
            'How to use Gracenote View for tracking content',
            'How to use Gracenote View for accounting content airings'
        ],
        icon: 'fas fa-check'
    },
    {
        title: 'Program Airings',
        button_id_prefix: 'program-airings-',
        watch_links: [
            'How to search for airings of a program'
        ],
        icon: 'fas fa-clipboard-list'

    },
    {
        title: 'Program Availability',
        button_id_prefix: 'program-availability-',
        watch_links: [
            'How to verify streaming catalog deep links'
        ],
        icon: 'fas fa-clipboard-list'
    },
    {
        title: 'Programs',
        button_id_prefix: 'programs-',
        watch_links: [
            'How to locate a series and upcoming episode',
            'How to search by TMS ID',
            'How to locate imagery and enhanced data for a program'
        ],
        icon: 'fas fa-tv'
    },
    {
        title: 'Schedules',
        button_id_prefix: 'schedules-',
        watch_links: [
            'How to do a schedule look up for a channel',
            'How to compare content of multiple channels'
        ],
        icon: 'fas fa-clock'
    },
    {
        title: 'Sports',
        button_id_prefix: 'sports-',
        watch_links: [
            'How to look up an upcoming game'
        ],
        icon: 'fas fa-football'
    },
    {
        title: 'Stations',
        button_id_prefix: 'stations-',
        watch_links: [
            'How to see more details regarding a channel'
        ],
        icon: 'fas fa-video'
    },
    {
        title: 'Account Settings',
        button_id_prefix: 'account-settings-',
        watch_links: [
            'How to set your search defaults to improve usability of View'
        ],
        icon: 'fas fa-user'
    },
    {
        title: 'My Saved Items',
        button_id_prefix: 'my-saved-items-',
        watch_links: [
            'How to save searches to cut out search time from your workflow'
        ],
        icon: 'fas fa-bookmark'
    },
    {
        title: 'US Markets',
        button_id_prefix: 'us-markets-',
        watch_links: [
            'How to find local affiliates of a channel'
        ],
        icon: 'fas fa-location-dot'
    }
]