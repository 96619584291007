import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    SCHEDULE_DATE_OPTIONS,
    SCHEDULE_DATE_SELECTION,
    SCHEDULE_REPORT,
    SCHEDULE_SEARCH_TYPES
} from '../../../constants/Schedule';
import {
    BASIC_TIER_LOOKUP_LIMIT_MESSAGE,
    BASIC_TIER_RESET_MESSAGE,
    BASIC_TIER_UPGRADE_MESSAGE
} from '../../../constants/Program';
import { UPGRADE_EMAIL } from '../../../constants/LookupMax';
import {
    gnviewClearSelectedStation,
    gnviewCreateSavedSearch,
    gnviewGetAllSavedSearches,
    gnviewGetSavedSearch,
    gnviewGetStation,
    gnviewSearchStations,
    gnviewUpdateSavedSearch
} from '../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import { Button, Form } from 'react-bootstrap';
import "./ScheduleSearch.scss";
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import { getDateSelectObj, getFormattedDate, getThisWeek } from '../../../utils/DateUtils';
import { ROUTES } from '../../../config/Routes';
import GNDateSelection from '../../../components/common/gnDateSelection/GNDateSelection';
import GNStationTypeahead from '../../../components/common/gnStationTypeahead/GNStationTypeahead';
import moment from 'moment-timezone';
import { gvauthSelEmail, gvauthSelLookupCount, gvauthSelLookupMax, gvauthIsBasic } from '../../../reducers/GNVAuthReducer';
import queryString from 'query-string';
import { SCHEDULE_SEARCH_FORM_VALIDATIONS } from '../../../constants/ScheduleSearch';
import GNRadioGroup from '../../../components/common/gnRadioGroup/GNRadioGroup';
import { INPUT_PLACEHOLDER_OPTIONS, CHANNEL_NAME, CHANNEL_SEARCH_TYPES_LABEL } from '../../../constants/Station';
import isEmpty from 'lodash.isempty';
import ReactRouterPropTypes from "react-router-prop-types";
import { checkStationExists } from '../../../utils/StationUtils';
import { gnviewSelSelectedStation } from '../../../reducers/StationDetailsReducer';
import MySavedItemsButton from "../../../components/common/saveButton/MySavedItemsButton";
import {
    MY_SAVED_ITEMS,
    MY_SAVED_ITEMS_TYPES,
    RECENT_SAVED_SEARCHES_LIMIT,
    scheduleSavedItemBodyFrom,
    sortSavedItemsByDate,
    viewAllOrViewLessButton
} from "../../../constants/MySavedItems";
import RecentSavedItem from '../../../components/common/recentSavedItem/RecentSavedItem';
import LazyLoad from 'react-lazyload';

export class ScheduleSearchView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            callSign: '',
            date: getThisWeek(),
            dateType: SCHEDULE_DATE_OPTIONS[1],
            errorMessages: {},
            exactEndDate: moment().endOf('week'),
            exactStartDate: moment().startOf('week'),
            invalidStationIds: [],
            invalidCallSign: false,
            isRelative: true,
            isSearchStationsLoading: false,
            placeholderText: INPUT_PLACEHOLDER_OPTIONS.channelName,
            saveSearchModalShow: false,
            savedSearchIsLoading: true,
            scheduleSavedSearches: [],
            searchType: CHANNEL_NAME,
            selectedSearchTitle: null,
            selectedStations: [],
            stationId: null,
            stationSearchResultOptions: [],
            savedSearchIsExpanded: false
        };
    }

    componentWillUnmount() {
        if (this.cancelToken) {
            this.cancelToken.cancel();
        }
    }

    componentDidMount() {
        if (this.props.isBasic && this.props.lookupCount > this.props.lookupMax) {
            this.props.history.push(ROUTES.LOOKUP_MAX);
            return;
        }
        this.props.gnviewGetAllSavedSearches().then((allSavedSearchesResponse) => {
            const scheduleSavedSearches = sortSavedItemsByDate(allSavedSearchesResponse?.result?.filter(savedSearch => savedSearch.search_type === MY_SAVED_ITEMS_TYPES.SCHEDULES)) || [];
            this.setState({ scheduleSavedSearches });
        }).catch((error) => {
            this.props.gnviewSendLogMessage(`gnviewGetAllSavedSearches error: ${error.message}`, error);
        });
        const params = queryString.parse(this.props.location?.search);
        if (params.search_id) {
            this.props.gnviewGetSavedSearch(params.search_id).then((response) => {
                // autofill page with data from apiService response
                const startDate = response.result?.query_obj?.start_date;
                const endDate = response.result?.query_obj?.end_date;
                const dateType = response.result?.query_obj?.date_type;

                const exactStartDate = moment(startDate).startOf('day');
                const exactEndDate = moment(endDate).endOf('day');
                const selectedSearchTitle = response.result?.title;

                const stationIds = response.result?.query_obj?.station_ids;
                const stationRequests = stationIds.map(id => this.props.gnviewGetStation(id).catch(error => {
                    const errorStationId = error.config.url.split('/').slice(-1)[0];
                    const invalidStationIdsCopy = this.state.invalidStationIds;
                    invalidStationIdsCopy.push(errorStationId);
                    this.setState({ invalidStationIds: invalidStationIdsCopy });
                }));

                Promise.all(stationRequests).then((stationResponses) => {
                    const selectedStations = stationResponses.filter(station => !isEmpty(station)).map(station => station.result);
                    if (dateType === 'Exact Date') {
                        this.onDateTypeSelect(dateType, null, exactStartDate, exactEndDate);
                        this.setState({ selectedStations, selectedSearchTitle, isRelative: false, savedSearchIsLoading: false });
                    } else {
                        // relative date
                        this.onDateTypeSelect(dateType);
                        this.setState({ selectedStations, selectedSearchTitle, savedSearchIsLoading: false });
                    }
                }).catch((error) => {
                    console.error('gnviewGetStation error', error);
                    this.setState({ savedSearchIsLoading: false });
                });
            }).catch((error) => {
                // API throws an error if saved search does not exist or is not owned by user
                this.setState({ savedSearchIsLoading: false }, () => this.validateForm(null, false, true, error?.response?.status));
                this.props.history.push(ROUTES.SCHEDULES);
            });
        } else {
            const selectedStations = this.props?.selectedStation ? [this.props.selectedStation] : [];
            this.props.gnviewClearSelectedStation();
            this.setState({ savedSearchIsLoading: false, selectedStations });
        }
    }

    validateForm = (stationId, buttonClick = false, saveSearch = false, errorCode) => {
        const errorMessages = {
            ...(buttonClick && this.state.selectedStations.length <= 0 && { formMessage: SCHEDULE_SEARCH_FORM_VALIDATIONS.STATION_MINIMUM }),
            ...(saveSearch && this.state.selectedStations.length <= 0 && { formMessage: SCHEDULE_SEARCH_FORM_VALIDATIONS.STATION_MINIMUM }),
            ...(!buttonClick && this.state.selectedStations.length >= 20 && { formMessage: SCHEDULE_SEARCH_FORM_VALIDATIONS.STATION_MAXIMUM }),
            ...(checkStationExists(this.state.selectedStations, stationId) && { formMessage: SCHEDULE_SEARCH_FORM_VALIDATIONS.STATION_DUPLICATE }),
            ...(saveSearch && errorCode === 403 && { formMessage: SCHEDULE_SEARCH_FORM_VALIDATIONS.UNAUTHORIZED }),
            ...(errorCode === 'invalidStationId' && { formMessage: SCHEDULE_SEARCH_FORM_VALIDATIONS.INVALID_ID })
        };
        this.setState({ errorMessages });
        const errorsArray = Object.keys(errorMessages);
        return errorsArray.length !== 0;
    }

    handleChangeStation = (stations) => {
        const stationId = stations.length < this.state.selectedStations.length ? null : stations[stations.length - 1]?.id;
        const currentStationsCopy = (stations.length === 1 || stations.length < this.state.selectedStations.length) ? stations : stations.slice(0, -1);
        if (!this.validateForm(stationId)) {
            this.setState({ selectedStations: stations });
        } else {
            this.setState({ selectedStations: currentStationsCopy });
        }
    }

    handleSearchButtonClick = () => {
        if (!this.validateForm(null, true)) {
            this.setState({ formMessage: '' });
            const start = this.state.isRelative ? moment(this.state.date.startDate, 'MMMM D, YYYY').format('YYYY-MM-DD') : this.state.exactStartDate.format('YYYY-MM-DD');
            const end = this.state.isRelative ? moment(this.state.date.endDate, 'MMMM D, YYYY').format('YYYY-MM-DD') : this.state.exactEndDate.format('YYYY-MM-DD');
            const currentParams = queryString.parse(this.props.location?.search);
            const params = {
                station_ids: this.state.selectedStations.map(station => station.id),
                start_date: start, // YYYY-MM-DD
                end_date: end, // YYYY-MM-DD
                date_type: this.state.dateType,
                // if url contains search_id, add that to params for schedule result
                ...(currentParams?.search_id && { search_id: currentParams.search_id })
            };
            const paramsString = queryString.stringify(params);
            this.props.history.push(`${ROUTES.SCHEDULES}/result?${paramsString}`)
        }
    }

    onDateTypeSelect = (dateType, event, exactStartDate, exactEndDate) => {
        const dateObj = getDateSelectObj(dateType, event, exactStartDate, exactEndDate);
        if (dateObj) {
            this.setState(dateObj);
        }
    }

    createSavedSearchBody = () => scheduleSavedItemBodyFrom(
        this.props.email,
        this.state.selectedSearchTitle,
        this.state.selectedStations.map(station => station.id),
        this.state.dateType,
        this.state.exactStartDate.format('YYYY-MM-DD'),
        this.state.exactEndDate.format('YYYY-MM-DD'),
        this.state.date
    )

    onSearchTypeSelect = (type) => {
        const placeholderText = this.handlePlaceholderText(type);
        this.setState({ searchType: type, placeholderText, errorMessages: {} });
        this.setState({ stationId: '' });
    }

    handleStationIdChange = (e) => {
        const str = e.target.value;
        this.setState({ stationId: str });
    }

    /**
     * preventing enter key press from refreshing page in station id field, this should
     * probably be changed to request handleStationIdButtonClick instead - bg
     **/
    handleStationIdKeyPress = (e) => {
        if (e.charCode === 13) {
            e.preventDefault();
        }
    }

    handleStationIdButtonClick = () => {
        if (this.state.stationId) {
            if (this.validateForm(this.state.stationId)) {
                return;
            } else {
                this.validateForm();
                this.props.gnviewGetStation(this.state.stationId).then((response) => {
                    const station = response?.result;
                    const formattedStation = {
                        call_sign: station.call_sign,
                        country_codes: station.country_codes,
                        id: station.id,
                        name: station.name,
                        timezone: station.timezone,
                        type: station.type
                    };
                    const selectedStationsCopy = [...this.state.selectedStations, formattedStation];
                    this.setState({ selectedStations: selectedStationsCopy });
                }).catch((error) => {
                    this.validateForm(null, false, false, 'invalidStationId');
                    this.props.gnviewSendLogMessage(`gnviewGetStation error: ${error.message}`, error, {});
                });
            }
        }
    }

    handlePlaceholderText = (searchType) => {
        if (searchType === SCHEDULE_SEARCH_TYPES.channelName) {
            return INPUT_PLACEHOLDER_OPTIONS.channelName
        } else if (searchType === SCHEDULE_SEARCH_TYPES.channelCallSign) {
            return INPUT_PLACEHOLDER_OPTIONS.channelCallSign
        } else {
            return ' ';
        }
    }

    render() {
        const searchId = queryString.parse(this.props.location?.search)['search_id'];
        return (
            <ErrorBoundary>
                <div className="gnview-schedule-content">
                    {this.props.isBasic && <div className='basic-tier-message-container'>
                        <div className='limit-message-container'>
                            <span className='limit-message'>{BASIC_TIER_LOOKUP_LIMIT_MESSAGE}</span><span>{BASIC_TIER_RESET_MESSAGE}</span>
                        </div>
                        <div className='upgrade-message-container'>
                            <span className='upgrade-message'>{BASIC_TIER_UPGRADE_MESSAGE}</span><a className='upgrade-email' href ={`mailto:${UPGRADE_EMAIL}`}>{UPGRADE_EMAIL}</a>
                        </div>
                    </div>}
                    <div className="filter-bar-container schedule-container">
                        <div className="gnview-header-title">{SCHEDULE_REPORT}</div>
                        {!this.state.savedSearchIsLoading && <div className="schedule-bar">
                            <GNDateSelection
                                radioLabel={SCHEDULE_DATE_SELECTION}
                                radioList={SCHEDULE_DATE_OPTIONS}
                                onRadioSelect={this.onDateTypeSelect}
                                defaultRadio={this.state.dateType}
                                isRelative={this.state.isRelative}
                                dateTypeSelected={this.state.dateType}
                                relativeStartDate={this.state.date.startDate}
                                relativeEndDate={this.state.date.endDate}
                                exactDailyOrWeekly={true}
                                exactStartDate={this.state.exactStartDate}
                                exactEndDate={this.state.exactEndDate}
                                onExactDatesChange={({ startDate, endDate }) => this.setState({ exactStartDate: startDate, exactEndDate: endDate })} />
                            <GNRadioGroup label={CHANNEL_SEARCH_TYPES_LABEL} list={Object.values(SCHEDULE_SEARCH_TYPES)} onSelect={this.onSearchTypeSelect} default={SCHEDULE_SEARCH_TYPES.channelName} />
                            {this.state.searchType === SCHEDULE_SEARCH_TYPES.channelId && <div className="station-id-selection">
                                <Form>
                                    <Form.Row>
                                        <Form.Control
                                            value={this.state.stationId}
                                            placeholder={INPUT_PLACEHOLDER_OPTIONS.channelId}
                                            onChange={this.handleStationIdChange}
                                            onKeyPress={this.handleStationIdKeyPress}>
                                        </Form.Control>
                                        <Button onClick={this.handleStationIdButtonClick}>Add Channel</Button>
                                    </Form.Row>
                                </Form>
                            </div>}
                            <GNStationTypeahead
                                selectedStations={this.state.selectedStations}
                                multipleStations={true}
                                handleChangeStation={this.handleChangeStation}
                                isCallSignSearch={this.state.searchType === SCHEDULE_SEARCH_TYPES.channelCallSign}
                                showTypeAhead={this.state.searchType !== SCHEDULE_SEARCH_TYPES.channelId}
                                placeholder={this.state.placeholderText} />
                            {this.state.searchType === SCHEDULE_SEARCH_TYPES.channelId && !this.state.selectedStations.length > 0 && <div className='no-stations-selected-msg'>No stations selected</div>}
                            <div className="filter-bar-col">
                                {this.state.errorMessages.formMessage && <div className="form-message">{this.state.errorMessages.formMessage}</div>}
                                {this.state.invalidStationIds.length > 0 && <div className="form-message">
                                    Unable to load invalid stations: {this.state.invalidStationIds.map((id, idx) => <div key={idx} className='error-id-items'>- {id}</div>)}
                                </div>}
                                <Button variant="primary" className="search-button" onClick={this.handleSearchButtonClick}>Search</Button>
                                <MySavedItemsButton
                                    body={this.createSavedSearchBody()}
                                    searchId={searchId}
                                    formattedDate={getFormattedDate(this.state.isRelative, this.state.dateType, this.state.date.startDate, this.state.date.endDate, this.state.exactStartDate, this.state.exactEndDate)}
                                    selectedStations={this.state.selectedStations}
                                    shouldReloadPage={true}
                                    validationCallback={() => this.validateForm(null, true, !isEmpty(searchId))}
                                />
                            </div>
                        </div>}
                    </div>
                    {this.state.scheduleSavedSearches.length > 0 && (
                        <div className='my-saved-items-section'>
                            <div className='my-saved-item-header'>{MY_SAVED_ITEMS}</div>
                            <div className='my-saved-items-list'>
                                {!this.state.savedSearchIsExpanded
                                    ? this.state.scheduleSavedSearches
                                        .slice(0, RECENT_SAVED_SEARCHES_LIMIT)
                                        .map((savedSearch) => (
                                            <LazyLoad overflow={true} scroll={true} key={savedSearch}>
                                                <RecentSavedItem savedSearch={savedSearch} />
                                            </LazyLoad>
                                        ))
                                    : this.state.scheduleSavedSearches.map((savedSearch) => (
                                        <LazyLoad overflow={true} scroll={true} key={savedSearch}>
                                            <RecentSavedItem savedSearch={savedSearch} />
                                        </LazyLoad>
                                    ))}
                            </div>
                            {this.state.scheduleSavedSearches.length > RECENT_SAVED_SEARCHES_LIMIT && (
                                <div className='scheduleSavedItemBtnContainer'>
                                    <span onClick={() => this.setState({ savedSearchIsExpanded: !this.state.savedSearchIsExpanded })} className='scheduleSavedItemBtn'>
                                        {viewAllOrViewLessButton(this.state.savedSearchIsExpanded)}
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        isBasic: gvauthIsBasic(state),
        email: gvauthSelEmail(state),
        lookupCount: gvauthSelLookupCount(state),
        lookupMax: gvauthSelLookupMax(state),
        selectedStation: gnviewSelSelectedStation(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewClearSelectedStation,
        gnviewCreateSavedSearch,
        gnviewGetAllSavedSearches,
        gnviewGetSavedSearch,
        gnviewGetStation,
        gnviewSearchStations,
        gnviewSendLogMessage,
        gnviewUpdateSavedSearch
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleSearchView);

ScheduleSearchView.propTypes = {
    email: PropTypes.string,
    gnviewCreateSavedSearch: PropTypes.func,
    gnviewGetAllSavedSearches: PropTypes.func,
    gnviewGetSavedSearch: PropTypes.func,
    gnviewGetStation: PropTypes.func,
    gnviewSendLogMessage: PropTypes.func,
    gnviewUpdateSavedSearch: PropTypes.func,
    history: ReactRouterPropTypes.history.isRequired,
    isBasic: PropTypes.bool,
    location: ReactRouterPropTypes.location.isRequired,
    lookupCount: PropTypes.number,
    lookupMax: PropTypes.number,
    selectedStation: PropTypes.object,
    gnviewClearSelectedStation: PropTypes.func
}