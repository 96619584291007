import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import GNProgramSelection from '../../../components/common/gnProgramSelection/GNProgramSelection';
import GNProgramCard from '../../../components/common/gnProgramCard/GNProgramCard';
import "./ProgramAvailabilitySearchStep1.scss";
import PropTypes from 'prop-types';

export class ProgramAvailabilitySearchStep1 extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ErrorBoundary>
                <div className="gnview-program-availability-step-1">
                    <GNProgramSelection
                        handleChangeProgram={this.props.handleChangeProgram}
                        tmsId={this.props.tmsId}
                        handleTmsIdChange={this.props.handleTmsIdChange}
                        handleTMSIdButtonClick={this.props.handleTMSIdButtonClick}
                        validateForm={this.props.validateForm}
                        tmsIdButtonText="Add Program"
                        disabled={this.props.disableProgramSearch}
                        selectedProgramIds={this.props.selectedPrograms?.map(prog => prog.tmsid)}
                        isMultiple={true} />
                    {this.props.errorMessages?.programDuplicate && <div className="gnview-error-msg">{this.props.errorMessages.programDuplicate}</div>}
                    {this.props.errorMessages?.program && <div className="gnview-error-msg">{this.props.errorMessages.program}</div>}
                    {this.props.errorMessages?.episodeTMSIDSearch && <div className="gnview-error-msg">{this.props.errorMessages.episodeTMSIDSearch}</div>}
                    {this.props.selectedPrograms?.length > 0 && <div className="selected-programs-container">
                        {this.props.selectedPrograms.map((program, index) => (
                            <div className="program-tile" key={index}>
                                <GNProgramCard
                                    additionalSeriesInfo={true}
                                    horizontalImage={true}
                                    isDataFromSavedSearch={this.props.isDataFromSavedSearch}
                                    program={program}
                                    handleProgramRemove={this.props.handleProgramRemove}
                                    seriesCheckboxHandler={this.props.seriesCheckboxHandler}
                                    seasonCheckboxHandler={this.props.seasonCheckboxHandler}
                                    episodeCheckboxHandler={this.props.episodeCheckboxHandler} />
                            </div>
                        ))}
                    </div>}
                </div>
            </ErrorBoundary>
        )
    }
}

ProgramAvailabilitySearchStep1.propTypes = {
    disableProgramSearch: PropTypes.func,
    episodeCheckboxHandler: PropTypes.func,
    errorMessages: PropTypes.object,
    handleChangeProgram: PropTypes.func,
    handleProgramRemove: PropTypes.func,
    handleTmsIdChange: PropTypes.func,
    handleTMSIdButtonClick: PropTypes.func,
    isDataFromSavedSearch: PropTypes.bool,
    seasonCheckboxHandler: PropTypes.func,
    selectedPrograms: PropTypes.array,
    seriesCheckboxHandler: PropTypes.func,
    tmsId: PropTypes.string,
    validateForm: PropTypes.func
};

const mapStateToProps = () => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramAvailabilitySearchStep1);
