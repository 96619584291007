import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import { PROGRAM_AIRINGS, PROGRAM_AIRINGS_GNVIEW_LAST_UPDATED } from '../../../constants/ProgramAirings';
import moment from 'moment-timezone';
import {
    gnviewGetExportProgramAirings,
    gnviewGetMarket,
    gnviewGetProgramDetails,
    gnviewGetSavedSearch,
    gnviewGetStation,
    gnviewProgramAiringsStoreSearch,
    gnviewGetProgramAiringsLastUpdated
} from '../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import {
    getProgramTitle,
    getProgramTitleAltText,
    getProgramImageHorizontal,
    isProgramAnEpisode,
    formatProgramObject,
    getLocalGNVIEWIngestedLastupdated
} from '../../../utils/ProgramUtils';
import './ProgramAiringsSearch.scss';
import { ROUTES } from '../../../config/Routes';
import { Link } from 'react-router-dom';
import { convertCountryCodes, downloadExportFile, formatExportFileName } from '../../../utils/GeneralUtils';
import "./ProgramAiringsResult.scss";
import LoadingSpinner from '../../../components/common/loadingSpinner/LoadingSpinner';
import { getFormattedDate} from '../../../utils/DateUtils';
import ProgramAiringsCard from './ProgramAiringsCard';
import ExportButton from '../../../components/common/exportButton/ExportButton';
import ReactRouterPropTypes from "react-router-prop-types";
import { gnviewSelProgramAiringsQueryObj } from '../../../reducers/ProgramAiringsReducer';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {gnviewSelUserSettings, gvauthSelEmail} from '../../../reducers/GNVAuthReducer';
import GNUserSettingsIcon from '../../../components/common/gnUserSettingsIcon/GNUserSettingsIcon';
import MySavedItemsButton from "../../../components/common/saveButton/MySavedItemsButton";
import queryString from "query-string";
import {EXACT_DATE} from "../../../constants/Schedule";
import {programAiringsSavedItemBodyFrom} from "../../../constants/MySavedItems";
import {GLOBAL_TIMEZONE_DEFAULT} from "../../../constants/AccountSettings";

export class ProgramAiringsResultView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exportIsLoading: false,
            isLoading: true,
            lastUpdated: '',
            markets: [],
            params: {},
            programs: [],
            savedItem: null,
            stations: []
        };
    }

    componentDidMount() {
        this.getLastUpdated();
        const params = this.props.queryObj;
        // First convert to UTC to get the results using timezone
        // If user settings defaultTimezone doesn't exist, uses the Internationalization API in supported browsers to determine the user's timezone
        const currentTimezone = this.props.defaultTimezone || moment.tz.guess();
        if (currentTimezone && params.start_date && params.end_date && (params.parent_tms_ids || params.tms_ids)) {
            const countryCodes = Array.isArray(params.country_codes) ? params.country_codes : [params.country_codes];
            const countryNameAndCodes = convertCountryCodes(countryCodes);
            const programRequests = [];
            const marketRequests = [];
            const stationRequests = [];
            const startDateTime = moment.tz(params.start_date, currentTimezone).startOf('day');
            const endDateTime = moment.tz(params.end_date, currentTimezone).endOf('day');
            // Convert date to UTC
            const utcStartDate = startDateTime.toISOString();
            const utcEndDate = endDateTime.toISOString();
            if (params.parent_tms_ids?.length > 0) {
                const programIdList = Array.isArray(params.parent_tms_ids) ? params.parent_tms_ids : [params.parent_tms_ids];
                programIdList.forEach((tmsid) => {
                    programRequests.push(this.props.gnviewGetProgramDetails(tmsid));
                });
            }
            if (params.tms_ids?.length > 0) {
                const episodeIdList = Array.isArray(params.tms_ids) ? params.tms_ids : [params.tms_ids];
                episodeIdList.forEach((tmsid) => {
                    programRequests.push(this.props.gnviewGetProgramDetails(tmsid));
                });
            }
            // marketRequests will be [] when Optional type 'Station' was selected
            if (params.market_ids?.length > 0) {
                const marketIdList = Array.isArray(params.market_ids) ? params.market_ids : [params.market_ids];
                marketIdList.forEach((id) => {
                    marketRequests.push(this.props.gnviewGetMarket(id));
                });
            }
            // stationRequests will be [] when Optional type 'Country' was selected
            if (params.station_ids?.length > 0) {
                const stationIdList = Array.isArray(params.station_ids) ? params.station_ids : [params.station_ids];
                stationIdList.forEach((id) => {
                    stationRequests.push(this.props.gnviewGetStation(id));
                });
            }
            Promise.all(programRequests).then((programData) => {
                const programs = programData.map((prog) => {
                    prog.result.mainTitle = getProgramTitle(prog.result);
                    prog.result.progTitleAlt = getProgramTitleAltText(prog.result);
                    prog.result.progImageURL = getProgramImageHorizontal(prog.result);
                    const programAiringsBody = {
                        start_date_time: utcStartDate,
                        end_date_time: utcEndDate,
                        // Non-episode program
                        ...(!isProgramAnEpisode(prog.result) && {parent_tms_ids: [prog.result.tmsid]}),
                        // Episodic program
                        ...(isProgramAnEpisode(prog.result) && {tms_ids: [prog.result.tmsid]}),
                        // Optional type 'Country' selected
                        ...(params.country_codes && { country_codes: params.country_codes }),
                        ...(params.market_ids && { market_ids: params.market_ids }),
                        ...(params.station_types && { station_types: Array.isArray(params.station_types) ? params.station_types : [params.station_types] }),
                        // Optional type 'Station' selected
                        ...(params.station_ids && { station_ids: params.station_ids })
                    };
                    return {
                        program: prog.result,
                        programAiringsPageInfo: programAiringsBody
                    };
                });
                // marketRequests will be [] when Optional type 'Station' was selected
                Promise.all(marketRequests).then((marketData) => {
                    const markets = marketData.map((market) => market.result);
                    // stationRequests will be [] when Optional type 'Country' was selected
                    Promise.all(stationRequests).then((stationData) => {
                        const stations = stationData.map((station) => station.result);
                        const convertedParams = {
                            startDateTime, // Moment object : converted to correct timezone
                            endDateTime, // Moment object : converted to correct timezone
                            utcStartDate, // UTC start date time
                            utcEndDate, // UTC start date time
                            parent_tms_ids: params?.parent_tms_ids || [],
                            tms_ids: params?.tms_ids || [],
                            // Optional type 'Country' was selected
                            ...(params.country_codes && { countryNameAndCodes }),
                            ...(params.market_ids && { market_ids: params.market_ids }),
                            ...(params.station_types && { station_types: Array.isArray(params.station_types) ? params.station_types : [params.station_types] }),
                            // Optional type 'Station' was selected
                            ...(params.station_ids && { station_ids: params.station_ids })
                        };
                        this.setState({ programs, markets, stations, params: convertedParams, isLoading: false });
                    }).catch((error) => {
                        this.props.gnviewSendLogMessage(`gnviewGetStation error: ${error.message}`, error, params);
                        this.setState({ isLoading: false });
                    });
                }).catch((error) => {
                    this.props.gnviewSendLogMessage(`gnviewGetMarket error: ${error.message}`, error, params);
                    this.setState({ isLoading: false });
                });
            }).catch((error) => {
                this.props.gnviewSendLogMessage(`gnviewGetProgramDetails error: ${error.message}`, error, params);
                this.setState({ isLoading: false });
            });
        }
        const searchId = queryString.parse(this.props.location?.search)['search_id'];
        if (searchId) {
            this.props.gnviewGetSavedSearch(searchId).then((response) => {
                this.setState({savedItem: response.result});
            }).catch(() => {
                // API throws an error if saved search does not exist or is not owned by user
                this.setState({savedSearchIsLoading: false});
                this.props.history.push(ROUTES.PROGRAM_AIRINGS);
            });
        }
    }

    getLastUpdated() {
        this.props
            .gnviewGetProgramAiringsLastUpdated()
            .then((response) => {
                if (response?.result?.length > 0) {
                    const lastUpdated = getLocalGNVIEWIngestedLastupdated(response.result);
                    this.setState({ lastUpdated });
                }
            })
            .catch((error) => {
                this.props.gnviewSendLogMessage(`gnviewGetProgramAiringsLastUpdated error: ${error.message}`, error);
                this.setState({ isLoading: false });
            });
    }

    getProgramCountText = () => {
        if (!this.state.programs?.length) {
            return '# Programs';
        }
        return this.state.programs.length <= 1 ? this.state.programs.length + ' Program' : this.state.programs.length + ' Programs';
    }

    exportProgramAirings = () => {
        this.setState({exportIsLoading: true}, () => {
            const params = this.state.params;
            if (params.utcStartDate && params.utcEndDate && params.parent_tms_ids) {
                const stationIds = params.station_ids || null;
                const marketIds = params.market_ids || null;
                const countryCodes = params?.countryNameAndCodes?.map((country) => country.code) || null;
                const stationTypes = params.station_types || null;
                this.props.gnviewGetExportProgramAirings(params.utcStartDate, params.utcEndDate, params.parent_tms_ids, params.tms_ids, stationIds, marketIds, countryCodes, stationTypes, this.props.defaultTimezone)
                    .then((response) => {
                        downloadExportFile(response.result, 'text/csv; charset=utf-8', formatExportFileName(response));
                        this.setState({exportIsLoading: false});
                    }).catch((error) => {
                        this.props.gnviewSendLogMessage(`gnviewGetExportSchedules error: ${error.message}`, error, params);
                        this.setState({exportIsLoading: false});
                    })
            }
        });
    }

    render() {
        const searchId = queryString.parse(this.props.location?.search)['search_id'];
        const savedItemBody = programAiringsSavedItemBodyFrom(
            this.props.email,
            this.state.savedItem?.title,
            this.props.queryObj.country_codes,
            this.props.queryObj.market_ids,
            this.props.queryObj.program_ids,
            this.props.queryObj.station_ids,
            this.props.queryObj.station_types,
            this.props.queryObj.date_type,
            this.props.queryObj.search_type,
            this.props.queryObj.start_date,
            this.props.queryObj.end_date
        );
        const formattedDate = getFormattedDate(
            this.state.dateType !== EXACT_DATE,
            this.state.dateType,
            this.state.params.startDateTime?.format('MMMM D, YYYY'),
            this.state.params.endDateTime?.format('MMMM D, YYYY'),
            this.state.params.startDateTime,
            this.state.params.endDateTime
        );
        return (
            <ErrorBoundary>
                <div className="gnview-program-airings-result-content">
                    <div className="filter-bar-container program-airings-top-bar-container">
                        <div className="gnview-breadcrumbs-container">
                            <div className="gnview-breadcrumbs">
                                <Link to={ROUTES.PROGRAM_AIRINGS}>{PROGRAM_AIRINGS}</Link>
                                <i className="fas fa-angle-right gnview-breadcrumbs-separator"/>
                                {!this.state.isLoading && <span>{this.getProgramCountText()}</span>}
                            </div>
                            {!this.state.isLoading && <div className="right-section">
                                <ExportButton type='.csv' exportFunction={this.exportProgramAirings}
                                    isLoading={this.state.exportIsLoading}/>
                                <MySavedItemsButton
                                    body={savedItemBody}
                                    searchId={searchId}
                                    formattedDate={formattedDate}
                                    selectedStations={this.state.stations}
                                    selectedCountries={this.state.params.countryNameAndCodes}
                                    selectedMarkets={this.state.markets}
                                    selectedPrograms={this.state.programs?.map(p => formatProgramObject({result: p.program}))}
                                    validationCallback={() => false}
                                />
                            </div>}
                        </div>
                        {!this.state.isLoading && <div className="program-airings-top-bar">
                            <div className="left-section">
                                {this.state.programs?.length > 0 && <div className="section">
                                    <span className="label">Programs: </span>
                                    {this.state.programs.map((prog) => getProgramTitle(prog.program, false, true)).join(', ')}
                                </div>}
                                {this.state.params.countryNameAndCodes?.length > 0 && <div className="section">
                                    <span className="label">Countries: </span>
                                    {this.state.params.countryNameAndCodes.map((country) => country.name).join(', ')}
                                </div>}
                                {this.state.markets?.length > 0 && <div className="section">
                                    <span className="label">US Market Areas: </span>
                                    {this.state.markets.map((market, idx) => (
                                        <span className="market" key={idx}>
                                            {market.name}&nbsp;
                                            <span className="market-rank">({market.rank})</span>
                                            {idx !== this.state.markets.length - 1 && ', '}
                                        </span>
                                    ))}
                                </div>}
                                {this.state.params.station_types?.length > 0 && <div className="section">
                                    <span className="label">Station Types: </span>
                                    {this.state.params.station_types.join(', ')}
                                </div>}
                                {this.state.stations?.length > 0 && <div className="section">
                                    <span className="label">Channels: </span>
                                    {this.state.stations.map((station, idx) => (
                                        <span className="station" key={idx}>
                                            {station.name}&nbsp;
                                            <span className="station-call-sign">({station.call_sign})</span>
                                            {idx !== this.state.stations.length - 1 && ', '}
                                        </span>
                                    ))}
                                </div>}
                                {this.state.params?.startDateTime && this.state.params?.endDateTime && <div className="section">
                                    <span className="label">Timeframe: </span>
                                    {getFormattedDate(false, null, null, null, this.state.params.startDateTime, this.state.params.endDateTime)}
                                </div>}
                            </div>
                            <div className="right-section">
                                <div className="section">
                                    <OverlayTrigger delay={{ hide: 500 }} overlay={<Tooltip>This is the timezone the airings results are currently in.</Tooltip>} placement="bottom">
                                        <span className="displayed-timezone">
                                            <span className="label">Displayed Timezone: </span>
                                            {this.props.defaultTimezone || GLOBAL_TIMEZONE_DEFAULT.name} {this.props.defaultTimezone && <span>({moment().tz(this.props.defaultTimezone).zoneAbbr()})</span>}
                                        </span>
                                    </OverlayTrigger>
                                    <GNUserSettingsIcon tooltipText="Update the displayed timezone in Settings."/>
                                </div>
                                {this.state.lastUpdated?.length > 0 && (
                                    <div className='section'>
                                        <span className='label'>{PROGRAM_AIRINGS_GNVIEW_LAST_UPDATED}</span>
                                        <span className='last-updated'>{this.state.lastUpdated}</span>
                                    </div>
                                )}
                            </div>
                        </div>}
                        {this.state.isLoading && <LoadingSpinner/>}
                    </div>
                </div>
                <div className="program-airings-result-container">
                    {!this.state.isLoading && this.state.programs?.length > 0 && this.state.programs.map((prog, idx) => (
                        <ProgramAiringsCard program={prog.program} programAiringsPageInfo={prog.programAiringsPageInfo} key={idx}/>
                    ))}
                </div>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        defaultTimezone: gnviewSelUserSettings(state)?.timezone,
        queryObj: gnviewSelProgramAiringsQueryObj(state),
        email: gvauthSelEmail(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewGetExportProgramAirings,
        gnviewGetMarket,
        gnviewGetProgramDetails,
        gnviewGetSavedSearch,
        gnviewGetStation,
        gnviewGetProgramAiringsLastUpdated,
        gnviewProgramAiringsStoreSearch,
        gnviewSendLogMessage
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramAiringsResultView);

ProgramAiringsResultView.propTypes = {
    gnviewGetExportProgramAirings: PropTypes.func,
    gnviewGetMarket: PropTypes.func.isRequired,
    gnviewGetProgramDetails: PropTypes.func.isRequired,
    gnviewGetStation: PropTypes.func.isRequired,
    gnviewSendLogMessage: PropTypes.func,
    gnviewGetSavedSearch: PropTypes.func,
    gnviewGetProgramAiringsLastUpdated: PropTypes.func,
    location: ReactRouterPropTypes.location,
    defaultTimezone: PropTypes.string,
    queryObj: PropTypes.object,
    email: PropTypes.string,
    history: ReactRouterPropTypes.history
}