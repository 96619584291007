import advancedSearchImage from '../images/access_denied/advancedsearch.gif';
import sportsImage from '../images/access_denied/sports.gif';
import stationsImage from '../images/access_denied/stations.gif';
import marketsImage from '../images/access_denied/markets.gif';
import programAiringsImage from '../images/access_denied/programairings.gif';
import mySavedItemsImage from '../images/access_denied/mysaveditems.gif';
import programAvailabilityImage from '../images/access_denied/programavailability.gif'

export const ACCESS_DENIED_HEADER = 'Ruff luck! Upgrade to unlock this page.'
export const ACCESS_DENIED_SECTION_CONTENT = {
    ADVANCED_SEARCH: {
        title: 'Advanced Search',
        features: ['Search by program attributes, Root ID or Series ID', 'Locate hard-to-find programs by program metadata fields such as title, program type, release year, along with a Root ID or Series ID', 'Aggregate programs based on program attributes such as genre and original network'],
        image: advancedSearchImage,
        icon: 'fas fa-magnifying-glass'
    },
    SPORTS: {
        title: 'Sports',
        features: ['Validate games on today, or in the past or future', 'Search for program records of sport events based on sport, league, team, and/or game date'],
        image: sportsImage,
        icon: 'fas fa-football'
    },
    STATIONS: {
        title: 'Stations',
        features: ['Verify channel creation', 'Locate channel attributes to ensure correct channel for schedule lookup'],
        image: stationsImage,
        icon: 'fas fa-video'
    },
    US_MARKETS: {
        title: 'US Markets',
        features: ['Locate local channel affiliates by designated market areas'],
        image: marketsImage,
        icon: 'fas fa-location-dot'
    },
    PROGRAM_AIRINGS: {
        title: 'Program Airings',
        features: ['Find channels a title has aired on linear TV across a set of channels or a region', 'Reconcile airing counts against guarantees'],
        image: programAiringsImage,
        icon: 'fas fa-clipboard-list'
    },
    MY_SAVED_ITEMS: {
        title: 'My Saved Items',
        features: ['Organize routine searches such as program lookups and schedules into customized lists to reduce time spent searching'],
        image: mySavedItemsImage,
        icon: 'fas fa-bookmark'
    },
    PROGRAM_AVAILABILITY: {
        title: 'Program Availability',
        features: ['Locate where programs are streaming across SVOD, AVOD, and TVOD streaming services', 'Access deep links to programs'],
        image: programAvailabilityImage,
        icon: 'fas fa-clipboard-list',
        header: 'Ruff luck! License Streaming Video Catalogs to unlock this page.'
    }
}