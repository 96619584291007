import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gnviewSelProgramDetails } from '../../../../reducers/ProgramDetailsReducer';
import GNCard from '../../../../components/common/gnCard/GNCard';
import GNServerTable from '../../../../components/common/gnServerTable/GNServerTable';
import { gnviewGetExportEpisodes, gnviewGetProgramDetailsMultiple } from '../../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../../services/GeneralService';
import "./SeasonsAndEpisodesTab.scss";
import ExportButton from '../../../../components/common/exportButton/ExportButton';
import { downloadExportFile, formatExportFileName } from '../../../../utils/GeneralUtils.js';
import { getProgramSeasonAndEpisodeHeaders } from '../../../../utils/ProgramUtils';
import GNSummary from '../../../../components/common/gnSummary/GNSummary';
import GNDropdown from '../../../../components/common/gnDropdown/GNDropdown';
import { CLIENT_TABLE_PAGE_SIZE } from '../../../../constants/App';
import { filterEpisodesBySeason } from '../../../../utils/SeasonUtils';
import {PROGRAM_SEASON_INFO_FIELDS} from "../../../../components/common/programTabs/programTabs";

export const SeasonsAndEpisodesTab = () => {
    const dispatch = useDispatch();
    const programDetails = useSelector(gnviewSelProgramDetails);
    const tmsId = programDetails?.tmsid;
    const [seasonSelected, setSeasonSelected] = useState(null);
    const [exportIsLoading, setExportIsLoading] = useState(false);

    const onSeasonSelect = useCallback((season) => {
        if (season) {
            setSeasonSelected(season);
        }
    }, []);

    useEffect(() => {
        if (programDetails.allEpisodes?.length > 0 && programDetails?.allSeasons[0]?.value) {
            onSeasonSelect(programDetails?.allSeasons[0]?.value);
        }
    }, [tmsId, onSeasonSelect, programDetails.allEpisodes, programDetails?.allSeasons]);

    const fetchData = useCallback((pageIndex) => {
        if (seasonSelected) {
            const seasonEpisodes = filterEpisodesBySeason(seasonSelected, programDetails.allEpisodes);
            // Sort episodes by most recent original air date (it gets returned by oldest first)
            const sortedEpisodes = [...seasonEpisodes];
            const startIndex = pageIndex * CLIENT_TABLE_PAGE_SIZE;
            const endIndex = startIndex + CLIENT_TABLE_PAGE_SIZE
            const pageEpisodes = sortedEpisodes.slice(startIndex, endIndex).map((ep) => ep.tms_id);
            const totalCount = sortedEpisodes.length;
            return gnviewGetProgramDetailsMultiple(pageEpisodes, totalCount)
        }
        return [];
    }, [programDetails.allEpisodes, seasonSelected]);

    const getSeason = (season) => {
        return programDetails?.seasons?.filter((s) => s.season_number === parseInt(season))[0];
    }

    const exportEpisodes = () => {
        setExportIsLoading(true);
        dispatch(gnviewGetExportEpisodes(tmsId)).then((response) => {
            downloadExportFile(response.result, 'text/csv; charset=utf-8', formatExportFileName(response));
            setExportIsLoading(false);
        }).catch((error) => {
            dispatch(gnviewSendLogMessage(`gnviewGetExportEpisodes error: ${error.message}`, error, {tmsId}));
            setExportIsLoading(false);
        });
    }

    return (
        <div className="gnview-seasons-and-episodes-tab-content">
            <GNCard title="Season and Episode Summary" headerOutsideOfCard={true}>
                {programDetails.allSeasons?.length > 0 && programDetails.allEpisodes?.length > 0 && seasonSelected && <GNDropdown
                    value={seasonSelected}
                    options={programDetails.allSeasons}
                    handleChange={(item) => onSeasonSelect(item)} />}
                {programDetails.allSeasons?.length > 0 && programDetails.allEpisodes?.length > 0 && <ExportButton exportFunction={exportEpisodes} type='.csv' isLoading={exportIsLoading} />}
                {seasonSelected && <GNSummary fields={PROGRAM_SEASON_INFO_FIELDS} data={getSeason(seasonSelected)} columns={3} key={seasonSelected} />}
                {seasonSelected && <GNServerTable
                    className="season-and-episode-table"
                    columns={getProgramSeasonAndEpisodeHeaders(true)}
                    fetchData={fetchData} />}
                {programDetails.allSeasons?.length === 0 && programDetails.allEpisodes?.length === 0 && <div className="no-season-episode-message">No season or episode data available</div>}
            </GNCard>
        </div>
    )
}

export default SeasonsAndEpisodesTab;