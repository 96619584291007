import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { gnviewSelProgramDetails } from '../../../../reducers/ProgramDetailsReducer';
import GNCard from '../../../../components/common/gnCard/GNCard';
import GNClientTable from '../../../../components/common/gnClientTable/GNClientTable';
import {getProgramReleaseHeaders} from '../../../../utils/ProgramUtils';

export class ReleasesTab extends Component {
    render() {
        const releaseHeaders = getProgramReleaseHeaders(this.props.programDetails);
        return (
            <div className="gnview-releases-tab-content">
                <GNCard>
                    {this.props.programDetails?.releases?.length > 0 && <GNClientTable className="releases-table" columns={releaseHeaders} data={this.props.programDetails.releases} isLoading={false}/>}
                    {(!this.props.programDetails?.releases || this.props.programDetails.releases.length === 0) && <div className="no-releases-message">No releases data available</div>}
                </GNCard>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        programDetails: gnviewSelProgramDetails(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ReleasesTab);

ReleasesTab.propTypes = {
    programDetails: PropTypes.object.isRequired
}