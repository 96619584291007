import {
    GVAUTH_AUTH_UPDATE,
    GVAUTH_AUTH_CLEAR,
    GVAUTH_USER_SETTINGS_UPDATE,
    GVAUTH_SET_LAST_VISITED_URL,
    GVAUTH_LOOKUP_COUNT_UPDATE
} from '../actions/ActionTypes';
import { MOBIUS_ACCESS_TYPES } from '../constants/App';
import { ACCOUNT_TYPES } from '../constants/LookupMax';

export const GNVAUTH_STORE_NAME = 'gvauth';

export const MEDIACLOUD_DOMAIN = 'tmsimg.com';

/**
 * Initial State
 */
export const initialState = {
    // User info fields
    firstName: null,
    email: null,
    entitlements: [],
    group: null,
    internalUser: null,
    lastName: null,
    // Basic Tier
    lookup: null,
    token: null,
    // Report an Issue
    lastURLVisited: null,
    // User settings
    userSettings: {},
    userId: null
};

/**
 * Reducer
 */
export const GNVAuthReducer = (state = initialState, action) => {
    switch (action.type) {
    case GVAUTH_AUTH_UPDATE:
        return {
            ...state,
            email: action.payload.email,
            entitlements: action.payload.entitlements,
            firstName: action.payload.firstName,
            group: action.payload.group,
            internalUser: action.payload.internalUser,
            lastName: action.payload.lastName,
            lookup: action.payload.lookup,
            token: action.payload.token,
            userId: action.payload.user_id
        };

    case GVAUTH_AUTH_CLEAR:
        return {
            ...initialState
        };

    case GVAUTH_LOOKUP_COUNT_UPDATE:
        return {
            ...state,
            lookup: {
                settings: state.lookup.settings,
                userLookupCount: action.payload.userLookupCount,
                userLastUpdated: action.payload.userLastUpdated
            }
        };

    case GVAUTH_USER_SETTINGS_UPDATE:
        return {
            ...state,
            userSettings: action.payload.userSettings
        }

    case GVAUTH_SET_LAST_VISITED_URL:
        return {
            ...state,
            lastURLVisited: action.payload.url
        };

    default:
        return state;
    }
};


/**
 * Selectors
 */

// User Fields
export const gvauthSelEmail = state => state[GNVAUTH_STORE_NAME].email;
export const gvauthSelFirstName = state => state[GNVAUTH_STORE_NAME].firstName;
export const gvauthSelGroup = state => state[GNVAUTH_STORE_NAME].group;
export const gvauthSelInternalUser = state => state[GNVAUTH_STORE_NAME].internalUser || false;
export const gvauthSelAccountType = state => state[GNVAUTH_STORE_NAME].group?.accountType;
export const gvauthIsBasic = state => state[GNVAUTH_STORE_NAME].group?.accountType === ACCOUNT_TYPES.BASIC || false;
export const gvauthSelModulesPermitted = state => state[GNVAUTH_STORE_NAME].group?.modulesPermitted;
export const gvauthSelLastName = state => state[GNVAUTH_STORE_NAME].lastName;
export const gvauthSelLookupCount = state => state[GNVAUTH_STORE_NAME].lookup?.userLookupCount || null;
export const gvauthSelLookupMax = state => state[GNVAUTH_STORE_NAME].lookup?.settings?.maxCount || null;
// GNView Entitlements
export const gvauthSelEntitlements = state => state[GNVAUTH_STORE_NAME].entitlements;
export const gvauthSelEntitledCountries = state => {
    const allCountries = state[GNVAUTH_STORE_NAME].entitlements?.schedules?.include?.countries;
    // Removing any duplicates
    return allCountries?.filter((countryCode, idx) => allCountries?.indexOf(countryCode) === idx) || [];
};
export const gvauthSelLoading = state => state[GNVAUTH_STORE_NAME].loading;
export const gvauthSelToken = state => state[GNVAUTH_STORE_NAME].token;
export const gvauthSelFail = state => state[GNVAUTH_STORE_NAME].fail;
export const gvauthSelFailErrorMessage = state => state[GNVAUTH_STORE_NAME].errorMessage;
export const gvauthGetEntitledProgramLanguages = state => state[GNVAUTH_STORE_NAME]?.entitlements?.programs?.include?.languages || [];
export const gvauthHasSchedulesEntitlement = state => state[GNVAUTH_STORE_NAME].entitlements?.schedules?.include?.countries?.length > 0;
export const gvauthHasUSAEntitlements = state => state[GNVAUTH_STORE_NAME].entitlements?.schedules?.include?.countries?.includes('USA');
export const gvauthHasExportEntitlements = state => state[GNVAUTH_STORE_NAME].entitlements?.global?.include?.roles?.includes('export') || state[GNVAUTH_STORE_NAME].entitlements?.global?.include?.featureFlags?.includes('export');
export const gvauthHasChannelAlerts = state => state[GNVAUTH_STORE_NAME].entitlements?.global?.include?.featureFlags?.includes('channel-alerts');
export const gvauthHasStreamId = state => state[GNVAUTH_STORE_NAME].entitlements?.global?.include?.streamId?.length > 0;
export const gvauthHasMediaCloudEntitlements = state => state[GNVAUTH_STORE_NAME].entitlements?.global?.include?.imageDomain?.some(domain => domain.includes(MEDIACLOUD_DOMAIN));
export const gvauthHasVideoDescriptorsEntitlements = state => state[GNVAUTH_STORE_NAME].entitlements?.programs?.include?.videoDescriptor;
export const gvauthGetMediaCloudURL = state => {
    const domain = state[GNVAUTH_STORE_NAME].entitlements?.global?.include?.imageDomain?.length > 0 ? state[GNVAUTH_STORE_NAME].entitlements.global.include.imageDomain[0] : null;
    return domain ? `https://${domain}/assets/` : '';
};
export const gvauthIsAdmin = state => state[GNVAUTH_STORE_NAME].entitlements?.global?.include?.roles?.includes('admin');
export const gvauthIsBetaUser = state => state[GNVAUTH_STORE_NAME].entitlements?.global?.include?.roles?.includes('beta');
export const gvauthSelFeatureFlags = state => state[GNVAUTH_STORE_NAME].entitlements?.global?.include?.featureFlags || [];

// Mobius
export const gvauthHasMobiusEntitlements = state => state[GNVAUTH_STORE_NAME].entitlements?.mobius?.include?.access?.length > 0 || state[GNVAUTH_STORE_NAME].entitlements?.mobius?.include?.sourceId?.length > 0;
export const gvauthSelMobiusLanguages = state => state[GNVAUTH_STORE_NAME].entitlements?.mobius?.include?.languages;
export const gvauthSelMobiusSourceID = state => state[GNVAUTH_STORE_NAME].entitlements?.mobius?.include?.sourceId;
export const gvauthHasMobiusWriteAccess = state => state[GNVAUTH_STORE_NAME].entitlements?.mobius?.include?.access[0] === MOBIUS_ACCESS_TYPES.WRITE;

// User Settings
export const gnviewSelUserSettings = state => state[GNVAUTH_STORE_NAME].userSettings;

// Report an Issue
export const gnviewSelLastURLVisited = state => state[GNVAUTH_STORE_NAME].lastURLVisited;

/**
 * Default export
 */
export default GNVAuthReducer;