import React, { useEffect, useRef, useState } from 'react';
import "./FileUpload.scss";
import { Button } from 'react-bootstrap';
import GNNotification from '../gnNotification/GNNotification';
import { convertFileToBase64, formatFileSize } from '../../../utils/GeneralUtils';
import PropTypes from 'prop-types';

const FileUpload = ({ setBase64Files, acceptTypes, maxSize, maxTotalFiles }) => {
    const [files, setFiles] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [disableButton, setDisableButton] = useState(false);
    const inputRef = useRef();

    useEffect(() => {
        if (files.length >= maxTotalFiles) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [files, setDisableButton, maxTotalFiles]);

    const handleClick = () => {
        inputRef.current.click();
    }

    /**
     * fileArr is an array-like object (but not an array?) with a length value and one
     * or more file descriptor objects. If the user has already added attachments, then
     * drags in an array of attachments that would cause the total to exceed maxTotalFiles,
     * then we want to prevent the extra files from being passed into validateFile, as the
     * state object won't have been updated yet.
     **/
    const handleFiles = async (fileArr) => {
        const limit = Math.max(Math.min(fileArr.length, maxTotalFiles - files.length), 1);
        const newFiles = [...files];
        for (let i = 0; i < limit; i++) {
            const file = fileArr?.[i];
            if (validateFile(file)) {
                await convertFileToBase64(file).then((convertedImg) => {
                    file.base64 = convertedImg;
                    newFiles.push(file);
                });
            }
        }
        setFiles(newFiles);
        setBase64Files(newFiles.map((f) => ({
            name: f.name,
            data: f.base64
        })));

        // null out the value so that duplicate attempts to upload a file are recognized
        inputRef.current.value = null;
    }

    /**
     * If maxSize is set (in bytes) the file should not exceed that size.
     * If acceptTypes is set (a string of comma-delimited accepted file extensions,
     * e.g. ".js, .png, .xml") then the file should have a matching extension.
     * If maxTotalFiles is set, don't allow the number of attachments to exceed the limit
     **/
    const validateFile = (file) => {
        if (!file) {
            return false;
        }
        if (files.filter((storedFile) => storedFile.name === file.name).length) {
            setNotificationMsg(`This file was already attached`);
            setShowNotification(true);
            return false;
        }
        if ((maxSize > 0) && (file?.size > maxSize)) {
            setNotificationMsg(`Maximum file size is ${formatFileSize(maxSize)}`);
            setShowNotification(true);
            return false;
        }
        if (acceptTypes) {
            const extension = file?.name?.split('.').pop();
            if (acceptTypes.indexOf(extension) < 0) {
                setNotificationMsg(`We only allow these file types: ${acceptTypes}`);
                setShowNotification(true);
                return false;
            }
        }
        if ((maxTotalFiles > 0) && (files?.length >= maxTotalFiles)) {
            setNotificationMsg(`Maximum number of attachments is ${maxTotalFiles}`);
            setShowNotification(true);
            return false;
        }
        return true;
    }

    const removeFile = (name) => {
        const newFiles = files.filter((file) => file.name !== name);
        setFiles(newFiles);
        setBase64Files(newFiles.map((f) => f.base64));
    }

    const preventBubbling = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <div className="file-upload-container">
            <div
                className="file-drop-area"
                onDragEnter={preventBubbling}
                onDragOver={preventBubbling}
                onDrop={(e) => {
                    preventBubbling(e)
                    handleFiles(e.dataTransfer.files)
                }}
            >
                <div className="message">Drag and drop an image or click Add Attachment below. Maximum of 3 attachments.</div>
                <input
                    ref={inputRef}
                    type="file"
                    className="file-input"
                    onChange={(e) => handleFiles(e.target.files)}
                    accept={acceptTypes}
                />
                <Button variant="secondary" onClick={handleClick} disabled={disableButton}>
                    <i className="attach-icon fa fa-paperclip" />
                    Add Attachment
                </Button>
            </div>
            {files?.length > 0 &&
                <div className="file-list">
                    <div className="title">Your Attachments:</div>
                    <ul className="files">
                        {files.map((file) => (
                            <li key={file.name} onClick={() => removeFile(file.name)}>
                                <span>
                                    {file.name} <span className="file-size">({formatFileSize(file.size)})</span>
                                </span>
                                <span>
                                    <i className="fa-solid fa-times fa-sm" />
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            }
            <GNNotification
                handleShow={setShowNotification}
                message={notificationMsg}
                milliseconds={5000}
                show={showNotification}
                success={false}
            />
        </div>
    )
}

FileUpload.defaultProps = {
    acceptTypes: '',
    maxSize: 0,
    maxTotalFiles: 0
};

FileUpload.propTypes = {
    setBase64Files: PropTypes.func.isRequired,
    acceptTypes: PropTypes.string,
    maxSize: PropTypes.number,
    maxTotalFiles: PropTypes.number
};

export default FileUpload;
