import React from 'react';
import '../views/details/programs/tabs/VideoDescriptorsTab.scss';

export const VIDEO_DESCRIPTORS_DESCRIPTION = <span className='video-descriptors-description'><span className='italic-text'>Video Descriptors</span> are a set of deep distinct weighted descriptors organized across 16 <span className='italic-text'>Video Descriptor Types</span> and bound by a unified <span className='italic-text'>Video Descriptors Taxonomy</span>.</span>;
export const VIDEO_DESCRIPTORS_NO_ENTITLEMENTS_LOCK_MSG = 'THIS FEATURE REQUIRES A LICENSE';
export const VIDEO_DESCRIPTORS_NO_ENTITLEMENTS_HEADER = 'Gracenote Video Descriptors: Deep Content Understanding Fueling Discoverability';
export const VIDEO_DESCRIPTORS_NO_ENTITLEMENTS_MESSAGE = 'Trusted by top search and discovery platforms, Gracenote Video Descriptors delves deep into program storylines and context. This breakthrough content classification enables nuanced content understanding and relating programs in new ways to power search and discovery experiences.';
export const NO_VIDEO_DESCRIPTORS_MESSAGE = 'Gracenote Video Descriptors are available for programs at the TV Series and Movie level. Sports, Paid, Local and Adult programming are not covered. Programs are prioritized for Video Descriptor editorial curation based on general popularity as well as availability across linear TV schedules and streaming catalogs. Gracenote Video Descriptors are currently available for the US, Germany, Nordics and Australia markets and will be expanding to more countries in 2021. To request Video Descriptor tagging for a specific TV Series or Movie program or a specific market of interest, please reach out to your Customer Care representative.';
export const VIDEO_DESCRIPTORS = 'Video Descriptors';
export const LEARN_MORE_LINK = 'https://www.gracenote.com/video/global-video-data/video-descriptors';
export const CONTACT_US_LINK = 'https://content.nielsen.com/l/881703/2023-02-07/4g9wr';
export const ERROR = 'Error';

export const VIDEO_DESCRIPTORS_TYPES_ORDER = [
    { name: 'Video Mood', description: 'A tone of the work, as expressed through the combination of story, characters, setting, dialog, art direction, cinematography, music, effects, etc.' },
    { name: 'Theme', description: 'An abstract concept of human experience that the work addresses' },
    { name: 'Scenario', description: 'A specific situation, often personal or interpersonal, that sets the plot into movement, or moves it forward' },
    { name: 'Concept Source', description: 'The type of original source material that provided the inspiration or story for the work' },
    { name: 'Character', description: 'Actual or fictional being/group relevant to a work, encompassing occupation, personality, relationship, nationality, ethnicity, religion, cultural affinities, physical / mental condition, life stage, etc.' },
    { name: 'Setting - Time', description: 'A time period in which all, or a portion, of the plot is set' },
    { name: 'Setting - Time Period', description: 'Setting Time Period' },
    { name: 'Setting - Place', description: 'A type of physical environment in which all, or a portion of, the plot is set - realm, area, specific outdoor or indoor environment' },
    { name: 'Setting - Occasion', description: 'A global cultural, religious or national holiday or festival, or transitory personal event addressed in the work' },
    { name: 'Subject - Specific Location', description: 'A continent, region, country, state, province, celestial object, planet, solar system, fantasy worlds or supernatural realm noted as a subject of the work' },
    { name: 'Subject - Issue', description: 'A mental state, emotion, personal quality or mental or physical condition noted as a subject of the work' },
    { name: 'Subject - Personal Issue', description: 'A mental state, emotion, personal quality or mental or physical condition that impact one’s being noted as a subject of the work' },
    { name: 'Subject - Specific Being / Group', description: 'An actual, fictional or spiritual person, group or organization noted as a subject the work' },
    { name: 'Subject - Specific Event', description: 'An actual, fictional or spiritual event of the past, present or future noted as a subject of the work' },
    { name: 'Subject - Milieu', description: 'A well known combination of place and time, usually with additional explicit or implicit historical or cultural context that is noted as a subject of the work' },
    { name: 'Subject - Practice', description: 'A professional, vocational, activist, scholarly, religious or artistic pursuit, or other committed endeavor noted as a subject of the work' },
    { name: 'Subject - Activity', description: 'A pursuit done for enjoyment, interest, or lifestyle outside of a professional context noted as a subject of the work' },
    { name: 'Miscellaneous - Error', description: 'Video descriptors for which we could not retrieve a name' }
];

export const UNENTITLED_VIDEO_DESCRIPTOR_TYPES = ['Video Mood', 'Theme', 'Scenario', 'Concept Source', 'Character'];
export const UNENTITLED_VIDEO_DESCRIPTOR_SIZES = ['small', 'large'];
export const BLANK_VIDEO_DESCRIPTORS_COUNT = 20;