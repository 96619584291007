import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { gnviewSelProgramDetails } from '../../../../reducers/ProgramDetailsReducer';
import GNCard from '../../../../components/common/gnCard/GNCard';
import './CastAndCrewTab.scss';
import { Col, Row } from 'react-bootstrap';
import GNPersonImage from '../../../../components/common/gnImage/GNPersonImage';
import PropTypes from 'prop-types';
import GNRadioGroup from '../../../../components/common/gnRadioGroup/GNRadioGroup';
import { CAST_AND_CREW_SUBTABS } from '../../../../constants/Program';

export class CastAndCrewTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subTabSelected: CAST_AND_CREW_SUBTABS.cast
        };
    }

    onSubTabSelect = (tab) => {
        this.setState({ subTabSelected: tab });
    }

    render() {
        return (
            <div className="gnview-cast-and-crew-tab-content">
                <GNRadioGroup style="flat" list={Object.values(CAST_AND_CREW_SUBTABS)} onSelect={this.onSubTabSelect} default={CAST_AND_CREW_SUBTABS.cast}/>
                {this.state.subTabSelected === CAST_AND_CREW_SUBTABS.cast && <GNCard title="" headerOutsideOfCard={true}>
                    <Row>
                        {this.props.programDetails?.cast?.length > 0 && this.props.programDetails.cast.map((castMem, index) => {
                            return (
                                <Col className="person-section" key={index}>
                                    <GNPersonImage personId={castMem.person_id} text={castMem.name} />
                                    <div><strong>{castMem.name}</strong></div>
                                    <div>{castMem.character_name}</div>
                                    <div className="person-sub-section">
                                        {castMem.role}
                                        {castMem.departed && <span className="departed">(Not Active)</span>}
                                    </div>
                                </Col>
                            )
                        })}
                        {(!this.props.programDetails?.cast || this.props.programDetails.cast.length === 0) && <div className="no-info-message">No cast information available</div>}
                    </Row>
                </GNCard>}
                {this.state.subTabSelected === CAST_AND_CREW_SUBTABS.crew && <GNCard title="" headerOutsideOfCard={true}>
                    <Row>
                        {this.props.programDetails?.crew?.length > 0 && this.props.programDetails.crew.map((crewMem, index) => {
                            return (
                                <Col className="person-section" key={index}>
                                    <GNPersonImage personId={crewMem.person_id} text={crewMem.name} />
                                    <div><strong>{crewMem.name}</strong></div>
                                    <div className="person-sub-section">
                                        {crewMem.role}
                                        {crewMem.departed && <span className="departed">(Not Active)</span>}
                                    </div>
                                </Col>
                            )
                        })}
                        {(!this.props.programDetails?.crew || this.props.programDetails.crew.length === 0) && <div className="no-info-message">No crew information available</div>}
                    </Row>
                </GNCard>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        programDetails: gnviewSelProgramDetails(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CastAndCrewTab);

CastAndCrewTab.propTypes = {
    programDetails: PropTypes.object.isRequired
}