import { STATION } from '../constants/Station';
import find from 'lodash.find';

export const stationTransform = (key) => {
    return STATION[key] || 'error';
};

export const getStationImage = (station) => {
    const stationImages = station?.images?.filter(img => !img.expired_date) || [];
    if (stationImages.length > 0) {
        const defaultImage = stationImages[0]?.image;
        return find(stationImages, (img) => img.category.toLowerCase().includes('light'))?.image || defaultImage || '';
    }
    return '';
};

export const checkStationExists = (list, id) => {
    return list.some(station => parseInt(station.id) === parseInt(id));
};