import {
    GNVIEW_PROGRAM_DETAILS_IN_PROGRESS,
    GNVIEW_PROGRAM_DETAILS_SUCCESS,
    GNVIEW_PROGRAM_DETAILS_FAIL,
    GNVIEW_PROGRAM_DETAILS_CLEAR,
    GNVIEW_ADD_RECENT_PROGRAM
} from '../actions/ActionTypes';

export const PROGRAM_DETAILS_STORE = 'programDetails';

const MAX_RECENT_PROGRAMS = 10;

/**
 * Initial State
 */
export const initialState = {
    programDetails: {},
    programDetailsLoading: false,
    recentPrograms: []
};

/**
 * Reducer
 */
export const ProgramDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
    case GNVIEW_PROGRAM_DETAILS_IN_PROGRESS:
        return { ...state, programDetails: {}, programDetailsLoading: true };
    case GNVIEW_PROGRAM_DETAILS_SUCCESS:
        return { ...state, programDetails: action.payload.details, programDetailsLoading: false };
    case GNVIEW_PROGRAM_DETAILS_FAIL:
        return { ...state, programDetails: action.payload.details, programDetailsLoading: false };
    case GNVIEW_PROGRAM_DETAILS_CLEAR:
        return { ...state, programDetails: {} };
    case GNVIEW_ADD_RECENT_PROGRAM: {
        if (action.payload.programId) {
            const recentPrograms = [...state.recentPrograms].filter((p) => p !== action.payload.programId);
            // Add new program to beginning of list, since we want the most recent first
            recentPrograms.unshift(action.payload.programId);
            // When we reach the max, remove the oldest
            if (recentPrograms.length > MAX_RECENT_PROGRAMS) {
                recentPrograms.pop();
            }
            return { ...state, recentPrograms };
        }
        return state;
    }
    default:
        return state;
    }
};


/**
 * Selectors
 */
export const gnviewSelProgramDetails = state => state[PROGRAM_DETAILS_STORE].programDetails;
export const gnviewSelProgramDetailsLoading = state => state[PROGRAM_DETAILS_STORE].programDetailsLoading;
export const gnviewSelProgramDetailsRecentPrograms = state => state[PROGRAM_DETAILS_STORE].recentPrograms;

/**
 * Default export
 */
export default ProgramDetailsReducer;