import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    gnviewDeleteSavedSearch,
    gnviewGetAllSavedSearches,
    gnviewGetProgramById,
    gnviewGetSavedSearch,
    gnviewUpdateSavedSearch
} from '../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../services/GeneralService';
import {
    MY_SAVED_ITEMS,
    MY_SAVED_ITEMS_EMPTY_MESSAGE,
    MY_SAVED_ITEMS_TABS,
    MY_SAVED_ITEMS_TYPES,
    DELETE_SAVED_SEARCH_MODAL_MESSAGE,
    DELETE_SAVED_SEARCH_MODAL_HEADER,
    DELETE_SAVED_SEARCH_MODAL_FIRST_BUTTON,
    DELETE_SAVED_SEARCH_MODAL_SECOND_BUTTON,
    DELETE_SAVED_SEARCH_SUCCESS,
    RENAME_SAVED_SEARCH_MODAL_HEADER,
    RENAME_SAVED_SEARCH_SUCCESS,
    RENAME_SAVED_SEARCH_ERROR_MESSAGE
} from '../../constants/MySavedItems';
import { Container, Form } from 'react-bootstrap';
import GNClientTable from '../../components/common/gnClientTable/GNClientTable';
import "./MySavedItems.scss";
import GNModal from '../../components/common/gnModal/GNModal';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { ROUTES } from '../../config/Routes';
import localTimeConverter from '../../utils/DateUtils';
import SuccessMessageModal from '../../components/common/successMessageModal/SuccessMessageModal';
import ErrorBoundary from '../../components/common/errorBoundary/ErrorBoundary';
import { Modal } from 'react-bootstrap';
import GNRadioGroup from '../../components/common/gnRadioGroup/GNRadioGroup';
import groupBy from 'lodash.groupby';
import LoadingSpinner from '../../components/common/loadingSpinner/LoadingSpinner';
import { gvauthSelEmail } from '../../reducers/GNVAuthReducer';
import { sortSavedItemsByDate } from '../../constants/MySavedItems';
import MySavedItemsSubMenu from '../../components/common/mySavedItemsSubMenu/MySavedItemsSubMenu';
import isEmpty from 'lodash.isempty';

export const MySavedItems = () => {
    const dispatch = useDispatch();
    const email = useSelector(gvauthSelEmail);
    const [savedSearches, setSavedSearches] = useState([]);
    const [selectedTabSearches, setSelectedTabSearches] = useState([]);
    const [selectedTab, setSelectedTab] = useState(MY_SAVED_ITEMS_TABS[MY_SAVED_ITEMS_TYPES.PROGRAM_AIRINGS]);
    const [modalShow, setModalShow] = useState(false);
    const [selectedSavedSearch, setSelectedSavedSearch] = useState({});
    const [successMessageShow, setSuccessMessageShow] = useState(false);
    const [savedSearchesLoading, setSavedSearchesLoading] = useState(true);
    const [selectedProgramList, setSelectedProgramList] = useState(null);
    const [selectedProgramListData, setSelectedProgramListData] = useState([]);
    const [isModalTypeUpdate, setIsModalTypeUpdate] = useState(false);
    const [renamedSavedSearchString, setRenamedSavedSearchString] = useState('');
    const [showRenamedStringError, setShowRenamedStringError] = useState(false);

    useEffect(() => {
        dispatch(gnviewGetAllSavedSearches()).then((response) => {
            const groupedSearchesList = groupBy(response.result, (s) => s.search_type);
            // Sorting ALL Saved Items by updated_at date since API returns it ordered by id
            Object.keys(groupedSearchesList).forEach(searchList => sortSavedItemsByDate(groupedSearchesList[searchList]));
            setSavedSearches(groupedSearchesList);
            if (groupedSearchesList[MY_SAVED_ITEMS_TABS[MY_SAVED_ITEMS_TYPES.PROGRAM_AIRINGS].name]) {
                setSelectedTabSearches(groupedSearchesList[MY_SAVED_ITEMS_TABS[MY_SAVED_ITEMS_TYPES.PROGRAM_AIRINGS].name]);
            }
            setSavedSearchesLoading(false);
        }).catch((error) => {
            setSavedSearchesLoading(false);
            dispatch(gnviewSendLogMessage(`MySavedItems gnviewGetAllSavedSearches error: ${error.message}`, error));
        });
    }, [dispatch]);

    const handleDelete = (savedSearchId) => {
        dispatch(gnviewDeleteSavedSearch(savedSearchId)).then(() => {
            const filteredSavedSearches = savedSearches[selectedTab?.name].filter((s) => s.id !== savedSearchId);
            const filteredSavedTabSearches = selectedTabSearches.filter((s) => s.id !== savedSearchId);
            const newSavedSearchesObj = {...savedSearches, [selectedTab?.name]: filteredSavedSearches};
            setSavedSearches(newSavedSearchesObj);
            setSelectedTabSearches(filteredSavedTabSearches);
            handleModal()
            setSuccessMessageShow(prevSuccessMessageShow => !prevSuccessMessageShow);
            setTimeout(() => {
                setSuccessMessageShow(prevSuccessMessageShow => !prevSuccessMessageShow);
            }, 2000);
        }, (error) => {
            dispatch(gnviewSendLogMessage(`MySavedItems gnviewDeleteSavedSearch error: ${error.message}`, error));
        })
    }

    const handleUpdate = (savedSearch) => {
        if (renamedSavedSearchString) {
            const body = {
                query_obj: savedSearch.query_obj,
                search_type: savedSearch.search_type,
                title: renamedSavedSearchString,
                username: email
            };
            dispatch(gnviewUpdateSavedSearch(savedSearch.id, body)).then(() => {
                dispatch(gnviewGetSavedSearch(savedSearch.id)).then((response) => {
                    const newSavedSearch = response?.result;
                    const filteredSavedSearches = savedSearches[selectedTab?.name].filter((s) => s.id !== savedSearch.id);
                    const filteredSavedTabSearches = selectedTabSearches.filter((s) => s.id !== savedSearch.id);
                    filteredSavedSearches.unshift(newSavedSearch);
                    filteredSavedTabSearches.unshift(newSavedSearch);
                    const newSavedSearchesObj = {...savedSearches, [selectedTab?.name]: filteredSavedSearches};
                    setSelectedSavedSearch(newSavedSearch);
                    setSavedSearches(newSavedSearchesObj);
                    setSelectedTabSearches(filteredSavedTabSearches);
                    handleModal();
                    setSuccessMessageShow(prevSuccessMessageShow => !prevSuccessMessageShow);
                    setTimeout(() => {
                        setSuccessMessageShow(prevSuccessMessageShow => !prevSuccessMessageShow);
                    }, 2000);
                });
            }, (error) => {
                dispatch(gnviewSendLogMessage(`MySavedItems gnviewUpdateSavedSearch error: ${error.message}`, error));
            })
        } else {
            setShowRenamedStringError(true);
        }
    }

    const handleModal = (isOpenRequest = false, savedSearch = {}, isUpdate = false) => {
        setModalShow(prevModalShow => !prevModalShow);
        setShowRenamedStringError(false);
        // The modal flashes the "delete" view when the modal closes when it's set to the "update" view, so we're only toggling the modal type when it's an "open modal" request
        if (isOpenRequest) {
            setIsModalTypeUpdate(isUpdate);
        }
        if (!isEmpty(savedSearch)) {
            setSelectedSavedSearch(savedSearch);
        }
    }

    /* eslint-disable react/display-name */
    /* eslint-disable react/prop-types */
    const handleTableColumnHeaders = () => [
        { accessor: 'title', Header: 'Name', Cell: ({row}) => {
            if (row.original.search_type === MY_SAVED_ITEMS_TYPES.PROGRAM_LISTS) {
                return row.original.query_obj.program_ids.length > 0 ? <Link onClick={() => fetchProgramListData(row.original.id, row.original.query_obj.program_ids)}>{row.original?.title}</Link> : row.values?.title;
            } else {
                const url = createSavedSearchURL(row.original.search_type, row.original.id);
                return <span key={row.original.id}><Link target="_blank" rel="noreferrer" to={url}>{row.values?.title}</Link></span>
            }
        }},
        ...(selectedTab === MY_SAVED_ITEMS_TABS[MY_SAVED_ITEMS_TYPES.PROGRAM_LISTS] ? [{ accessor: '', Header: 'Number of Programs', Cell: ({row}) => {
            return row.original.query_obj.program_ids.length
        }}] : []),
        { accessor: 'updated_at', Header: 'Last Updated', Cell: ({row}) => {
            return localTimeConverter.convertToLocalTime(row.values.updated_at)
        }},
        { accessor: 'searchButton', Header: '', Cell: ({row}) => {
            return (
                <MySavedItemsSubMenu
                    savedItem={row.original}
                    handleModal={handleModal} />
            );
        }}
    ];

    const PROGRAM_LIST_COLUMN_HEADERS = [
        { accessor: 'title', Header: 'Name', Cell: ({row}) => {
            return <Link target='_blank' rel='noreferrer' to={`${ROUTES.PROGRAM_DETAILS}/${row.original.tmsid}`}>{row.values?.title}</Link>
        }},
        { accessor: 'type', Header: 'Type' },
        { accessor: 'genres', Header: 'Genres', Cell: ({row}) => {
            return row?.values?.genres?.join(', ')
        }},
        { accessor: 'release_year', Header: 'Release Year' },
        { accessor: 'tmsid', Header: 'TMSID' },
        { accessor: 'deleteButton', Header: '', Cell: ({row}) => {
            return (<Button variant="" bsPrefix='btn' onClick={() => removeProgramFromProgramList(row.original.tmsid)}>
                <i className="fas fa-trash-can"/>
            </Button>)
        }}
    ];
    /* eslint-enable react/display-name */
    /* eslint-enable react/prop-types */

    const createSavedSearchURL = (searchType, searchId) => {
        const routes = {
            [MY_SAVED_ITEMS_TYPES.PROGRAM_AIRINGS]: ROUTES.PROGRAM_AIRINGS,
            [MY_SAVED_ITEMS_TYPES.PROGRAM_AVAILABILITY]: ROUTES.PROGRAM_AVAILABILITIES,
            [MY_SAVED_ITEMS_TYPES.SCHEDULES]: ROUTES.SCHEDULES
        };
        return `${routes[searchType]}?search_id=${searchId}`;
    }

    const onTabSelect = (tab) => {
        clearSelectedProgramList();
        setSelectedTabSearches(savedSearches[tab.name] || []);
        setSelectedTab(tab);
    }

    const clearSelectedProgramList = () => {
        setSelectedProgramList(null);
        setSelectedProgramListData([]);
    }

    const fetchProgramListData = (listId, tmsIds) => {
        const programList = selectedTabSearches.find(list => list.id === listId);
        dispatch(gnviewGetProgramById(tmsIds)).then((response) => {
            setSelectedProgramListData(response.result);
            setSelectedProgramList(programList);
        }).catch((error) => {
            dispatch(gnviewSendLogMessage(`MySavedItems gnviewGetProgramById error: ${error.message}`, error));
        })
    }

    const removeProgramFromProgramList = (tmsid) => {
        if (selectedProgramList && tmsid) {
            setSavedSearchesLoading(true);
            const programListCopy = JSON.parse(JSON.stringify(selectedProgramList));
            const newProgramTMSIDList = programListCopy.query_obj.program_ids.filter(id => id !== tmsid);
            programListCopy.query_obj.program_ids = newProgramTMSIDList;
            const newBody = {
                title: programListCopy?.title,
                search_type: programListCopy.search_type,
                username: email,
                query_obj: { program_ids: newProgramTMSIDList }
            };
            dispatch(gnviewUpdateSavedSearch(programListCopy.id, newBody)).then(() => {
                const savedSearchesCopy = JSON.parse(JSON.stringify(savedSearches));
                savedSearchesCopy[selectedTab?.name].find(savedSearch => savedSearch.id === selectedProgramList.id).query_obj.program_ids = newProgramTMSIDList;
                const filteredSelectedProgramListData = selectedProgramListData.filter(program => program.tmsid !== tmsid);
                setSavedSearches(savedSearchesCopy);
                setSelectedTabSearches(savedSearchesCopy[selectedTab?.name]);
                setSelectedProgramList(programListCopy);
                setSelectedProgramListData(filteredSelectedProgramListData);
                setSavedSearchesLoading(false);
            }).catch((error) => {
                setSavedSearchesLoading(false);
                dispatch(gnviewSendLogMessage(`MySavedItems gnviewUpdateSavedSearch error: ${error.message}`, error));
            })
        }
    }

    return (
        <ErrorBoundary>
            <div className='gnview-my-saved-items-content'>
                <div className='gnview-header-title'>
                    {MY_SAVED_ITEMS}
                </div>
                <div className='my-saved-items-table-container'>
                    <GNRadioGroup style="flat" list={Object.values(MY_SAVED_ITEMS_TABS)} onSelect={onTabSelect} default={selectedTab}/>
                    <Container fluid>
                        {savedSearchesLoading && <LoadingSpinner />}
                        {!savedSearchesLoading && selectedProgramListData.length > 0 && <div className='program-list-bread-crumb-container'>
                            <Link className='program-list-breadcrumb-link' onClick={clearSelectedProgramList}>{selectedTab?.name}</Link>
                            <i className="fas fa-angle-right gnview-breadcrumbs-separator" />
                            <span className='program-list-breadcrumb-title'>{selectedProgramList?.title}</span>
                            <div className='program-list-table-header'>{selectedProgramList?.title}</div>
                        </div>}
                        {!savedSearchesLoading && selectedTabSearches.length > 0 && !selectedProgramListData.length > 0 && <GNClientTable columns={handleTableColumnHeaders()} data={selectedTabSearches}/>}
                        {!savedSearchesLoading && selectedTabSearches.length > 0 && selectedProgramListData.length > 0 && <GNClientTable className='program-list-data-table' columns={PROGRAM_LIST_COLUMN_HEADERS} data={selectedProgramListData}/>}
                        {!savedSearchesLoading && selectedTabSearches.length === 0 && (
                            <div className="no-saved-items-container" key={selectedTab?.name}>
                                {MY_SAVED_ITEMS_EMPTY_MESSAGE[selectedTab.name]}
                            </div>
                        )}
                    </Container>
                </div>
                {!savedSearchesLoading && <GNModal show={modalShow} title={isModalTypeUpdate ? RENAME_SAVED_SEARCH_MODAL_HEADER : DELETE_SAVED_SEARCH_MODAL_HEADER} onHide={handleModal}>
                    <Modal.Body>
                        {isModalTypeUpdate ? <div>
                            <Form.Control onChange={(e) => setRenamedSavedSearchString(e.target.value)} placeholder='New Saved Item Name' />
                            {showRenamedStringError && <div className='gnview-error-msg'>{RENAME_SAVED_SEARCH_ERROR_MESSAGE}</div>}
                        </div> : DELETE_SAVED_SEARCH_MODAL_MESSAGE}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='light' onClick={() => handleModal(false)}>{DELETE_SAVED_SEARCH_MODAL_FIRST_BUTTON}</Button>
                        <Button variant='primary' onClick={() => isModalTypeUpdate ? handleUpdate(selectedSavedSearch) : handleDelete(selectedSavedSearch?.id)}>{DELETE_SAVED_SEARCH_MODAL_SECOND_BUTTON}</Button>
                    </Modal.Footer>
                </GNModal>}
                {!savedSearchesLoading && successMessageShow && <SuccessMessageModal
                    animation={false}
                    message={isModalTypeUpdate ? RENAME_SAVED_SEARCH_SUCCESS(selectedSavedSearch?.title) : DELETE_SAVED_SEARCH_SUCCESS(selectedSavedSearch?.title)}
                />}
            </div>
        </ErrorBoundary>
    )
}

export default MySavedItems;