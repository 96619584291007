// GN View Auth
export const GVAUTH_AUTH_UPDATE = 'gvauth/AUTH_UPDATE';
export const GVAUTH_AUTH_CLEAR = 'gvauth/AUTH_CLEAR';
export const GVAUTH_USER_SETTINGS_UPDATE = 'gvauth/USER_SETTINGS_UPDATE';
export const GVAUTH_LOOKUP_COUNT_UPDATE = 'gvauth/LOOKUP_COUNT_UPDATE';

// Report an Issue
export const GVAUTH_SET_LAST_VISITED_URL = 'gvauth/SET_LAST_VISITED_URL';
export const GNVIEW_REPORT_AN_ISSUE = 'gnview/REPORT_AN_ISSUE';

// Program Details
export const GNVIEW_PROGRAM_DETAILS_IN_PROGRESS = 'gnview/PROGRAM_DETAILS_IN_PROGRESS';
export const GNVIEW_PROGRAM_DETAILS_SUCCESS = 'gnview/PROGRAM_DETAILS_SUCCESS';
export const GNVIEW_PROGRAM_DETAILS_FAIL = 'gnview/PROGRAM_DETAILS_FAIL';
export const GNVIEW_PROGRAM_DETAILS_CLEAR = 'gnview/PROGRAM_DETAILS_CLEAR';
export const GNVIEW_ADD_RECENT_PROGRAM = 'gnview/ADD_RECENT_PROGRAM';

// Advanced Search
export const GNVIEW_ADVANCED_SEARCH_UPDATE_SEARCH_BY = 'gnview/ADVANCED_SEARCH_UPDATE_SEARCH_BY';
export const GNVIEW_ADVANCED_SEARCH_FIELDS_CLEAR = 'gnview/ADVANCED_SEARCH_FIELDS_CLEAR';
export const GNVIEW_ADVANCED_SEARCH_RESULT_TABLE_RESET = 'gnview/ADVANCED_SEARCH_RESULT_TABLE_RESET';
// Advanced Search - Root Id
export const GNVIEW_ADVANCED_SEARCH_ROOT_ID_FIELDS_UPDATE = 'gnview/ADVANCED_SEARCH_ROOT_ID_FIELDS_UPDATE';
// Advanced Search - Series Id
export const GNVIEW_ADVANCED_SEARCH_SERIES_ID_FIELDS_UPDATE = 'gnview/ADVANCED_SEARCH_SERIES_ID_FIELDS_UPDATE';
// Advanced Search - Program Attributes
export const GNVIEW_ADVANCED_SEARCH_PROGRAM_ATTRIBUTES_FIELDS_UPDATE = 'gnview/ADVANCED_SEARCH_PROGRAM_ATTRIBUTES_FIELDS_UPDATE';

// Program Availability
export const GNVIEW_PROGRAM_AVAIL_ADD_PROGRAM_IDS = 'gnview/PROGRAM_AVAIL_ADD_PROGRAM_IDS';
export const GNVIEW_PROGRAM_AVAIL_ADD_STREAMING_CATALOG_IDS = 'gnview/PROGRAM_AVAIL_ADD_STREAMING_CATALOG_IDS';
export const GNVIEW_PROGRAM_AVAIL_ADD_QUERY_OBJ = 'gnview/PROGRAM_AVAIL_ADD_QUERY_OBJ';

// Streaming Video Catalogs
export const GNVIEW_CATALOGS_IN_PROGRESS = 'gnvcatalogs/CATALOGS_IN_PROGRESS';
export const GNVIEW_CATALOGS_SUCCESS = 'gnvcatalogs/CATALOGS_SUCCESS';
export const GNVIEW_CATALOGS_FAIL = 'gnvcatalogs/CATALOGS_FAIL';
export const GNVIEW_CATALOGS_CLEAR = 'gnvcatalogs/CATALOGS_CLEAR';

// Program Airings
export const GNVIEW_PROGRAM_AIRINGS_STORE_SEARCH = 'gnview/PROGRAM_AIRINGS_SAVE_SEARCH';
export const GNVIEW_PROGRAM_AIRINGS_CLEAR_SEARCH = 'gnview/PROGRAM_AIRINGS_CLEAR_SEARCH';
export const GNVIEW_PROGRAM_AIRINGS_ADD_PROGRAM = 'gnview/PROGRAM_AIRINGS_ADD_PROGRAM';

// Station Details
export const GNVIEW_ADD_RECENT_STATION = 'gnview/ADD_RECENT_STATION';
export const GNVIEW_ADD_SELECTED_STATION = 'gnview/ADD_SELECTED_STATION';
export const GNVIEW_CLEAR_SELECTED_STATION = 'gnview/CLEAR_SELECTED_STATION';

// Sports
export const GNVIEW_SPORTS_SET_LEAGUE_DATA = 'gnview/SPORTS_SET_LEAGUE_DATA';
export const GNVIEW_SPORTS_SET_LEAGUE_ID = 'gnview/SPORTS_SET_LEAGUE_ID';
export const GNVIEW_SPORTS_SET_MATCH_DATA = 'gnview/SPORTS_SET_MATCH_DATA';
export const GNVIEW_SPORTS_SET_SPORT_ID = 'gnview/SPORTS_SET_SPORT_ID';
export const GNVIEW_SPORTS_SET_START_DATE = 'gnview/SPORTS_SET_START_DATE';
export const GNVIEW_SPORTS_SET_TEAM_ID = 'gnview/SPORTS_SET_TEAM_ID';

// Data Alerts
export const GNVIEW_DATA_ALERTS_SET_ALERTS = 'gnview/DATA_ALERTS_SET_ALERTS';
export const GNVIEW_DATA_ALERTS_SET_SUBSCRIPTIONS = 'gnview/DATA_ALERTS_SET_SUBSCRIPTIONS';
export const GNVIEW_DATA_ALERTS_SET_PRG_SVC_IDS = 'gnview/DATA_ALERTS_SET_PRG_SVC_IDS';
export const GNVIEW_DATA_ALERTS_SET_ALERTS_COUNTS = 'gnview/GNVIEW_DATA_ALERTS_SET_ALERTS_COUNTS';
export const GNVIEW_DATA_ALERTS_SET_ALERTS_COUNTS_THIS_WEEK = 'gnview/GNVIEW_DATA_ALERTS_SET_ALERTS_COUNTS_THIS_WEEK';
export const GNVIEW_DATA_ALERTS_SET_SELECTED_WINDOW = 'gnview/GNVIEW_DATA_ALERTS_SET_SELECTED_WINDOW';