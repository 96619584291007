export const ROUTES = {
    LANDING: "/",
    AUTH_FORBIDDEN: "/auth-forbidden",
    CONTACT_US: "/contact-us",
    NOT_FOUND: "/not-found",
    GNVIEW_TEASER: "/gnview-teaser",
    ACCESS_DENIED: "/access-denied",
    GET_STARTED: "/get-started",
    LOOKUP_MAX: "/lookup-max",

    // Details
    MARKET_DETAILS: "/markets",
    PROGRAM_DETAILS: "/programs",
    STATION_DETAILS: "/stations",
    ADVANCED_SEARCH: "/advanced-search",
    ADVANCED_SEARCH_RESULT: "/advanced-search/result",
    SPORTS: "/sports",
    SPORTS_RESULTS: "/sports/results",

    // Reports
    PROGRAM_AIRINGS: "/program-airings",
    SCHEDULES: "/schedules",
    DIRECT_RESPONSE: "/direct-response",

    // User
    MY_SAVED_ITEMS: "/my-saved-items",
    DATA_ALERTS: "/data-alerts",

    // Streaming Video
    PROGRAM_AVAILABILITIES: "/program-availabilities",

    // Mobius
    GNID_DISTRIBUTION: "/gnid-distribution",

    // Support
    REPORT_AN_ISSUE: "/report-an-issue",
    STATUS: "/case-status"
};
