import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { gnviewGetSchedule, gnviewGetProgramDetails, gnviewGetProgramDetailsUnrestricted } from '../../../actions/GNViewActions';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import { getImageURL } from '../../../utils/ImageUtils';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import ScheduleEventTile from "./ScheduleEventTile";
import "./ScheduleCalendar.scss";
import moment from 'moment-timezone';
import ClassNames from 'classnames';
import LoadingSpinner from '../../../components/common/loadingSpinner/LoadingSpinner';
import { ROUTES } from '../../../config/Routes';
import { Link } from "react-router-dom";
import {
    getProgramTitle,
    getProgramTitleAltText,
    getProgramColor,
    getProgramImageVertical,
    getProgramDescription,
    getReleaseYear
} from '../../../utils/ProgramUtils';
import GNModal from '../../../components/common/gnModal/GNModal';
import { PROGRAM_GNPROGTYPES } from '../../../constants/Program';
import { Button, Modal } from 'react-bootstrap';
import GNImage from '../../../components/common/gnImage/GNImage';
import { SCHEDULE_GRID_FORMATS } from '../../../constants/AccountSettings';
import { gnviewSelUserSettings } from '../../../reducers/GNVAuthReducer';
import { SCHEDULE_LAST_UPDATED } from '../../../constants/Schedule';

export class ScheduleCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isEventsLoading: true,
            events: [],
            programSelected: {},
            showProgramModal: false
        }
    }

    transformEvents = (events, timezone) => {
        if (events?.length) {
            return events.map((event) => ({
                start: event.start_date_time,
                end: event.end_date_time,
                extendedProps: {
                    startTimeFormatted: moment(event.start_date_time).tz(timezone).format('h:mma'),
                    endTimeFormatted: moment(event.end_date_time).tz(timezone).format('h:mma'),
                    tmsid: event.tmsid,
                    progInfo: {
                        quals: event?.quals || '',
                        rating: event?.rating?.code || '',
                        syndicationSource: event?.syndication_source || '',
                        syndicationType: event?.syndication_type || ''
                    }
                }
            }));
        }
        return [];
    }

    getScheduleData = () => {
        this.setState({isLoading: true});
        this.props.gnviewGetSchedule(this.props.station.id, this.props.utcStartDate, this.props.utcEndDate).then((eventResponse) => {
            const { schedules } = eventResponse.result[0];
            const transformedEvents = this.transformEvents(schedules, this.props.station.timezone);
            this.setState({events: transformedEvents, isLoading: false});
            window.dispatchEvent(new Event('resize'));
        }).catch((error) => {
            console.error('Schedule getScheduleData error', error);
            this.setState({isLoading: false});
        });
    }

    componentDidMount() {
        this.getScheduleData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.utcStartDate !== this.props.utcStartDate) {
            this.getScheduleData();
        }
    }

    hideProgramModal = () => {
        this.setState({ showProgramModal: false });
    }

    eventClick = ({event}) => {
        this.props.gnviewGetProgramDetails(event.extendedProps.tmsid).then(
            (programResponse) => {
                const program = programResponse.result;
                if (program) {
                    const programData = {
                        ...program,
                        progTitle: getProgramTitle(program),
                        progTitleAlt: getProgramTitleAltText(program),
                        progColor: getProgramColor(program.type) || 'light-gray',
                        progImageURL: getImageURL(getProgramImageVertical(program)) || '',
                        progReleaseYear: getReleaseYear(program),
                        progDescription: getProgramDescription(program)
                    };
                    this.setState({ programSelected: {...event.extendedProps, ...programData}, showProgramModal: true });
                }
            }).catch((error) => {
            this.setState({ programSelected: {...event.extendedProps, ...event.extendedProps.program}, showProgramModal: true });
            console.error('eventRender gnview_getProgramDetails error', error);
        });
    }

    renderProgQuals = (quals) => {
        const progQuals = quals.split('|');
        return (<div className="schedule-prog-quals">{progQuals.map((qual, index) => {
            const qualText = (index === progQuals.length - 1) ? qual : qual + ' | ';
            if (qual === 'New') {
                return <span key={index}><i className="fas fa-certificate qual-icon" />{qualText}</span>;
            } else if (qual === 'Live') {
                return <span key={index}><i className="fas fa-microphone qual-icon"></i>{qualText}</span>;
            }
            return <span key={index}>{qualText}</span>;
        })}</div>);
    }

    eventContentRender = ({event}) => {
        return (
            <LazyLoad scrollContainer="fc-scrollgrid" overflow={true} once>
                <ScheduleEventTile event={event} renderProgQuals={this.renderProgQuals} isWeekly={this.props.isWeekly} isStationSubscribed={this.props.isStationSubscribed} setAlerts={this.props.setAlerts} handleShowLoadingIndicator={this.props.handleShowLoadingIndicator} />
            </LazyLoad>
        );
    }

    getAlternateEpisode = () => {
        return this.state.programSelected?.episode_info?.alternate_episodes &&
            this.state.programSelected?.episode_info?.alternate_episodes.find(ele => String(ele.station_id) === String(this.props.station.id));
    }

    // After fullcalendar is mounted, scroll to 6:00am
    viewDidMount = () => {
        const wrapper = document.getElementsByClassName('gnview-main-container')[0];
        if (wrapper) {
            // scrollTop should be supported in IE versus scrollTo
            wrapper.scrollTop = 0;
        }
        const axis = document.getElementsByClassName('schedule-time-axis')[0];
        if (axis?.classList) {
            axis.classList.add('show');
        }
    }

    // After ScheduleCalendar is unmounted, scroll to top, this will make the multi-station weekly view smoother
    componentWillUnmount() {
        const wrapper = document.getElementsByClassName('gnview-main-container')[0];
        // if not IE
        if (wrapper) {
            // scrollTop should be supported in IE versus scrollTo
            wrapper.scrollTop = 0;
        }
        // Show axis in all browsers
        const axis = document.getElementsByClassName('schedule-time-axis')[0];
        if (axis?.classList) {
            axis.classList.remove('show');
        }
    }

    render() {
        const gridStartTime = this.props.defaultGridStart === SCHEDULE_GRID_FORMATS.SCHEDULE_DAY.value ? "00:00:00" : "06:00:00";
        const gridMaxTime = this.props.defaultGridStart === SCHEDULE_GRID_FORMATS.SCHEDULE_DAY.value ? "24:00:00" : "30:00:00";
        return (
            <ErrorBoundary>
                <div className="gnview-schedule-calendar">
                    {this.props.station?.id && !this.state.isLoading && this.state.events?.length > 0 && this.props.station.timezone && this.props.currentStartDate && <div className={ClassNames('fullcalendar-wrapper', {'hidden': this.state.isLoading})}>
                        <FullCalendar
                            initialView={this.props.isWeekly ? 'custom7Days' : 'timeGridDay'}
                            {... (this.props.isWeekly && {
                                views: {
                                    custom7Days: {
                                        type: 'timeGrid', duration: { days: 7 }
                                    }
                                }
                            })}
                            plugins={[ timeGridPlugin, momentTimezonePlugin ]}
                            headerToolbar={false}
                            initialDate={this.props.currentStartDate}
                            timeZone={this.props.station.timezone}
                            events={this.state.events}
                            slotLabelInterval="00:30"
                            slotMinTime={gridStartTime}
                            slotMaxTime={gridMaxTime}
                            allDaySlot={false}
                            progressiveEventRendering={true}
                            eventClassNames={['event-container']}
                            eventContent={this.eventContentRender}
                            slotLabelContent={<span className="empty-slot"></span>}
                            dayHeaders={false}
                            eventClick={this.eventClick}
                            viewDidMount={this.viewDidMount}
                            // Unfortunately height = parent isn't available anymore so need to manually set a height here
                            height={3800}
                            loading={this.state.isLoading}
                        />
                    </div>}
                    {!this.state.isLoading && this.state.events?.length === 0 && <div className="no-schedule-data">There is no schedule data available.</div>}
                    <GNModal show={this.state.showProgramModal} onHide={this.hideProgramModal} className="schedule-program-modal">
                        <Modal.Body className={`${this.state.programSelected.progColor}-border`}>
                            <div className="image-section">
                                <GNImage className={ClassNames('program-image', (!this.state.programSelected.progImageURL || this.state.programSelected.progImageURL.length === 0) && 'schedule-calendar-blank-image')} url={this.state.programSelected.progImageURL} text={this.state.programSelected.progTitleAlt} />
                            </div>
                            <div className="info-section">
                                {this.state.programSelected?.subtype && <div className={`prog-subtype ${this.state.programSelected.progColor}-label`}>{this.state.programSelected.subtype}</div>}
                                {this.state.programSelected?.progTitle && <div className="prog-title">{this.state.programSelected.progTitle}</div>}
                                {this.state.programSelected?.episode_info?.season && <span className="season-info">Season {this.state.programSelected.episode_info.season} Episode {this.state.programSelected.episode_info.number}</span>}
                                {this.getAlternateEpisode() && <div className="alternate-number">{this.props.station.call_sign}: Season {this.getAlternateEpisode().season} Episode {this.getAlternateEpisode().episode}</div>}
                                {this.state.programSelected?.episode_info?.syn_num && <div className="episode-number">Industry Episode Number: {this.state.programSelected.episode_info.syn_num}</div>}
                                {this.state.programSelected?.startTimeFormatted && this.state.programSelected?.endTimeFormatted && <div className="time-info">{this.state.programSelected.startTimeFormatted} - {this.state.programSelected.endTimeFormatted}</div>}
                                {this.state.programSelected?.tmsid && <div className="tmsid">{this.state.programSelected.tmsid}</div>}
                                { this.state.programSelected?.progInfo?.rating && <span className="section">{this.state.programSelected.progInfo.rating}<span className={'section-spacer'}>&#8226;</span></span> }
                                { this.state.programSelected?.duration && <span className="section">{this.state.programSelected.duration} mins</span> }
                                { this.state.programSelected?.progReleaseYear && <span className="section"><span className={'section-spacer'}>&#8226;</span>{this.state.programSelected.progReleaseYear}</span> }
                                { this.state.programSelected?.progInfo?.quals && this.renderProgQuals(this.state.programSelected.progInfo.quals)}
                                { this.state.programSelected?.progInfo?.rating && <div className="section"><strong>Syndication Source: </strong>{this.state.programSelected.progInfo.syndicationSource}</div>}
                                { this.state.programSelected?.progInfo?.rating && <div className="section"><strong>Syndication Type: </strong>{this.state.programSelected.progInfo.syndicationType}</div>}
                                {this.state.programSelected?.update_date && (
                                    <div className='section updated-time'>
                                        <strong className='updated-time__label'>{SCHEDULE_LAST_UPDATED}: </strong>
                                        <span className='updated-time__text'>
                                            {moment(this.state.programSelected.update_date)
                                                .tz(moment.tz.guess(true))
                                                .format('MM/DD/YYYY h:mma')}{' '}
                                            ({moment().tz(moment.tz.guess(true)).zoneAbbr()})
                                        </span>
                                    </div>
                                )}
                                { this.state.programSelected?.genres && <div className="section"><strong>Genres: </strong>{this.state.programSelected.genres.join(', ')}</div>}
                                { this.state.programSelected?.progDescription && <div className="section"><strong>Description: </strong>{this.state.programSelected.progDescription}</div>}
                                { this.state.programSelected?.gn_progtype === PROGRAM_GNPROGTYPES.EPISODE && this.state.programSelected?.entitled && <div className="button-section">
                                    <Link target="_blank" rel="noreferrer" to={`${ROUTES.PROGRAM_DETAILS}/${this.state.programSelected.series_info.tmsid}`}><Button>View Series Details</Button></Link>
                                    <Link target="_blank" rel="noreferrer" to={`${ROUTES.PROGRAM_DETAILS}/${this.state.programSelected.tmsid}`}><Button>View Episode Details</Button></Link>
                                </div>}
                                { this.state.programSelected?.gn_progtype !== PROGRAM_GNPROGTYPES.EPISODE && this.state.programSelected?.entitled && <div className="button-section">
                                    <Link target="_blank" rel="noreferrer" to={`${ROUTES.PROGRAM_DETAILS}/${this.state.programSelected.tmsid}`}><Button>View Program Details</Button></Link>
                                </div>}
                            </div>
                        </Modal.Body>
                    </GNModal>
                    {this.state.isLoading && <LoadingSpinner />}
                </div>
            </ErrorBoundary>
        )
    }
}

ScheduleCalendar.propTypes = {
    currentStartDate: PropTypes.string.isRequired,
    defaultGridStart: PropTypes.string,
    gnviewGetProgramDetails: PropTypes.func.isRequired,
    gnviewGetSchedule: PropTypes.func.isRequired,
    handleShowLoadingIndicator: PropTypes.func.isRequired,
    isWeekly: PropTypes.bool.isRequired,
    isStationSubscribed: PropTypes.bool.isRequired,
    station: PropTypes.object.isRequired,
    setAlerts: PropTypes.func,
    utcEndDate: PropTypes.string.isRequired,
    utcStartDate: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
    return {
        defaultGridStart: gnviewSelUserSettings(state)?.schedule_grid_start_type
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewGetSchedule,
        gnviewGetProgramDetails,
        gnviewGetProgramDetailsUnrestricted
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCalendar);
