import React from 'react';
import "./GetStarted.scss";
import { GET_STARTED_TOP_SECTION_CONTENT, GET_STARTED_CARD_CONTENT } from '../../constants/GetStarted';

export const GetStartedView = () => {
    return (
        <div className='gnview-get-started-content'>
            <div className="gnview-header-title">Get Started</div>
            <div className="container">
                <div className="top-section row">
                    <div className="col-md-6">
                        <div id="get-started-introduction" className="info">
                            <span className='section-icon'><i className={GET_STARTED_TOP_SECTION_CONTENT.INTRODUCTION.icon} /> <h3>{GET_STARTED_TOP_SECTION_CONTENT.INTRODUCTION.title }</h3></span>
                            <img src={ GET_STARTED_TOP_SECTION_CONTENT.INTRODUCTION.image } className="get-started-section-image" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <a id="overview-link" href="http://www.gracenote.com/documentation/gn-view/html/Content/view/Overview.htm" rel="noreferrer" target='_blank'>
                            <div className="info" id="get-started-step-by-step">
                                <span className='section-icon'><i className={GET_STARTED_TOP_SECTION_CONTENT.STEP_BY_STEP.icon} /> <h3>{GET_STARTED_TOP_SECTION_CONTENT.STEP_BY_STEP.title }</h3></span>
                                <img src={ GET_STARTED_TOP_SECTION_CONTENT.STEP_BY_STEP.image } className="get-started-section-image" />
                            </div>
                        </a>
                    </div>
                </div>
                <strong className="focus">Focus on a Section: </strong>
                <div className="bottom-section row">
                    { GET_STARTED_CARD_CONTENT.map((el) => <>
                        <div className="col-md-4 get-started-help-section">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title"><span className='section-icon'><i className={el.icon} /> </span>
                                        <strong>{el.title}</strong></h5>
                                    <ul className="card-text">
                                        { el.watch_links.map((linkTitle, index) => <li key={index}><div className="row"><div className="col watch-text"> { linkTitle } </div><div className="col watch-button"><button id={ el.button_id_prefix + index } className="watch-button">Watch</button></div></div></li>) }
                                    </ul>
                                </div>
                            </div>
                        </div></>)}
                </div>
            </div>
        </div>
    )
}

export default GetStartedView;
