import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GNImage from '../../../components/common/gnImage/GNImage';
import GNCard from '../../../components/common/gnCard/GNCard';
import { gnviewGetProgramAirings } from '../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import GNClientTable from '../../../components/common/gnClientTable/GNClientTable';
import { Button } from 'react-bootstrap';
import {
    PROGRAM_AIRINGS_ADDITIONAL_FIELDS,
    PROGRAM_AIRINGS_OTHER_COLUMN_HEADERS,
    PROGRAM_AIRINGS_SERIES_COLUMN_HEADERS,
    PROGRAM_AIRINGS_SPORTS_COLUMN_HEADERS
} from '../../../constants/ProgramAirings';
import moment from 'moment-timezone';
import { numberWithCommas } from '../../../utils/GeneralUtils';
import { getImageURLFromObj } from '../../../utils/ImageUtils';
import GNListGroup from "../../../components/common/gnListGroup/GNListGroup";
import { PROGRAM_GNPROGTYPES } from '../../../constants/Program';
import "./ProgramAiringsCard.scss";
import { Link } from "react-router-dom";
import { ROUTES } from '../../../config/Routes';
import LoadingSpinner from '../../../components/common/loadingSpinner/LoadingSpinner';
import { getProgramTitle } from '../../../utils/ProgramUtils';
import { gnviewSelUserSettings } from '../../../reducers/GNVAuthReducer';

export class ProgramAiringsCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            programAirings: [],
            programAiringsLoading: true,
            pageIndex: 0,
            pageSize: 20,
            nextDateTime: null,
            nextStationId: null,
            prevDateTimes: [],
            prevStationIds: [],
            totalCount: null,
            currentCount: 20
        };
    }

    fetchData = () => {
        const body = {
            ...this.props.programAiringsPageInfo,
            size: this.state.pageSize,
            after_start_date_time: this.state.nextDateTime,
            after_station_id: this.state.nextStationId
        };
        this.props.gnviewGetProgramAirings(body).then((response) => {
            const totalCount = response.headers?.total_count;
            const nextDateTime = moment.unix(response.headers?.next_after_start_date_time / 1000).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            const nextStationId = response.headers?.next_after_station_id;
            const programAirings = response.result.map((airing) => {
                const timezone = this.props.defaultTimezone || airing?.station?.timezone || 'UTC';
                return {
                    ...airing,
                    airDate: moment.tz(airing.start_date_time, timezone).format('MM/DD/YYYY'),
                    duration: (airing.start_date_time && airing.end_date_time) ? moment.duration(moment(airing.end_date_time).diff(moment(airing.start_date_time))).as('minutes') + ' mins' : '',
                    startTimeFormatted: airing.start_date_time ? moment.tz(airing.start_date_time, timezone).format('h:mma') : '',
                    station: {
                        ...airing?.station,
                        stationOriginalTimezone: airing?.station?.timezone,
                        timezone
                    }
                }
            });
            if (this.state.nextDateTime && this.state.nextStationId && this.state.pageIndex > 0) {
                this.state.prevDateTimes.push(this.state.nextDateTime);
                this.state.prevStationIds.push(this.state.nextStationId);
            }
            this.setState({programAirings, nextDateTime, nextStationId, totalCount, programAiringsLoading: false});
        }).catch((error) => {
            this.props.gnviewSendLogMessage(`gnviewGetProgramAirings error: ${error.message}`, error, body);
            this.setState({ programAiringsLoading: false });
        });
    }

    componentDidMount() {
        this.fetchData();
    }

    getColumnHeaders = () => {
        if ((this.props.program.gn_progtype === PROGRAM_GNPROGTYPES.SERIES) || (this.props.program.gn_progtype === PROGRAM_GNPROGTYPES.EPISODE)) {
            return PROGRAM_AIRINGS_SERIES_COLUMN_HEADERS;
        } else if (this.props.program.gn_progtype === PROGRAM_GNPROGTYPES.SPORTS || this.props.program.gn_progtype === PROGRAM_GNPROGTYPES.SPORTS_SUMMARY) {
            return PROGRAM_AIRINGS_SPORTS_COLUMN_HEADERS;
        } else {
            return PROGRAM_AIRINGS_OTHER_COLUMN_HEADERS;
        }
    };

    previousPage = () => {
        if (this.state.pageIndex <= 1) {
            this.setState((prevState) => ({nextDateTime: null, nextStationId: null, prevDateTimes: [], prevStationIds: [], pageIndex: prevState.pageIndex - 1, currentCount: prevState.currentCount - 20}), () => {
                this.fetchData();
            });
        } else {
            this.setState((prevState) => ({nextDateTime: prevState.prevDateTimes.splice(-2)[0], nextStationId: prevState.prevStationIds.splice(-2)[0], pageIndex: prevState.pageIndex - 1, currentCount: prevState.currentCount - 20}), () => {
                this.fetchData();
            });
        }
    }

    nextPage = () => {
        this.setState((prevState) => ({pageIndex: prevState.pageIndex + 1, currentCount: prevState.currentCount + 20}), () => {
            this.fetchData();
        });
    }

    showPagination = () => {
        if (this.state.programAirings?.length < this.state.pageSize && this.state.pageIndex === 0) {
            return false;
        }
        return true;
    }

    renderTotalCountPagination = () => {
        const startPaginationCount = (this.state.pageIndex * 20) + 1;
        const maxRange = (this.state.pageIndex * 20) + 20;
        const endPaginationCount = maxRange >= this.state.totalCount ? this.state.totalCount : maxRange;
        return `${numberWithCommas(startPaginationCount)}-${numberWithCommas(endPaginationCount)} of ${numberWithCommas(this.state.totalCount)}`;
    }

    render() {
        return (
            <GNCard className="gnview-program-airings-card" title={`${getProgramTitle(this.props.program, false, true)} (${this.props.program.tmsid})`} key={this.props.program.tmsid}>
                <div className="program-info-section">
                    {this.props.program?.progImageURL && <GNImage className="program-image" url={getImageURLFromObj(this.props.program, 'progImageURL')} text={this.props.program.progTitleAlt} />}
                    <div className="sub-section">
                        <GNListGroup fields={PROGRAM_AIRINGS_ADDITIONAL_FIELDS} data={{...this.props.program, ...(this.state.totalCount && {total_count: numberWithCommas(this.state.totalCount)})}} />
                        { this.props.program?.gn_progtype === PROGRAM_GNPROGTYPES.SERIES && <div className="button-section">
                            <Link target="_blank" rel="noreferrer" to={`${ROUTES.PROGRAM_DETAILS}/${this.props.program.series_info.tmsid}`}><Button>View Series Details</Button></Link>
                        </div>}
                        { this.props.program?.gn_progtype !== PROGRAM_GNPROGTYPES.SERIES && <div className="button-section">
                            <Link target="_blank" rel="noreferrer" to={`${ROUTES.PROGRAM_DETAILS}/${this.props.program.tmsid}`}><Button>View Program Details</Button></Link>
                        </div>}
                    </div>
                </div>
                {this.state.programAirings?.length > 0 && <div className="table-container">
                    <GNClientTable columns={this.getColumnHeaders()} data={this.state.programAirings} pagination={false} sorting={false} />
                </div>}
                {this.state.programAiringsLoading && <LoadingSpinner />}
                {!this.state.programAiringsLoading && this.state.programAirings?.length <= 0 && <div className="no-airings">No airings available.</div>}
                {this.showPagination() && <div className="table-pagination-container">
                    <Button variant="light" onClick={() => this.previousPage()} disabled={this.state.pageIndex === 0}>
                        <i className="fas fa-angle-left" />
                    </Button>
                    <Button variant="light" onClick={() => this.nextPage()} disabled={this.state.currentCount >= this.state.totalCount}>
                        <i className="fas fa-angle-right" />
                    </Button>
                    {this.showPagination() && <span className='total-count-pagination'>{this.renderTotalCountPagination()}</span>}
                </div>}
            </GNCard>
        )
    }
}

const mapStateToProps = state => {
    return {
        defaultTimezone: gnviewSelUserSettings(state).timezone
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewGetProgramAirings,
        gnviewSendLogMessage
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramAiringsCard);

ProgramAiringsCard.propTypes = {
    defaultTimezone: PropTypes.string,
    gnviewGetProgramAirings: PropTypes.func.isRequired,
    gnviewSendLogMessage: PropTypes.func,
    program: PropTypes.object.isRequired,
    programAiringsPageInfo: PropTypes.object.isRequired
}