import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Button, FormControl, Modal, Overlay, OverlayTrigger, Tooltip} from 'react-bootstrap';
import ClassNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import "./MySavedItemsButton.scss";
import PropTypes from 'prop-types'
import GNModal from "../gnModal/GNModal";
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {gnviewCreateSavedSearch, gnviewGetAllSavedSearches, gnviewUpdateSavedSearch} from "../../../actions/GNViewActions";
import {gnviewSendLogMessage} from "../../../services/GeneralService";
import SuccessMessageModal from "../successMessageModal/SuccessMessageModal";
import {CREATE_SAVED_SEARCH} from "../../../constants/Schedule";
import isEmpty from 'lodash.isempty';
import {SCHEDULE_SEARCH_FORM_VALIDATIONS} from "../../../constants/ScheduleSearch";
import {MY_SAVED_ITEMS_TYPES, SUCCESS_MODAL_TYPES} from "../../../constants/MySavedItems";
import {PROGRAM_AIRINGS_OPTIONAL_FILTER_TYPES} from "../../../constants/ProgramAirings";
import {FAVORITES_LIST_LABEL, SAVE_PROGRAM_DROPDOWN_VIEW_TYPES} from '../../../constants/Program';
import {gvauthSelEmail} from '../../../reducers/GNVAuthReducer';
import SaveButton from './SaveButton';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import GNInput from '../gnInput/GNInput';
import {sortByTitle} from '../../../utils/GeneralUtils';

export const MySavedItemsButton = ({
    body,
    formattedDate,
    isSaveToProgramList,
    program,
    searchId,
    selectedCountries = [],
    selectedMarkets = [],
    selectedPrograms = [],
    selectedStations = [],
    shouldReloadPage = false,
    validationCallback
}) => {
    const email = useSelector(gvauthSelEmail);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    // SAVE SEARCH
    const queryParams = new URLSearchParams(location.search);
    const [showModal, setShowModal] = useState(false);
    const [successModalShow, setSuccessModalShow] = useState(false);
    const [successModalType, setSuccessModalType] = useState(SUCCESS_MODAL_TYPES.SAVE);
    const [saveSearchTitle, setSaveSearchTitle] = useState(null);
    const [saveSearchErrorMessage, setSaveSearchErrorMessage] = useState(null);

    const handleCreateSavedSearch = () => {
        if (!isEmpty(saveSearchTitle)) {
            dispatch(gnviewCreateSavedSearch({...body, title: saveSearchTitle})).then((response) => {
                queryParams.set('search_id', response.result.insertId)
                history.push(`${location.pathname}?${queryParams}`)
                setSuccessModalType(SUCCESS_MODAL_TYPES.SAVE);
                setSuccessModalShow(true);
                setTimeout(() => {
                    setSuccessModalShow(false);
                    setShowModal(false);
                }, 2000);
                handleReloadPage();
            }, (error) => {
                dispatch(gnviewSendLogMessage(`gnviewCreateSavedSearch error: ${error.message}`, error, body));
            });
        } else {
            setSaveSearchErrorMessage(SCHEDULE_SEARCH_FORM_VALIDATIONS.SAVE_SEARCH_TITLE);
        }
    };

    const handleUpdateSavedSearch = () => {
        if (searchId) {
            if (!isEmpty(saveSearchTitle)) {
                dispatch(gnviewUpdateSavedSearch(searchId, {...body, title: saveSearchTitle})).then(
                    () => {
                        queryParams.set('search_id', searchId)
                        history.push(`${location.pathname}?${queryParams}`)
                        setSuccessModalType(SUCCESS_MODAL_TYPES.UPDATE);
                        setSuccessModalShow(true);
                        setTimeout(() => {
                            setSuccessModalShow(false);
                            setShowModal(false);
                        }, 2000);
                        handleReloadPage();
                    }, (error) => {
                        dispatch(gnviewSendLogMessage(`gnviewUpdateSavedSearch error: ${error.message}`, error, {
                            searchId,
                            body
                        }));
                    }
                );
            } else {
                setSaveSearchErrorMessage(SCHEDULE_SEARCH_FORM_VALIDATIONS.SAVE_SEARCH_TITLE);
            }
        }
    };

    const handleSaveSearchTitle = e => {
        const newTitle = e.target.value;
        setSaveSearchTitle(newTitle);
        if (!isEmpty(newTitle)) {
            setSaveSearchErrorMessage(null);
        } else {
            setSaveSearchErrorMessage(SCHEDULE_SEARCH_FORM_VALIDATIONS.SAVE_SEARCH_TITLE);
        }
    };

    const handleReloadPage = () => {
        if (shouldReloadPage) {
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    };

    // SAVE TO PROGRAM LIST
    const [showProgramListDropdown, setShowProgramListDropdown] = useState(false);
    const [savedProgramLists, setSavedProgramLists] = useState([]);
    const [saveDropdownViewType, setSaveDropdownViewType] = useState(SAVE_PROGRAM_DROPDOWN_VIEW_TYPES.SAVED_TO_LISTS);
    const [newListString, setNewListString] = useState('');
    const [newListStringTouched, setNewListStringTouched] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [savingToListIsLoading, setSavingToListIsLoading] = useState(false);
    const [currentListId, setCurrentListId] = useState(null);
    const target = useRef(null);

    const createProgramListBodyParams = useCallback((title, tmsIds = []) => ({
        title,
        search_type: MY_SAVED_ITEMS_TYPES.PROGRAM_LISTS,
        username: email,
        query_obj: { program_ids: tmsIds }
    }), [email]);

    const updateSavedProgramLists = useCallback(() => {
        dispatch(gnviewGetAllSavedSearches()).then((response) => {
            const updatedProgramList = response?.result.filter((savedSearch) => savedSearch.search_type === MY_SAVED_ITEMS_TYPES.PROGRAM_LISTS).sort((a, b) => sortByTitle(a, b));
            const isSavedValue = updatedProgramList.some(list => programExistsInList(list));
            setSavedProgramLists(updatedProgramList);
            setIsSaved(isSavedValue);
            setSavingToListIsLoading(false);
        }).catch((error) => {
            dispatch(gnviewSendLogMessage((`gnviewGetAllSavedSearches error: ${error.message}`, error)));
        })
    }, [dispatch, programExistsInList]);

    const programExistsInList = useCallback((list) => list.query_obj.program_ids.includes(program?.tmsid), [program?.tmsid]);

    const handleSaveProgramDropdownBody = () => {
        const dropdownBody = document.querySelector('.save-prog-dropdown-body');
        dropdownBody.scrollTop = 0;
    };

    const handleSaveButtonClick = () => setShowProgramListDropdown(prev => !prev);

    const isCreateNewListView = () => saveDropdownViewType === SAVE_PROGRAM_DROPDOWN_VIEW_TYPES.CREATE_NEW_LIST;

    const toggleSaveDropdownView = () => {
        // Resetting these fields every time a user clicks cancel or save
        setNewListString('');
        setNewListStringTouched(false);
        const newViewType = saveDropdownViewType === SAVE_PROGRAM_DROPDOWN_VIEW_TYPES.SAVED_TO_LISTS ? SAVE_PROGRAM_DROPDOWN_VIEW_TYPES.CREATE_NEW_LIST : SAVE_PROGRAM_DROPDOWN_VIEW_TYPES.SAVED_TO_LISTS;
        return setSaveDropdownViewType(newViewType);
    };

    const createNewProgramList = () => {
        if (newListString.length > 0) {
            setSavingToListIsLoading(true);
            const newList = createProgramListBodyParams(newListString, [program?.tmsid]);
            dispatch(gnviewCreateSavedSearch(newList)).then(() => {
                updateSavedProgramLists();
                toggleSaveDropdownView();
            }).catch((error) => dispatch(gnviewSendLogMessage((`gnviewcreateSavedSearch error: ${error.message}`, error))));
        } else {
            setNewListStringTouched(true);
        }
    };

    const updateProgramList = (list, isAddToList) => {
        setSavingToListIsLoading(true);
        setCurrentListId(list.id);
        if (isAddToList && program?.tmsid) {
            list.query_obj.program_ids.push(program?.tmsid);
        }
        const updatedProgramListIds = isAddToList ? list.query_obj.program_ids : list.query_obj.program_ids.filter((tmsId) => tmsId !== program?.tmsid)
        const updatedProgramList = createProgramListBodyParams(list.title, updatedProgramListIds);
        dispatch(gnviewUpdateSavedSearch(list.id, updatedProgramList)).then(() => {
            updateSavedProgramLists();
        }).catch((error) => {
            dispatch(gnviewSendLogMessage((`gnviewUpdateSavedSearch error: ${error.message}`, error)))
            setSavingToListIsLoading(false);
        });
    };

    useEffect(() => {
        if (!isSaveToProgramList) {
            if (!isEmpty(body?.title)) {
                setSaveSearchTitle(body?.title)
            }
        } else {
            dispatch(gnviewGetAllSavedSearches()).then((response) => {
                const programLists = response?.result.filter((savedSearch) => savedSearch.search_type === MY_SAVED_ITEMS_TYPES.PROGRAM_LISTS).sort((a, b) => sortByTitle(a, b));
                if (programLists.length === 0) {
                    const favoritesList = createProgramListBodyParams(FAVORITES_LIST_LABEL);
                    dispatch(gnviewCreateSavedSearch(favoritesList)).catch((error) => dispatch(gnviewSendLogMessage((`gnviewcreateSavedSearch error: ${error.message}`, error))));
                    updateSavedProgramLists();
                } else {
                    const isSavedValue = programLists.some(list => programExistsInList(list));
                    setSavedProgramLists(programLists);
                    setIsSaved(isSavedValue);
                }
            });
        }
    }, [
        body?.title,
        createProgramListBodyParams,
        dispatch,
        isSaveToProgramList,
        programExistsInList,
        updateSavedProgramLists
    ]);

    return (
        <React.Fragment>
            {/* SAVE SEARCH */}
            {!isSaveToProgramList && <span className='save-search-main-container'>
                <Button variant="custom" className={ClassNames("gnview-save-button", {'selected': searchId})}
                    onClick={() => {
                        if (!validationCallback()) {
                            setShowModal(true)
                        }
                    }}>
                    <FontAwesomeIcon className="save-icon" icon={searchId ? ['fas', 'bookmark'] : ['far', 'bookmark']}/>
                    {searchId ? 'Saved' : 'Save'}
                </Button>
                <GNModal show={showModal} title={CREATE_SAVED_SEARCH}
                    onHide={() => setShowModal(false)} className='save-search-modal'>
                    <Modal.Body>
                        <div className='header'>Search Name:</div>
                        <FormControl className='search-title' onChange={handleSaveSearchTitle}
                            defaultValue={saveSearchTitle || body?.title || ''}/>
                        {saveSearchErrorMessage &&
                        <div className='title-message'>{saveSearchErrorMessage}</div>}
                        {formattedDate && <div className='date-section'>
                            <div className='header'>Date:</div>
                            <div className='modal-date-container'>
                                <i className="fas fa-calendar-days"/>
                                <div>{formattedDate}</div>
                            </div>
                        </div>}
                        {!isEmpty(selectedPrograms) && <div className='header'>Programs:</div>}
                        {selectedPrograms?.map((prog, key) => (
                            <div className='programs-list' key={key}>{prog?.title} ({prog?.tms_id})</div>))}
                        {body?.query_obj?.search_type === PROGRAM_AIRINGS_OPTIONAL_FILTER_TYPES.COUNTRY && <div>
                            <div className='header'>Countries:</div>
                            {selectedCountries.map((country, key) => (
                                <div className='countries-list' key={key}>{country.name}</div>))}
                            {selectedMarkets.length > 0 && <div className='header'>US Market Area:</div>}
                            {selectedMarkets.map((market, key) => (
                                <div className='markets-list' key={key}>{market.name} ({market.rank})</div>))}
                            <div className='header'>Station Types:</div>
                            <div>{body?.query_obj?.station_types?.join(', ')}</div>
                        </div>}
                        {(body?.query_obj?.search_type === PROGRAM_AIRINGS_OPTIONAL_FILTER_TYPES.CHANNEL || body?.search_type === MY_SAVED_ITEMS_TYPES.SCHEDULES) && <div>
                            <div className='header'>Channels:</div>
                            {selectedStations?.map((station, key) => (
                                <div className='stations-list' key={key}>{station.name}</div>))}
                        </div>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='light' onClick={() => setShowModal(false)}>Cancel</Button>
                        {!searchId &&
                        <Button variant='primary' onClick={handleCreateSavedSearch}>Save</Button>}
                        {searchId &&
                        <Button variant='light' onClick={handleCreateSavedSearch}>Save as new</Button>}
                        {searchId &&
                        <Button variant='primary' onClick={handleUpdateSavedSearch}>Update</Button>}
                    </Modal.Footer>
                </GNModal>
                {successModalShow && <SuccessMessageModal animation={false} message={`${successModalType} Successful`}/>}
            </span>}
            {/* SAVE TO PROGRAM LIST */}
            {isSaveToProgramList && <span className='save-to-lists-main-container'>
                <OverlayTrigger overlay={<Tooltip>Save Program</Tooltip>} placement="bottom" trigger={['hover', 'focus']}>
                    <div className='save-button-container' id={'gnview-save-program-details-button'} ref={target}><SaveButton handleClick={handleSaveButtonClick} isSelected={isSaved} /></div>
                </OverlayTrigger>
                <Overlay transition={false} rootClose={true} show={showProgramListDropdown} onHide={() => setShowProgramListDropdown(false)} placement='bottom-end' target={target.current}>
                    <div className='save-program-dropdown-container'>
                        <div className='save-prog-dropdown-header'>
                            <div className='header'>{saveDropdownViewType}</div>
                            {!isCreateNewListView() && <Button variant="primary" onClick={toggleSaveDropdownView} className='new-list-button'><i className='fas fa-plus'/>New List</Button>}
                        </div>
                        {!isCreateNewListView() && (
                            <div
                                ref={handleSaveProgramDropdownBody}
                                className='save-prog-dropdown-body'
                            >
                                {savedProgramLists
                                    .map((list, idx) => (
                                        <div className='list-item' key={idx}>
                                            {((!savingToListIsLoading && programExistsInList(list)) || (savingToListIsLoading && list.id !== currentListId && programExistsInList(list))) && <i className='fas fa-circle-check' onClick={() => updateProgramList(list, false)} />}
                                            {((!savingToListIsLoading && !programExistsInList(list)) || (savingToListIsLoading && list.id !== currentListId && !programExistsInList(list))) && <i className='far fa-circle' onClick={() => updateProgramList(list, true)} />}
                                            {savingToListIsLoading && list.id === currentListId && <LoadingSpinner />}
                                            <div className='list-item-title' onClick={() => updateProgramList(list, !programExistsInList(list))}>{list.title}</div>
                                        </div>
                                    ))}
                            </div>)}
                        {isCreateNewListView() && <div className='save-prog-dropdown-body create'>
                            {!savingToListIsLoading && <GNInput
                                className='create-new-list-input'
                                handleChange={(item) => {
                                    setNewListString(item);
                                    setNewListStringTouched(true);
                                }}
                                isRequired={false}
                                placeholder={SAVE_PROGRAM_DROPDOWN_VIEW_TYPES.CREATE_NEW_LIST}
                                // Return true when newListString hasn't been touched to pass the validation
                                validateInput={(item) => newListStringTouched ? item.length > 0 : true}
                                value={newListString} />}
                            {savingToListIsLoading && <LoadingSpinner />}
                            <div className='button-section'>
                                <Button className='cancel-new-list' variant='light' onClick={toggleSaveDropdownView}>Cancel</Button>
                                <Button className='save-new-list' variant='primary' onClick={createNewProgramList}>Save</Button>
                            </div>
                        </div>}
                    </div>
                </Overlay>
            </span>}
        </React.Fragment>
    )
}

MySavedItemsButton.defaultProps = {
    isSaveToProgramList: false,
    selectedCountries: [],
    selectedMarkets: [],
    selectedPrograms: [],
    selectedStations: [],
    shouldReloadPage: false
};

MySavedItemsButton.propTypes = {
    body: PropTypes.object,
    formattedDate: PropTypes.string,
    isSaveToProgramList: PropTypes.bool,
    program: PropTypes.object,
    searchId: PropTypes.string,
    selectedCountries: PropTypes.array,
    selectedMarkets: PropTypes.array,
    selectedPrograms: PropTypes.array,
    selectedStations: PropTypes.array,
    shouldReloadPage: PropTypes.bool,
    validationCallback: PropTypes.func
};

export default MySavedItemsButton;