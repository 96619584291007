import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sportsGetScheduleResults, sportsSetLeagueData, sportsSetMatchData } from '../../../actions/SportsActions';
import { ROUTES } from '../../../config/Routes';
import "./Matches.scss";
import moment from 'moment-timezone';
import LazyLoad from 'react-lazyload';
import { DATES_IN_LOCAL_TIMEZONE, TODAYS_GAMES, NO_GAMES_SCHEDULED, TRY_ADJUSTING_CRITERIA } from '../../../constants/Sports';
import { gnviewSendLogMessage } from "../../../services/GeneralService";
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import { displayTextWithPlaceholder, organizeBySportsAndLeague } from '../../../utils/SportUtils';
import LoadingSpinner from '../../../components/common/loadingSpinner/LoadingSpinner';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { sportsSelLeagueId, sportsSelSportId, sportsSelTeamId, sportsSelUsersTimezone } from '../../../reducers/SportsReducer';
import _isEmpty from 'lodash.isempty';

export const Matches = ({ timeFrom, timeTo }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const timezone = useSelector(sportsSelUsersTimezone);
    const sportDropdownId = useSelector(sportsSelSportId);
    const leagueDropdownId = useSelector(sportsSelLeagueId);
    const teamDropdownId = useSelector(sportsSelTeamId);
    const [isLoading, setIsLoading] = useState(true);
    const [allSports, setAllSports] = useState([]);
    const [allLeagues, setAllLeagues] = useState([]);
    const [matchesBySport, setMatchesBySport] = useState([]);
    const isToday = moment(timeFrom).isSame(moment(), 'day');
    const currentDate = isToday ? TODAYS_GAMES : moment(timeFrom)?.tz(timezone)?.format('dddd MMMM D, YYYY');

    useEffect(() => {
        setIsLoading(true);
        if (_isEmpty(leagueDropdownId)) {
            dispatch(sportsGetScheduleResults(timeFrom, timeTo, sportDropdownId, null, null))
                .then((response) => setMatches(response))
                .catch((error) => dispatch(gnviewSendLogMessage(`sportsGetScheduleResults error: ${error.message}`, error)));
        }

        // League is selected
        if (!_isEmpty(sportDropdownId) && !_isEmpty(leagueDropdownId) && _isEmpty(teamDropdownId)) {
            dispatch(sportsGetScheduleResults(timeFrom, timeTo, null, leagueDropdownId, null))
                .then((response) => setMatches(response))
                .catch((error) => dispatch(gnviewSendLogMessage(`sportsGetLeaguesScheduleResults error: ${error.message}`, error)));
        }

        // Team is selected
        if (!_isEmpty(sportDropdownId) && !_isEmpty(leagueDropdownId) && !_isEmpty(teamDropdownId)) {
            dispatch(sportsGetScheduleResults(timeFrom, timeTo, null, null, teamDropdownId))
                .then((response) => setMatches(response))
                .catch((error) => dispatch(gnviewSendLogMessage(`sportsGetTeamsScheduleResults error: ${error.message}`, error)));
        }
    }, [allSports.length, dispatch, leagueDropdownId, sportDropdownId, teamDropdownId, timeFrom, timeTo]);

    const handleRowClick = (match, league) => {
        dispatch(sportsSetMatchData(match));
        dispatch(sportsSetLeagueData(league));
        history.push(ROUTES.SPORTS_RESULTS);
    };

    const setMatches = (response) => {
        const sports = response?.result?.sports || [];
        const leagues = response?.result?.leagues || [];
        const leagueSeasons = response?.result?.leagueSeasons || [];
        const matches = response?.result?.matches || [];
        const organizedMatches = organizeBySportsAndLeague(matches, sports, leagues, leagueSeasons);
        setAllSports(sports);
        setAllLeagues(leagues);
        setMatchesBySport(organizedMatches);
        setIsLoading(false);
    };

    return (
        <ErrorBoundary>
            <div className="gnview-matches-container">
                {isLoading && <LoadingSpinner />}
                {!isLoading && <div className="gnview-matches-inner">
                    <div className="sport-card-header">
                        <div className="current-date">{currentDate}</div>
                        <div className="dates-in-local">{DATES_IN_LOCAL_TIMEZONE}</div>
                    </div>
                    {Object.keys(matchesBySport)?.length > 0 && Object.keys(matchesBySport).map((sportId) =>
                        <div className="sport-card" key={sportId}><span className="sport-name">{allSports.find((s) => s.id === sportId)?.names?.default}</span>
                            {Object.keys(matchesBySport[sportId]).length > 0 && Object.keys(matchesBySport[sportId])?.map((leagueId) => {
                                const league = allLeagues?.find((l) => l.id === leagueId);
                                return (
                                    <div className="league-section" key={leagueId}>
                                        <div className="league-header">
                                            {league?.images?.logo && <LazyLoad offset={500}><img src={league?.images?.logo} className="league-image" /></LazyLoad>}
                                            {!league?.images?.logo && <span className="placeholder-league-image" />}
                                            <span className="league-name">{league?.names?.default}</span>
                                        </div>
                                        <div className="match-table">
                                            {matchesBySport[sportId][leagueId]?.length > 0 &&
                                                matchesBySport[sportId][leagueId].map((m) => {
                                                    const time = moment(m?.dateAndTimeInfo?.scheduledStartTimeUTC);
                                                    const dateFormatted = time.format('MM/DD/YYYY');
                                                    const timeFormatted = time.format('hh:mma');
                                                    return (
                                                        <div className="match-row" key={m.id} onClick={() => handleRowClick(m, league)}>
                                                            <div className="team-section">
                                                                {m?.awayParticipant?.images?.teamLogo && <LazyLoad offset={500}><img src={m?.awayParticipant?.images?.teamLogo} className="team-image" /></LazyLoad>}
                                                                {!m?.awayParticipant?.images?.teamLogo && <span className="placeholder-team-image" />}
                                                                <span className="team-name">{displayTextWithPlaceholder(m?.awayParticipant?.names?.default)}</span>
                                                            </div>
                                                            <div>at</div>
                                                            <div className="team-section">
                                                                {m?.homeParticipant?.images?.teamLogo && <LazyLoad offset={500}><img src={m?.homeParticipant?.images?.teamLogo} className="team-image" /></LazyLoad>}
                                                                {!m?.homeParticipant?.images?.teamLogo && <span className="placeholder-team-image" />}
                                                                <span className="team-name">{displayTextWithPlaceholder(m?.homeParticipant?.names?.default)}</span>
                                                            </div>
                                                            <div>{displayTextWithPlaceholder(dateFormatted)}</div>
                                                            <div>{displayTextWithPlaceholder(timeFormatted)}</div>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                    {Object.keys(matchesBySport)?.length === 0 &&
                        <div className="sport-card">
                            <div className="empty-sport-card-container">
                                <div className="header">{NO_GAMES_SCHEDULED}</div>
                                <div className="message">{TRY_ADJUSTING_CRITERIA}</div>
                            </div>
                        </div>
                    }
                </div>}
            </div>
        </ErrorBoundary>
    )
}

Matches.propTypes = {
    timeFrom: PropTypes.string.isRequired,
    timeTo: PropTypes.string.isRequired
};

export default Matches;
