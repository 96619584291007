import React from 'react';
import { useSelector } from 'react-redux';
import ProgramAttributes from '../../../components/advancedSearch/ProgramAttributes/ProgramAttributes';
import { ADVANCED_PROGRAM_SEARCH_TYPES } from '../../../constants/AdvancedSearch';
import './AdvancedSearch.scss';
import AdvancedSearchHeader from './AdvancedSearchHeader';
import { gnviewSelAdvancedSearchType } from '../../../reducers/AdvancedSearchReducer';
import IDSearch from '../../../components/advancedSearch/IDSearch/IDSearch';

const AdvancedSearch = () => {
    const searchType = useSelector(gnviewSelAdvancedSearchType);

    return (
        <div className='gnview-advanced-search-content'>
            <div className='gnview-advanced-search-search-view'>
                <AdvancedSearchHeader isResultsView={false} />
                <div className='gnview-advanced-search-main-container'>
                    {searchType === ADVANCED_PROGRAM_SEARCH_TYPES.programAttributes && <ProgramAttributes />}
                    {searchType === ADVANCED_PROGRAM_SEARCH_TYPES.rootId && <IDSearch isRootId={true} />}
                    {searchType === ADVANCED_PROGRAM_SEARCH_TYPES.seriesId && <IDSearch isRootId={false} />}
                </div>
            </div>
        </div>
    );
};

export default AdvancedSearch;
