import PropTypes from 'prop-types'
import React, { Component } from 'react';
import ErrorBoundary from '../../../../components/common/errorBoundary/ErrorBoundary';
import GNCard from '../../../../components/common/gnCard/GNCard';
import { getImgCategories, getImage, sortImagesArray } from '../../../../utils/ImageUtils';
import { getFirstElement, getObjsFilter } from '../../../../utils/GeneralUtils';
import './StationsImagesTab.scss';
import GNRadioGroup from '../../../../components/common/gnRadioGroup/GNRadioGroup';
import GNImagesTabImages from '../../../../components/common/gnImagesTabImages/GNImagesTabImages';
import { gvauthHasMediaCloudEntitlements, gvauthGetMediaCloudURL } from "../../../../reducers/GNVAuthReducer";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

export class StationsImagesTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            allStationImages: [],
            imageCategories: [],
            selectedCategory: null,
            selectedStationImages: []
        }
    }

    componentDidMount() {
        if (this.props.noError()) {
            const allStationImages = (this.props.selectedStation.images.length > 0 && this.props.hasMediaCloudEntitlements) ? sortImagesArray(this.props.selectedStation.images.map(img => getImage(img, this.props.mediaCloudURL))) : [];
            const imageCategories = getImgCategories(allStationImages);
            const selectedCategory = getFirstElement(imageCategories);
            const selectedStationImages = getObjsFilter(allStationImages, 'category', selectedCategory, 'Uncategorized');
            this.setState({ allStationImages, imageCategories, selectedCategory, selectedStationImages, isLoading: false });
        }
    }

    onImageCategorySelect = (category) => {
        const selectedStationImages = getObjsFilter(this.state.allStationImages, 'category', category, 'Uncategorized');
        this.setState({ selectedCategory: category, selectedStationImages });
    };

    render() {
        return (
            <ErrorBoundary>
                <div className="gnview-stations-images-tab-container">
                    {!this.state.isLoading && !this.props.unentitled && <GNCard title='Station Images'>
                        {this.props.noError() && <div className='stations-images-card-content'>
                            <GNRadioGroup
                                label={'Image Type'}
                                list={this.state.imageCategories}
                                onSelect={this.onImageCategorySelect}
                                default={this.state.selectedCategory}/>
                            <GNImagesTabImages selectedImages={this.state.selectedStationImages} backgroundColor={true} />
                        </div>}
                    </GNCard>}
                </div>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        hasMediaCloudEntitlements: gvauthHasMediaCloudEntitlements(state),
        mediaCloudURL: gvauthGetMediaCloudURL(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(StationsImagesTab);

StationsImagesTab.propTypes = {
    hasMediaCloudEntitlements: PropTypes.bool.isRequired,
    mediaCloudURL: PropTypes.string.isRequired,
    noError: PropTypes.func.isRequired,
    selectedStation: PropTypes.object.isRequired,
    unentitled: PropTypes.bool.isRequired
}