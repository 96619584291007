import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion, Card } from 'react-bootstrap';
import "./GNCard.scss";
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class GNCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: false
        };
    }

    toggleCollapsed = () => {
        if (this.props.handleToggle) {
            this.props.handleToggle();
        } else {
            this.setState((prevState) => ({isCollapsed: !prevState.isCollapsed}));
        }
    }

    render() {
        return (
            <Accordion className={ClassNames("gnview-card", {'black': this.props.color === 'black'}, {'blue': this.props.color === 'blue'}, this.props.className)}
                {...(!this.props.handleToggle || !this.props.isCollapsed) && { defaultActiveKey: "0" }}
                {...(this.props.handleToggle && !this.props.isCollapsed) && { activeKey: "0" }}>
                {this.props.title && this.props.headerOutsideOfCard && <div className="outside-card-header">{this.props.title}</div>}
                <Card>
                    {this.props.title && !this.props.headerOutsideOfCard && <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.toggleCollapsed}>
                        {this.props.title}
                        <FontAwesomeIcon className={ClassNames({'collapsed': this.state.isCollapsed || this.props.isCollapsed})} icon="angle-down" />
                        {this.props.subtitle}
                        {this.props.color === 'black' && !this.props.isCollapsed && this.props.children && <div className="separator" />}
                    </Accordion.Toggle>}
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            {this.props.children}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        )
    }
}

GNCard.defaultProps = {
    color: 'blue',
    headerOutsideOfCard: false
};

GNCard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.string,
    handleToggle: PropTypes.func, // For handling collapse toggle outside of GNCard, used with isCollapsed prop
    headerOutsideOfCard: PropTypes.bool,
    isCollapsed: PropTypes.bool,
    subtitle: PropTypes.element,
    title: PropTypes.string
};

const mapStateToProps = () => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GNCard);