import React from 'react';
import { ROUTES } from '../config/Routes';
import { Link } from "react-router-dom";
import { convertCountryCodes, convertLanguageFromCode } from '../utils/GeneralUtils';
import moment from 'moment-timezone';
import { MARKET_RANK_MINIMUM, MARKET_RANK_MAXIMUM } from './Markets';

export const PROGRAM_AIRINGS = 'Program Airings';
export const PROGRAM_AIRINGS_DATE_SELECTION = 'Date Selection';
export const PROGRAM_AIRINGS_DATE_OPTIONS = {
    LAST_WEEK: 'Last week',
    THIS_WEEK: 'This week',
    NEXT_WEEK: 'Next week',
    TODAY: 'Today',
    LAST_QUARTER: 'Last quarter',
    THIS_QUARTER: 'This quarter',
    NEXT_QUARTER: 'Next quarter',
    LAST_MONTH: 'Last month',
    THIS_MONTH: 'This month',
    NEXT_MONTH: 'Next month',
    EXACT_DATE: 'Exact Date'
};

export const PROGRAM_AIRINGS_GNVIEW_LAST_UPDATED = 'GNVIEW Last Updated: ';
export const PROGRAM_AIRINGS_OPTIONAL_FILTERS = 'Filters';
export const PROGRAM_AIRINGS_SEARCH_BY = 'Search by';
export const PROGRAM_AIRINGS_OPTIONAL_FILTER_TYPES = {COUNTRY: 'Country', CHANNEL: 'Channel'};

export const PROGRAM_AIRINGS_PROGRAM_MAXIMUM = 30;

export const PROGRAM_AIRINGS_FORM_VALIDATIONS = {
    PROGRAM_MINIMUM: 'Please select at least one program.',
    PROGRAM_MAXIMUM: `A maximum of ${PROGRAM_AIRINGS_PROGRAM_MAXIMUM} programs is allowed per Report.`,
    PROGRAM_INVALID_TMSID: ' is not a valid TMS ID.',
    PROGRAM_UNAUTHORIZED: 'You do not have access to view this program: ',
    COUNTRY_MINIMUM: 'Please select at least one country.',
    STATION_MINIMUM: 'Please select at least one station.',
    SAVED_SEARCH_UNAUTHORIZED: 'Sorry, you do not own this Saved Search',
    NO_END_DATE_SELECTED: 'Please select an end date.',
    PROGRAM_DUPLICATE: 'This program is already selected - Please use a different TMS ID.',
    INVALID_STATION_ID: 'Invalid station ID.',
    DUPLICATE_STATION_ID: 'This station is already selected - Please use a different station ID.'
};

export const PROGRAM_AIRINGS_MARKET_RANGE_VALIDATIONS = {
    START_AND_END_INVALID: `Please enter a valid start and end market range. Valid market ranks are between ${MARKET_RANK_MINIMUM} and ${MARKET_RANK_MAXIMUM}.`,
    START_INVALID: `Please enter a valid start market range. Valid market ranks are between ${MARKET_RANK_MINIMUM} and ${MARKET_RANK_MAXIMUM}.`,
    END_INVALID: `Please enter a valid end market range. Valid market ranks are between ${MARKET_RANK_MINIMUM} and ${MARKET_RANK_MAXIMUM}.`
};

export const CREATE_SAVED_SEARCH = 'Save Item';

export const PROGRAM_AIRINGS_ADDITIONAL_FIELDS = [
    { prop: 'original_air_date', label: 'Original Air Date'},
    { prop: 'total_count', label: 'Total Count'}
];

/* eslint-disable react/prop-types */
export const PROGRAM_AIRINGS_OTHER_COLUMN_HEADERS = [
    { accessor: 'airDate', Header: 'Air Date'},
    { accessor: 'startTimeFormatted', Header: 'Start Time'},
    { accessor: '', Header: 'Displayed Timezone', Cell: ({row}) => {
        return row.values?.station?.timezone;
    }},
    { accessor: '', Header: 'Station Original Timezone', Cell: ({row}) => {
        return row.values?.station?.stationOriginalTimezone;
    }},
    { accessor: 'duration', Header: 'Duration'},
    { id: 'descriptionLanguage', Header: 'Description Language', Cell: ({row}) => {
        if (row.original?.program?.description_language) {
            return convertLanguageFromCode(row.original.program.description_language);
        }
        return '';
    }},
    { accessor: 'station', Header: 'Station Name', Cell: ({row}) => {
        if (row.values?.station?.name && row.values?.station?.id) {
            const url = `${ROUTES.STATION_DETAILS}/${row.values.station.id}`;
            return <Link target="_blank" rel="noreferrer" to={url}>{row.values.station.name}</Link>;
        }
        return '';
    }},
    { accessor: 'stationCountries', Header: 'Station Countries', Cell: ({row}) => {
        if (row.values?.station?.country_codes) {
            const countryNameAndCodes = convertCountryCodes(row.values.station.country_codes);
            return countryNameAndCodes.map((cty) => cty.name).join(', ');
        }
        return '';
    }},
    { accessor: 'market', Header: 'Market Name', Cell: ({row}) => {
        if (row.values?.market?.name && row.values?.market?.id) {
            const url = `${ROUTES.MARKET_DETAILS}/${row.values.market.id}`;
            return <Link target="_blank" rel="noreferrer" to={url}>{row.values.market.name}</Link>;
        }
        return '';
    }}
];

export const PROGRAM_AIRINGS_SERIES_COLUMN_HEADERS = [
    { id: 'tmsId', Header: 'TMS ID', Cell: ({row}) => {
        if (row.original?.program?.tms_id) {
            const url = `${ROUTES.PROGRAM_DETAILS}/${row.original.program.tms_id}`;
            return <Link target="_blank" rel="noreferrer" to={url}>{row.original.program.tms_id}</Link>;
        }
        return ''
    }},
    { id: 'episodeTitle', Header: 'Episode Title', Cell: ({row}) => {
        return row.original?.program?.episode_info?.title || '';
    }},
    { id: 'seasonNum', Header: 'Season #', Cell: ({row}) => {
        return row.original?.program?.episode_info?.season || '';
    }},
    { id: 'episodeNum', Header: 'Episode #', Cell: ({row}) => {
        return row.original?.program?.episode_info?.number || '';
    }},
    { accessor: 'airDate', Header: 'Air Date'},
    { accessor: 'startTimeFormatted', Header: 'Start Time'},
    { accessor: '', Header: 'Displayed Timezone', Cell: ({row}) => {
        return row.values?.station?.timezone;
    }},
    { accessor: '', Header: 'Station Original Timezone', Cell: ({row}) => {
        return row.values?.station?.stationOriginalTimezone;
    }},
    { accessor: 'duration', Header: 'Duration'},
    { id: 'descriptionLanguage', Header: 'Description Language', Cell: ({row}) => {
        if (row.original?.program?.description_language) {
            return convertLanguageFromCode(row.original.program.description_language);
        }
        return '';
    }},
    { id: 'indEpisodeNum', Header: 'Industry Episode #', Cell: ({row}) => {
        return row.original?.program?.episode_info?.industry_episode || '';
    }},
    { id: 'altIndEpisodeNum', Header: 'Alternate Industry Episode #', Cell: ({row}) => {
        return row.original?.program?.episode_info?.alternate_industry_episode || '';
    }},
    { accessor: 'station', Header: 'Station Name', Cell: ({row}) => {
        if (row.values?.station?.name && row.values?.station?.id) {
            const url = `${ROUTES.STATION_DETAILS}/${row.values.station.id}`;
            return <Link target="_blank" rel="noreferrer" to={url}>{row.values.station.name}</Link>;
        }
        return '';
    }},
    { accessor: 'stationCountries', Header: 'Station Countries', Cell: ({row}) => {
        if (row.values?.station?.country_codes) {
            const countryNameAndCodes = convertCountryCodes(row.values.station.country_codes);
            return countryNameAndCodes.map((cty) => cty.name).join(', ');
        }
        return '';
    }},
    { accessor: 'market', Header: 'Market Name', Cell: ({row}) => {
        if (row.values?.market?.name && row.values?.market?.id) {
            const url = `${ROUTES.MARKET_DETAILS}/${row.values.market.id}`;
            return <Link target="_blank" rel="noreferrer" to={url}>{row.values.market.name}</Link>;
        }
        return '';
    }}
];

export const PROGRAM_AIRINGS_SPORTS_COLUMN_HEADERS = [
    { id: 'tmsId', Header: 'TMS ID', Cell: ({row}) => {
        if (row.original?.program?.tms_id) {
            const url = `${ROUTES.PROGRAM_DETAILS}/${row.original.program.tms_id}`;
            return <Link target="_blank" rel="noreferrer" to={url}>{row.original.program.tms_id}</Link>;
        }
        return '';
    }},
    { id: 'episodeTitle', Header: 'Episode Title', Cell: ({row}) => {
        return row.original?.program?.episode_info?.title || '';
    }},
    { id: 'season', Header: 'Season', Cell: ({row}) => {
        return row.original?.program?.sports_info?.sports_season || '';
    }},
    { id: 'gameDate', Header: 'Game Date', Cell: ({row}) => {
        const gameDate = row.original?.program?.sports_info?.game_date;
        return gameDate ? moment.tz(gameDate, 'UTC').format('MM/DD/YYYY') : '';
    }},
    { accessor: 'airDate', Header: 'Air Date'},
    { accessor: 'startTimeFormatted', Header: 'Start Time'},
    { accessor: '', Header: 'Displayed Timezone', Cell: ({row}) => {
        return row.values?.station?.timezone;
    }},
    { accessor: '', Header: 'Station Original Timezone', Cell: ({row}) => {
        return row.values?.station?.stationOriginalTimezone;
    }},
    { accessor: 'duration', Header: 'Duration'},
    { id: 'descriptionLanguage', Header: 'Description Language', Cell: ({row}) => {
        if (row.original?.program?.description_language) {
            return convertLanguageFromCode(row.original.program.description_language);
        }
        return '';
    }},
    { accessor: 'station', Header: 'Station Name', Cell: ({row}) => {
        if (row.values?.station?.name && row.values?.station?.id) {
            const url = `${ROUTES.STATION_DETAILS}/${row.values.station.id}`;
            return <Link target="_blank" rel="noreferrer" to={url}>{row.values.station.name}</Link>;
        }
        return '';
    }},
    { accessor: 'stationCountries', Header: 'Station Countries', Cell: ({row}) => {
        if (row.values?.station?.country_codes) {
            const countryNameAndCodes = convertCountryCodes(row.values.station.country_codes);
            return countryNameAndCodes.map((cty) => cty.name).join(', ');
        }
        return '';
    }},
    { accessor: 'market', Header: 'Market Name', Cell: ({row}) => {
        if (row.values?.market?.name && row.values?.market?.id) {
            const url = `${ROUTES.MARKET_DETAILS}/${row.values.market.id}`;
            return <Link target="_blank" rel="noreferrer" to={url}>{row.values.market.name}</Link>;
        }
        return '';
    }}
];
/* eslint-enable react/prop-types */