import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { gnviewSelProgramDetails } from '../../../../reducers/ProgramDetailsReducer';
import './RelatedProgramsTab.scss';
import GNRadioGroup from '../../../../components/common/gnRadioGroup/GNRadioGroup';
import { RELATED_PROGRAMS_TAB_NAMES, RELATED_PROGRAMS_TABLE_COLUMN_HEADERS } from '../../../../constants/RelatedPrograms';
import { getRelatedProgramsSubTabs, formatRelatedPrograms } from '../../../../utils/ProgramUtils';
import GNServerTable from '../../../../components/common/gnServerTable/GNServerTable';
import { gnviewGetProgramDetailsMultiple } from '../../../../actions/GNViewActions';
import { CLIENT_TABLE_PAGE_SIZE } from '../../../../constants/App';
import GNCard from '../../../../components/common/gnCard/GNCard';

export const RelatedProgramsTab = () => {
    const programDetails = useSelector(gnviewSelProgramDetails);
    const [subTabSelected, setSubTabSelected] = useState();
    const [tabDataAccessor, setTabDataAccessor] = useState();
    const [relatedPrograms, setRelatedPrograms] = useState();

    useEffect(() => {
        if (programDetails) {
            setRelatedPrograms(formatRelatedPrograms(programDetails));
        }
    }, [programDetails]);

    useEffect(() => {
        if (relatedPrograms && Object.keys(relatedPrograms).length) {
            const firstCategory = Object.entries(relatedPrograms).find(type => type[1].length > 0);
            setSubTabSelected(RELATED_PROGRAMS_TAB_NAMES[firstCategory[0]]);
            setTabDataAccessor(Object.keys(RELATED_PROGRAMS_TAB_NAMES).find(key => RELATED_PROGRAMS_TAB_NAMES[key] === RELATED_PROGRAMS_TAB_NAMES[firstCategory[0]]));
        }
    }, [relatedPrograms]);

    const onSubTabSelect = (tab) => {
        setSubTabSelected(tab);
        setTabDataAccessor(Object.keys(RELATED_PROGRAMS_TAB_NAMES).find(key => RELATED_PROGRAMS_TAB_NAMES[key] === tab));
    };

    const fetchData = useCallback((pageIndex) => {
        if (tabDataAccessor && relatedPrograms && relatedPrograms[tabDataAccessor]?.length) {
            const selectedTabData = relatedPrograms[tabDataAccessor];
            const startIndex = pageIndex * CLIENT_TABLE_PAGE_SIZE;
            const endIndex = startIndex + CLIENT_TABLE_PAGE_SIZE
            const tmsIds = selectedTabData.slice(startIndex, endIndex).map((d) => d.tms_id);
            const totalCount = selectedTabData.length;
            return gnviewGetProgramDetailsMultiple(tmsIds, totalCount);
        }
        return [];
    }, [relatedPrograms, tabDataAccessor]);

    return (
        <div className='related-programs-tab-content'>
            {relatedPrograms && subTabSelected && <div>
                <GNRadioGroup style="flat" list={getRelatedProgramsSubTabs(relatedPrograms)} onSelect={onSubTabSelect} default={subTabSelected} />
                <GNCard>
                    <GNServerTable
                        className='related-programs-tab-table'
                        columns={RELATED_PROGRAMS_TABLE_COLUMN_HEADERS}
                        fetchData={fetchData}
                    />
                </GNCard>
            </div>}
        </div>
    );
}

export default RelatedProgramsTab;
