import React from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

export const StationDropdown = (props) => {
    return (
        <div className={'filter-bar-col gnview-station-dropdown'}>
            <div className={'filter-label'}>{props.label}</div>
            <Dropdown>
                {props.stations?.length > 0 && props.stations.map((station, index) => {
                    if (station.show) {
                        return (
                            <Dropdown.Toggle variant="light" key={index}>
                                {station.name} <i className={'fa fa-caret-down'} />
                            </Dropdown.Toggle>
                        );
                    } else {
                        return null;
                    }
                })}
                <Dropdown.Menu>
                    {props.stations?.length > 0 && props.stations.map((station, i) => (
                        <Dropdown.Item
                            active={ station.show }
                            onClick={ () => props.handleStationChange(station) }
                            key={i}>{station.name} ({station.call_sign})
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

StationDropdown.propTypes = {
    label: PropTypes.string.isRequired,
    stations: PropTypes.array.isRequired,
    handleStationChange: PropTypes.func.isRequired
};

export default StationDropdown;