import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Fragment, useRef, useState } from 'react';
import { Badge, Button, Overlay, Popover } from 'react-bootstrap';

export const AlertsSummaryButton = ({alerts, alertsCount}) => {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleClick = () => {
        setShow(!show);
        setTarget(ref.current);
    };
    return (
        <div ref={ref}>
            <Button className='alerts-count-button' onClick={handleClick}>
                <FontAwesomeIcon icon='exclamation-circle' />
                <span className='count'>{alertsCount}</span>
            </Button>
            <Overlay
                show={show}
                target={target}
                placement="right"
                container={ref}
                containerPadding={100}
            >
                {/* Using inline styles as the Popover loads a portal at document level, so using inline styles to customize */}
                <Popover className='alerts-popover' style={{
                    width: '240px',
                    minWidth: 'auto',
                    position: 'relative !important'
                }}>
                    <Popover.Title className='header' style={{
                        display: 'flex',
                        fontSize: '18px',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '12px 20px',
                        color: 'gray',
                        fontFamily: 'roboto',
                        letterSpacing: '0.5px',
                        lineHeight: '27px'
                    }}>
                        <span>Missing Data</span>
                        <FontAwesomeIcon icon="xmark" size="lg" onClick={handleClick} title='Close'
                            style={{
                                cursor: 'pointer'
                            }} />
                    </Popover.Title>
                    <Popover.Content>
                        <ul style={{
                            paddingLeft: '20px',
                            marginBottom: '0px',
                            color: 'gray',
                            fontFamily: 'roboto',
                            letterSpacing: '0.5px',
                            lineHeight: '27px'
                        }}>
                            {Object.keys(alerts).map((key, index) => (
                                <Fragment key={index}>
                                    <li>
                                        <div className='item' style={{
                                            color: '$color-sports-dark-grey',
                                            padding: '5px 10px',
                                            fontSize: '13px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}>
                                            <span className="alert-name" style={{
                                                fontSize: '15px'
                                            }}>{key}</span>
                                            <Badge bg="secondary" className="alert-count" style={{
                                                backgroundColor: '#605a5a',
                                                padding: '5px',
                                                color: 'white',
                                                fontWeight: 'bolder'
                                            }}>
                                                {alerts[key]}
                                            </Badge>
                                        </div>
                                    </li>
                                    {index !== Object.keys(alerts).length - 1 && <div className='divider' style={{
                                        borderTop: '0.5px solid gray',
                                        marginRight: '10%'
                                    }} />}
                                </Fragment>
                            ))}
                        </ul>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </div>
    );
};

AlertsSummaryButton.propTypes = {
    alerts: PropTypes.any,
    alertsCount: PropTypes.number
}