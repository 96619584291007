import moment from 'moment-timezone';
import {PROGRAM_AIRINGS_DATE_OPTIONS} from "../constants/ProgramAirings";
import { BROADCAST_DAY_START } from "../constants/AccountSettings";

class LocalTimeConverter {
    convertToLocalTime = (ISOstr) => {
        if (ISOstr) {
            const date = moment(ISOstr);
            return date.format('MM/DD/YYYY');
        }
        return '';
    }
}
const localTimeConverter = new LocalTimeConverter();
export default localTimeConverter;

// Returns last quarter's dates in format {startDate: 'MMMM D, YYYY', endDate: 'MMMM D, YYYY'}
export const getLastQuarter = () => {
    const startDate = moment().subtract(1, 'quarters').startOf('quarter').format('MMMM D, YYYY');
    const endDate = moment().subtract(1, 'quarters').endOf('quarter').format('MMMM D, YYYY');
    return {startDate, endDate};
};

// Returns this quarter's dates in format {startDate: 'MMMM D, YYYY', endDate: 'MMMM D, YYYY'}
export const getThisQuarter = () => {
    const startDate = moment().startOf('quarter').format('MMMM D, YYYY');
    const endDate = moment().endOf('quarter').format('MMMM D, YYYY');
    return {startDate, endDate};
};

// Returns next quarter's dates in format {startDate: 'MMMM D, YYYY', endDate: 'MMMM D, YYYY'}
export const getNextQuarter = () => {
    const startDate = moment().add(1, 'quarters').startOf('quarter').format('MMMM D, YYYY');
    const endDate = moment().add(1, 'quarters').endOf('quarter').format('MMMM D, YYYY');
    return {startDate, endDate};
};

// Returns last month's dates in format {startDate: 'MMMM D, YYYY', endDate: 'MMMM D, YYYY'}
export const getLastMonth = () => {
    const startDate = moment().subtract(1, 'month').startOf('month').format('MMMM D, YYYY');
    const endDate = moment().subtract(1, 'month').endOf('month').format('MMMM D, YYYY');
    return {startDate, endDate};
};

// Returns this month's dates in format {startDate: 'MMMM D, YYYY', endDate: 'MMMM D, YYYY'}
export const getThisMonth = () => {
    const startDate = moment().startOf('month').format('MMMM D, YYYY');
    const endDate = moment().endOf('month').format('MMMM D, YYYY');
    return {startDate, endDate};
};

// Returns next month's dates in format {startDate: 'MMMM D, YYYY', endDate: 'MMMM D, YYYY'}
export const getNextMonth = () => {
    const startDate = moment().add(1, 'months').startOf('month').format('MMMM D, YYYY');
    const endDate = moment().add(1, 'months').endOf('month').format('MMMM D, YYYY');
    return {startDate, endDate};
};

// Returns last week date in format {startDate: 'MMMM D, YYYY', endDate: 'MMMM D, YYYY'}
export const getLastWeek = () => {
    const startDate = moment().subtract(1, 'weeks').startOf('week').format('MMMM D, YYYY');
    const endDate = moment().subtract(1, 'weeks').endOf('week').format('MMMM D, YYYY');
    return {startDate, endDate};
};

// Returns this week date in format {startDate: 'MMMM D, YYYY', endDate: 'MMMM D, YYYY'}
export const getThisWeek = () => {
    const startDate = moment().startOf('week').format('MMMM D, YYYY');
    const endDate = moment().endOf('week').format('MMMM D, YYYY');
    return {startDate, endDate};
};

// Returns next week date in format {startDate: 'MMMM D, YYYY', endDate: 'MMMM D, YYYY'}
export const getNextWeek = () => {
    const startDate = moment().add(1, 'weeks').startOf('week').format('MMMM D, YYYY');
    const endDate = moment().add(1, 'weeks').endOf('week').format('MMMM D, YYYY');
    return {startDate, endDate};
};

// Returns today's date in format {startDate: 'MMMM D, YYYY', endDate: 'MMMM D, YYYY'}
export const getToday = () => {
    const startDate = moment().format('MMMM D, YYYY');
    const endDate = moment().format('MMMM D, YYYY');
    return {startDate, endDate};
};

// Generate 30 minute slots for calendar time axis for a 24 hour period
export const generateTimeAxis = (gridStartHour = BROADCAST_DAY_START) => {
    const hours = [];
    for (let hour = gridStartHour; hour < (gridStartHour + 24); hour++) {
        const hourAdjust = hour >= 24 ? (hour - 24) : hour;
        hours.push(moment({ hour: hourAdjust }).format('h:mma'));
        hours.push(moment({ hour: hourAdjust, minute: 30 }).format('h:mma'));
    }
    return hours;
};

// Used with onChange event (that's why there's an event - but we're not using it)
// Returns a date obj
// If Relative {date, isRelative, dateType}
// If exact {exactStartDate, exactEndDate, isRelative, dateType}
export const getDateSelectObj = (dateType, event, exactStartDate, exactEndDate) => {
    const isRelative = dateType !== 'Exact Date';
    const dateSelectObj = { isRelative, dateType };
    if (isRelative) {
        const date = (type => {
            switch (type) {
            case PROGRAM_AIRINGS_DATE_OPTIONS.LAST_QUARTER:
                return getLastQuarter();
            case PROGRAM_AIRINGS_DATE_OPTIONS.THIS_QUARTER:
                return getThisQuarter();
            case PROGRAM_AIRINGS_DATE_OPTIONS.NEXT_QUARTER:
                return getNextQuarter();
            case PROGRAM_AIRINGS_DATE_OPTIONS.LAST_MONTH:
                return getLastMonth();
            case PROGRAM_AIRINGS_DATE_OPTIONS.THIS_MONTH:
                return getThisMonth();
            case PROGRAM_AIRINGS_DATE_OPTIONS.NEXT_MONTH:
                return getNextMonth();
            case PROGRAM_AIRINGS_DATE_OPTIONS.LAST_WEEK:
                return getLastWeek();
            case PROGRAM_AIRINGS_DATE_OPTIONS.THIS_WEEK:
                return getThisWeek();
            case PROGRAM_AIRINGS_DATE_OPTIONS.NEXT_WEEK:
                return getNextWeek();
            case PROGRAM_AIRINGS_DATE_OPTIONS.TODAY:
                return getToday();
            default:
                return getThisWeek();
            }
        })(dateType);
        return {
            ...dateSelectObj,
            date
        }
    } else {
        return {
            ...dateSelectObj,
            exactStartDate: exactStartDate || moment().startOf('week'),
            exactEndDate: exactEndDate || moment().endOf('week')
        };
    }
};

// Returns a string formatted MMMM D, YYYY based on whether it is relative and if the date is for 1 day versus a range
export const getFormattedDate = (isRelative, dateType, relativeStartDate, relativeEndDate, exactStartDate, exactEndDate) => {
    if (isRelative && dateType !== 'Today') {
        return relativeStartDate + ' - ' + relativeEndDate;
    } else if (isRelative && dateType === 'Today') {
        return relativeStartDate;
    } else if (exactStartDate && exactEndDate) {
        const formattedExactStartDate = exactStartDate.format('MMMM D, YYYY');
        const formattedExactEndDate = exactEndDate.format('MMMM D, YYYY');
        if (formattedExactStartDate !== formattedExactEndDate) {
            return formattedExactStartDate + ' - ' + formattedExactEndDate;
        } else {
            return formattedExactStartDate;
        }
    }
    return null;
};

export const getPrevDate = (date, isWeekly) => {
    // Cloning date to not mutate original date
    const dateCopy = date.clone();
    return isWeekly ? dateCopy.subtract(1, 'weeks') : dateCopy.subtract(1, 'days');
};

export const getNextDate = (date, isWeekly) => {
    // Cloning date to not mutate original date
    const dateCopy = date.clone();
    return isWeekly ? dateCopy.add(1, 'weeks') : dateCopy.add(1, 'days');
};

/**
 * For Sports Search Page - Given a start date and timezone, returns a week's worth of moment dates (Start date + 6)
 * @param {object} startDate - Moment Object
 * @param {string} timezone - timezone
 * @returns {[object]} - Array of Moment Objects
 */
export const getWeekOfTimeFrames = (startDate, timezone) => {
    if (!startDate || !timezone) {
        return [];
    }
    const date = moment(startDate);
    const timeFrames = [];
    for (let a = 0; a < 7; a++) {
        const day = {
            timeFrom: moment.tz(date, timezone).add(a, 'day').startOf('day').format(),
            timeTo: moment.tz(date, timezone).add(a, 'day').endOf('day').format()
        };
        timeFrames.push(day);
    }
    return timeFrames;
}