import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import "./GNImage.scss";
import { gnviewGetPersonDetailsById } from '../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import { getImageURL } from '../../../utils/ImageUtils';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';

export class GNPersonImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            imageURL: null
        }
    }

    componentDidMount() {
        this.props.gnview_getPersonDetailsById(this.props.personId).then(
            (person) => {
                const imageURL = getImageURL(person.result.images.filter(img => !img.expired_date)[0]?.image);
                this.setState({ imageURL, isLoading: false });
                this.cancelToken = person.cancelToken;
            }, (error) => {
                this.props.gnviewSendLogMessage(`gnview_getPersonDetailsById error: ${error.message}`, error, {personId: this.props.personId});
                this.setState({ isLoading: false });
            });
    }

    componentWillUnmount() {
        if (this.cancelToken) {
            this.cancelToken.cancel();
        }
    }

    render() {
        return (
            <div className="person-image-wrapper">
                { this.state.imageURL && !this.state.isLoading && <LazyLoad overflow={true} once><img className="person-image" src={this.state.imageURL} onClick={this.props.onClick} alt={this.props.text} /></LazyLoad>}
                { !this.state.imageURL && !this.state.isLoading && <div className="blank-image" onClick={this.props.onClick}>{this.props.text}</div>}
            </div>
        )
    }
}

GNPersonImage.propTypes = {
    personId: PropTypes.number,
    text: PropTypes.string,
    onClick: PropTypes.func,
    gnview_getPersonDetailsById: PropTypes.func,
    gnviewSendLogMessage: PropTypes.func
};

const mapStateToProps = () => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnview_getPersonDetailsById: gnviewGetPersonDetailsById,
        gnviewSendLogMessage
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GNPersonImage);
