import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './GNUserSettingsIcon.scss';
import PropTypes from 'prop-types';

export const GNUserSettingsIcon = ({tooltipText}) => {
    const handleClick = () => {
        // TODO: Ideally this event string is in a common repo will fix in https://jira.gracenote.com/browse/VIEW-1769
        const settingsEvent = new Event("gnview-settings-toggle");
        window.dispatchEvent(settingsEvent);
    };

    return (
        <span className="gnview-settings-icon" onClick={handleClick}>
            <OverlayTrigger delay={{ hide: 500 }} overlay={<Tooltip id="settings-icon-tooltip">{tooltipText}</Tooltip>} placement="bottom">
                <FontAwesomeIcon icon="cog"/>
            </OverlayTrigger>
        </span>
    )
};

GNUserSettingsIcon.propTypes = {
    tooltipText: PropTypes.string
};

export default GNUserSettingsIcon;