import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { gnviewSelProgramDetails } from '../../../../reducers/ProgramDetailsReducer';
import GNCard from '../../../../components/common/gnCard/GNCard';
import GNClientTable from '../../../../components/common/gnClientTable/GNClientTable';
import {PROGRAM_RATINGS_COLUMN_HEADERS} from '../../../../constants/Program';

export class RatingsTab extends Component {
    render() {
        return (
            <div>
                <GNCard>
                    {this.props.programDetails?.ratings?.length > 0 && <GNClientTable columns={PROGRAM_RATINGS_COLUMN_HEADERS} data={this.props.programDetails.ratings}/>}
                    {(!this.props.programDetails?.ratings || this.props.programDetails.ratings.length === 0) && <div className="no-ratings-message">No ratings data available</div>}
                </GNCard>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        programDetails: gnviewSelProgramDetails(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RatingsTab);

RatingsTab.propTypes = {
    programDetails: PropTypes.object.isRequired
}