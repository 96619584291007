import React from 'react';
import './GNToggleButton.scss';
import PropTypes from 'prop-types';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

export const GNToggleButton = ({checked, defaultChecked, label, onClick, isDisabled, className, tooltipMsg}) => {
    return (
        <span className={classNames('gn_toggle', className)}>
            <span>
                <FontAwesomeIcon icon="bell" size="lg" className='fa-regular' />
            </span>
            <OverlayTrigger overlay={<Tooltip>{tooltipMsg}</Tooltip>} placement="bottom" trigger={(isDisabled || !checked) ? ['hover'] : []} delay={500}>
                <div className={classNames({ 'disabled': isDisabled })}>
                    <Form.Check
                        disabled={isDisabled}
                        reverse
                        checked={checked}
                        onClick={onClick}
                        type="switch"
                        className='form-switch'
                        id="switch"
                        data-testid="toggle-button"
                        label={label}
                        defaultChecked={defaultChecked}
                        style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                    >
                    </Form.Check>
                </div>
            </OverlayTrigger>
        </span>
    );
}

GNToggleButton.defaultProps = {
    label: null,
    onClick: null,
    checked: false,
    isDisabled: false,
    defaultChecked: false,
    className: null,
    tooltipMsg: null
};

GNToggleButton.propTypes = {
    onClick: PropTypes.func,
    checked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    label: PropTypes.string,
    className: PropTypes.string,
    tooltipMsg: PropTypes.string
};

export default GNToggleButton;