import React from 'react';
import PropTypes from 'prop-types';
import GNImage from '../../../components/common/gnImage/GNImage';
import { Link } from 'react-router-dom';
import { getStationImage } from '../../../utils/StationUtils';
import { getImageURL } from '../../../utils/ImageUtils';
import moment from 'moment-timezone';
import { ROUTES } from '../../../config/Routes';
import './StationHeader.scss';
import GNUserSettingsIcon from '../../../components/common/gnUserSettingsIcon/GNUserSettingsIcon';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const StationHeader = (props) => {
    const imageURL = getImageURL(getStationImage(props.station));

    return (
        <div className='gnview-station-header'>
            {imageURL && <GNImage className={'station-image'} url={imageURL} text={props.station.call_sign} />}
            {!imageURL && <div className='station-name'>{props.station.name}</div>}
            <div className='station-info-section'>
                {props.station?.timezone && (
                    <div className='station-timezone'>
                        <OverlayTrigger
                            delay={{ hide: 500 }}
                            overlay={<Tooltip>This is the timezone the schedule grid is currently in.</Tooltip>}
                            placement='left'
                        >
                            <span className='displayed-timezone'>
                                <span className='displayed-timezone-label'>Displayed Timezone: </span>
                                {props.station.timezone} ({moment().tz(props.station.timezone).zoneAbbr()})
                            </span>
                        </OverlayTrigger>
                        <GNUserSettingsIcon tooltipText='Update the displayed timezone in Settings.' />
                    </div>
                )}
                <OverlayTrigger
                    delay={{ hide: 500 }}
                    overlay={<Tooltip>Timezone the station is in.</Tooltip>}
                    placement='bottom'
                >
                    <div className='sub-section'>
                        <Link target='_blank' rel='noreferrer' to={`${ROUTES.STATION_DETAILS}/${props.station.id}`}>
                            <div className='station-call-sign'>{props.station.call_sign}</div>
                        </Link>
                        <div className='station-original-timezone'>
                            <span className='timezone-label'>Timezone:</span> {props.station.stationOriginalTimezone} (
                            {moment().tz(props.station.stationOriginalTimezone).zoneAbbr()})
                        </div>
                    </div>
                </OverlayTrigger>
            </div>
        </div>
    );
};

StationHeader.propTypes = {
    station: PropTypes.object.isRequired
};

export default StationHeader;
