import moment from 'moment-timezone';
import {
    GNVIEW_SPORTS_SET_LEAGUE_DATA,
    GNVIEW_SPORTS_SET_LEAGUE_ID,
    GNVIEW_SPORTS_SET_MATCH_DATA,
    GNVIEW_SPORTS_SET_SPORT_ID,
    GNVIEW_SPORTS_SET_START_DATE,
    GNVIEW_SPORTS_SET_TEAM_ID
} from '../actions/ActionTypes';

export const SPORTS_STORE = 'sports';

/**
 * Initial State
 */
export const initialState = {
    leagueData: null,
    matchData: null,
    searchValues: {
        sportId: null,
        leagueId: null,
        teamId: null
    },
    startDate: moment().format('YYYY-MM-DD'),
    usersTimezone: moment.tz.guess(true)
};

/**
 * Reducer
 */
export const SportsReducer = (state = initialState, action) => {
    switch (action.type) {
    case GNVIEW_SPORTS_SET_LEAGUE_DATA:
        return { ...state, leagueData: action.payload.leagueData };
    case GNVIEW_SPORTS_SET_LEAGUE_ID:
        return {
            ...state,
            searchValues: {
                ...state.searchValues,
                leagueId: action.payload.leagueId
            }};
    case GNVIEW_SPORTS_SET_MATCH_DATA:
        return { ...state, matchData: action.payload.matchData };
    case GNVIEW_SPORTS_SET_SPORT_ID:
        return {
            ...state,
            searchValues: {
                ...state.searchValues,
                sportId: action.payload.sportId
            }};
    case GNVIEW_SPORTS_SET_START_DATE:
        return { ...state, startDate: action.payload.startDate };
    case GNVIEW_SPORTS_SET_TEAM_ID:
        return {
            ...state,
            searchValues: {
                ...state.searchValues,
                teamId: action.payload.teamId
            }};
    default:
        return state;
    }
};

/**
 * Selectors
 */
export const sportsSelLeagueData = (state) => state[SPORTS_STORE]?.leagueData;
export const sportsSelLeagueId = (state) => state[SPORTS_STORE]?.searchValues.leagueId;
export const sportsSelMatchData = state => state[SPORTS_STORE]?.matchData;
export const sportsSelSportId = state => state[SPORTS_STORE]?.searchValues.sportId;
export const sportsSelStartDate = state => state[SPORTS_STORE]?.startDate;
export const sportsSelUsersTimezone = state => state[SPORTS_STORE]?.usersTimezone;
export const sportsSelTeamId = (state) => state[SPORTS_STORE]?.searchValues.teamId;

export default SportsReducer;