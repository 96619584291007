import React from 'react';
import { ButtonGroup, Button, Form, Table } from 'react-bootstrap';
import ClassNames from 'classnames';
import "./GNClientTable.scss";
import { useTable, useSortBy, usePagination, useBlockLayout } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { CLIENT_TABLE_PAGE_SIZE } from '../../../constants/App';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';

export const GNClientTable = ({
    checkboxes = false,
    className,
    columns,
    data,
    isLoading = false,
    pagination = true,
    rowCheckboxHandler,
    sorting = true,
    numberOfRows,
    customWidth = false
}) => {
    const allColumns = React.useMemo(() => [...columns], [columns]);
    if (checkboxes) {
        allColumns.unshift({
            accessor: 'isSelected',
            /* eslint-disable react/display-name */
            Cell: ({ row }) => (
                <Form.Check
                    // For custom checkboxes you need the prop 'custom', label, and a unique id
                    custom
                    label=""
                    id={`${row.original.className}-${row.original?.uniqId}`}
                    className={row.original.className}
                    type="checkbox"
                    defaultChecked={row.values.isSelected}
                    onChange={(e) => {
                        if (rowCheckboxHandler) {
                            rowCheckboxHandler(e, row)
                        }
                    }}
                />
            )
            /* eslint-enable react/display-name */
        });
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex } } = useTable({ columns: allColumns, data, initialState: {pageSize: numberOfRows ? numberOfRows : CLIENT_TABLE_PAGE_SIZE } }, useSortBy, usePagination, customWidth && useBlockLayout)

    return (
        <div className={'gn-client-table-wrapper'}>
            {!isLoading && <Table className={ClassNames('gn-client-table', className)} responsive {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup, i) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                            {headerGroup.headers.map((column, j) => {
                                if (sorting) {
                                    return (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            {column.isSorted && <span>&nbsp;<FontAwesomeIcon className='sort-icon' icon={column.isSortedDesc ? 'arrow-down' : 'arrow-up'} /></span>}
                                        </th>
                                    );
                                }
                                return column.hideHeader ? null : (
                                    <th {...column.getHeaderProps()} key={j}>
                                        {column.render('Header')}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={i}>
                                {row.cells.map((cell, j) => {
                                    return <td {...cell.getCellProps()} key={j}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>}
            {(pageOptions.length > 1 && pagination && !isLoading) && <div className="pagination-container">
                <ButtonGroup>
                    <Button variant="light" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        <i className="fas fa-angles-left" />
                    </Button>
                    <Button variant="light" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <i className="fas fa-angle-left" />
                    </Button>
                    <Button variant="light" onClick={() => nextPage()} disabled={!canNextPage}>
                        <i className="fas fa-angle-right" />
                    </Button>
                    <Button variant="light" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        <i className="fas fa-angles-right" />
                    </Button>
                </ButtonGroup>
                <span className="page-count"> Page {pageIndex + 1} of {pageOptions.length}</span>
            </div>}
            {isLoading && <LoadingSpinner />}
        </div>
    )
};

GNClientTable.propTypes = {
    checkboxes: PropTypes.bool,
    className: PropTypes.string,
    columns: PropTypes.array,
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    pagination: PropTypes.bool,
    row: PropTypes.object,
    rowCheckboxHandler: PropTypes.func,
    sorting: PropTypes.bool,
    numberOfRows: PropTypes.number,
    customWidth: PropTypes.bool
};

export default GNClientTable;