import apiService from '../services/ApiService';
import { API_ENDPOINTS } from '../config/Api';
import moment from 'moment';

export const updateSubscription = async (subscriptionId, data) => {
    const response = await apiService.makeApiCall(API_ENDPOINTS.SUBSCRIPTIONS + '/' + subscriptionId, data, 'put');
    return response;
}

export const deleteSubscription = async (subscriptionId) => {
    const response = await apiService.makeApiCall(API_ENDPOINTS.SUBSCRIPTIONS + '/' + subscriptionId, {}, 'delete');
    return response;
}

export const gnviewCreateSubscription = (body) => {
    return async () => {
        return await apiService.makeApiCall(`${API_ENDPOINTS.SUBSCRIPTIONS}`, body, 'post');
    }
};

const getTimeFrame = (timeFrame) => {
    switch (timeFrame) {
    case 'next-week':
        return {
            windowStart: moment().add(1, 'weeks').startOf('isoWeek').format("DD-MM-YYYY"),
            windowEnd: moment().add(1, 'weeks').endOf('isoWeek').format("DD-MM-YYYY")
        };
    case 'three-weeks-out':
        return {
            windowStart: moment().add(2, 'weeks').startOf('isoWeek').format("DD-MM-YYYY"),
            windowEnd: moment().add(2, 'weeks').endOf('isoWeek').format("DD-MM-YYYY")
        };
    case 'this-week':
    default:
        return {
            windowStart: moment().startOf('isoWeek').format("DD-MM-YYYY"),
            windowEnd: moment().endOf('isoWeek').format("DD-MM-YYYY")
        };
    }
}

export const gnviewGetAlertsCount = async (email, timeFrame) => {
    if (!email) {
        return 0;
    }
    const { windowStart, windowEnd } = getTimeFrame(timeFrame);
    return await apiService.makeApiCall(`${API_ENDPOINTS.ALERTS_COUNT}?username=${email}&window_start=${windowStart}&window_end=${windowEnd}`, {}, 'get');
}

export const getSubscriptionStations = (subscriptions) => {
    const results = [];
    for (const subscription of subscriptions) {
        const subscriptionData = {
            subscription_id: subscription.subscription_id,
            station_name: subscription.station_name,
            station_id: subscription.station_id,
            station_image: subscription.station_image || '',
            source_of_channel: subscription.source_of_channel,
            distributionPlatforms: subscription.distributionPlatforms,
            labels: subscription.labels
        }
        results.push(subscriptionData);
    }
    return results;
}

export const getSubscriptionStation = async (stationId) => {
    try {
        const response = await apiService.makeApiCall(`${API_ENDPOINTS.GET_STATION}/${stationId}`, {}, 'get');
        return response.result;
    } catch (error) {
        return null;
    }
}
