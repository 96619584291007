import React from 'react';
import lockIcon from '../images/Lock.png';
import { LOOKUP_MAX_HEADER, LOOKUP_MAX_TEXT, LOOKUP_MAX_UPGRADE_TEXT, UPGRADE_EMAIL } from '../constants/LookupMax';
import countReachedIllustration from '../images/countreachedillustration.png';
import "./LookupMax.scss";

export const LookupMaxView = () => {
    return (
        <div className='gnview-lookup-max-main-container'>
            <img src={countReachedIllustration} alt="lookup-max" className="lookup-max-image" />
            <div className="lookup-max-header"><img src={lockIcon} alt="lookup-max" className="lookup-max-header-icon" />{ LOOKUP_MAX_HEADER }</div>
            <div className="lookup-max-text">{ LOOKUP_MAX_TEXT }</div>
            <div className="horizontal-line" />
            <p className="lookup-max-upgrade-text">{ LOOKUP_MAX_UPGRADE_TEXT }</p>
            <a className="lookup-max-upgrade-email" href ={`mailto:${UPGRADE_EMAIL}`}>{UPGRADE_EMAIL}</a>
        </div>
    )
};

export default LookupMaxView;
