import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GNUSMarketTypeahead from '../../../components/common/gnUSMarketTypeahead/GNUSMarketTypeahead';
import { ROUTES } from '../../../config/Routes';
import "./MarketsSearch.scss";
import { MARKET_DETAILS } from '../../../constants/Markets';
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import ReactRouterPropTypes from "react-router-prop-types";

export class MarketsSearchView extends Component {
    constructor(props) {
        super(props);
    }

    handleChangeMarket = (market) => {
        const marketId = (market.length > 0 && market[0].id) ? market[0].id : null;
        if (marketId) {
            const route = `${ROUTES.MARKET_DETAILS}/${marketId}`;
            this.props.history.push(route);
        }
    }

    render() {
        return (
            <ErrorBoundary>
                <div className="gnview-market-content">
                    <div className="filter-bar-container market-container">
                        <div className="gnview-header-title">{MARKET_DETAILS}</div>
                        <div className="market-bar">
                            <GNUSMarketTypeahead
                                multipleMarkets={false}
                                handleChangeMarket={this.handleChangeMarket} />
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        )
    }
}

MarketsSearchView.propTypes = {
    history: ReactRouterPropTypes.history
};

const mapStateToProps = () => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketsSearchView);