import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sportsGetRelatedPrograms } from '../../../actions/SportsActions';
import { gnviewGetProgramOtherVersions } from '../../../actions/GNViewActions';
import "./SportsResults.scss";
import {
    ALL_LANGUAGES_OPTION,
    SPORTS_RESULTS_COLUMN_HEADERS,
    SPORTS_NO_RELATED_PROGRAMS_HEADER,
    SPORTS_NO_RELATED_PROGRAMS_MESSAGE,
    TITLE_LANGUAGE_DROPDOWN_LABEL,
    DESC_LANGUAGE_DROPDOWN_LABEL
} from '../../../constants/Sports';
import { gnviewSendLogMessage } from "../../../services/GeneralService";
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import {
    filterProgramsByLanguage,
    filterRelatedPrograms,
    formatSportsDescriptionLanguageOptions,
    formatSportsTitleLanguageOptions
} from '../../../utils/SportUtils';
import { GNClientTable } from '../../../components/common/gnClientTable/GNClientTable';
import GNDropdown from '../../../components/common/gnDropdown/GNDropdown';
import LoadingSpinner from '../../../components/common/loadingSpinner/LoadingSpinner';
import ResultsHeader from './ResultsHeader';
import { sportsSelMatchData } from '../../../reducers/SportsReducer';
import { gvauthGetEntitledProgramLanguages } from '../../../reducers/GNVAuthReducer';

export const SportsResults = () => {
    const dispatch = useDispatch();
    const matchId = useSelector(sportsSelMatchData).id;
    const entitledLanguages = useSelector(gvauthGetEntitledProgramLanguages);
    const [isLoading, setIsLoading] = useState(true);
    const [titleLanguageOptions, setTitleLanguageOptions] = useState([]);
    const [descriptionLanguageOptions, setDescriptionLanguageOptions] = useState([]);
    const [allPrograms, setAllPrograms] = useState(null);
    const [selectedTitleLanguage, setSelectedTitleLanguage] = useState('');
    const [selectedDescriptionLanguage, setSelectedDescriptionLanguage] = useState('');
    const [languageFilteredPrograms, setLanguageFilteredPrograms] = useState(null);

    useEffect(() => {
        dispatch(sportsGetRelatedPrograms(matchId)).then((response) => {
            if (response.result) {
                const filteredResults = filterRelatedPrograms(response.result.gns?.RelatedPrograms?.relatedProgram);
                const programRequests = filteredResults?.map(p => dispatch(gnviewGetProgramOtherVersions(p.program.id)));
                Promise.all(programRequests).then((programData) => {
                    const formattedProgramData = programData.flatMap(data => data.result);
                    setAllPrograms(formattedProgramData);
                    const filteredTitleLanguages = formatSportsTitleLanguageOptions(entitledLanguages, formattedProgramData);
                    setTitleLanguageOptions(filteredTitleLanguages);
                    const filteredDescriptionLanguages = formatSportsDescriptionLanguageOptions(entitledLanguages, formattedProgramData);
                    setDescriptionLanguageOptions(filteredDescriptionLanguages);
                }).catch((error) => {
                    dispatch(gnviewSendLogMessage(`gnviewGetProgramOtherVersions error: ${error.message}`, error));
                    setAllPrograms([]);
                    setIsLoading(false);
                });
            }
        }).catch((error) => {
            dispatch(gnviewSendLogMessage(`sportsGetRelatedPrograms error: ${error.message}`, error));
            setAllPrograms([]);
            setIsLoading(false);
        });
    }, [dispatch, entitledLanguages, matchId]);

    useEffect(() => {
        if (allPrograms !== null && (selectedDescriptionLanguage.length > 0 || selectedTitleLanguage.length > 0)) {
            const filteredPrograms = filterProgramsByLanguage(selectedDescriptionLanguage, selectedTitleLanguage, allPrograms);
            setLanguageFilteredPrograms(filteredPrograms);
        } else {
            setLanguageFilteredPrograms(allPrograms);
        }
    }, [allPrograms, selectedDescriptionLanguage, selectedTitleLanguage]);

    useEffect(() => {
        if (languageFilteredPrograms !== null) {
            setIsLoading(false);
        }
    }, [languageFilteredPrograms, setIsLoading]);

    return (
        <ErrorBoundary>
            <div className="gnview-sports-results-container">
                <div className="sports-results-inner">
                    <div className="results-header-container">
                        <ResultsHeader />
                        {!isLoading && languageFilteredPrograms && <>
                            <div className="dropdown-container">
                                {titleLanguageOptions.length !== 0 && <GNDropdown
                                    handleChange={(item) => setSelectedTitleLanguage(item)}
                                    handleClear={() => setSelectedTitleLanguage(ALL_LANGUAGES_OPTION.value)}
                                    label={TITLE_LANGUAGE_DROPDOWN_LABEL}
                                    options={titleLanguageOptions}
                                    value={selectedTitleLanguage} />
                                }
                                {descriptionLanguageOptions.length !== 0 && <GNDropdown
                                    handleChange={(item) => setSelectedDescriptionLanguage(item)}
                                    handleClear={() => setSelectedDescriptionLanguage(ALL_LANGUAGES_OPTION.value)}
                                    label={DESC_LANGUAGE_DROPDOWN_LABEL}
                                    options={descriptionLanguageOptions}
                                    value={selectedDescriptionLanguage} />
                                }
                            </div>
                            <span className="results-count">{languageFilteredPrograms.length} Results</span>
                        </>
                        }
                    </div>
                    {!isLoading && <GNClientTable
                        columns={SPORTS_RESULTS_COLUMN_HEADERS}
                        data={languageFilteredPrograms}
                    />}
                    {isLoading && <LoadingSpinner />}
                    {!isLoading && languageFilteredPrograms && languageFilteredPrograms.length === 0 && <div className="empty-sports-results-container">
                        <div className="header">{SPORTS_NO_RELATED_PROGRAMS_HEADER}</div>
                        <div className="message">{SPORTS_NO_RELATED_PROGRAMS_MESSAGE}</div>
                    </div>
                    }
                </div>
            </div>
        </ErrorBoundary>
    )
}

export default SportsResults;
