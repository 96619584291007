import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form } from 'react-bootstrap';
import {
    gnviewSearchStations,
    gnviewGetStation
} from '../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import { ROUTES } from '../../../config/Routes';
import {
    CHANNEL_DETAILS,
    CHANNEL_SEARCH_TYPES,
    CHANNEL_SEARCH_TYPES_LABEL,
    RECENT_SEARCHES_HEADER,
    INPUT_PLACEHOLDER_OPTIONS
} from '../../../constants/Station';
import "./StationsSearch.scss";
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import GNRadioGroup from "../../../components/common/gnRadioGroup/GNRadioGroup";
import GNStationTypeahead from '../../../components/common/gnStationTypeahead/GNStationTypeahead';
import ReactRouterPropTypes from "react-router-prop-types";
import { gnviewSelStationDetailsRecentStations } from '../../../reducers/StationDetailsReducer';
import SavedItem from '../../../components/common/savedItem/SavedItem';
import PropTypes from 'prop-types';

export class StationsSearchView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchType: CHANNEL_SEARCH_TYPES.channelName,
            stationId: '',
            placeholderText: INPUT_PLACEHOLDER_OPTIONS.channelName,
            selectedStations: []
        };
        this.asyncRef = React.createRef();
    }

    handleChangeStation = (station) => {
        const stationId = (station.length > 0 && station[0].id) ? station[0].id : null;
        if (stationId) {
            const route = `${ROUTES.STATION_DETAILS}/${stationId}`;
            this.props.history.push(route);
        }
    }

    // Station Name/Call Sign vs Station ID search type
    onSearchTypeSelect = (type) => {
        const placeholderText = this.handlePlaceholderText(type);
        this.setState({ searchType: type, placeholderText });
        this.setState({ stationId: '' });
    }

    // Station ID input
    handleStationIdChange = (e) => {
        const str = e.target.value;
        this.setState({ stationId: str });
    }

    // Station ID search button
    handleSearchButtonClick = (e) => {
        // Prevent page refresh
        e.preventDefault();
        if (this.state.stationId) {
            const route = `${ROUTES.STATION_DETAILS}/${this.state.stationId}`;
            this.props.history.push(route);
        }
    }

    isCallSignSearch = () => {
        return this.state.searchType === CHANNEL_SEARCH_TYPES.channelCallSign;
    }

    handlePlaceholderText = (searchType) => {
        if (searchType === CHANNEL_SEARCH_TYPES.channelName) {
            return INPUT_PLACEHOLDER_OPTIONS.channelName
        } else if (searchType === CHANNEL_SEARCH_TYPES.channelCallSign) {
            return INPUT_PLACEHOLDER_OPTIONS.channelCallSign
        } else {
            return INPUT_PLACEHOLDER_OPTIONS.channelId;
        }
    }

    render() {
        return (
            <ErrorBoundary>
                <div className="gnview-stations-content">
                    <div className="filter-bar-container stations-container">
                        <div className="gnview-header-title">{CHANNEL_DETAILS}</div>
                        <div className="stations-bar">
                            <GNRadioGroup label={CHANNEL_SEARCH_TYPES_LABEL} list={Object.values(CHANNEL_SEARCH_TYPES)} onSelect={this.onSearchTypeSelect} default={CHANNEL_SEARCH_TYPES.channelName}/>
                            {(this.state.searchType === CHANNEL_SEARCH_TYPES.channelName || this.isCallSignSearch()) && <div className="station-title-section">
                                <GNStationTypeahead
                                    multipleStations={false}
                                    handleChangeStation={this.handleChangeStation}
                                    selectedStations={this.state.selectedStations}
                                    isCallSignSearch={this.isCallSignSearch()}
                                    placeholder={this.state.placeholderText} />
                            </div>}
                            {this.state.searchType === CHANNEL_SEARCH_TYPES.channelId && <div className="station-id-selection">
                                <Form onSubmit={this.handleSearchButtonClick}>
                                    <Form.Row>
                                        <Form.Control value={this.state.stationId} onChange={this.handleStationIdChange} placeholder={this.state.placeholderText}></Form.Control>
                                        <Button variant="primary" className="search-button" onClick={this.handleSearchButtonClick}>Search</Button>
                                    </Form.Row>
                                </Form>
                            </div>}
                        </div>
                    </div>
                    {this.props.recentStations?.length > 0 && <div className='gnview-recent-stations-section'>
                        <div className='recent-searches-header'>{RECENT_SEARCHES_HEADER}</div>
                        <div className='recent-searches-list'>{this.props.recentStations.map((stationId) => <SavedItem id={stationId} key={stationId} isProgramSearch={false} />)}</div>
                    </div>}
                </div>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        recentStations: gnviewSelStationDetailsRecentStations(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewSearchStations,
        gnviewGetStation,
        gnviewSendLogMessage
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(StationsSearchView);

StationsSearchView.propTypes = {
    history: ReactRouterPropTypes.history,
    recentStations: PropTypes.array
}