import React, { useState, useEffect } from 'react';
import { DATA_ALERTS_WINDOWS, NO_SUBSCRIPTIONS } from '../../../constants/DataAlerts';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import "./AlertsTab.scss";
import ClassNames from "classnames";
import PropTypes from "prop-types";
import { Badge } from "react-bootstrap";
import {
    gnviewDataAlertsSetAlertsCounts,
    gnviewGetUserAlerts,
    gnviewGetUserSubscriptions
} from "../../../actions/DataAlertsActions";
import { gnviewGetAlertsCount } from "../../../actions/SubscriptionActions";
import DataAlertsCards from "./DataAlertsCard";
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import moment from 'moment';
import Filters from '../Filters';
import GNNotification from '../../../components/common/gnNotification/GNNotification';
import { gnviewSelDataAlertsAlertsCounts, gnviewSelDataAlertsPrgSvcIDs, gnviewSelDataAlertsSubscriptions } from '../../../reducers/DataAlertsReducer';
import { getImageURL } from '../../../utils/ImageUtils';
import LoadingSpinner from '../../../components/common/loadingSpinner/LoadingSpinner';
import GNButton from '../../../components/common/gnButton/GNButton';
import { Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isEmpty from 'lodash.isempty';
import SubscribeModal from '../../details/stations/Subscribe/SubscribeModal';

export const AlertsTab = ({ email }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const { range } = queryString.parse(search);
    const [isLoading, setIsLoading] = useState(true);
    const [startAndEnd, setStartAndEnd] = useState(null);
    const [selectedWindow, setSelectedWindow] = useState(DATA_ALERTS_WINDOWS[0].week);
    const prgSvcIDs = useSelector(gnviewSelDataAlertsPrgSvcIDs);
    const subscriptions = useSelector(gnviewSelDataAlertsSubscriptions);
    const alertsCounts = useSelector(gnviewSelDataAlertsAlertsCounts);
    const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);
    const [filteredAlerts, setFilteredAlerts] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [stationsDetailsLoading, setStationsDetailsLoading] = useState(false);
    const [stationDetails, setStationDetails] = useState({});
    const [showSubscribeModal, setShowSubscribeChannelModal] = useState(false);
    const [subscriptionsLoading, setSubscriptionsLoading] = useState(true);

    const openSubscribeModal = () => {
        setShowSubscribeChannelModal(true);
    };

    const closeSubscribeModal = () => {
        setShowSubscribeChannelModal(false);
        dispatch(gnviewGetUserSubscriptions(email))
            .catch((err) => {
                gnviewSendLogMessage('Retrieving subscriptions error:', err)
            });
    };

    useEffect(() => {
        if (range === '' || !["this-week", "next-week", "three-weeks-out"].includes(range)) {
            history.push({ search: `range=${DATA_ALERTS_WINDOWS[0].rangeValue}` });
            setSelectedWindow(DATA_ALERTS_WINDOWS[0].week);
            setStartAndEnd({
                start: moment().startOf('isoWeek'),
                end: moment().endOf('isoWeek')
            });
        } else if (range === 'next-week') {
            setSelectedWindow(DATA_ALERTS_WINDOWS[1].week);
            setStartAndEnd({
                start: moment().add(1, 'weeks').startOf('week').startOf('isoWeek'),
                end: moment().add(1, 'weeks').endOf('week').endOf('isoWeek')
            });
        } else if (range === 'three-weeks-out') {
            setSelectedWindow(DATA_ALERTS_WINDOWS[2].week);
            setStartAndEnd({
                start: moment().add(2, 'weeks').startOf('isoWeek'),
                end: moment().add(2, 'weeks').endOf('isoWeek')
            });
        } else if (range === 'this-week') {
            setSelectedWindow(DATA_ALERTS_WINDOWS[0].week);
            setStartAndEnd({
                start: moment().startOf('isoWeek'),
                end: moment().endOf('isoWeek')
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [range]);

    const setWindowOption = (index) => {
        setStationsDetailsLoading(true);
        history.push({ search: `range=${DATA_ALERTS_WINDOWS[index].rangeValue}` })
    };

    useEffect(() => {
        if (email) {
            dispatch(gnviewGetUserSubscriptions(email))
                .catch((err) => {
                    gnviewSendLogMessage('Retrieving subscriptions error:', err)
                }).finally(() => {
                    setSubscriptionsLoading(false)
                })
        }
    }, [email, dispatch]);

    useEffect(() => {
        setStationsDetailsLoading(true);

        if (prgSvcIDs?.length > 0 && startAndEnd !== null) {
            dispatch(gnviewGetUserAlerts(prgSvcIDs, email, moment(startAndEnd.start).format("DD-MM-YYYY"), moment(startAndEnd.end).format("DD-MM-YYYY")))
                .then(() => {
                    setIsLoading(false);
                    setStationsDetailsLoading(false);
                })
                .catch((err) => {
                    gnviewSendLogMessage('Retrieving alerts error:', err);
                    setIsLoading(false);
                    setStationsDetailsLoading(false);
                });
        } else if (startAndEnd !== null && prgSvcIDs?.length === 0) {
            setStationsDetailsLoading(false)
            setIsLoading(false)
        }
    }, [email, prgSvcIDs, startAndEnd, dispatch]);

    useEffect(() => {
        const fetchAlertsCounts = async () => {
            const alertPromises = DATA_ALERTS_WINDOWS.map(alert =>
                gnviewGetAlertsCount(email, alert.rangeValue)
            );

            try {
                const responses = await Promise.all(alertPromises);
                const counts = responses.reduce((acc, response, index) => {
                    const { alert_count: alertCount } = response.result;
                    acc[DATA_ALERTS_WINDOWS[index].rangeValue] = alertCount;
                    return acc;
                }, {});

                dispatch(gnviewDataAlertsSetAlertsCounts(counts));
            } catch (error) {
                console.error('Error fetching alerts counts:', error);
            }
        };

        fetchAlertsCounts();
    }, [email, dispatch, subscriptions]);

    useEffect(() => {
        const stationIds = subscriptions.map(sub => sub.station_id);
        if (!stationIds.length) {
            return;
        }
        setStationsDetailsLoading(true);
        const stationsInfoFromSubscription = {};
        for (const subscription of subscriptions) {
            const stationInfo = {
                name: subscription.station_name,
                id: subscription.station_id,
                imageURL: getImageURL(subscription.station_image) || ''
            }
            stationsInfoFromSubscription[subscription.station_id] = stationInfo;
        }
        setStationDetails(stationsInfoFromSubscription);
        setStationsDetailsLoading(false);
    }, [subscriptions, dispatch]);

    const updateAlertsCounts = (delta) => {
        // setUpdatedAlertsCounts(updatedAlertsCounts + 1);
        const selectedRangeValue = DATA_ALERTS_WINDOWS.find(each => each.week === selectedWindow)?.rangeValue;
        const counts = {...alertsCounts, [selectedRangeValue]: alertsCounts[selectedRangeValue] + delta};
        dispatch(gnviewDataAlertsSetAlertsCounts(counts));
    }

    return (
        <>
            {isLoading && <LoadingSpinner />}
            {!isLoading && <div className='gnview-data-alerts-top-section'>
                <div className='data-alerts-table-container'>
                    <>
                        <div className='filters-container'>
                            <div className='window-tabs-wrapper'>
                                {DATA_ALERTS_WINDOWS.map((option, index) => {
                                    return (
                                        <span
                                            key={index}
                                            className={ClassNames("window-option", {
                                                option_selected: option.week === selectedWindow
                                            })}
                                            onClick={() => setWindowOption(index)}
                                        >
                                            {option.week}
                                            {
                                                <Badge pill variant='primary'>
                                                    {" "}
                                                    {alertsCounts[DATA_ALERTS_WINDOWS[index].rangeValue]}{" "}
                                                </Badge>
                                            }
                                        </span>
                                    );
                                })}
                            </div>
                            <div className='data-alerts-filters-wrapper'>
                                <Filters setFilteredAlerts={setFilteredAlerts} setSelectedSubscriptions={setSelectedSubscriptions} windowStart={startAndEnd.start} windowEnd={startAndEnd.end} />
                            </div>
                        </div>
                    </>
                    {(stationsDetailsLoading || subscriptionsLoading) && <LoadingSpinner />}
                    { !stationsDetailsLoading && !subscriptionsLoading && subscriptions.length === 0 && (<div className='empty-subcriptions-container'>
                        {NO_SUBSCRIPTIONS}
                        <div className='button-info-container'>
                                Use the <GNButton
                                tooltip={
                                    <Tooltip id='request-publish-button-tooltip'>
                                        {"Requires Write Access"}
                                    </Tooltip>
                                }
                                buttonClass='register-button'
                                hasWriteAccess={true}
                                trigger={false}
                                onClick={openSubscribeModal}
                                disabled={subscriptions.length >= 10}
                            >
                                <FontAwesomeIcon icon='bell' />
                                {"Track Channel"}
                            </GNButton> button to subscribe to a channel and start receiving data gap alerts.
                        </div>
                        {showSubscribeModal && (
                            <SubscribeModal trackChannel = {true} show={showSubscribeModal} handleModal={closeSubscribeModal} subscriptions={subscriptions} />
                        )}
                    </div>
                    )}
                    {!stationsDetailsLoading && selectedSubscriptions.length > 0 && !isEmpty(stationDetails)
                        && filteredAlerts
                        && (
                            <div className="cards-container">
                                {selectedSubscriptions.map((subscription, index) => {
                                    const showDropdown = (index === 0);
                                    return (
                                        <>
                                            <DataAlertsCards
                                                key={subscription.station_id}
                                                subscription={subscription}
                                                stationData={stationDetails[subscription.station_id]}
                                                showDropdown={showDropdown}
                                                alerts={filteredAlerts[subscription.station_id] ? filteredAlerts[subscription.station_id] : []}
                                                windowStart={startAndEnd.start}
                                                windowEnd={startAndEnd.end}
                                                errorNotificationMessage={setNotificationMsg}
                                                showErrorNotification={setShowNotification}
                                                updateAlertsCounts={updateAlertsCounts}
                                            />
                                        </>
                                    );
                                })}
                            </div>
                        )
                    }
                </div>
                <GNNotification
                    handleShow={setShowNotification}
                    message={notificationMsg}
                    milliseconds={5000}
                    show={showNotification}
                    success={false}
                />
            </div>}
        </>
    );
};

AlertsTab.propTypes = {
    email: PropTypes.string
};

export default AlertsTab;
