export const APP_TYPES = {
    GNVIEW: 'Gracenote View',
    GNID: 'ID Distribution System'
};

export const MOBIUS_ACCESS_TYPES = {
    WRITE: 'WRITE',
    READ_ONLY: 'READ_ONLY'
};

export const CLIENT_TABLE_PAGE_SIZE = 20;

export const FEATURE_FLAGS = {
    CHANNEL_ALERTS: 'channel-alerts',
    USER_SELECTED_MAPPING: 'user-selected-mapping'
}