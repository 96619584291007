import React from 'react';
import "./GNDatePickerWithYear.scss";
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const GNDatePickerWithYear = (props) => {
    return (
        <div className="gnview-date-picker-with-year">
            <div className={'date-picker-label'}>{props.label}</div>
            <i className="fas fa-calendar-day" />
            <DatePicker
                isClearable={props.isClearable}
                onChange={date => props.handleChange(date)}
                placeholderText={props.placeholder}
                selected={props.value}
            />
            <div className={ClassNames("validation-text", {'invalid': !props?.validateYear()})}>
                <Form.Text className="required-text">{props?.isRequired && '*Required '}{props?.validationText}</Form.Text>
            </div>
        </div>
    );
}

GNDatePickerWithYear.defaultProps = {
    isRequired: false,
    isClearable: true,
    placeholder: ''
};

GNDatePickerWithYear.propTypes = {
    handleChange: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    isClearable: PropTypes.bool,
    placeholder: PropTypes.string,
    validateYear: PropTypes.func.isRequired,
    validationText: PropTypes.string,
    value: PropTypes.object // Date object or null
};

export default GNDatePickerWithYear;