import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AsyncTypeaheadFilter from "../asyncTypeaheadFilter/AsyncTypeaheadFilter";
import { gnviewSearchMarkets } from '../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import { Highlighter } from "react-bootstrap-typeahead";
import TypeaheadSingleToken from '../typeaheadSingleToken/TypeaheadSingleToken';
import { MARKET_AREA_NAME, MARKET_RANK_MINIMUM, MARKET_RANK_MAXIMUM } from '../../../constants/Markets';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import "./GNUSMarketTypeahead.scss";
import ClassNames from 'classnames';

export class GNUSMarketTypeahead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            marketSearchResultOptions: [],
            isSearchMarketsLoading: false,
            marketRangeStart: '',
            marketRangeEnd: ''
        };
        this.asyncRef = React.createRef();
    }

    handleMarketSearch = (searchString) => {
        if (searchString) {
            this.setState({isSearchMarketsLoading: true});
            this.props.gnviewSearchMarkets(searchString).then(
                (response) => {
                    this.setState({
                        marketSearchResultOptions: response.result,
                        isSearchMarketsLoading: false
                    });
                    this.searchMarketsCancelToken = response.cancelToken;
                }, (error) => {
                    this.props.gnviewSendLogMessage(`gnviewSearchMarkets error: ${error.message}`, error, {searchString});
                    this.setState({isSearchMarketsLoading: false});
                }
            );
        }
    }

    renderChildrenForMarket = (item, props, index) => {
        return (
            <div key={index}>
                <Highlighter key="name" search={props.text}>{item.name}</Highlighter>
                <span className="dd-sub-title">(<Highlighter key="callsign" search={props.text}>{item.rank.toString()}</Highlighter>)</span>
            </div>
        );
    }

    handleMarketStartChange = (e) => {
        const str = e.target.value;
        this.setState({ marketRangeStart: str });
    }

    handleMarketEndChange = (e) => {
        const str = e.target.value;
        this.setState({ marketRangeEnd: str });
    }

    render() {
        return (
            <div className={ClassNames("gnview-market-content", {"with-market-range": this.props.showMarketRangeSelection})}>
                {
                    (!this.props.selectedMarkets || this.props.multipleMarkets) && <AsyncTypeaheadFilter
                        filterLabel={MARKET_AREA_NAME}
                        labelKey={option => `${option.name} (${option.rank})`}
                        multiple={this.props.multipleMarkets}
                        renderMenuItemChildren={this.renderChildrenForMarket}
                        options={this.state.marketSearchResultOptions}
                        isLoading={this.state.isSearchMarketsLoading}
                        onSearch={this.handleMarketSearch}
                        placeholder=""
                        onChange={(item) => this.props.handleChangeMarket(item)}
                        {... (this.props.multipleMarkets && {selected: this.props.selectedMarkets})}
                        ref={
                            (async) => {
                                this.asyncRef = async;
                            }
                        }
                    />
                }
                {this.props.selectedMarkets && this.props.clearSelectedMarket && !this.props.multipleMarkets &&
                    <TypeaheadSingleToken label={MARKET_AREA_NAME} onClearToken={this.props.clearSelectedMarket}>
                        {this.props.selectedMarkets.name} <span className="dd-sub-title">({this.props.selectedMarkets.rank})</span>
                    </TypeaheadSingleToken>}
                { this.props.showMarketRangeSelection && this.props.addMarketRange && <Form onSubmit={() => this.props.addMarketRange(this.state.marketRangeStart, this.state.marketRangeEnd)} className="market-range-selection">
                    <div className="filter-label">Market Range:</div>
                    <Form.Row>
                        <Form.Control value={this.state.marketRangeStart} onChange={this.handleMarketStartChange} placeholder={MARKET_RANK_MINIMUM}></Form.Control>
                        <span className="range-label">to</span>
                        <Form.Control value={this.state.marketRangeEnd} onChange={this.handleMarketEndChange} placeholder={MARKET_RANK_MAXIMUM}></Form.Control>
                        <Button variant="primary" className="add-markets-button" onClick={() => this.props.addMarketRange(this.state.marketRangeStart, this.state.marketRangeEnd)}>Add Markets</Button>
                        {this.props.marketRangeErrorMessage && <span className="gnview-error-msg">{this.props.marketRangeErrorMessage}</span>}
                    </Form.Row>
                </Form>}
            </div>
        )
    }
}

GNUSMarketTypeahead.defaultProps = {
    showMarketRangeSelection: false
};

GNUSMarketTypeahead.propTypes = {
    addMarketRange: (props, propName) => {
        if ((props['showMarketRangeSelection'] === true && (props[propName] === undefined || typeof (props[propName]) !== 'function'))) {
            return new Error('Please provide a addMarketRange function!');
        }
        return null;
    },
    clearSelectedMarket: PropTypes.func,
    gnviewSearchMarkets: PropTypes.func,
    gnviewSendLogMessage: PropTypes.func,
    handleChangeMarket: PropTypes.func.isRequired,
    marketRangeErrorMessage: PropTypes.string,
    multipleMarkets: PropTypes.bool.isRequired,
    selectedMarkets: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    showMarketRangeSelection: PropTypes.bool
};

const mapStateToProps = () => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewSearchMarkets,
        gnviewSendLogMessage
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GNUSMarketTypeahead);
