import React from 'react';
import { useSelector } from 'react-redux';
import { getWeekOfTimeFrames } from '../../../utils/DateUtils';
import Matches from './Matches';
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import LazyLoad from 'react-lazyload';
import SearchBar from './SearchBar';
import "./SportsSearch.scss";
import { sportsSelStartDate, sportsSelUsersTimezone } from '../../../reducers/SportsReducer';

export const SportsSearch = () => {
    const startDate = useSelector(sportsSelStartDate);
    const timezone = useSelector(sportsSelUsersTimezone);
    const timeFrames = getWeekOfTimeFrames(startDate, timezone);
    return (
        <ErrorBoundary>
            <div className="gnview-sports-search-container">
                <SearchBar />
                <div className="matches-wrapper">
                    {timeFrames?.length > 0 && timeFrames.map((tf, index) =>
                        <LazyLoad offset={100} overflow once key={index}>
                            <Matches timeFrom={tf.timeFrom} timeTo={tf.timeTo}/>
                        </LazyLoad>
                    )}
                </div>
            </div>
        </ErrorBoundary>
    )
}

export default SportsSearch;
