import {
    GNVIEW_PROGRAM_AIRINGS_STORE_SEARCH,
    GNVIEW_PROGRAM_AIRINGS_CLEAR_SEARCH,
    GNVIEW_PROGRAM_AIRINGS_ADD_PROGRAM
} from '../actions/ActionTypes';

export const PROGRAM_AIRINGS_STORE = 'programAirings';

/**
 * Initial State
 */
export const initialState = {
    queryObj: {
        program_ids: []
    }
};

/**
 * Reducer
 */
export const ProgramAiringsReducer = (state = initialState, action) => {
    switch (action.type) {
    case GNVIEW_PROGRAM_AIRINGS_STORE_SEARCH:
        return { ...state, queryObj: action.payload.queryObj };
    case GNVIEW_PROGRAM_AIRINGS_CLEAR_SEARCH:
        return initialState;
    case GNVIEW_PROGRAM_AIRINGS_ADD_PROGRAM:
        return { ...state, queryObj: { ...state.queryObj, program_ids: [action.payload.programId]} };
    default:
        return state;
    }
};

/**
 * Selectors
 */
export const gnviewSelProgramAiringsQueryObj = state => state[PROGRAM_AIRINGS_STORE]?.queryObj;

export default ProgramAiringsReducer;