import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ClassNames from 'classnames';
import { ROUTES } from '../../../config/Routes';
import {
    EPISODIC_PROGRAM_SUBTYPES,
    PROGRAM_DETAILS,
    PROGRAM_POPULARITY_DEFINITION
} from '../../../constants/Program';
import {
    getProgramTitle,
    getProgramTitleAltText,
    getProgramRating,
    getProgramDescription,
    getProgramImageHorizontal,
    getProgramColor,
    getReleaseYear
} from '../../../utils/ProgramUtils';
import GNImage from '../../../components/common/gnImage/GNImage';
import {getImageURLH} from "../../../utils/ImageUtils";
import { convertLanguageFromCode } from '../../../utils/GeneralUtils';
import PropTypes from "prop-types";
import "./ProgramsDetailsTopSection.scss";
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { gnviewProgramAiringsAddProgram } from '../../../actions/GNViewActions';
import moment from "moment-timezone";
import MySavedItemsButton from '../../../components/common/saveButton/MySavedItemsButton';

export const ProgramsDetailsTopSection = ({program}) => {
    const dispatch = useDispatch();
    const progTitle = getProgramTitle(program);
    const progTitleAlt = getProgramTitleAltText(program);
    const progRating = getProgramRating(program);
    const progDesc = getProgramDescription(program);
    const progImageURL = getImageURLH(getProgramImageHorizontal(program), '?h=500') || '';
    const progColor = getProgramColor(program?.type) || 'light-gray';
    const releaseYear = getReleaseYear(program);
    const descriptionLanguage = convertLanguageFromCode(program?.description_language);
    const defaultTimezone = moment.tz.guess(true);
    const popularityOverlay = (<Popover>
        <Popover.Content>
            <div className='popover-content-main-container'>
                <div className='popover-content-body'>{PROGRAM_POPULARITY_DEFINITION}</div>
            </div>
        </Popover.Content>
    </Popover>);
    const episodeParts = ((program.episode_info?.part_number && program.episode_info?.total_parts) ?
        `(Part ${program.episode_info?.part_number} of ${program.episode_info?.total_parts})` : '');
    const sportEpisodeParts = ((program.sports_info?.part_number && program.sports_info?.total_parts) ?
        `(Part ${program.sports_info?.part_number} of ${program.sports_info?.total_parts})` : '');

    const handleViewProgramAirings = () => {
        if (program?.tmsid) {
            dispatch(gnviewProgramAiringsAddProgram(program?.tmsid));
            // Open program airings in a new tab
            window.open(`${window.location.origin}${ROUTES.PROGRAM_AIRINGS}`, "_blank");
        }
    }

    const seriesId = program?.series_info?.series_id;
    return (
        <div className={`programs-top-section ${progColor}-background`}>
            <div className="gnview-breadcrumbs">
                <Link to={ROUTES.PROGRAM_DETAILS}>{PROGRAM_DETAILS} </Link>
                {program?.series_info?.tmsid && EPISODIC_PROGRAM_SUBTYPES.includes(program?.subtype) && <span>
                    <i className="fas fa-angle-right gnview-breadcrumbs-separator" />
                    <Link target="_self" to={`${ROUTES.PROGRAM_DETAILS}/${program?.series_info.tmsid}`}>{program?.series_info.tmsid} </Link></span>
                }
                <i className="fas fa-angle-right gnview-breadcrumbs-separator" />
                <span>{program?.tmsid}</span>
            </div>
            <div className={`program-container ${progColor}-border`}>
                <div className="left-section">
                    <div className="program-content">
                        <div className='program-subtype-section'>
                            {program?.subtype && <div className={`prog-subtype ${progColor}-label`}>{program?.subtype}</div>}
                            <MySavedItemsButton program={program} isSaveToProgramList={true} />
                        </div>
                        {progTitle && <div className="program-title">{progTitle}</div>}
                        {program?.subtype && <div className="program-subtype-popularity">
                            {program?.popularity_score && <OverlayTrigger trigger={['hover', 'focus']} placement='bottom' overlay={popularityOverlay} delay={500}>
                                <div className="program-popularity-score">
                                    <i className={`fas fa-fire-flame-curved fa-lg pop-icon ${progColor}-label`}/>
                                    <span className='label'>U.S. Video Popularity Score: </span>
                                    <span className='popularity-score'>{program?.popularity_score} </span>
                                </div>
                            </OverlayTrigger>}
                        </div>}
                        {program?.episode_info?.season && <span className="season-info">Season {program?.episode_info.season} Episode {program?.episode_info.number} {episodeParts}</span>}
                        {(!program?.episode_info?.season && episodeParts) && <span className="season-info">{episodeParts}</span>}
                        {sportEpisodeParts && <span className="season-info">{sportEpisodeParts}</span>}
                        <div className="meta-data">
                            { program?.genres?.length > 0 && <span className="program-genres meta-value">{program?.genres.join(', ')}</span>}
                            { progRating && <span className="rating meta-value">{progRating}</span> }
                            { descriptionLanguage && <span className="description-language meta-value">{descriptionLanguage}</span> }
                            { program?.duration && <span className="duration meta-value">{program?.duration} mins</span> }
                            { releaseYear && <span className="release-year meta-value">{releaseYear}</span> }
                        </div>
                        {progDesc && <div className="program-description">{progDesc}</div>}
                    </div>
                    <div className="program-ids">
                        <span className='label'>TMS ID: </span>
                        <span className='tmsid'>{program?.tmsid || ''}</span>
                        <span className={'section-spacer'}>&#8226;</span>
                        <span className='label'>Root ID: </span>
                        <span className='rootid'>{program?.rootid || ''}</span>
                        { seriesId &&
                            <span>
                                <span className={'section-spacer'}>&#8226;</span>
                                <span className='label'>Series ID: </span>
                                <span className='seriesid'>{seriesId}</span>
                            </span>
                        }
                        <div className="bottom-row">
                            <Button variant="primary" onClick={handleViewProgramAirings} className="view-program-airings-button"><i className="fas fa-clipboard-list icon"/>View Program Airings</Button>
                            {program?.update_date && (
                                <span className='update-date'>
                                    Last Updated{' '}
                                    {moment(program.update_date).tz(defaultTimezone).format('M/DD/YYYY, h:mmA')} (
                                    {moment(program.update_date).tz(defaultTimezone).zoneAbbr()})
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <GNImage className={ClassNames('program-image', progImageURL.length === 0 && 'program-details-blank-image')} url={progImageURL} text={progTitleAlt} />
            </div>
        </div>
    )
}

ProgramsDetailsTopSection.propTypes = {
    program: PropTypes.object
};

export default ProgramsDetailsTopSection;