import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './SubscribedChannelsTab.scss';
import { Modal, Overlay } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSubscriptionStations, updateSubscription, deleteSubscription } from '../../../actions/SubscriptionActions';
import { gnviewDataAlertsSetSubscriptions } from '../../../actions/DataAlertsActions';
import GNClientTable from '../../../components/common/gnClientTable/GNClientTable';
import GNModal from '../../../components/common/gnModal/GNModal';
import SubscribeForm from '../../details/stations/Subscribe/SubscribeForm/SubscribeForm';
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import { gvauthSelEmail } from '../../../reducers/GNVAuthReducer';
import {
    SUBSCRIPTION_TABLE_HEADERS,
    SUBSCRIPTION_MODAL_UNSUBSCRIBE_TITLE,
    SUBSCRIPTION_MODAL_UNSUBSCRIBE_MESSAGE,
    SUBSCRIPTION_MODAL_EDIT_TITLE,
    SUBSCRIPTION_MODAL_CANCEL,
    SUBSCRIPTION_MODAL_UNSUBSCRIBE,
    SUBSCRIPTION_MODAL_SAVE,
    SUBSCRIPTION_EDIT_BUTTON,
    SUBSCRIPTION_UNSUBSCRIBE_BUTTON
} from '../../../constants/Subscriptions';
import { SOURCE_TYPES_MAPPING } from '../../../constants/Station';

import { gnviewSelDataAlertsSubscriptions } from '../../../reducers/DataAlertsReducer';
import { gnviewGetAlertsCount } from '../../../actions/SubscriptionActions';
import { gnviewDataAlertsSetAlertsCounts } from '../../../actions/DataAlertsActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';

const SubscribedChannelsTab = () => {
    const dispatch = useDispatch();
    const subscriptionsData = useSelector(gnviewSelDataAlertsSubscriptions);
    const [isLoading, setIsLoading] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);
    const [subscriptionColumns, setSubscriptionColumns] = useState([...SUBSCRIPTION_TABLE_HEADERS]);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState({});
    const [currentRow, setCurrentRow] = useState(null);
    const [values, setValues] = useState({});
    const email = useSelector(gvauthSelEmail);

    useEffect(() => {
        setSubscriptions(getSubscriptionStations(subscriptionsData));
        setIsLoading(false);
    }, [subscriptionsData]);

    useEffect(() => {
        const columnHeaders = [...SUBSCRIPTION_TABLE_HEADERS];
        const tablePopoverIndex = columnHeaders.findIndex((col) => col.id === 'TablePopover');
        if (tablePopoverIndex !== -1) {
            columnHeaders[tablePopoverIndex] = {
                ...columnHeaders[tablePopoverIndex],
                /* eslint-disable react/display-name */
                /* eslint-disable react/prop-types */
                Cell: React.memo(({ row }) => { // Optimize the rendering of the menu item components using React.memo
                    const target = useRef(null);
                    return (
                        <>
                            <Button data-testid="menu-button" role="menu-button" variant="text" ref={target} onClick={() => {
                                if (currentRow === row.original?.subscription_id) {
                                    setCurrentRow(0);
                                } else {
                                    setCurrentRow(row.original?.subscription_id);
                                }
                            }}>
                                <FontAwesomeIcon icon="ellipsis-h" className="popover-ellipsis" />
                            </Button>
                            <Overlay transition={false} rootClose={true} target={target.current} show={currentRow === row.original?.subscription_id} placement="bottom">
                                {({
                                    ...props
                                }) => (


                                    <div className='table-popover-dropdown'
                                        {...props}>
                                        <div className="register-buttons">
                                            <div className='menu-item' onClick={() => {
                                                updateSubscriptionModal(row.original?.subscription_id);
                                                setCurrentRow(0);
                                            }}>
                                                {SUBSCRIPTION_EDIT_BUTTON}
                                            </div>
                                            <div className='menu-item' onClick={() => {
                                                deleteSubscriptionModal(row.original?.subscription_id);
                                                setCurrentRow(0);
                                            }}>
                                                {SUBSCRIPTION_UNSUBSCRIBE_BUTTON}
                                            </div>
                                            <hr className='menu-line' />
                                        </div>

                                    </div>
                                )}
                            </Overlay>
                        </>
                    );
                })
                /* eslint-enable react/display-name */
                /* eslint-enable react/prop-types */
            };
        }
        setSubscriptionColumns(columnHeaders);

        const deleteSubscriptionModal = (subscriptionId) => {
            const currentSubscription = subscriptions.find((sub) => sub.subscription_id === subscriptionId)
            setDeleteModalShow(true);
            setSelectedSubscription(currentSubscription);
        }

        const updateSubscriptionModal = (subscriptionId) => {
            const currentSubscription = subscriptions.find((sub) => sub.subscription_id === subscriptionId)
            setEditModalShow(true);
            setSelectedSubscription(currentSubscription);
        }
    }, [currentRow, selectedSubscription, editModalShow, deleteModalShow, values, subscriptions, setValues, setDeleteModalShow, setEditModalShow, handleModal]);

    // Declaration of handleModal
    const handleModal = useCallback(async (isDeleteRequest = false, isUpdate = false) => {
        if (isDeleteRequest) {
            deleteSubscription(selectedSubscription.subscription_id).then((response) => {
                if (response?.result) {
                    // remove the subscription from the list of subscriptions
                    const updatedSubscriptions = subscriptions.filter((sub) => sub.subscription_id !== selectedSubscription.subscription_id);
                    dispatch(gnviewDataAlertsSetSubscriptions(updatedSubscriptions));
                    gnviewGetAlertsCount(email, 'this-week').then((res) => {
                        const count = {'this-week': res?.result?.alert_count}
                        dispatch(gnviewDataAlertsSetAlertsCounts(count))
                    }).catch(error => {
                        gnviewSendLogMessage(error)
                    })
                }
            });
        }
        if (isUpdate) {
            // the values returned by the update form do not match the values required by the put request
            const payload = { ...values };
            payload.username = email;
            payload.sourceType = SOURCE_TYPES_MAPPING[payload.sourceType];
            payload.labels = payload.labels || [];
            payload.distributionPlatforms = payload.distributionPlatforms || [];
            payload.labels = typeof payload.labels[0] === 'object' ? Object.keys(payload.labels).map((key) => payload.labels[key].label) : payload.labels;
            payload.distributionPlatforms = typeof payload.distributionPlatforms[0] === 'object' ? Object.keys(payload.distributionPlatforms).map((key) => payload.distributionPlatforms[key].label) : payload.distributionPlatforms;
            updateSubscription(selectedSubscription.subscription_id, payload).then((updateResponse) => {
                if (updateResponse?.result) {
                    const updatedSubscriptions = subscriptions ? subscriptions.map((sub) => {
                        if (sub.subscription_id === selectedSubscription.subscription_id) {
                            sub.distributionPlatforms = updateResponse?.result?.distributionPlatforms;
                            sub.labels = updateResponse?.result?.labels;
                            sub.source_of_channel = updateResponse?.result?.source_of_channel;
                            sub.station_id = updateResponse?.result?.station_id;
                            sub.station_name = updateResponse?.result?.station_name;
                            sub.subscription_id = updateResponse?.result?.subscription_id;
                            sub.station_image = updateResponse?.result?.station_image || '';
                        }
                        return sub;
                    }) : [];
                    dispatch(gnviewDataAlertsSetSubscriptions(updatedSubscriptions));
                }
            });
        }
        setDeleteModalShow(false);
        setEditModalShow(false);
    }, [selectedSubscription, subscriptions, values, email, setDeleteModalShow, setEditModalShow, dispatch]);

    const renderTable = () => {
        return (
            <GNClientTable columns={subscriptionColumns} data={subscriptions} isLoading={isLoading} pagination={false} sorting={false} />
        );
    };

    return (
        <ErrorBoundary>
            <div className="gnview-data-alerts-subscriptions-tab-container">
                {renderTable()}
                {!isLoading && subscriptions?.length === 0 ? <div className="no-data-alerts">No Subscriptions</div> : null}
                {deleteModalShow && <GNModal className="unsubscribe-modal" show={deleteModalShow} title={SUBSCRIPTION_MODAL_UNSUBSCRIBE_TITLE} onHide={() => setDeleteModalShow(!deleteModalShow)}>
                    <Modal.Body>
                        <p>{SUBSCRIPTION_MODAL_UNSUBSCRIBE_MESSAGE}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='btn btn-secondary-text' onClick={() => handleModal(false, false)}>{SUBSCRIPTION_MODAL_CANCEL}</Button>
                        <Button variant='btn btn-primary-text' onClick={() => handleModal(true, false)}>{SUBSCRIPTION_MODAL_UNSUBSCRIBE}</Button>
                    </Modal.Footer>
                </GNModal>}
                {editModalShow && <GNModal show={editModalShow} title={SUBSCRIPTION_MODAL_EDIT_TITLE} onHide={() => setEditModalShow(!editModalShow)}>
                    <Modal.Body>
                        <SubscribeForm selectedStation={selectedSubscription} setValues={setValues} isUpdate={true} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='light' onClick={() => handleModal(false, false)}>{SUBSCRIPTION_MODAL_CANCEL}</Button>
                        <Button variant='dark' onClick={() => handleModal(false, true)}>{SUBSCRIPTION_MODAL_SAVE}</Button>
                    </Modal.Footer>
                </GNModal>}
            </div>
        </ErrorBoundary>
    );
};

export default SubscribedChannelsTab;

SubscribedChannelsTab.propTypes = {
    email: PropTypes.string
};
