import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ROUTES } from '../../../config/Routes';
import './ProgramsSearch.scss';
import GNProgramSelection from '../../../components/common/gnProgramSelection/GNProgramSelection';
import {
    PROGRAM_DETAILS,
    RECENT_SEARCHES,
    ADVANCED_SEARCH,
    PROGRAM_LIST_LAST_UPDATED,
    BASIC_TIER_LOOKUP_LIMIT_MESSAGE,
    BASIC_TIER_RESET_MESSAGE,
    BASIC_TIER_UPGRADE_MESSAGE
} from '../../../constants/Program';
import { UPGRADE_EMAIL } from '../../../constants/LookupMax';
import { gvauthIsBasic, gvauthSelLookupCount, gvauthSelLookupMax } from '../../../reducers/GNVAuthReducer';
import { gnviewSelProgramDetailsRecentPrograms } from '../../../reducers/ProgramDetailsReducer';
import SavedItem from '../../../components/common/savedItem/SavedItem';
import { gnviewGetAllSavedSearches } from '../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import {
    MY_SAVED_ITEMS_TYPES,
    PROGRAM_DETAILS_SAVED_ITEMS_LIMIT,
    viewAllOrViewLessButton
} from '../../../constants/MySavedItems';
import LazyLoad from 'react-lazyload';
import moment from 'moment-timezone';

export const ProgramsSearchView = () => {
    const isBasic = useSelector(gvauthIsBasic);
    const lookupCount = useSelector(gvauthSelLookupCount);
    const lookupMax = useSelector(gvauthSelLookupMax);
    const [tmsId, setTmsId] = useState('');
    const defaultTimezone = moment.tz.guess(true);
    const recentPrograms = useSelector(gnviewSelProgramDetailsRecentPrograms);
    // Program Lists - right now, we're using the 3 most recently updated lists. Later, we'll need to grab the lists they have saved in their settings
    const [programLists, setProgramLists] = useState(null);
    // Program List Button Checker
    const [programListsIsExpanded, setprogramListsIsExpanded] = useState([]);
    const [recentProgramsIsExpanded, setrecentProgramsIsExpanded] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isBasic && lookupCount && lookupCount > lookupMax) {
            history.push(ROUTES.LOOKUP_MAX);
        }
    }, [history, isBasic, lookupCount, lookupMax]);

    useEffect(() => {
        dispatch(gnviewGetAllSavedSearches())
            .then((response) => {
                if (response.result) {
                    const filteredProgramLists = response.result
                        .filter(
                            (item) =>
                                item.search_type === MY_SAVED_ITEMS_TYPES.PROGRAM_LISTS &&
                                item?.query_obj?.program_ids?.length > 0
                        )
                        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
                    setProgramLists(filteredProgramLists.slice(0, 3));
                    setprogramListsIsExpanded(
                        filteredProgramLists.map((search) => {
                            return {
                                ...search,
                                expanded: false
                            };
                        })
                    );
                }
            })
            .catch((error) => {
                dispatch(gnviewSendLogMessage(`gnviewGetAllSavedSearches error: ${error.message}`, error));
            });
    }, [dispatch]);

    const handleChangeProgram = (program) => {
        const programId = program.length > 0 && program[0].tmsid ? program[0].tmsid : null;
        if (programId) {
            const route = `${ROUTES.PROGRAM_DETAILS}/${programId}`;
            history.push(route);
        }
    };

    // TMS ID input
    const handleTmsIdChange = (e) => {
        const tmsIdVal = e.target.value;
        setTmsId(tmsIdVal);
    };

    // TMS ID search button
    const handleTMSIdButtonClick = (e, tmsIdVal) => {
        // Prevent page refresh
        e.preventDefault();
        if (tmsIdVal) {
            const route = `${ROUTES.PROGRAM_DETAILS}/${tmsIdVal}`;
            history.push(route);
        }
    };

    const handleRecentProgramsButtonClick = (e) => {
        e.preventDefault();
        setrecentProgramsIsExpanded(!recentProgramsIsExpanded);
    };

    const handleProgramListsButtonClick = (e, savedSearchId) => {
        e.preventDefault();

        const updatedProgramList = programListsIsExpanded.map((item, idx) => {
            if (idx === savedSearchId) {
                return {
                    ...item,
                    expanded: !item.expanded
                };
            }
            return item;
        });

        setprogramListsIsExpanded(updatedProgramList);
    };

    return (
        <div className='gnview-programs-search-content'>
            {isBasic && <div className='basic-tier-message-container'>
                <div className='limit-message-container'>
                    <span className='limit-message'>{BASIC_TIER_LOOKUP_LIMIT_MESSAGE}</span><span>{BASIC_TIER_RESET_MESSAGE}</span>
                </div>
                <div className='upgrade-message-container'>
                    <span className='upgrade-message'>{BASIC_TIER_UPGRADE_MESSAGE}</span><a className='upgrade-email' href ={`mailto:${UPGRADE_EMAIL}`}>{UPGRADE_EMAIL}</a>
                </div>
            </div>}
            <div className='filter-bar-container programs-search-container'>
                <div className='gnview-header-title'>{PROGRAM_DETAILS}</div>
                <div className='programs-search-bar'>
                    <GNProgramSelection
                        handleChangeProgram={handleChangeProgram}
                        tmsId={tmsId}
                        handleTmsIdChange={handleTmsIdChange}
                        handleTMSIdButtonClick={handleTMSIdButtonClick}
                        isMultiple={false}
                    />
                    <Link to={ROUTES.ADVANCED_SEARCH} className='advanced-search-link'>
                        <span className='advanced-search-title'>{ADVANCED_SEARCH}</span>
                    </Link>
                </div>
            </div>

            {/* Recently Viewed Programs */}
            {recentPrograms?.length > 0 && (
                <div className='gnview-saved-item-section'>
                    <div className='saved-item-header'>{RECENT_SEARCHES}</div>
                    <div className='saved-item-list'>
                        {!recentProgramsIsExpanded
                            ? recentPrograms.slice(0, PROGRAM_DETAILS_SAVED_ITEMS_LIMIT).map((tmsid) => (
                                <div className='saved-item-container' key={tmsId}>
                                    <LazyLoad overflow={true} scroll={true} key={tmsid}>
                                        <SavedItem id={tmsid} isProgramSearch={true} />
                                    </LazyLoad>
                                </div>
                            ))
                            : recentPrograms.map((tmsid) => (
                                <div className='saved-item-container' key={tmsId}>
                                    <LazyLoad overflow={true} scroll={true} key={tmsid}>
                                        <SavedItem id={tmsid} isProgramSearch={true} />
                                    </LazyLoad>
                                </div>
                            ))}
                        {recentPrograms.length > PROGRAM_DETAILS_SAVED_ITEMS_LIMIT && (
                            <div className='programListBtnContainer'>
                                <span onClick={handleRecentProgramsButtonClick} className='programListBtn'>
                                    {viewAllOrViewLessButton(recentProgramsIsExpanded)}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* Program Lists - Saved Items */}
            {programLists?.length > 0 &&
                programLists.map((list, listIdx) => (
                    <div className='gnview-saved-item-section' key={list.id}>
                        <div className='saved-item-header-container'>
                            <div className='saved-item-header-container__title'>{list.title}</div>
                            <div className='saved-item-header-container__last-update'>
                                {PROGRAM_LIST_LAST_UPDATED}:{' '}
                                {moment(list.updated_at).tz(defaultTimezone).format('MM/DD/YYYY h:mmA')} (
                                {moment(list.updated_at).tz(defaultTimezone).zoneAbbr()})
                            </div>
                        </div>
                        <div className='saved-item-list'>
                            {!programListsIsExpanded[listIdx]?.expanded
                                ? list?.query_obj?.program_ids
                                    .slice(0, PROGRAM_DETAILS_SAVED_ITEMS_LIMIT)
                                    .map((tmsid) => (
                                        <div className='saved-item-container' key={tmsid}>
                                            <LazyLoad overflow={true} scroll={true} key={tmsid}>
                                                <SavedItem id={tmsid} isProgramSearch={true} />
                                            </LazyLoad>
                                        </div>
                                    ))
                                : list?.query_obj?.program_ids.map((tmsid) => (
                                    <div className='saved-item-container' key={tmsid}>
                                        <LazyLoad overflow={true} scroll={true} key={tmsid}>
                                            <SavedItem id={tmsid} isProgramSearch={true} />
                                        </LazyLoad>
                                    </div>
                                ))}
                            {list?.query_obj?.program_ids?.length > PROGRAM_DETAILS_SAVED_ITEMS_LIMIT && (
                                <div className='programListBtnContainer'>
                                    <span
                                        onClick={(e) => handleProgramListsButtonClick(e, listIdx)}
                                        className='programListBtn'
                                    >
                                        {viewAllOrViewLessButton(programListsIsExpanded[listIdx]?.expanded)}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default ProgramsSearchView;
