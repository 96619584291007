/* eslint react/prop-types: 0 */
/* eslint react/display-name: 0 */
import localTimeConverter from "../../../utils/DateUtils";
import StationInfo from "../stationInfo/StationInfo";
import React from "react";

const seriesEpisodeFields = [
    {prop: 'genres', label: 'Genres'},
    {prop: 'countries_of_origin', label: 'Countries'},
    {prop: 'original_audio_language_converted', label: 'Original Audio Language'},
    {prop: 'original_air_date', label: 'Original Air Date', render: (value) => localTimeConverter.convertToLocalTime(value)},
    {prop: 'source_type', label: 'Source Type'},
    {prop: 'original_network_id', label: 'Original Network', extra: ['original_network_call_sign'], render: (value, extra) => {
        return value ? <StationInfo stationId={value} extra={extra} /> : null;
    }},
    {prop: 'last_original_network_id', label: 'Last Original Network', extra: ['last_original_network_call_sign'], render: (value, extra) => {
        return value ? <StationInfo stationId={value} extra={extra} /> : null;
    }}
];

export const PROGRAM_SUMMARY_FIELDS = {
    FEATURE_FILM: [
        {prop: 'genres', label: 'Genres'},
        {prop: 'countries_of_origin', label: 'Countries'},
        {prop: 'original_audio_language_converted', label: 'Original Audio Language'},
        {prop: 'color_code', label: 'Color Code'},
        {prop: 'sound_mixes', label: 'Sound Mixes'},
        {prop: 'picture_formats', label: 'Picture Formats'},
        {prop: 'production_companies', label: 'Production Companies'},
        {
            prop: 'trailer', label: 'Trailer', render: (value) => {
                return (<a href={value} target="_blank" rel="noreferrer">{value}</a>)
            }
        }
    ],
    SERIES: [
        ...seriesEpisodeFields,
        {
            prop: 'series_premiere',
            label: 'Series Premiere',
            render: (value) => localTimeConverter.convertToLocalTime(value)
        },
        {prop: 'series_finale', label: 'Series Finale', render: (value) => localTimeConverter.convertToLocalTime(value)}
    ],
    EPISODE: [
        ...seriesEpisodeFields,
        {prop: 'episode_info.num_in_series', label: 'Sequential Episode Number'}
    ],
    SPORTS_EVENT: [
        {prop: 'genres', label: 'Genres'},
        {
            prop: 'original_air_date',
            label: 'Original Air Date',
            render: (value) => localTimeConverter.convertToLocalTime(value)
        },
        {
            prop: 'sports_info.game_date',
            label: 'Game Date',
            render: (value) => localTimeConverter.convertToLocalTime(value)
        },
        {prop: 'sports_info.game_time', label: 'Game Time'},
        {prop: 'sports_info.game_timezone', label: 'Game Timezone'},
        {prop: 'sports_info.venue.name', label: 'Venue'},
        {prop: 'sports_info.venue.id', label: 'Venue Brand ID'},
        {prop: 'sports_info.season.type', label: 'Season Type'},
        {prop: 'sports_info.season.name', label: 'Season Name'},
        {prop: 'countries_of_origin', label: 'Countries'},
        {prop: 'original_audio_language_converted', label: 'Original Audio Language'},
        {prop: 'color_code', label: 'Color Code'},
        {prop: 'source_type', label: 'Source Type'},
        {
            prop: 'original_network_id',
            label: 'Original Network',
            extra: ['original_network_call_sign'],
            render: (value, extra) => {
                return value ? <StationInfo stationId={value} extra={extra}/> : null;
            }
        },
        {
            prop: 'last_original_network_id',
            label: 'Last Original Network',
            extra: ['last_original_network_call_sign'],
            render: (value, extra) => {
                return value ? <StationInfo stationId={value} extra={extra}/> : null;
            }
        },
        {
            prop: 'series_premiere',
            label: 'Series Premiere',
            render: (value) => localTimeConverter.convertToLocalTime(value)
        },
        {prop: 'series_finale', label: 'Series Finale', render: (value) => localTimeConverter.convertToLocalTime(value)}
    ],
    SPORTS_NON_EVENT: [
        {prop: 'genres', label: 'Genres'},
        {
            prop: 'original_air_date',
            label: 'Original Air Date',
            render: (value) => localTimeConverter.convertToLocalTime(value)
        },
        {prop: 'countries_of_origin', label: 'Countries'},
        {prop: 'original_audio_language_converted', label: 'Original Audio Language'},
        {prop: 'color_code', label: 'Color Code'},
        {prop: 'source_type', label: 'Source Type'},
        {
            prop: 'original_network_id',
            label: 'Original Network',
            extra: ['original_network_call_sign'],
            render: (value, extra) => {
                return value ? <StationInfo stationId={value} extra={extra}/> : null;
            }
        },
        {
            prop: 'last_original_network_id',
            label: 'Last Original Network',
            extra: ['last_original_network_call_sign'],
            render: (value, extra) => {
                return value ? <StationInfo stationId={value} extra={extra}/> : null;
            }
        },
        {
            prop: 'series_premiere',
            label: 'Series Premiere',
            render: (value) => localTimeConverter.convertToLocalTime(value)
        },
        {prop: 'series_finale', label: 'Series Finale', render: (value) => localTimeConverter.convertToLocalTime(value)}
    ],
    PREVIEW: seriesEpisodeFields
};

export const PROGRAM_RELEASES_COLUMN_HEADERS = {
    FEATURE_FILM: [
        {
            accessor: 'release_date', Header: 'Date', Cell: ({row}) => {
                return localTimeConverter.convertToLocalTime(row.values.release_date);
            }
        },
        {accessor: 'country_code', Header: 'Country'},
        {accessor: 'type', Header: 'Type'},
        {accessor: 'medium', Header: 'Medium'},
        {
            accessor: 'distributors', Header: 'Distributors', Cell: ({row}) => {
                const listItems = row.values.distributors.map((d) =>
                    <dd key={d}>
                        {d}
                    </dd>);
                return (<dl>{listItems}</dl>);
            }
        },
        {
            accessor: 'attributes', Header: 'Attributes', Cell: ({row}) => {
                const listItems = row.values.attributes.map((d) =>
                    <dd key={d}>
                        {d}
                    </dd>);
                return (<dl>{listItems}</dl>);
            }
        },
        {
            accessor: 'station_id', Header: 'Station', Cell: ({row}) => {
                if (row.values.station_id) {
                    return <StationInfo stationId={row.values.station_id}/>
                } else {
                    return null;
                }
            }
        }
    ],
    SERIES_EPISODE: [
        {accessor: 'country_code', Header: 'Country'},
        {accessor: 'type', Header: 'Type'},
        {
            accessor: 'release_date', Header: 'Date', Cell: ({row}) => {
                return localTimeConverter.convertToLocalTime(row.values.release_date);
            }
        },
        {accessor: 'medium', Header: 'Medium'},
        {
            accessor: 'distributors', Header: 'Distributors', Cell: ({row}) => {
                const listItems = row.values.distributors.map((d) =>
                    <dd key={d}>
                        {d}
                    </dd>);
                return (<dl>{listItems}</dl>);
            }
        },
        {
            accessor: 'attributes', Header: 'Attributes', Cell: ({row}) => {
                const listItems = row.values.attributes.map((d) =>
                    <dd key={d}>
                        {d}
                    </dd>);
                return (<dl>{listItems}</dl>);
            }
        },
        {accessor: 'season_year', Header: 'Season Year'},
        {accessor: 'season_name', Header: 'Season Name'},
        {
            accessor: 'station_id', Header: 'Station', Cell: ({row}) => {
                if (row.values.station_id) {
                    return <StationInfo stationId={row.values.station_id}/>
                } else {
                    return null;
                }
            }
        }
    ]
};

export const PROGRAM_SEASON_INFO_FIELDS = [
    {prop: 'premiere_date', label: 'Premiere Date'},
    {prop: 'finale_date', label: 'Finale Date'},
    {
        prop: 'original_network_id',
        label: 'Original Network',
        extra: ['original_network_call_sign'],
        render: (value, extra) => {
            return value ? <StationInfo stationId={value} extra={extra}/> : null;
        }
    },
    {
        prop: 'last_original_network_id',
        label: 'Last Original Network',
        extra: ['last_original_network_call_sign'],
        render: (value, extra) => {
            return value ? <StationInfo stationId={value} extra={extra}/> : null;
        }
    },
    {prop: 'total_episode_count', label: 'Total Episode Count'}
];