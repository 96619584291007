import React, {useState} from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import './GNRadioGroup.scss';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const GNRadioGroup = (props) => {
    const value = props?.value || '';
    const [inputValue, setValue] = useState(value);

    return (
        <span className={ClassNames(`gn-radio-group gn-${props.style}-style`, props.className)}>
            {props.label && <span className='gn-radio-group-label'>{props.label}: </span>}
            <ToggleButtonGroup
                name='gnradiogroup'
                onChange={(item) => {
                    props.onSelect(item);
                    setValue(item);
                }}
                defaultValue={props.default}
                {...(inputValue && { value: inputValue })}
            >
                {props.list.map((item, index) => (
                    <ToggleButton key={index} type='radio' value={item} variant={props.style}>
                        <span className='button-text'>
                            {item?.icon && <FontAwesomeIcon className='button-icon' icon={item.icon} />}
                            {item?.name || item}
                        </span>
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            {props.style === 'layered' && <div className='layered-bottom-border' />}
        </span>
    );
};

GNRadioGroup.defaultProps = {
    style: 'radio'
};

GNRadioGroup.propTypes = {
    className: PropTypes.string,
    default: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    label: PropTypes.string,
    list: PropTypes.array,
    onSelect: PropTypes.func,
    style: PropTypes.oneOf(['radio', 'flat', 'layered']),
    value: PropTypes.string
};

export default GNRadioGroup;
