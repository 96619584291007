import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GNRadioGroup from '../gnRadioGroup/GNRadioGroup';
import AsyncTypeaheadFilter from "../asyncTypeaheadFilter/AsyncTypeaheadFilter";
import { Button, Form } from 'react-bootstrap';
import { gnviewSearchPrograms } from '../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import {
    PROGRAM_TITLE,
    PROGRAM_TMS_ID,
    PROGRAM_SEARCH_TYPES_LABEL,
    PROGRAM_SEARCH_TYPES,
    PROGRAM_SEARCH_FILTER_LABEL,
    PROGRAM_FILTER_TYPES
} from '../../../constants/Program';
import ErrorBoundary from '../errorBoundary/ErrorBoundary';
import GNProgramCard from '../gnProgramCard/GNProgramCard';
import "./GNProgramSelection.scss";
import PropTypes from 'prop-types';
import TypeaheadFilter from '../typeaheadFilter/TypeaheadFilter';
import GNBadge from '../gnBadge/GNBadge';
import { convertLanguageFromCode } from '../../../utils/GeneralUtils';
import { gvauthGetEntitledProgramLanguages, gnviewSelUserSettings } from '../../../reducers/GNVAuthReducer';

export class GNProgramSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            programSearchResultOptions: [],
            isSearchProgramsLoading: false,
            searchType: PROGRAM_TITLE,
            allLanguages: [],
            isLanguageDropdownLoading: false,
            selectedLanguage: null,
            searchString: null,
            formMessage: null,
            progTypeFilters: PROGRAM_FILTER_TYPES.map(filter => ({ ...filter, selected: false }))
        };
        this.asyncRef = React.createRef();
    }

    setupLanguageDropdown = () => {
        this.setState({isLanguageDropdownLoading: true});
        const languages = this.props.entitledLanguages.map((lang) => {
            return {
                code: lang,
                text: convertLanguageFromCode(lang)
            }
        }).filter(item => item.text).sort((a, b) => a.text > b.text ? 1 : -1);
        const defaultLanguage = this.props.defaultLanguage || languages.find((lang) => lang.code === 'en') || languages[0];
        const selectedLanguage = typeof defaultLanguage === 'string' ? [{code: this.props.defaultLanguage, text: convertLanguageFromCode(this.props.defaultLanguage)}] : [defaultLanguage];
        this.setState({
            allLanguages: languages,
            isLanguageDropdownLoading: false,
            selectedLanguage
        });
    }

    componentDidMount() {
        this.setupLanguageDropdown();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.defaultLanguage !== this.props.defaultLanguage) {
            this.setupLanguageDropdown();
        }
    }

    handleProgramSearch = (searchString) => {
        if (!this.state.selectedLanguage[0]) {
            this.setState({ formMessage: 'Please select a language', searchString })
        } else {
            this.setState({isSearchProgramsLoading: true});
            const langCode = this.state.selectedLanguage[0].code;
            const progTypes = this.state.progTypeFilters.filter(filter => filter.selected).map(filter => filter.progTypes).flat();
            this.props.gnviewSearchPrograms(searchString, langCode, progTypes).then(
                (response) => {
                    const programSearchResultOptions = (this.props.isMultiple && response.result.map(prog => !this.props.selectedProgramIds.includes(prog.tmsid) && prog).filter(item => item !== false)) || response.result;
                    this.setState({
                        programSearchResultOptions,
                        searchString,
                        isSearchProgramsLoading: false
                    });
                }, (error) => {
                    this.props.gnviewSendLogMessage(`gnviewSearchPrograms error: ${error.message}`, error, {searchString});
                    this.setState({isSearchProgramsLoading: false});
                }
            );
        }
    }

    renderChildrenForProgram = (item, props, index) => {
        return (
            <ErrorBoundary>
                <div className="program-typeahead-container" key={index}>
                    <GNProgramCard program={item} typeahead={true} typeaheadText={props.text} />
                </div>
            </ErrorBoundary>
        );
    }

    // Program Title vs TMS ID search type
    onSearchTypeSelect = (type) => {
        this.setState({searchType: type}, () => {
            if (this.props.validateForm) {
                this.props.validateForm();
            }
        });
    }

    // Program Title language dropdown
    handleLanguageChange = (item) => {
        this.setState({ selectedLanguage: item, formMessage: null }, () => this.handleProgramSearch(this.state.searchString));
    }

    handleFilterClick = (filter) => {
        const filterIndex = this.state.progTypeFilters.findIndex((type) => type.name === filter.name);
        const filtersCopy = [...this.state.progTypeFilters];
        filtersCopy[filterIndex].selected = !filter.selected;
        this.setState({ progTypeFilters: filtersCopy }, () => {
            if (this.state.searchString) {
                this.handleProgramSearch(this.state.searchString)
            }
        });
    }

    render() {
        return (
            <ErrorBoundary>
                <div className="gnview-program-selection">
                    <GNRadioGroup label={PROGRAM_SEARCH_TYPES_LABEL} list={PROGRAM_SEARCH_TYPES} onSelect={this.onSearchTypeSelect} default={PROGRAM_SEARCH_TYPES[0]}/>
                    {this.state.searchType === PROGRAM_TITLE && <div className='filter-section'>
                        <span className='filter-label'>{PROGRAM_SEARCH_FILTER_LABEL}</span>
                        <span>{this.state.progTypeFilters.map((filter, idx) => (
                            <GNBadge type={filter.selected ? filter.progTypes[0] : null} subtype={filter.name} className='filter-item' onClick={() => this.handleFilterClick(filter)} key={idx}/>
                        ))}</span>
                    </div>}
                    {this.state.searchType === PROGRAM_TITLE && <div className="program-title-section">
                        <AsyncTypeaheadFilter
                            filterLabel=''
                            labelKey="title"
                            multiple={false}
                            renderMenuItemChildren={this.renderChildrenForProgram}
                            options={this.state.programSearchResultOptions}
                            isLoading={this.state.isSearchProgramsLoading}
                            onSearch={this.handleProgramSearch}
                            placeholder="Enter Program by Name"
                            onChange={
                                (item) => {
                                    this.props.handleChangeProgram(item);
                                }
                            }
                            ref={
                                (async) => {
                                    this.asyncRef = async;
                                }
                            }
                            maxHeight={'515px'}
                            disabled={this.state.isLanguageDropdownLoading || this.props.disabled}
                            onFocus={() => {
                                if (this.state.searchString) {
                                    this.handleProgramSearch(this.state.searchString)
                                }
                            }}
                            hasSearchString={this.state.searchString?.length > 0} />
                        {!this.state.isLanguageDropdownLoading && this.state.selectedLanguage && <div className={'filter-bar-col lang-col'}>
                            <TypeaheadFilter
                                filterLabel=''
                                labelKey='text'
                                multiple={false}
                                list={this.state.allLanguages}
                                placeholder=''
                                selected={this.state.selectedLanguage}
                                handleChange={
                                    (item) => {
                                        this.handleLanguageChange(item)
                                    }
                                }
                                disabled={this.props.disabled} />
                        </div>}
                    </div>}
                    {this.state.formMessage && <div className='form-message'>{this.state.formMessage}</div>}
                    {this.state.searchType === PROGRAM_TMS_ID && <div className="filter-bar-col">
                        <Form className='tmsid-search-container' onSubmit={(e) => this.props.handleTMSIdButtonClick(e, this.props.tmsId)} disabled={this.props.disabled}>
                            <div className="filter-label">{PROGRAM_TMS_ID}:</div>
                            <Form.Row>
                                <Form.Control value={this.props.tmsId} onChange={this.props.handleTmsIdChange} />
                                <Button variant="primary" className="search-button" onClick={(e) => this.props.handleTMSIdButtonClick(e, this.props.tmsId)} disabled={this.props.disabled}>{this.props.tmsIdButtonText || 'Search'}</Button>
                            </Form.Row>
                        </Form>
                    </div>}
                </div>
            </ErrorBoundary>
        )
    }
}

GNProgramSelection.defaultProps = {
    disabled: false
};

GNProgramSelection.propTypes = {
    disabled: PropTypes.bool,
    defaultLanguage: PropTypes.string,
    entitledLanguages: PropTypes.array,
    gvauthGetEntitledProgramLanguages: PropTypes.func,
    gnviewSearchPrograms: PropTypes.func,
    gnviewSendLogMessage: PropTypes.func,
    handleChangeProgram: PropTypes.func.isRequired,
    handleTMSIdButtonClick: PropTypes.func.isRequired,
    handleTmsIdChange: PropTypes.func.isRequired,
    isMultiple: PropTypes.bool,
    selectedProgramIds: PropTypes.array,
    tmsId: PropTypes.string.isRequired,
    tmsIdButtonText: PropTypes.string,
    validateForm: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        defaultLanguage: gnviewSelUserSettings(state).program_language,
        entitledLanguages: gvauthGetEntitledProgramLanguages(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewSearchPrograms,
        gnviewSendLogMessage
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GNProgramSelection);