import React from 'react';
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AsyncTypeaheadFilter = React.forwardRef((props, ref) => (
    <div className={ClassNames('filter-bar-col', props.className || '')}>
        <div className={'filter-label'}>{props.filterLabel}</div>
        {!props.isLoading && !props.selected?.length > 0 && !props.hasSearchString && !props.disabled && <FontAwesomeIcon icon="magnifying-glass" />}
        <AsyncTypeahead
            {... (props.labelKey && {labelKey: props.labelKey})}
            {... (props.renderMenuItemChildren && {renderMenuItemChildren: props.renderMenuItemChildren })}
            {... (props.maxHeight && {maxHeight: props.maxHeight})}
            {... (props.disabled && {disabled: props.disabled})}
            {... (props.onFocus && {onFocus: props.onFocus})}
            isLoading={ props.isLoading }
            minLength={ 1 }
            allowNew={ false }
            multiple={ props.multiple }
            clearButton={ true }
            delay={ 500 }
            options={ props.options }
            onSearch={ props.onSearch }
            placeholder={ props.placeholder }
            onChange={(item) => props.onChange(item)}
            selected={ props.selected }
            id={'async-typeahead-' + props.filterLabel}
            ref={ref}
            // Disable client-side filtering
            // JIRA: https://jira.gracenote.com/browse/VIEW-532
            // GitHub: https://github.com/ericgio/react-bootstrap-typeahead/issues/160
            filterBy={() => true}
            // Turns caching off to always show apiService results
            useCache={false}
        />
    </div>
));

AsyncTypeaheadFilter.propTypes = {
    className: PropTypes.string,
    filterLabel: PropTypes.string.isRequired,
    labelKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    isLoading: PropTypes.bool.isRequired,
    selected: PropTypes.array,
    renderMenuItemChildren: PropTypes.func,
    maxHeight: PropTypes.string,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    options: PropTypes.array,
    onSearch: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    hasSearchString: PropTypes.bool,
    onFocus: PropTypes.func
};

AsyncTypeaheadFilter.displayName = 'AsyncTypeaheadFilter';

export default AsyncTypeaheadFilter;