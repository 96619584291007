import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    ADVANCED_PROGRAM_SEARCH_PRODUCTION_COMPANIES_LABEL,
    APPLIES_TO,
    PROGRAM_ATTRIBUTES_PRODUCTION_COMPANIES_SECTION
} from '../../../../constants/AdvancedSearch';
import GNBadge from '../../../common/gnBadge/GNBadge';
import AsyncTypeaheadFilter from '../../../common/asyncTypeaheadFilter/AsyncTypeaheadFilter';
import { gnviewSearchProductionCompanyIdentifiers } from '../../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../../services/GeneralService';
import PropTypes from 'prop-types';
import './ProductionCompanyFilter.scss';
import AnyOrAllToggle from '../anyOrAllToggle/AnyOrAllToggle';

export const ProductionCompanyFilter = ({ handleFormChange, values }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [allProductionCompanies, setAllProductionCompanies] = useState([]);
    const [selectedProductionCompanies, setSelectedProductionCompanies] = useState(
        values[PROGRAM_ATTRIBUTES_PRODUCTION_COMPANIES_SECTION.VALIDATION_LABEL]
    );

    const handleSearchProductionCompanies = (searchString) => {
        if (searchString) {
            setIsLoading(true);
            dispatch(gnviewSearchProductionCompanyIdentifiers(searchString))
                .then((response) => {
                    if (response?.result) {
                        const filteredProductionCompanies = response.result.filter(
                            (company) => !selectedProductionCompanies.includes(company.name)
                        );
                        setAllProductionCompanies(filteredProductionCompanies);
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    dispatch(
                        gnviewSendLogMessage(`gnviewSearchProductionCompanyIdentifiers error: ${error.message}`, error)
                    );
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        if (selectedProductionCompanies !== values[PROGRAM_ATTRIBUTES_PRODUCTION_COMPANIES_SECTION.VALIDATION_LABEL]) {
            setSelectedProductionCompanies(values[PROGRAM_ATTRIBUTES_PRODUCTION_COMPANIES_SECTION.VALIDATION_LABEL]);
        }
    }, [selectedProductionCompanies, values]);

    return (
        <div className='programAttributesSections'>
            <div className='programAttributesSections__section-header'>
                <div className='programAttributesSections__section'>
                    {ADVANCED_PROGRAM_SEARCH_PRODUCTION_COMPANIES_LABEL}
                </div>
                <div className='programAttributesSections__section-header-applies'>{APPLIES_TO}</div>
                <div className='programAttributesSections__section-header-badges'>
                    <GNBadge type='Feature Film' subtype='film' />
                </div>
            </div>
            <div className='programAttributesSections__section-production-companies'>
                <AsyncTypeaheadFilter
                    filterLabel={ADVANCED_PROGRAM_SEARCH_PRODUCTION_COMPANIES_LABEL}
                    labelKey={(option) => option?.name || ''}
                    multiple={true}
                    options={allProductionCompanies}
                    isLoading={isLoading}
                    onSearch={handleSearchProductionCompanies}
                    placeholder='All Production Companies'
                    selected={selectedProductionCompanies.map((company) => ({ name: company }))}
                    onChange={(item) => {
                        const newProductionCompanies = item.map((companyObj) => companyObj?.name);
                        setSelectedProductionCompanies(newProductionCompanies);
                        handleFormChange(
                            PROGRAM_ATTRIBUTES_PRODUCTION_COMPANIES_SECTION.VALIDATION_LABEL,
                            newProductionCompanies
                        );
                    }}
                />
                <AnyOrAllToggle
                    section={PROGRAM_ATTRIBUTES_PRODUCTION_COMPANIES_SECTION}
                    handleFormChange={handleFormChange}
                    values={values}
                />
            </div>
        </div>
    );
};

ProductionCompanyFilter.propTypes = {
    handleFormChange: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired
};

export default ProductionCompanyFilter;
