import React from 'react';
import { Button } from 'react-bootstrap';
import ClassNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./SaveButton.scss";
import PropTypes from 'prop-types'

export const SaveButton = ({handleClick, isSelected}) => {
    return (
        <Button variant="custom" className={ClassNames("gnview-save-button", {'selected': isSelected})} onClick={handleClick}>
            <FontAwesomeIcon className="save-icon" icon={isSelected ? ['fas', 'bookmark'] : ['far', 'bookmark']} />
            {isSelected ? 'Saved' : 'Save'}
        </Button>
    )
}

SaveButton.propTypes = {
    handleClick: PropTypes.func,
    isSelected: PropTypes.bool
};

export default SaveButton;