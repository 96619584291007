import programDetailsPhoto from '../images/program_details.png';
import programAiringsPhoto from '../images/program_airings.png';
import schedulePhoto from '../images/schedule.png';
import availabilityPhoto from '../images/availability.png';
import { LEARN_MORE_LINK } from './ContactUs';

export const LANDING_CONTACT_SALES = {
    buttonText: 'FAST Program Trial',
    url: LEARN_MORE_LINK
};

export const WHAT_YOU_GET_HEADER = 'What you get with Gracenote View';
export const WHAT_YOU_GET_DESC = 'One tool to manage all your content operation needs for exploring, tracking, and monitizing the content ecosystem.';

export const WHAT_YOU_GET_TYPES = {
    PROGRAM_DETAILS: 'Program Details',
    PROGRAM_AIRINGS: 'Program Airings',
    SCHEDULE: 'Schedule',
    PROGRAM_AVAILABILITY: 'Program Availability'
};

export const WHAT_YOU_GET_IMAGES = {
    [WHAT_YOU_GET_TYPES.PROGRAM_DETAILS]: {
        img: programDetailsPhoto,
        description: 'Look up and download Gracenote Video Data: program details, IDs, descriptive data, and imagery'
    },
    [WHAT_YOU_GET_TYPES.PROGRAM_AIRINGS]: {
        img: programAiringsPhoto,
        description: 'Airing details for a specified set of programs across all global TV'
    },
    [WHAT_YOU_GET_TYPES.SCHEDULE]: {
        img: schedulePhoto,
        description: 'Linear TV schedule for a specified channel, country or market area'
    },
    [WHAT_YOU_GET_TYPES.PROGRAM_AVAILABILITY]: {
        img: availabilityPhoto,
        description: 'Deep links to programs across various streaming services'
    }
};