import React, { useEffect, useState } from "react";
import { gnviewPostReviewedAlerts, gnviewDeleteReviewedAlerts } from "../../../actions/DataAlertsActions";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../../components/common/errorBoundary/ErrorBoundary";
import PropTypes from "prop-types";
import GNBadge from "../../../components/common/gnBadge/GNBadge";
import "./DataAlertsCard.scss";
import {
    DATA_ALERTS_HEADERS,
    DATA_ALERT_ERROR_MESSAGES,
    REVIEWED_DATA_ALERTS_NO_HEADERS
} from "../../../constants/DataAlerts";
import GNClientTable from "../../../components/common/gnClientTable/GNClientTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { ROUTES } from '../../../config/Routes';
import { gvauthSelEmail } from "../../../reducers/GNVAuthReducer";
import { gnviewSendLogMessage } from "../../../services/GeneralService";
import { debounce } from "lodash";
import { gnviewSetLastURLVisited } from "../../../actions/GNViewAuthActions";
import moment from "moment";
import {ZENDESK_URL} from "../../../constants/DataAlerts";

export const DataAlertsCards = ({
    subscription,
    stationData,
    showDropdown = false,
    alerts,
    windowStart,
    windowEnd,
    showErrorNotification,
    errorNotificationMessage,
    updateAlertsCounts
}) => {
    const dispatch = useDispatch();
    const { station_id: stationId, labels } = subscription;
    const [unreviewedAlerts, setUnreviewedAlerts] = useState([]);
    const [reviewedAlerts, setReviewedAlerts] = useState([]);
    const [showAlerts, setShowAlerts] = useState(showDropdown);
    const [showReviewedAlerts, setShowReviewedAlerts] = useState(true);
    const [reviewedStatusInProcess, setReviewedStatusInProcess] = useState(false);

    const email = useSelector(gvauthSelEmail);
    const [alertsAreMovedToSection, setAlertsAreMovedToSection] = useState(false);

    const image = stationData?.imageURL;

    const dataAlertsColumns = [...DATA_ALERTS_HEADERS];

    const reviewedColumnsIdx = dataAlertsColumns.findIndex(
        (col) => col.id === "unreviewedCheckmarkIdx"
    );

    if (reviewedColumnsIdx !== -1) {
        dataAlertsColumns[reviewedColumnsIdx] = {
            ...dataAlertsColumns[reviewedColumnsIdx],
            /* eslint-disable react/display-name */
            /* eslint-disable react/prop-types */
            Header: <i className='fa-regular fa-circle-check'></i>,
            Cell: ({ row }) => (
                <div
                    className='list-item'
                    onClick={() => unreviewedCheckmarkHandler(row)}
                >
                    {row.original.is_reviewed === "marked reviewed" ? <i className="fa-solid fa-spinner"></i> : <i className='far fa-circle' />}

                </div>
                /* eslint-enable react/display-name */
                /* eslint-enable react/prop-types */
            )
        };
    }

    const reviewedAlertsRows = [...REVIEWED_DATA_ALERTS_NO_HEADERS];

    const reviewedAlertsColumnsIdx = reviewedAlertsRows.findIndex(
        (col) => col.id === "reviewedCheckmarkIdx"
    );

    /* eslint-disable react/prop-types */
    /* eslint-disable react/display-name */
    if (reviewedAlertsColumnsIdx !== -1) {
        reviewedAlertsRows[reviewedAlertsColumnsIdx] = {
            ...reviewedAlertsRows[reviewedAlertsColumnsIdx],
            Cell: ({ row }) => (
                <div
                    className='list-item'
                    onClick={() => reviewedCheckmarkHandler(row)}
                >
                    {row.original.is_reviewed === "marked unreviewed" ? <i className="fa-solid fa-spinner"></i> : <i className='fa fa-circle-check' />}
                </div>
            )
        };
    }
    /* eslint-enable react/display-name */
    /* eslint-enable react/prop-types */

    /* eslint-disable react/prop-types */
    const filterUnreviwedAlerts = (stationAlerts) => {
        return stationAlerts.filter((alert) => alert.is_reviewed === "false" || alert.is_reviewed === "marked reviewed");
    };
    const filterReviewedAlerts = (stationAlerts) => {
        return stationAlerts.filter((alert) => alert.is_reviewed === "true" || alert.is_reviewed === "marked unreviewed");
    };

    const unreviewedCheckmarkHandler = debounce((row) => {
        if (reviewedStatusInProcess) {
            return;
        }
        setReviewedStatusInProcess(true)
        const unreviewedRow = row.original;
        const updatedUnreviewedAlerts = unreviewedAlerts.map((alert) => {
            if (alert.alertId === unreviewedRow.alertId) {
                return {
                    ...alert,
                    is_reviewed: "marked reviewed"
                };
            }
            return alert;
        });
        _setUnreviewedAlerts(updatedUnreviewedAlerts);
        dispatch(gnviewPostReviewedAlerts(unreviewedRow.alertId, email))
            .then((res) => {
                setReviewedStatusInProcess(false)
                const newUnreviewedAlerts = unreviewedAlerts.filter(
                    (unreviewedAlert) => unreviewedAlert.alertId !== unreviewedRow.alertId
                );
                _setUnreviewedAlerts(newUnreviewedAlerts);
                if (unreviewedRow.reviewedAlertId === null && unreviewedRow.alertId === res.result.alert_id) {
                    unreviewedRow.reviewedAlertId = res.result.id
                }
                const newReviewedAlerts = [...reviewedAlerts, unreviewedRow];
                _setReviewedAlerts(newReviewedAlerts);
                updateAlertsCounts(-1);
            })
            .catch((err) => {
                setReviewedStatusInProcess(false)
                errorNotificationMessage(DATA_ALERT_ERROR_MESSAGES.UNABLE_TO_CHECK)
                showErrorNotification(true)
                gnviewSendLogMessage('Post reviewed alerts error:', err)
            });
    });

    const reviewedCheckmarkHandler = debounce((row) => {
        if (reviewedStatusInProcess) {
            return;
        }
        setReviewedStatusInProcess(true)
        const reviewedRow = row.original;
        const updatedReviewedAlerts = reviewedAlerts.map((alert) => {
            if (alert.alertId === reviewedRow.alertId) {
                return {
                    ...alert,
                    is_reviewed: "marked unreviewed"
                };
            }
            return alert;
        });
        _setReviewedAlerts(updatedReviewedAlerts);
        dispatch(gnviewDeleteReviewedAlerts(reviewedRow.reviewedAlertId))
            .then((res) => {
                setReviewedStatusInProcess(false)
                const newReviewedAlerts = reviewedAlerts.filter(
                    (reviewedAlert) => reviewedAlert.alertId !== reviewedRow.alertId
                );
                if (reviewedRow.unreviewedAlertId === null && reviewedRow.alertId === res.result.alert_id) {
                    reviewedRow.unreviewedAlertId = res.result.id
                }
                _setReviewedAlerts(newReviewedAlerts);
                const newUnreviewedAlerts = [...unreviewedAlerts, reviewedRow];
                _setUnreviewedAlerts(newUnreviewedAlerts.sort((a, b) => new Date(a.scheduleDate).getTime() - new Date(b.scheduleDate).getTime()));
                updateAlertsCounts(1);
            })
            .catch((err) => {
                errorNotificationMessage(DATA_ALERT_ERROR_MESSAGES.UNABLE_TO_UNCHECK)
                showErrorNotification(true)
                gnviewSendLogMessage('Delete reviewed alerts error:', err)
                setReviewedStatusInProcess(false)
            });
    });

    const handleSendUpdateButton = () => {
        dispatch(gnviewSetLastURLVisited(`${window.location}&station_ids=${stationId}`));
        window.open(ZENDESK_URL, '_blank');
    }
    /* eslint-enable react/prop-types */

    useEffect(() => {
        setAlertsAreMovedToSection(false)
        const filteredAlerts = alerts.filter(alert => moment(alert.scheduleDate).local() >= windowStart.local() && moment(alert.scheduleDate).local() <= windowEnd.local());
        _setUnreviewedAlerts(filterUnreviwedAlerts(filteredAlerts));
        _setReviewedAlerts(filterReviewedAlerts(filteredAlerts));
        setAlertsAreMovedToSection(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alerts]);

    const _setUnreviewedAlerts = (alertsArray) => {
        setUnreviewedAlerts(alertsArray.sort((a, b) => new Date(a.scheduleDate).getTime() - new Date(b.scheduleDate).getTime()));
    }

    const _setReviewedAlerts = (alertsArray) => {
        setReviewedAlerts(alertsArray.sort((a, b) => new Date(a.scheduleDate).getTime() - new Date(b.scheduleDate).getTime()));
    }

    return (
        <ErrorBoundary>
            <div className='gnview-data-alerts-cards-container'>
                <div className='gnview-data-alerts-inner'>
                    <div className='data-alerts-card'>
                        <div className='card-header'>
                            <div className="card-left-half-container">
                                {image && <img className='station-image' src={image} />}
                                {!image && <img className='placeholder-league-image' />}
                                <span className='channel-name'>{stationData.name}</span>
                                <span className='schedule-link-button'>
                                    <Link target="_blank" rel="noreferrer" to={`${ROUTES.SCHEDULES}/result?date_type=This%20week&end_date=${windowEnd.format("YYYY-MM-DD")}&start_date=${windowStart.format("YYYY-MM-DD")}&station_ids=${stationId}`}>
                                        <Button>
                                            View Alerts in Schedule
                                            <span>
                                                <FontAwesomeIcon icon="external-link-alt" className='redirect_icon' />
                                            </span>
                                        </Button>
                                    </Link>
                                </span>
                                <span className='send-update-button'>
                                    <Link target="_blank" rel="noreferrer" onClick={handleSendUpdateButton}>
                                        <Button>
                                            Send Update
                                            <span>
                                                <FontAwesomeIcon icon="external-link-alt" className='redirect_icon' />
                                            </span>
                                        </Button>
                                    </Link>
                                </span>
                            </div>
                            <div className='reviewed-alerts-count'>
                                {unreviewedAlerts.length} / {unreviewedAlerts.length + reviewedAlerts.length} to Review
                                {showAlerts && (
                                    <span className='angle-down-unreviewed-alerts' onClick={() => setShowAlerts(!showAlerts)}>
                                        <FontAwesomeIcon
                                            icon='angle-down'
                                        />
                                    </span>
                                )}
                                {!showAlerts && (
                                    <span className='angle-right-unreviewed-alerts' onClick={() => setShowAlerts(!showAlerts)}>
                                        <FontAwesomeIcon
                                            icon='angle-right'
                                        />
                                    </span>
                                )}
                            </div>
                        </div>
                        {showAlerts && (
                            <>
                                <div>
                                    {labels.length > 0 && <div className='label-section'>
                                        {labels.map((label, idx) => {
                                            return (
                                                <GNBadge
                                                    subtype={label.label}
                                                    color={"color-data-alerts"}
                                                    key={idx}
                                                >
                                                    {" "}
                                                </GNBadge>
                                            );
                                        })}
                                    </div>}
                                    <div className='unreviewed-alerts-section'>
                                        { unreviewedAlerts.length > 0 && alertsAreMovedToSection && (<GNClientTable
                                            columns={dataAlertsColumns}
                                            data={unreviewedAlerts}
                                            pagination={false}
                                            className='unreviewed-alerts-table'
                                            sorting={false}
                                            customWidth = {true}
                                            numberOfRows={alerts.length}
                                        />) } { unreviewedAlerts.length === 0 && alertsAreMovedToSection && (
                                            <>
                                                <GNClientTable
                                                    columns={dataAlertsColumns}
                                                    data={[]}
                                                    pagination={false}
                                                    className='unreviewed-alerts-table'
                                                    sorting={false}
                                                />
                                                <div className='empty-unreviewed-alerts'> All Data Alerts Reviewed! </div>
                                            </>
                                        )}
                                        {unreviewedAlerts.length < 1 && <div className='adjust-filters-message'>
                                            {DATA_ALERT_ERROR_MESSAGES.NO_DATA_ALERTS}
                                        </div>}
                                    </div>
                                </div>
                                <div className='reviewed-alerts-section'>
                                    <span className='bottom-reviewed-alerts-count'>
                                        Reviewed ({reviewedAlerts.length})
                                        {showAlerts && showReviewedAlerts && (
                                            <span className='angle-down-reviewed-alerts' data-testid='angle-down-reviewed-alerts' onClick={() => setShowReviewedAlerts(!showReviewedAlerts)}>
                                                <FontAwesomeIcon
                                                    icon='angle-down'
                                                />
                                            </span>
                                        )}
                                        {showAlerts && !showReviewedAlerts && (
                                            <span className='angle-right-reviewed-alerts' data-testid='angle-right-reviewed-alerts' onClick={() => setShowReviewedAlerts(!showReviewedAlerts)}>
                                                <FontAwesomeIcon
                                                    icon='angle-right'
                                                />
                                            </span>
                                        )}
                                    </span>
                                    <div>
                                        {showReviewedAlerts && alertsAreMovedToSection && <GNClientTable
                                            columns={reviewedAlertsRows}
                                            data={reviewedAlerts}
                                            pagination={false}
                                            className='reviewed-alerts-table'
                                            sorting={false}
                                            numberOfRows={alerts.length}
                                            customWidth = {true}
                                        />
                                        }
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );
};

DataAlertsCards.propTypes = {
    subscription: PropTypes.object,
    showDropdown: PropTypes.bool,
    stationData: PropTypes.any,
    alerts: PropTypes.object,
    windowStart: PropTypes.any,
    windowEnd: PropTypes.any,
    errorNotificationMessage: PropTypes.func,
    showErrorNotification: PropTypes.func,
    updateAlertsCounts: PropTypes.func
};
export default DataAlertsCards;