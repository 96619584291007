import { array, boolean, number, object, string } from 'yup';

// -------------------
// Program Attributes
// -------------------

export const PROGRAM_ATTRIBUTES_MAX_CHARACTER_LIMIT = 500;
export const PROGRAM_ATTRIBUTES_MIN_YEAR = 1600;
export const PROGRAM_ATTRIBUTES_MAX_YEAR = 2999;

export const PROGRAM_ATTRIBUTES_INITIAL_VALUES = {
    audioLanguage: '',
    descriptionLanguage: '',
    genres: [],
    isAnyGenreSelected: true,
    isAnyProductionCompanySelected: true,
    mostRecentNetworkCountry: '',
    mostRecentNetworkFull: [],
    mostRecentNetworkId: '',
    mostRecentNetworkRadio: 'Network Name', // Can't use constant PROGRAM_ATTRIBUTES_NETWORK_SEARCH_TYPES here since it's a cyclic error eslint import/no-cycle
    originalNetworkCountry: '',
    originalNetworkFull: [],
    originalNetworkId: '',
    originalNetworkRadio: 'Network Name', // Can't use constant PROGRAM_ATTRIBUTES_NETWORK_SEARCH_TYPES here since it's a cyclic error eslint import/no-cycle
    programTypes: '',
    programSubtypes: '',
    productionCompanies: [],
    releaseYear: '',
    sportsType: '',
    title: '',
    titleLanguage: ''
};

export const PROGRAM_ATTRIBUTES_INITIAL_TOUCHED = {
    audioLanguage: false,
    descriptionLanguage: false,
    genres: false,
    isAnyGenreSelected: true,
    isAnyProductionCompanySelected: true,
    mostRecentNetworkCountry: false,
    mostRecentNetworkFull: false,
    mostRecentNetworkId: false,
    mostRecentNetworkRadio: false,
    originalNetworkCountry: false,
    originalNetworkFull: false,
    originalNetworkId: false,
    originalNetworkRadio: false,
    programTypes: false,
    programSubtypes: false,
    productionCompanies: false,
    releaseYear: false,
    sportsType: false,
    title: false,
    titleLanguage: false
};

export const PROGRAM_ATTRIBUTES_VALIDATION_SCHEMA = object({
    audioLanguage: string().nullable(),
    descriptionLanguage: string().nullable(),
    genres: array().of(string()),
    isAnyGenreSelected: boolean(),
    isAnyProductionCompanySelected: boolean(),
    mostRecentNetworkCountry: string().nullable(),
    mostRecentNetworkFull: object().nullable(),
    mostRecentNetworkId: string().nullable(),
    mostRecentNetworkRadio: string().nullable(),
    originalNetworkCountry: string().nullable(),
    originalNetworkFull: object().nullable(),
    originalNetworkId: string().nullable(),
    originalNetworkRadio: string().nullable(),
    programTypes: string().nullable(),
    programSubtypes: string().nullable(),
    productionCompanies: array().of(string()),
    releaseYear: number()
        .integer('Numerical whole digits only')
        .typeError('Numerical whole digits only')
        .min(PROGRAM_ATTRIBUTES_MIN_YEAR, '(Minimum Year of ${min})')
        .max(PROGRAM_ATTRIBUTES_MAX_YEAR, '(Maximum Year of ${max})')
        .nullable(),
    sportsType: string().nullable(),
    title: string()
        .min(1, '(Minimum of ${min} Character)')
        .max(PROGRAM_ATTRIBUTES_MAX_CHARACTER_LIMIT, '(Maximum ${max} Characters)')
        .nullable(),
    titleLanguage: string().nullable()
});

// --------
// Root ID
// --------

export const ROOT_ID_MAX_CHARACTER_LIMIT = 100;

export const ROOT_ID_INITIAL_VALUES = {
    descriptionLanguage: '',
    rootId: '',
    titleLanguage: ''
};

export const ROOT_ID_INITIAL_TOUCHED = {
    descriptionLanguage: false,
    rootId: false,
    titleLanguage: false
};

export const ROOT_ID_VALIDATION_SCHEMA = object({
    descriptionLanguage: string().nullable(),
    rootId: string()
        .min(1, '(Minimum of ${min} Character)')
        .max(ROOT_ID_MAX_CHARACTER_LIMIT, '(Maximum ${max} Characters)')
        .nullable(),
    titleLanguage: string().nullable()
});

// -----------
// Series ID
// -----------

export const SERIES_ID_MAX_CHARACTER_LIMIT = 100;

export const SERIES_ID_INITIAL_VALUES = {
    descriptionLanguage: '',
    seriesId: '',
    titleLanguage: ''
};

export const SERIES_ID_INITIAL_TOUCHED = {
    descriptionLanguage: false,
    seriesId: false,
    titleLanguage: false
};

export const SERIES_ID_VALIDATION_SCHEMA = object({
    descriptionLanguage: string().nullable(),
    seriesId: string()
        .min(1, '(Minimum of ${min} Character)')
        .max(SERIES_ID_MAX_CHARACTER_LIMIT, '(Maximum ${max} Characters)')
        .nullable(),
    titleLanguage: string().nullable()
});
