import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../config/Routes';
import { GNImage } from '../components/common/gnImage/GNImage';
import { getImageURLH } from '../utils/ImageUtils';
import { getProgramImageByRatio } from '../utils/ProgramUtils';
import { convertLanguageFromCode } from '../utils/GeneralUtils';
import { useDispatch } from 'react-redux';
import {
    gnviewAdvancedSearchFieldsClear,
    gnviewAdvancedSearchRootIdFieldsUpdate,
    gnviewAdvancedSearchSeriesIdFieldsUpdate,
    gnviewAdvancedSearchUpdateSearchBy
} from '../actions/GNViewActions';
import {
    ADVANCED_PROGRAM_SEARCH_ROOT_ID,
    ADVANCED_PROGRAM_SEARCH_SERIES_ID
} from './AdvancedSearch';
import _cloneDeep from 'lodash.clonedeep';
import { ROOT_ID_INITIAL_VALUES, SERIES_ID_INITIAL_VALUES } from './AdvancedSearchValidation';

export const DATES_IN_LOCAL_TIMEZONE = "All dates and times are in your local timezone";
export const TODAYS_GAMES = "Today's Games";
export const SPORTS_PLACEHOLDER = "-";
export const NO_GAMES_SCHEDULED = "No games scheduled";
export const TRY_ADJUSTING_CRITERIA = "Try adjusting your search criteria."
export const SPORTS_HEADER = "Sports";
export const TOOL_TIPS = {
    INFO_ICON_MESSAGE: "View sports matches here. Click into a sports match to view programs associated with it.",
    LEAGUE: "Select a specific sport first",
    TEAM: "Select a specific league first"
};
export const DROPDOWN_LABEL = {
    SPORT: "Sport",
    LEAGUE: "League",
    TEAM: "Team"
};
export const SPORTS_SEARCH_DROPDOWN_OPTIONS = {
    SPORT: { name: 'All Sports', value: '' },
    LEAGUE: { name: 'All Leagues', value: '' },
    TEAM: { name: 'All Teams', value: '' }
};
export const LEAGUE = 'League';
export const ALL_LANGUAGES_OPTION = { name: 'All Languages', value: ''};
export const INFO_ICON_MESSAGE = "View sports matches here. Click into a sports match to view programs associated with it.";
export const TITLE_LANGUAGE_DROPDOWN_LABEL = "Title Language";
export const DESC_LANGUAGE_DROPDOWN_LABEL = "Description Language";
export const SPORTS_NO_RELATED_PROGRAMS_HEADER = "Sorry, no results.";
export const SPORTS_NO_RELATED_PROGRAMS_MESSAGE = "There are no related programs for the given search.";

export const SPORTS_API_LANGUAGE = 'en-US';

// This may not be the complete set of Related Sport Event Types -> Will have to confirm with the Sports team
export const SPORTS_RELATED_SPORTS_EVENT_TYPES = {
    LEAGUE: "LEAGUE",
    LEAGUE_SEASON: "LEAGUE_SEASON",
    OVERALL: "OVERALL",
    PHASE: "PHASE" // Type details -> PHASE_GROUP, PHASE_ROUND, PHASE_MATCHROUND etc
};

/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
export const SPORTS_RESULTS_COLUMN_HEADERS = [
    {
        accessor: 'sports_info.title', Header: 'Sports Event', Cell: ({ row }) => {
            return <div className="title-container">
                <GNImage className="sports-image" url={getImageURLH(getProgramImageByRatio(row.original, '16:9'), '?h=100')} />
                <Link to={`${ROUTES.PROGRAM_DETAILS}/${row?.original?.tms_id}`} target='_blank' rel='noreferrer'>{row?.original?.sports_info?.title || row?.original?.sports_info?.titles?.[0]?.text || row?.original?.episode_info?.title || row?.original?.episode_info?.titles?.[0]?.text}</Link>
            </div> || null;
        }
    },
    { accessor: 'tms_id', Header: 'TMS ID', disableSortBy: true},
    { accessor: 'series_info.title', Header: 'Series', Cell: ({row}) => {
        return <Link to={`${ROUTES.PROGRAM_DETAILS}/${row?.original?.parent_tms_id}`} target='_blank' rel='noreferrer'>{row?.original?.series_info?.title}</Link>
    }},
    { accessor: (row) => convertLanguageFromCode(row?.sports_info?.titles[0].language) || null, Header: 'Title Lang.', Cell: ({value}) => {
        return value?.length > 0 ? value : null;
    }},
    { accessor: (row) => convertLanguageFromCode(row?.description_language) || null, Header: 'Descript. Lang.', Cell: ({value}) => {
        return value?.length > 0 ? value : null;
    }},
    { accessor: 'root_id', Header: 'Root ID', disableSortBy: true, Cell: ({ value }) => {
        const dispatch = useDispatch();
        return (
            <Link
                to={ROUTES.ADVANCED_SEARCH}
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                    dispatch(gnviewAdvancedSearchFieldsClear());
                    dispatch(gnviewAdvancedSearchUpdateSearchBy(ADVANCED_PROGRAM_SEARCH_ROOT_ID));
                    dispatch(gnviewAdvancedSearchRootIdFieldsUpdate({
                        ..._cloneDeep(ROOT_ID_INITIAL_VALUES),
                        rootId: value
                    }));
                }}
            >
                {value}
            </Link>
        );
    }},
    { accessor: 'series_info.series_id', Header: 'Series ID', disableSortBy: true, Cell: ({ value }) => {
        const dispatch = useDispatch();
        return (
            <Link
                to={ROUTES.ADVANCED_SEARCH}
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                    dispatch(gnviewAdvancedSearchFieldsClear());
                    dispatch(gnviewAdvancedSearchUpdateSearchBy(ADVANCED_PROGRAM_SEARCH_SERIES_ID));
                    dispatch(gnviewAdvancedSearchSeriesIdFieldsUpdate({
                        ..._cloneDeep(SERIES_ID_INITIAL_VALUES),
                        seriesId: value
                    }));
                }}
            >
                {value}
            </Link>
        );
    }}
];
/* eslint-enable react/prop-types */
/* eslint-enable react/display-name */
