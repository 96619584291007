import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gnviewGetProgramXML } from '../../../../actions/GNViewActions';
import { gnviewSelProgramDetails } from '../../../../reducers/ProgramDetailsReducer';
import { gnviewSendLogMessage } from '../../../../services/GeneralService';
import XMLViewer from 'react-xml-viewer';
import LoadingSpinner from '../../../../components/common/loadingSpinner/LoadingSpinner';
import './XMLTab.scss';

export const XMLTab = () => {
    const dispatch = useDispatch();
    const programDetails = useSelector(gnviewSelProgramDetails);
    const tmsId = programDetails?.tmsid;
    const [isLoading, setIsLoading] = useState(true);
    const [xml, setXML] = useState('');

    useEffect(() => {
        dispatch(gnviewGetProgramXML(tmsId)).then((response) => {
            setXML(response.result);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            dispatch(gnviewSendLogMessage(`gnviewGetProgramXML error: ${error.message}`, error));
        })
    }, [dispatch, tmsId]);

    return (
        <div className="gnview-xml-tab-content">
            {isLoading && <LoadingSpinner />}
            {!isLoading && xml && <XMLViewer xml={xml} />}
        </div>
    )
}

export default XMLTab;