// ISO 639-1 Language codes
export const LANGUAGES = {
    "af": "Afrikaans",
    "alg": "Algonquian languages",
    "ar": "Arabic",
    "ar-Latn": "Arabic (Rom.)",
    "arc": "Official Aramaic (700-300 BCE); Imperial Aramaic (700-300 BCE)",
    "az": "Azerbaijani",
    "be": "Belarusian",
    "bg": "Bulgarian",
    "bho": "Bhojpuri",
    "bm": "Bambara",
    "bn": "Bengali",
    "bo": "Tibetan Standard",
    "bs": "Bosnian",
    "ca": "Catalan",
    "cr": "Cree",
    "crp": "Creoles and pidgins",
    "cs": "Czech",
    "cy": "Welsh",
    "da": "Danish",
    "de": "German",
    "dz": "Dzongkha",
    "el": "Greek",
    "en": "English",
    "en-AU": "English (Australia)",
    "en-GB": "English (United Kingdom)",
    "en-IN": "English (India)",
    "es": "Spanish",
    "es-ES": "Castilian Spanish",
    "et": "Estonian",
    "eu": "Basque",
    "fa": "Persian",
    "fi": "Finnish",
    "fil": "Filipino; Pilipino",
    "fo": "Faroese",
    "fr-CA": "Canadian French",
    "fr-FR": "French",
    "gd": "Scottish Gaelic",
    "gl": "Galician",
    "gn": "Guaraní",
    "gu": "Gujarati",
    "haw": "Hawaiian",
    "he": "Hebrew",
    "hi": "Hindi",
    "hmn": "Hmong; Mong",
    "hr": "Croatian",
    "hu": "Hungarian",
    "hy": "Armenian",
    "id": "Indonesian",
    "inc-Latn": "Indic (Rom.)",
    "is": "Icelandic",
    "it": "Italian",
    "iu": "Inuktitut",
    "ja": "Japanese",
    "ja-Latn": "Japanese (Rom.)",
    "ka": "Georgian",
    "kal": "Kalaallisut; Greenlandic",
    "km": "Khmer",
    "kn": "Kannada",
    "ko": "Korean",
    "ko-Latn": "Korean (Rom.)",
    "ku": "Kurdish",
    "ky": "Kyrgyz",
    "la": "Latin",
    "lb": "Luxembourgish",
    "ln": "Lingala",
    "lo": "Lao",
    "lt": "Lithuanian",
    "lv": "Latvian",
    "mg": "Malagasy",
    "mi": "Māori",
    "mk": "Macedonian",
    "ml": "Malayalam",
    "mn": "Mongolian",
    "mr": "Marathi",
    "ms": "Malay",
    "mt": "Maltese",
    "nah": "Nahuatl languages",
    "ne": "Nepali",
    "nl": "Dutch",
    "no": "Norwegian",
    "nv": "Navajo",
    "oc": "Occitan",
    "pa": "Panjabi",
    "pap": "Papiamento",
    "pl": "Polish",
    "ps": "Pashto",
    "pt": "Portuguese",
    "pt-BR": "Brazilian Portuguese",
    "ro": "Romanian",
    "rom": "Romany",
    "ru": "Russian",
    "si": "Sinhala",
    "sit": "Sino-Tibetan languages",
    "sk": "Slovak",
    "sl": "Slovene",
    "sm": "Samoan",
    "smi": "Sami languages",
    "so": "Somali",
    "sq": "Albanian",
    "sr": "Serbian",
    "sv": "Swedish",
    "sw": "Swahili",
    "ta": "Tamil",
    "te": "Telugu",
    "tg": "Tajik",
    "th": "Thai",
    "th-Latn": "Thai (Rom.)",
    "tl": "Tagalog",
    "tr": "Turkish",
    "uk": "Ukrainian",
    "und": "Undetermined",
    "ur": "Urdu",
    "vi": "Vietnamese",
    "wo": "Wolof",
    "yi": "Yiddish",
    "zh": "Chinese",
    "zu": "Zulu"
};