import isEmpty from 'lodash.isempty';
import { NO_SEASON } from '../constants/Program';
/*  Look through episodes and grab all the unique seasons
    replace = false -> Returns an array like [4, 3, 2, 1];
    replace = true -> Returns an array like [4, 3, 2, 1, "No Season"];
**/
export const getUniqueSeasons = (episodes, replace = false) => {
    const seasons = episodes?.length > 0 ? episodes.map((season) => season.season_number || season.season_year) : [];
    const uniqueSeasons = [...new Set(seasons)];
    uniqueSeasons.sort((a, b) => b - a);
    // If there's a null element, remove it, then add a "No season" is replace is true
    if (uniqueSeasons.indexOf(null) !== -1 || uniqueSeasons.indexOf(undefined) !== -1) {
        const filteredSeasons = uniqueSeasons.filter((s) => s);
        if (replace) {
            filteredSeasons.push(NO_SEASON);
        }
        return filteredSeasons;
    }
    return uniqueSeasons;
}

/*
    Given an array of episodes, sort into an object grouped by seasons
    Returns {
        4: [Array of episodes for season 4],
        3: [Array of episodes for season 3],
        2: [Array of episodes for season 2],
        1: [Array of episodes for season 1],
        'No Season': [Array of episodes for season of 'No Season'],
    }
**/
export const groupBySeasons = (episodes, replacement = NO_SEASON) => {
    return episodes.reduce((acc, ep) => {
        const seasonNum = ep.season_number || ep.season_year || replacement;
        acc[seasonNum] = [...acc[seasonNum] || [], ep];
        return acc;
    }, {});
}

// Prepare a program object with seasons and episodes for checkbox selection
export const formatProgramWithSeasonsAndEpisodes = (program, episodes, selectedEpisodes = []) => {
    if (program?.tmsid && episodes?.length > 0) {
        // GNClientTable needs to be 'isSelected' for the checkbox
        const episodesWithExtra = episodes.map(ep => ({
            ...ep, isSelected: selectedEpisodes.includes(ep.tms_id) || isEmpty(selectedEpisodes),
            className: ep.series_tms_id + '-season-' + (ep.season_number || NO_SEASON),
            uniqId: ep.tms_id
        }));
        return {
            ...program,
            uniqueSeasons: getUniqueSeasons(episodesWithExtra, true),
            seasonsAndEpisodes: groupBySeasons(episodesWithExtra, NO_SEASON),
            episodeCount: episodesWithExtra.length
        }
    }
    return program;
}

export const formatSeasonAndEpisodeNumber = (seasonNum, epNum) => {
    if (seasonNum && epNum) {
        return `S${seasonNum}E${epNum}`;
    }
    return "";
}

export const formatSeasons = (seasons) => {
    const formattedSeasons = seasons?.length > 0 ? seasons.map(season => ({name: `${season !== NO_SEASON ? 'Season ' : ''}${season.toString()}`, value: season})) : [];
    if (formattedSeasons[0]?.name === NO_SEASON) {
        formattedSeasons.push(formattedSeasons.shift());
    }
    return formattedSeasons;
}

/**
 * Filters array of episodes by season
 * @param {string|number} seasonNum - Either the string "No Season" or a number
 * @param {[object]} episodes  - Array of episodes to filter by
 * @returns {[object]} filtered episodes
 */
export const filterEpisodesBySeason = (seasonNum, episodes) => {
    return episodes?.length > 0 ? episodes.filter((ep) => {
        // Checking on the root level for season_number or season_year matches
        const season = ep?.season_number || ep?.season_year;
        // Checking either episode_info or sports_info
        const seasonInfo = ep?.episode_info?.season || ep?.sports_info?.season_year;
        if (seasonNum === NO_SEASON) {
            return season === null || !season && !seasonInfo;
        } else {
            return season === seasonNum || parseInt(seasonInfo) === seasonNum;
        }
    }) : [];
}