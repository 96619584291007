import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import "./GNProgressBar.scss";

export const GNProgressBar = (props) => {
    return (
        <div className={ClassNames('gnview-progress-bar-container', {'active': props.active})}>
            <div className="progress-bar" />
            {props.label && <div className="progress-label">{props.label}</div>}
        </div>
    )
}

GNProgressBar.propTypes = {
    active: PropTypes.bool,
    label: PropTypes.string
};

export default GNProgressBar;