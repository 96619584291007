import React from 'react';
import { useSelector } from 'react-redux';
import ErrorBoundary from '../../components/common/errorBoundary/ErrorBoundary';
import "./Status.scss";
import { gvauthSelEmail } from '../../reducers/GNVAuthReducer';
import { gnviewGetUserCases } from '../../actions/GNViewActions';
import {
    CASE_STATUS_TABLE_COLUMN_HEADERS,
    REPORT_ISSUE_STATUS_NO_DATA_MESSAGE_HEADER,
    REPORT_ISSUE_STATUS_NO_DATA_MESSAGE,
    REPORT_ISSUE_STATUS_TABLE_HEADER
} from '../../constants/ReportAnIssue';
import GNServerTable from '../../components/common/gnServerTable/GNServerTable';
import NoDataMessage from '../../components/common/noDataMessage/NoDataMessage';

export const Status = () => {
    const email = useSelector(gvauthSelEmail);

    const fetchCaseStatusData = (pageIndex, tableSortBy) => {
        const sortField = tableSortBy[0]?.id || 'lastModifiedDate';
        const sortOrder = tableSortBy[0]?.desc ? 'desc' : 'asc';

        return gnviewGetUserCases(email, sortField, sortOrder);
    };

    return (
        <ErrorBoundary>
            <div className="gnview-status-container">
                <div className="status-header-container">
                    <span className="status-header">{REPORT_ISSUE_STATUS_TABLE_HEADER}</span>
                </div>
                <GNServerTable
                    className='case-status-results-table'
                    columns={CASE_STATUS_TABLE_COLUMN_HEADERS}
                    fetchData={fetchCaseStatusData}
                    settings={{
                        sortBy: [
                            {
                                desc: true,
                                id: 'lastModifiedDate'
                            }
                        ]
                    }}
                    showPagination={false}
                    noDataMessage={<NoDataMessage isStatusTable={true} header={REPORT_ISSUE_STATUS_NO_DATA_MESSAGE_HEADER} message={REPORT_ISSUE_STATUS_NO_DATA_MESSAGE} />}
                />
            </div>
        </ErrorBoundary>
    );
}

export default Status;

