import React, { useEffect, useRef, useState } from 'react';
import { ButtonGroup, Button, Table } from 'react-bootstrap';
import ClassNames from 'classnames';
import "./GNServerTable.scss";
import { useTable, useSortBy, usePagination, useExpanded } from 'react-table';
import PropTypes from 'prop-types';
import { numberWithCommas } from '../../../utils/GeneralUtils';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { useDispatch } from 'react-redux';
import { gnviewSendLogMessage } from '../../../services/GeneralService';

export const GNServerTable = ({className, columns, fetchData, noDataMessage, paginationBottom, rowExpansionComponent, settings, showPagination }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // Note: if using sticky column, this would only work for one column (ideally the first column), default is pinned = true
    // If you want to use sticky column add `pinnable: true` to the column you want pinned
    const [isPinned, setIsPinned] = useState(true);
    const stickyCol = columns.filter((c) => c.pinnable)?.[0];
    // These need to be a ref since we don't want fetchData to have startAfter or totalRows as a dependency for the useEffect and get called an extra time after we set it
    const totalRows = useRef(0);
    const startAfter = useRef({});

    const options = {
        columns: React.useMemo(() => [...columns], [columns]),
        data,
        initialState: {
            ...settings
        },
        // ** Sorting settings ** //
        // react-table won't handle sorting, we'll need to handle sorting with 'fetchData' and update 'data' passed in
        manualSortBy: true,
        // Disable multi-column sort, only one column can we sorted at a time
        disableMultiSort: true,
        // ** Pagination settings ** //
        // react-table won't automatically handle pagination
        manualPagination: true,
        // prevent going to page 1 once data changes
        autoResetPage: false,
        // Required if doing manual pagination, needed for canNextPage
        pageCount: Math.ceil(totalRows?.current / (settings?.pageSize || 20))
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        gotoPage,
        state: { pageIndex, pageSize, sortBy }
    } = useTable(options, useSortBy, useExpanded, usePagination);

    // reset the page index to 0 when sorting changes
    React.useEffect(() => {
        gotoPage(0);
    }, [gotoPage, sortBy]);


    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchData(pageIndex, sortBy, startAfter.current)).then((response) => {
            if (response?.result) {
                setData(response.result);
            }
            if (response?.headers?.total_count) {
                totalRows.current = parseInt(response.headers.total_count);
            }
            if (response?.headers?.start_after) {
                startAfter.current = ({ ...startAfter.current, [pageIndex + 1]: parseInt(response.headers.start_after) });
            }
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            dispatch(gnviewSendLogMessage(`GNServerTable fetchData error: ${error.message}`, error));
        });
    }, [dispatch, fetchData, pageIndex, sortBy]);

    const currentRowStart = (pageIndex * pageSize) + 1;
    const currentRowEnd = ((pageIndex * pageSize) + page.length);

    return (
        <div className={'gn-server-table-wrapper'}>
            {!paginationBottom && isLoading && <LoadingSpinner />}
            {!paginationBottom && showPagination && <div className="pagination-top-container">
                {!isLoading && <div className="pagination-buttons">
                    <span className="page-count">{numberWithCommas(currentRowStart)} - {numberWithCommas(currentRowEnd)} of {numberWithCommas(totalRows.current)}</span>
                    <ButtonGroup>
                        <Button variant="light" onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <i className="fas fa-angle-left" />
                        </Button>
                        <Button variant="light" onClick={() => nextPage()} disabled={!canNextPage}>
                            <i className="fas fa-angle-right" />
                        </Button>
                    </ButtonGroup>
                </div>}
            </div>}
            {data.length > 0 && !isLoading && <Table className={ClassNames('gn-server-table', className)} {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup, i) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                            {headerGroup.headers.map((column, j) => {
                                return (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} key={j} {...stickyCol && {className: ClassNames({'is-pinned': isPinned && column.pinnable}) }}>
                                        {column.pinnable &&
                                            <span>&nbsp;<i className={ClassNames('lock-icon fas', {'fa-lock': isPinned, 'fa-lock-open': !isPinned})} onClick={(e) => {
                                                e.stopPropagation();
                                                setIsPinned(prevIsPinned => !prevIsPinned)
                                            }}/></span>}
                                        {column.render('Header')}
                                        {column.isSorted && column.canSort && <span>&nbsp;<i className={ClassNames('sort-icon fas', {'fa-arrow-down': column.isSortedDesc, 'fa-arrow-up': !column.isSortedDesc})} /></span>}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <React.Fragment key={i}>
                                <tr {...row.getRowProps()} {... (row.original?.canExpand && row.getToggleRowExpandedProps())} className={ClassNames({'is-expanded': row.isExpanded})}>
                                    {row.cells.map((cell, j) => {
                                        return (
                                            <td {...cell.getCellProps()} key={j} {...stickyCol && { className: ClassNames({ 'is-pinned': isPinned && cell?.column?.id === stickyCol?.id }) }}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                                {row.isExpanded && <tr className="row-expansion">
                                    <td colSpan={columns.length} className="row-expansion-cell">
                                        <div className="row-expansion-component-wrapper">
                                            {/* Clone rowExpansionComponent so we can add `row` to it */}
                                            {React.cloneElement(rowExpansionComponent, {...rowExpansionComponent.props, row})}
                                        </div>
                                    </td>
                                </tr>}
                            </React.Fragment>
                        )
                    })}
                </tbody>
            </Table>}
            {data.length <= 0 && !isLoading && (
                <div className='no-data'>
                    {noDataMessage}
                </div>
            )}
            {isLoading && <LoadingSpinner />}
            {data.length > 0 && showPagination && paginationBottom && <div className="pagination-container">
                <span className="page-count">{numberWithCommas(currentRowStart)} - {numberWithCommas(currentRowEnd)} of {numberWithCommas(totalRows.current)}</span>
                <ButtonGroup>
                    <Button variant="light" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <i className="fas fa-angle-left" />
                    </Button>
                    <Button variant="light" onClick={() => nextPage()} disabled={!canNextPage}>
                        <i className="fas fa-angle-right" />
                    </Button>
                </ButtonGroup>
            </div>}
        </div>
    )
};

GNServerTable.defaultProps = {
    paginationBottom: true,
    settings: {
        pageSize: 20
    },
    showPagination: true
};

GNServerTable.propTypes = {
    // className: optional css class name
    className: PropTypes.string,
    // columns: Must have id, accessor, Header options https://react-table.tanstack.com/docs/api/useTable
    columns: PropTypes.array.isRequired,
    // fetchData: Function for grabbing data to fill the table, will be called initially
    fetchData: PropTypes.func.isRequired,
    // noDataMessage: Component to display when there is no data
    noDataMessage: PropTypes.func,
    // paginationBottom: default the pagination buttons will be at the bottom and sticky. Otherwise you can set to false to have the pagination on the top right.
    paginationBottom: PropTypes.bool,
    // rowExpansionComponent: Element to render when row is expanded
    rowExpansionComponent: PropTypes.element,
    // settings: options to pass in for initialState for the table. pageSize, sortBy etc.
    settings: PropTypes.object,
    // showPagination: optional boolean to disables pagination, defaults to true
    showPagination: PropTypes.bool
};

export default GNServerTable;