import { SELECTED_FILTERS_INITIAL_VALUES, DATA_CATEGORIES_FILTERS } from '../constants/DataAlerts';

export const generateDateFilters = (windowStart, windowEnd) => {
    if (!windowStart) {
        return [];
    }
    const options = Array.from({ length: 7 }, (_, index) => {
        const currentDate = windowStart.clone().add(index, "days");
        const name = [currentDate.format("ddd").toUpperCase(), currentDate.format('MM/DD/YY')].join('  ');
        return ({ name, value: currentDate.format("MM/DD/YY") });
    });
    options.unshift({
        name: `${windowStart.format("MM/DD/YY")} - ${windowEnd.format(
            "MM/DD/YY"
        )}`,
        value: SELECTED_FILTERS_INITIAL_VALUES.DATE.value
    });
    return options;
};

export const generateLabelFilters = (subscriptions) => {
    const labels = subscriptions.reduce((acc, subscription) => [...acc, ...subscription.labels], []);
    const uniqueLabels = new Set(labels.map(({ label }) => label));
    const options = Array.from(uniqueLabels, label => {
        return { name: label, value: label };
    })
    options.unshift(SELECTED_FILTERS_INITIAL_VALUES.LABELS);
    return options;
};

export const generateChannelFilters = (subscriptions) => {
    const options = subscriptions.reduce((acc, subscription) => [...acc, { name: subscription.station_name, value: subscription.station_id }], []);
    options.unshift(SELECTED_FILTERS_INITIAL_VALUES.CHANNELS);
    return options;
};

export const generateDataCategoryFilters = () => {
    const options = DATA_CATEGORIES_FILTERS.map((dc) => ({
        name: dc,
        value: dc
    }));
    options.unshift(SELECTED_FILTERS_INITIAL_VALUES.DATA_CATEGORIES);
    return options;
}