import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useLocation } from 'react-router-dom';
import GNDropdown from "../../components/common/gnDropdown/GNDropdown";
import moment from "moment";
import { Button } from "react-bootstrap";
import classNames from "classnames";
import { gnviewSelDataAlertsAlerts, gnviewSelDataAlertsSubscriptions } from '../../reducers/DataAlertsReducer';
import {
    generateChannelFilters,
    generateDataCategoryFilters,
    generateDateFilters,
    generateLabelFilters
} from "../../utils/DataAlertsUtils";

const Filters = ({ setFilteredAlerts, setSelectedSubscriptions, windowStart, windowEnd }) => {
    const subscriptions = useSelector(gnviewSelDataAlertsSubscriptions);
    const allAlerts = useSelector(gnviewSelDataAlertsAlerts);
    const dateOptions = generateDateFilters(windowStart, windowEnd);
    const labelOptions = generateLabelFilters(subscriptions);
    const channelOptions = generateChannelFilters(subscriptions);
    const dataCategoryOptions = generateDataCategoryFilters();

    const [dateFilter, setDateFilter] = useState(dateOptions[0].value);
    const [labelsFilter, setLabelsFilter] = useState(labelOptions[0].value);
    const [channelsFilter, setChannelsFilter] = useState(channelOptions[0].value);
    const [dataCategoriesFilter, setDataCategoriesFilter] = useState(dataCategoryOptions[0].value);
    const location = useLocation();

    useEffect(() => {
        let newSubscriptions = [...subscriptions];
        if (channelsFilter) {
            newSubscriptions = newSubscriptions.filter(subscription => {
                return subscription.station_id === channelsFilter
            })
        }
        if (labelsFilter) {
            newSubscriptions = newSubscriptions.filter(subscription => {
                return subscription.labels.some(({ label }) => label === labelsFilter);
            })
        }

        setSelectedSubscriptions(newSubscriptions);

        if (!channelsFilter && !labelsFilter) {
            setSelectedSubscriptions(subscriptions);
        }
    }, [channelsFilter, labelsFilter, subscriptions, setSelectedSubscriptions])

    useEffect(() => {
        const newAlerts = {...allAlerts};
        if (dataCategoriesFilter) {
            Object.keys(newAlerts).forEach(key => {
                newAlerts[key] = newAlerts[key].filter(alert => alert.alertCategory === dataCategoriesFilter);
            })
        }
        if (dateFilter) {
            Object.keys(newAlerts).forEach(key => {
                newAlerts[key] = newAlerts[key].filter(alert => {
                    return moment(alert.scheduleDate).format("MM/DD/YY") === moment(dateFilter).format("MM/DD/YY")
                });
            })
        }

        setFilteredAlerts(newAlerts);

        // Resetting the alerts
        if (!dataCategoriesFilter && !dateFilter) {
            setFilteredAlerts(allAlerts);
        }
    }, [dataCategoriesFilter, dateFilter, setFilteredAlerts, allAlerts]);

    useEffect(() => {
        handleResetDataAlerts();
    }, [location.search, handleResetDataAlerts]);

    const filters = [
        {
            id: "date-filter",
            options: dateOptions,
            handleChange: (item) => setDateFilter(item),
            value: dateFilter,
            handleClear: () => setDateFilter(dateOptions[0].value),
            showClearIcon: dateFilter !== '',
            renderOption: (props, option) => {
                return (
                    <button className={classNames('select-search__option', 'date-filter-option')} {...props}>
                        {option.index === 0 ? <><span className="date-filter-day">WEEK</span><span className="date-filter-date">{option.name}</span></> : <><span className="date-filter-day">{option.name.split('  ')[0]}</span><span className="date-filter-date">{option.name.split('  ')[1]}</span></>}
                    </button>
                )
            }
        },
        {
            id: "channels-filter",
            options: channelOptions,
            handleChange: (item) => setChannelsFilter(item),
            value: channelsFilter,
            handleClear: () => setChannelsFilter(channelOptions[0].value),
            showClearIcon: channelsFilter !== ''
        },
        {
            id: "labels-filter",
            options: labelOptions,
            handleChange: (item) => setLabelsFilter(item),
            value: labelsFilter,
            handleClear: () => setLabelsFilter(labelOptions[0].value),
            showClearIcon: labelsFilter !== ''
        },
        {
            id: "data-categories-filter",
            options: dataCategoryOptions,
            handleChange: (item) => setDataCategoriesFilter(item),
            value: dataCategoriesFilter,
            handleClear: () => setDataCategoriesFilter(dataCategoryOptions[0].value),
            showClearIcon: dataCategoriesFilter !== ''
        }
    ];

    const handleResetDataAlerts = useCallback(() => {
        setChannelsFilter(channelOptions[0].value);
        setDateFilter(dateOptions[0].value);
        setLabelsFilter(labelOptions[0].value);
        setDataCategoriesFilter(dataCategoryOptions[0].value);
    }, [channelOptions, dateOptions, labelOptions, dataCategoryOptions]);

    return <>
        {filters.map(({ id, ...rest }) => {
            return <GNDropdown key={id} {...rest} />;
        })}
        <Button className='reset-filters-button' variant='link' onClick={handleResetDataAlerts}>RESET</Button>
    </>
};

Filters.propTypes = {
    setFilteredAlerts: PropTypes.any,
    setSelectedSubscriptions: PropTypes.any,
    windowStart: PropTypes.any,
    windowEnd: PropTypes.any
};

export default Filters;
