import React from 'react';
import { connect } from 'react-redux';
import { Button, Tooltip } from 'react-bootstrap';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { gvauthHasExportEntitlements } from '../../../reducers/GNVAuthReducer';
import PropTypes from 'prop-types';
import './ExportButton.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { LEARN_MORE_LINK } from '../../../constants/ContactUs';

export const ExportButton = (props) => {
    return (
        <div className='gnview-export-button-container'>
            {props.hasExportEntitlements && <Button className='gnview-export-button' variant='primary' onClick={props.isLoading ? null : props.exportFunction} disabled={!props.hasExportEntitlements}>{props.isLoading ? <LoadingSpinner /> : `Export as ${props.type}`}</Button>}
            {!props.hasExportEntitlements && <OverlayTrigger delay={{ hide: 1000 }} overlay={<Tooltip id="untitled-export">You are not entitled to export this report. Contact your Customer Care rep to upgrade your account or submit a request <a target="_blank" rel="noreferrer" href={LEARN_MORE_LINK}>here</a></Tooltip>} flip={true}>
                <div className='tooltip-container'>
                    <Button className='disabled-export-button' variant='primary' disabled={true}>{`Export as ${props.type}`}</Button>
                </div>
            </OverlayTrigger>}
        </div>
    )
};

const mapStateToProps = state => {
    return {
        hasExportEntitlements: gvauthHasExportEntitlements(state)
    };
};

ExportButton.propTypes = {
    type: PropTypes.string.isRequired,
    exportFunction: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasExportEntitlements: PropTypes.bool
};

export default connect(mapStateToProps)(ExportButton);