import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./SearchBar.scss";
import { Popover, Tooltip } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { sportsGetLeaguesOptions, sportsGetSportsOptions, sportsGetTeamsOptions, sportsSetLeagueId, sportsSetTeamId } from '../../../actions/SportsActions';
import { SPORTS_HEADER, DROPDOWN_LABEL, TOOL_TIPS, SPORTS_SEARCH_DROPDOWN_OPTIONS } from '../../../constants/Sports';
import { formatDropdownOptions } from '../../../utils/SportUtils';
import { gnviewSendLogMessage } from "../../../services/GeneralService";
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import GNDropdown from '../../../components/common/gnDropdown/GNDropdown';
import DateCarousel from './DateCarousel';
import { sportsSetSportId } from '../../../actions/SportsActions';
import { sportsSelLeagueId, sportsSelSportId, sportsSelTeamId } from '../../../reducers/SportsReducer';
import _isEmpty from 'lodash.isempty';

export const SearchBar = () => {
    const dispatch = useDispatch();
    const selectedSport = useSelector(sportsSelSportId);
    const selectedLeague = useSelector(sportsSelLeagueId);
    const selectedTeam = useSelector(sportsSelTeamId);
    const [sportsOptions, setSportsOptions] = useState([]);
    const [leagueOptions, setLeagueOptions] = useState([]);
    const [teamOptions, setTeamOptions] = useState([]);
    const [disabledLeague, setDisabledLeague] = useState(true);
    const [disabledTeam, setDisabledTeam] = useState(true);

    useEffect(() => {
        // Sport
        if (_isEmpty(sportsOptions)) {
            dispatch(sportsGetSportsOptions()).then((response) => {
                if (response.result) {
                    const options = formatDropdownOptions(response.result.sports, DROPDOWN_LABEL.SPORT);
                    setSportsOptions(options);
                }
            }).catch((error) => {
                dispatch(gnviewSendLogMessage((`sportsGetSportsOptions error: ${error.message}`, error)));
            });
        }

        // League
        if (!_isEmpty(sportsOptions) && !_isEmpty(selectedSport)) {
            dispatch(sportsGetLeaguesOptions(selectedSport))
                .then((response) => {
                    if (response?.result) {
                        const options = formatDropdownOptions(response.result.leagues, DROPDOWN_LABEL.LEAGUE);
                        setLeagueOptions(options);
                        setDisabledLeague(false);
                    }
                })
                .catch((error) => {
                    dispatch(
                        gnviewSendLogMessage(
                            (`sportsGetLeaguesOptions error: ${error.message}`, error)
                        )
                    );
                });
        }

        // Team
        if (!_isEmpty(sportsOptions) && !_isEmpty(selectedSport) && !_isEmpty(selectedLeague)) {
            dispatch(sportsGetTeamsOptions(selectedLeague))
                .then((response) => {
                    if (response?.result) {
                        const options = formatDropdownOptions(response.result.teams, DROPDOWN_LABEL.TEAM);
                        setTeamOptions(options);
                        setDisabledTeam(false);
                    }
                })
                .catch((error) => {
                    dispatch(
                        gnviewSendLogMessage(
                            (`sportsGetTeamsOptions error: ${error.message}`, error)
                        )
                    );
                });
        }
    }, [sportsOptions, dispatch, selectedSport, selectedLeague]);

    const handleResetDropdowns = (dropdownType, id = '') => {
        const resetLeague = () => {
            // if user clicked clear button on either sport or league dropdown - disable league dropdown
            if (id?.length === 0) {
                setDisabledLeague(true);
            }
            setLeagueOptions([]);
            dispatch(sportsSetLeagueId(SPORTS_SEARCH_DROPDOWN_OPTIONS.LEAGUE.value));
        };

        const resetTeam = () => {
            // if user changed sport dropdown selection OR clicked clear button on either sport or league dropdown - disable team dropdown
            if (dropdownType === DROPDOWN_LABEL.SPORT || id?.length === 0) {
                setDisabledTeam(true);
            }
            setTeamOptions([]);
            dispatch(sportsSetTeamId(SPORTS_SEARCH_DROPDOWN_OPTIONS.TEAM.value));
        };

        if (dropdownType === DROPDOWN_LABEL.SPORT) {
            resetLeague();
            resetTeam();
        } else if (dropdownType === DROPDOWN_LABEL.LEAGUE) {
            resetTeam();
        }
    };

    const infoPopover = (
        <Popover>
            <Popover.Content>{TOOL_TIPS.INFO_ICON_MESSAGE}</Popover.Content>
        </Popover>
    );

    return (
        <ErrorBoundary>
            <div className="gnview-sports-search-bar">
                <div className="search-bar-inner">
                    <div className='gnview-sports-search-header'>
                        <span className="header-message">
                            {SPORTS_HEADER}
                        </span>
                        <OverlayTrigger trigger='hover' placement='top' overlay={infoPopover} delay={500}>
                            <i className='fas fa-circle-info' />
                        </OverlayTrigger>
                    </div>
                    {sportsOptions.length > 0 && (
                        <div className="sports-dropdown-container">
                            <GNDropdown
                                handleChange={(item) => {
                                    if (item === selectedSport) {
                                        return;
                                    }
                                    handleResetDropdowns(DROPDOWN_LABEL.SPORT, item);
                                    dispatch(sportsSetSportId(item));
                                }}
                                handleClear={() => {
                                    dispatch(sportsSetSportId(SPORTS_SEARCH_DROPDOWN_OPTIONS.SPORT.value));
                                    handleResetDropdowns(DROPDOWN_LABEL.SPORT);
                                }}
                                label={DROPDOWN_LABEL.SPORT}
                                options={sportsOptions}
                                value={selectedSport}
                            />
                            {disabledLeague ? (
                                <OverlayTrigger
                                    overlay={<Tooltip>{TOOL_TIPS.LEAGUE}</Tooltip>}
                                    placement='bottom'
                                >
                                    <div className='disabled-dropdown'>
                                        <GNDropdown
                                            handleChange={null}
                                            disabled={disabledLeague}
                                            label={DROPDOWN_LABEL.LEAGUE}
                                            options={[SPORTS_SEARCH_DROPDOWN_OPTIONS.LEAGUE]}
                                            value={selectedLeague}
                                        />
                                    </div>
                                </OverlayTrigger>
                            ) : (
                                <GNDropdown
                                    disabled={disabledLeague}
                                    handleChange={(item) => {
                                        if (item === selectedLeague) {
                                            return;
                                        }
                                        handleResetDropdowns(DROPDOWN_LABEL.LEAGUE, item);
                                        dispatch(sportsSetLeagueId(item));
                                    }}
                                    handleClear={() => {
                                        handleResetDropdowns(DROPDOWN_LABEL.LEAGUE);
                                        dispatch(sportsSetLeagueId(SPORTS_SEARCH_DROPDOWN_OPTIONS.LEAGUE.value))
                                    }}
                                    label={DROPDOWN_LABEL.LEAGUE}
                                    options={leagueOptions}
                                    value={selectedLeague}
                                />
                            )}
                            {disabledTeam ? (
                                <OverlayTrigger
                                    overlay={<Tooltip>{TOOL_TIPS.TEAM}</Tooltip>}
                                    placement='bottom'
                                >
                                    <div className='disabled-dropdown'>
                                        <GNDropdown
                                            disabled={disabledTeam}
                                            label={DROPDOWN_LABEL.TEAM}
                                            options={[SPORTS_SEARCH_DROPDOWN_OPTIONS.TEAM]}
                                            value={selectedTeam}
                                        />
                                    </div>
                                </OverlayTrigger>
                            ) : (
                                <GNDropdown
                                    handleChange={(item) => dispatch(sportsSetTeamId(item))}
                                    handleClear={() =>
                                        dispatch(sportsSetTeamId(SPORTS_SEARCH_DROPDOWN_OPTIONS.TEAM.value))
                                    }
                                    label={DROPDOWN_LABEL.TEAM}
                                    options={teamOptions}
                                    value={selectedTeam}
                                />
                            )}
                        </div>
                    )}
                </div>
                <DateCarousel />
            </div>
        </ErrorBoundary>
    )
}

export default SearchBar;
