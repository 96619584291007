import PropTypes from 'prop-types'
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {gnviewSelProgramDetails} from '../../../../reducers/ProgramDetailsReducer';
import GNCard from '../../../../components/common/gnCard/GNCard';
import GNRadioGroup from "../../../../components/common/gnRadioGroup/GNRadioGroup";
import { filterObjsWithKeyValueInArr, getFirstElement, getObjsFilter, getObjsFind, replaceNull } from "../../../../utils/GeneralUtils";
import {getImgCategories, getImage, sortImagesArray} from '../../../../utils/ImageUtils';
import "./ImagesTab.scss";
import { gvauthHasMediaCloudEntitlements, gvauthGetMediaCloudURL, gvauthIsAdmin, gvauthHasStreamId } from "../../../../reducers/GNVAuthReducer";
import GNImagesTabImages from '../../../../components/common/gnImagesTabImages/GNImagesTabImages';
import { IMAGES_SUBTABS, NO_SEASON, RESTRICTED_IMAGES } from '../../../../constants/Program';

export class ImagesTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            programImages: [],
            programImgCategories: [],
            programImgCategorySelected: null,
            programCategoryImages: [],
            allSeasons: [],
            seasonNumberSelected: null,
            allSeasonsWithImages: [],
            seasonSelected: null,
            seasonSelectedImages: [],
            seasonImgCategories: [],
            seasonImgCategorySelected: null,
            seasonSelectedCategoryImages: [],
            showRestrictedImages: this.props.isAdmin || this.props.hasStreamId,
            subTabSelected: IMAGES_SUBTABS.programImages
        }
    }

    getUniqueSeasons(seasonObjs) {
        const seasons = seasonObjs?.length > 0 ? seasonObjs.map(seasonObj => seasonObj.seasonNumber) : [];
        const uniqueSeasons = [...new Set(seasons)].map(season => parseInt(season)).sort((a, b) => b - a).map(season => season.toString());
        replaceNull(uniqueSeasons, NO_SEASON);
        return uniqueSeasons;
    }

    getSeasonImages(imgObj) {
        return {
            seasonNumber: imgObj.season_number,
            seasonImages: (imgObj.images?.length > 0 && this.props.hasMediaCloudEntitlements) ? sortImagesArray(imgObj.images.map(img => getImage(img, this.props.mediaCloudURL))) : []
        }
    }

    componentDidMount() {
        const programImages = (this.props.programDetails.images?.length > 0 && this.props.hasMediaCloudEntitlements) ? sortImagesArray(this.props.programDetails.images.map(img => getImage(img, this.props.mediaCloudURL))) : [];
        const programImgCategories = getImgCategories(programImages, this.state.showRestrictedImages);
        const programImgCategorySelected = getFirstElement(programImgCategories);
        const restrictedProgramImages = filterObjsWithKeyValueInArr(programImages, RESTRICTED_IMAGES.PROVIDER, RESTRICTED_IMAGES.RESTRICTED, true);
        if (restrictedProgramImages?.length === 0 && this.state.showRestrictedImages && programImgCategories?.[programImgCategories?.length - 1] === RESTRICTED_IMAGES.RESTRICTED) {
            programImgCategories.pop();
        }
        const removedRestrictedProgramImages = filterObjsWithKeyValueInArr(programImages, RESTRICTED_IMAGES.PROVIDER, RESTRICTED_IMAGES.RESTRICTED);
        let programCategoryImages = this.organizeExpiredImages(getObjsFilter(removedRestrictedProgramImages, 'category', programImgCategorySelected, 'Uncategorized'));
        // the abov e load assumes there is a mix of restricted and non-restricted images and only works when there are one or more NON-restricted images
        // since 'restricted' isn't a real category type (as in, restricted images can have one or more categories), we need to load the restricted images separately if that's all there is.
        // if the number of images and restricted images are the same, and our default category is empty, all images are restricted and get added to the 'restricted' pseudo-category
        if (restrictedProgramImages?.length === programImages?.length && programCategoryImages.length === 0 && this.state.showRestrictedImages) {
            programCategoryImages = this.organizeExpiredImages(restrictedProgramImages);
        }
        const allSeasonsWithImages = this.props.programDetails.season_images?.length > 0 ? this.props.programDetails.season_images.map(imgObj => this.getSeasonImages(imgObj)).map(imgObj => {
            return {...imgObj, seasonImgCategories: getImgCategories(imgObj.seasonImages)}
        }) : [];
        const allSeasons = this.getUniqueSeasons(allSeasonsWithImages);
        const seasonNumberSelected = getFirstElement(allSeasons);
        const seasonSelected = getObjsFind(allSeasonsWithImages, 'seasonNumber', seasonNumberSelected, NO_SEASON);
        const seasonSelectedImages = seasonSelected?.seasonImages || [];
        const seasonImgCategories = seasonSelected?.seasonImgCategories || [];
        const seasonImgCategorySelected = getFirstElement(seasonImgCategories);
        const removedRestrictedSeasonImages = filterObjsWithKeyValueInArr(seasonSelectedImages, RESTRICTED_IMAGES.PROVIDER, RESTRICTED_IMAGES.RESTRICTED);
        const seasonSelectedCategoryImages = this.organizeExpiredImages(getObjsFilter(removedRestrictedSeasonImages, 'category', seasonImgCategorySelected, 'Uncategorized'));
        this.setState({
            isLoading: false,
            programImages,
            programImgCategories,
            programImgCategorySelected,
            programCategoryImages,
            allSeasons,
            seasonNumberSelected,
            allSeasonsWithImages,
            seasonSelected,
            seasonSelectedImages,
            seasonImgCategories,
            seasonImgCategorySelected,
            seasonSelectedCategoryImages
        });
    }

    onProgramCategorySelect = (category) => {
        const shouldLoadRestrictedImage = this.state.showRestrictedImages && category === RESTRICTED_IMAGES.RESTRICTED;
        const restrictedProgramImages = filterObjsWithKeyValueInArr(this.state.programImages, RESTRICTED_IMAGES.PROVIDER, RESTRICTED_IMAGES.RESTRICTED, shouldLoadRestrictedImage);
        const programCategoryImages = category !== RESTRICTED_IMAGES.RESTRICTED ? this.organizeExpiredImages(getObjsFilter(restrictedProgramImages, 'category', category, 'Uncategorized')) : restrictedProgramImages;
        this.setState({programImgCategorySelected: category, programCategoryImages});
    };

    onSeasonSelect = (season) => {
        const seasonSelected = getObjsFind(this.state.allSeasonsWithImages, 'seasonNumber', season, NO_SEASON);
        const seasonSelectedImages = seasonSelected?.seasonImages || [];
        const seasonImgCategories = seasonSelected?.seasonImgCategories || [];
        const seasonImgCategorySelected =
            this.state.seasonImgCategorySelected && seasonImgCategories.includes(this.state.seasonImgCategorySelected)
                ? this.state.seasonImgCategorySelected
                : getFirstElement(seasonImgCategories);
        const removedRestrictedSeasonImages = filterObjsWithKeyValueInArr(seasonSelectedImages, RESTRICTED_IMAGES.PROVIDER, RESTRICTED_IMAGES.RESTRICTED);
        const seasonSelectedCategoryImages = this.organizeExpiredImages(getObjsFilter(removedRestrictedSeasonImages, 'category', seasonImgCategorySelected, 'Uncategorized'));
        this.setState({
            seasonNumberSelected: season,
            seasonSelected,
            seasonSelectedImages,
            seasonImgCategories,
            seasonImgCategorySelected,
            seasonSelectedCategoryImages
        });
    };

    onSeasonCategorySelect = (category) => {
        const removedRestrictedSeasonImages = filterObjsWithKeyValueInArr(this.state.seasonSelectedImages, RESTRICTED_IMAGES.PROVIDER, RESTRICTED_IMAGES.RESTRICTED);
        const seasonSelectedCategoryImages = this.organizeExpiredImages(getObjsFilter(removedRestrictedSeasonImages, 'category', category, 'Uncategorized'));
        this.setState({seasonImgCategorySelected: category, seasonSelectedCategoryImages});
    };

    organizeExpiredImages = (images) => {
        return images.sort((a, b) => {
            if (a.expired_date && b.expired_date) {
                return b.expired_date.localeCompare(a.expired_date);
            } else if (a.expired_date) {
                return 1
            } else if (b.expired_date) {
                return -1
            } else {
                return 0
            }
        });
    }

    onSubTabSelect = (tab) => {
        this.setState({ subTabSelected: tab });
    }

    render() {
        return (
            <div className="gnview-images-tab-content">
                <GNRadioGroup style="flat" list={this.state.allSeasons?.length > 0 ? Object.values(IMAGES_SUBTABS) : [IMAGES_SUBTABS.programImages]} onSelect={this.onSubTabSelect} default={IMAGES_SUBTABS.programImages}/>
                {this.props.hasMediaCloudEntitlements && this.state.subTabSelected === IMAGES_SUBTABS.programImages && <GNCard title="" headerOutsideOfCard={true}>
                    {!this.state.isLoading && this.state.programCategoryImages?.length > 0 &&
                    <GNRadioGroup label={'Image Type'} list={this.state.programImgCategories}
                        onSelect={this.onProgramCategorySelect} default={this.state.programImgCategorySelected}/>}
                    <GNImagesTabImages selectedImages={this.state.programCategoryImages} />
                </GNCard>}
                {this.props.hasMediaCloudEntitlements && this.state.allSeasons?.length > 0 && this.state.subTabSelected === IMAGES_SUBTABS.seasonSpecificImages && <GNCard title="" headerOutsideOfCard={true}>
                    {!this.state.isLoading && this.state.allSeasons?.length > 0 && (
                        <GNRadioGroup
                            label={'Season'}
                            list={this.state.allSeasons}
                            onSelect={this.onSeasonSelect}
                            default={this.state.seasonNumberSelected}
                        />
                    )}
                    {<br/>}
                    {!this.state.isLoading && this.state.seasonSelectedCategoryImages?.length > 0 &&
                    <GNRadioGroup label={'Image Type'} list={this.state.seasonImgCategories}
                        default={this.state.seasonImgCategorySelected}
                        onSelect={this.onSeasonCategorySelect}/>}
                    <GNImagesTabImages selectedImages={this.state.seasonSelectedCategoryImages} isSeasonSpecific={true} />
                </GNCard>}
                {!this.props.hasMediaCloudEntitlements && <div className="unentitled-message">
                    <span className="header">Access Denied</span><br/>You are not entitled to view Program Images. Please contact your Customer Care rep for further information.
                </div>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        hasMediaCloudEntitlements: gvauthHasMediaCloudEntitlements(state),
        hasStreamId: gvauthHasStreamId(state),
        isAdmin: gvauthIsAdmin(state),
        mediaCloudURL: gvauthGetMediaCloudURL(state),
        programDetails: gnviewSelProgramDetails(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ImagesTab);

ImagesTab.propTypes = {
    hasMediaCloudEntitlements: PropTypes.bool.isRequired,
    hasStreamId: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    mediaCloudURL: PropTypes.string.isRequired,
    programDetails: PropTypes.object.isRequired
}