import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from 'react-bootstrap';
import { TAB_NAMES, UnentitledMessageSection } from '../../../constants/Program';
import { getProgramTabs, getProgramColor } from '../../../utils/ProgramUtils';
import { gnviewGetProgramDetails, gnviewClearProgramDetails, gnviewProgramDetailsAddRecentProgram, gnviewGetProgramSeasonsAndEpisodes } from '../../../actions/GNViewActions';
import { gnviewLookupCountUpdate, gnviewPostLookup } from '../../../actions/GNViewAuthActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import { gnviewSelProgramDetails, gnviewSelProgramDetailsLoading } from '../../../reducers/ProgramDetailsReducer';
import "./ProgramsDetails.scss";
import LoadingSpinner from '../../../components/common/loadingSpinner/LoadingSpinner';
import GNRadioGroup from '../../../components/common/gnRadioGroup/GNRadioGroup';
import OverviewTab from './tabs/OverviewTab';
import SeasonsAndEpisodesTab from './tabs/SeasonsAndEpisodesTab';
import CastAndCrewTab from './tabs/CastAndCrewTab';
import RelatedProgramsTab from './tabs/RelatedProgramsTab';
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import OtherVersionsTab from "./tabs/OtherVersionsTab";
import ImagesTab from "./tabs/ImagesTab";
import { gvauthSelEntitlements, gvauthHasStreamId, gvauthIsAdmin, gvauthSelEmail, gvauthSelLookupCount, gvauthSelLookupMax, gvauthIsBasic } from "../../../reducers/GNVAuthReducer";
import AlternateEpisodesTab from './tabs/AlternateEpisodesTab';
import VideoDescriptorsTab from './tabs/VideoDescriptorsTab';
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import ProgramsDetailsTopSection from './ProgramsDetailsTopSection';
import XMLTab from './tabs/XMLTab';
import { ROUTES } from '../../../config/Routes';

export class ProgramsDetailsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabSelected: TAB_NAMES.OVERVIEW
        };
    }

    componentDidMount() {
        if (this.props.isBasic) {
            // getLookupReset
            // if lookupReset true, hit postLookup
            if (this.props.lookupCount <= this.props.lookupMax) {
                this.postLookup(this.props.email);
            }
        }
        const tmsId = this.props.match?.params.tmsId;
        if ((tmsId && !this.props.isBasic) || (tmsId && this.props.isBasic && this.props.lookupCount <= this.props.lookupMax)) {
            this.props.gnviewGetProgramDetails(tmsId, true, true, true).then((response) => {
                this.props.gnviewProgramDetailsAddRecentProgram(tmsId);
                this.cancelToken = response.cancelToken;
                this.setState({ tabSelected: TAB_NAMES.OVERVIEW });
            }).catch(
                (error) => {
                    this.props.gnviewSendLogMessage(`gnviewGetProgramDetails error: ${error.message}`, error, { tmsId });
                    if (error?.response?.status === 404) {
                        // Replace the current bad URL with /not-found so when user navigates back it will be /programs not /programs/123
                        this.props.history.replace(ROUTES.NOT_FOUND);
                    }
                }
            );
        }
    }

    componentDidUpdate(prevProps) {
        const tmsId = this.props.match?.params.tmsId;
        if (prevProps.match.params.tmsId !== tmsId) {
            if (this.props.isBasic) {
                if (this.props.lookupCount <= this.props.lookupMax) {
                    this.postLookup(this.props.email);
                }
            }
            this.props.gnviewGetProgramDetails(tmsId, true, true, true).then((response) => {
                this.props.gnviewProgramDetailsAddRecentProgram(tmsId);
                this.cancelToken = response.cancelToken;
                this.setState({ tabSelected: TAB_NAMES.OVERVIEW });
            }).catch(
                (error) => {
                    this.props.gnviewSendLogMessage(`gnviewGetProgramDetails error: ${error.message}`, error, { tmsId });
                    if (error?.response?.status === 404) {
                        // Replace the current bad URL with /not-found so when user navigates back it will be /programs not /programs/123
                        this.props.history.replace(ROUTES.NOT_FOUND);
                    }
                }
            );
        }
    }

    componentWillUnmount() {
        this.props.gnviewClearProgramDetails();
        if (this.cancelToken) {
            this.cancelToken.cancel();
        }
    }

    postLookup(email) {
        this.props.gnviewPostLookup({ email })
            .then((response) => {
                this.props.gnviewLookupCountUpdate(response.result);
            }).catch((error) => {
                this.props.gnviewSendLogMessage(`ProgramDetails postLookup error: ${error}`);
            })
    }

    onTabSelect = (tab) => {
        this.setState({ tabSelected: tab });
    };

    render() {
        const progTabs = [...getProgramTabs(this.props.programDetails)];
        if (this.props.isAdmin || this.props.hasStreamId) {
            progTabs.push(TAB_NAMES.XML);
        }
        const progColor = getProgramColor(this.props.programDetails?.type) || 'light-gray';
        const isEntitled = this.props.programDetails?.entitled;
        return (this.props.isBasic && this.props.lookupCount > this.props.lookupMax) ? <Redirect to={ROUTES.LOOKUP_MAX} /> : (
            <ErrorBoundary>
                <div className="gnview-programs-details-content">
                    {!this.props.programDetails?.hasError && <div className="filter-bar-container">
                        {!this.props.programDetailsLoading && <ProgramsDetailsTopSection program={this.props.programDetails} />}
                        {this.props.programDetailsLoading && <LoadingSpinner />}
                    </div>}
                    {!this.props.programDetailsLoading && isEntitled && <GNRadioGroup className={`${progColor}-tabs`} style="layered" list={progTabs} onSelect={this.onTabSelect} default={progTabs[0]} />}
                    <Container fluid className="program-details-container">
                        {!this.props.programDetailsLoading && isEntitled && this.state.tabSelected === TAB_NAMES.OVERVIEW && <OverviewTab />}
                        {!this.props.programDetailsLoading && isEntitled && this.state.tabSelected === TAB_NAMES.SEASONS_AND_EPISODES && <SeasonsAndEpisodesTab />}
                        {!this.props.programDetailsLoading && isEntitled && this.state.tabSelected === TAB_NAMES.CAST_AND_CREW && <CastAndCrewTab />}
                        {!this.props.programDetailsLoading && isEntitled && this.state.tabSelected === TAB_NAMES.IMAGES && <ImagesTab />}
                        {!this.props.programDetailsLoading && isEntitled && this.state.tabSelected === TAB_NAMES.RELATED_PROGRAMS && <RelatedProgramsTab />}
                        {!this.props.programDetailsLoading && isEntitled && this.state.tabSelected === TAB_NAMES.OTHER_VERSIONS && <OtherVersionsTab />}
                        {!this.props.programDetailsLoading && isEntitled && this.state.tabSelected === TAB_NAMES.ALTERNATE_EPISODES && <AlternateEpisodesTab />}
                        {!this.props.programDetailsLoading && isEntitled && this.state.tabSelected === TAB_NAMES.VIDEO_DESCRIPTORS && <VideoDescriptorsTab />}
                        {!this.props.programDetailsLoading && isEntitled && this.state.tabSelected === TAB_NAMES.XML && <XMLTab />}
                        {this.props.programDetailsLoading && <LoadingSpinner />}
                        {!this.props.programDetailsLoading && !isEntitled && <UnentitledMessageSection />}
                    </Container>
                </div>
            </ErrorBoundary>
        )
    }
}

ProgramsDetailsView.propTypes = {
    email: PropTypes.string,
    entitlements: PropTypes.object,
    gnviewGetProgramDetails: PropTypes.func.isRequired,
    gnviewSendLogMessage: PropTypes.func.isRequired,
    gnviewClearProgramDetails: PropTypes.func,
    gnviewLookupCountUpdate: PropTypes.func,
    gnviewProgramDetailsAddRecentProgram: PropTypes.func,
    gnviewPostLookup: PropTypes.func,
    hasStreamId: PropTypes.bool,
    history: ReactRouterPropTypes.history,
    isAdmin: PropTypes.bool,
    isBasic: PropTypes.bool,
    lookupCount: PropTypes.number,
    lookupMax: PropTypes.number,
    match: ReactRouterPropTypes.match,
    programDetails: PropTypes.object, // Marking as optional: won't be defined yet until the response is returned
    programDetailsLoading: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        email: gvauthSelEmail(state),
        entitlements: gvauthSelEntitlements(state),
        hasStreamId: gvauthHasStreamId(state),
        isAdmin: gvauthIsAdmin(state),
        isBasic: gvauthIsBasic(state),
        lookupCount: gvauthSelLookupCount(state),
        lookupMax: gvauthSelLookupMax(state),
        programDetails: gnviewSelProgramDetails(state),
        programDetailsLoading: gnviewSelProgramDetailsLoading(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewGetProgramDetails,
        gnviewClearProgramDetails,
        gnviewProgramDetailsAddRecentProgram,
        gnviewGetProgramSeasonsAndEpisodes,
        gnviewSendLogMessage,
        gnviewLookupCountUpdate,
        gnviewPostLookup
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramsDetailsView);
