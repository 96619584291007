import React from 'react';
import { Typeahead } from "react-bootstrap-typeahead";
import PropTypes from 'prop-types';
import './TypeaheadFilter.scss';
import ClassNames from 'classnames';

const REQUIRED = '*Required';

export const TypeaheadFilter = React.forwardRef((props, ref) => (
    <div className={ClassNames('filter-bar-col gnview-typeahead-filter', { [props.className]: props.className })}>
        <div className={'filter-label'}>{props.filterLabel}</div>
        {!props.selected?.length > 0 && <i className="fas fa-magnifying-glass" />}
        <Typeahead
            {... (props.validateTypeahead && {className: ClassNames('typeahead-main', {'invalid': !props.validateTypeahead()})})}
            {... (props.labelKey && { labelKey: props.labelKey})}
            onChange={(item) => props.handleChange(item)}
            options={ props.list }
            placeholder={ props.placeholder }
            onInputChange={(val) => props.onInputChange?.(val)}
            clearButton={ true }
            onBlur={(val) => {
                if (props.handleBlur) {
                    props.handleBlur(val);
                }
                if (props.domRef && val.target.value && props.multiple) {
                    props.domRef.clear();
                    props.domRef.focus();
                }
            }}
            multiple={ props.multiple }
            selected={ props.selected }
            id={'typeahead-' + props.filterLabel}
            ref={ref}
            allowNew={ props.allowNew }
            newSelectionPrefix={ props.addLabelKey } />
        <div className={ClassNames('required-message', {invalid: !props.hasValue && (!props?.validateTypeahead() || props?.errors)})}>
            {props?.isRequired && REQUIRED}
            {props?.validationText}
        </div>
    </div>
));

TypeaheadFilter.defaultProps = {
    allowNew: false,
    isRequired: false,
    newSelectionPrefix: 'Add new Item: ',
    validationText: '',
    validateTypeahead: () => true
};

TypeaheadFilter.propTypes = {
    addLabelKey: PropTypes.string,
    allowNew: PropTypes.bool,
    className: PropTypes.string,
    domRef: PropTypes.node,
    filterLabel: PropTypes.string.isRequired,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    isRequired: PropTypes.bool,
    labelKey: PropTypes.string,
    list: PropTypes.array.isRequired,
    multiple: PropTypes.bool,
    onInputChange: PropTypes.func,
    placeholder: PropTypes.string,
    selected: PropTypes.array,
    validateTypeahead: PropTypes.func,
    validationText: PropTypes.string,
    hasValue: PropTypes.bool,
    errors: PropTypes.array
};

TypeaheadFilter.displayName = 'TypeaheadFilter';

export default TypeaheadFilter;