import React from 'react';
import {
    ADVANCED_PROGRAM_SEARCH_LABEL,
    ADVANCED_PROGRAM_SEARCH_NETWORK_LABEL,
    APPLIES_TO,
    PROGRAM_ATTRIBUTES_NETWORK_SEARCH_TYPES,
    PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION,
    PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION
} from '../../../../constants/AdvancedSearch';
import GNBadge from '../../../common/gnBadge/GNBadge';
import GNRadioGroup from '../../../common/gnRadioGroup/GNRadioGroup';
import GNStationTypeahead from '../../../common/gnStationTypeahead/GNStationTypeahead';
import GNInput from '../../../common/gnInput/GNInput';
import PropTypes from 'prop-types';

export const NetworksFilter = ({ handleFormChange, values }) => {
    const recentNetworkSearchType = values[PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION.VALIDATION_LABEL_RADIO];
    const originalNetworkSearchType = values[PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION.VALIDATION_LABEL_RADIO];

    const getNetworksLabel = (isOriginalNetwork) =>
        isOriginalNetwork ? `Original ${originalNetworkSearchType}` : `Most Recent ${recentNetworkSearchType}`;


    /**
     * when user selects a different tab, reset the id back to the selected network - in case they type
     * a partial value in the field that doesn't match the current selection
     **/
    const handleRecentNetworkRadioButton = (type) => {
        if (values[PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION.VALIDATION_LABEL_RADIO] === PROGRAM_ATTRIBUTES_NETWORK_SEARCH_TYPES.ID) {
            const selectedId = values[PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION.VALIDATION_LABEL_FULL][0]?.id || '';
            handleFormChange(PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION.VALIDATION_LABEL_ID, selectedId);
        }
        handleFormChange(PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION.VALIDATION_LABEL_RADIO, type);
    }

    const handleOriginalNetworkRadioButton = (type) => {
        if (values[PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION.VALIDATION_LABEL_RADIO] === PROGRAM_ATTRIBUTES_NETWORK_SEARCH_TYPES.ID) {
            const selectedId = values[PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION.VALIDATION_LABEL_FULL][0]?.id || '';
            handleFormChange(PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION.VALIDATION_LABEL_ID, selectedId);
        }
        handleFormChange(PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION.VALIDATION_LABEL_RADIO, type);
    }

    return (
        <div className='programAttributesSections'>
            <div className='programAttributesSections__section-header'>
                <div className='programAttributesSections__section'>{ADVANCED_PROGRAM_SEARCH_NETWORK_LABEL}</div>
                <div className='programAttributesSections__section-header-applies'>{APPLIES_TO}</div>
                <div className='programAttributesSections__section-header-badges'>
                    <GNBadge type='Series' subtype='Series' />
                    <GNBadge type='Sports event' subtype='Sports' />
                </div>
            </div>
            <div className='programAttributesSections__section-networks'>
                <div className='programAttributesSections__network-search-container'>
                    <GNRadioGroup
                        default={recentNetworkSearchType}
                        label={ADVANCED_PROGRAM_SEARCH_LABEL}
                        list={Object.values(PROGRAM_ATTRIBUTES_NETWORK_SEARCH_TYPES)}
                        onSelect={(type) => {
                            handleRecentNetworkRadioButton(type);
                        }}
                        value={recentNetworkSearchType}
                    />
                    {recentNetworkSearchType !== PROGRAM_ATTRIBUTES_NETWORK_SEARCH_TYPES.ID && (
                        <div className='programAttributes__network-search-label'>{getNetworksLabel(false)}</div>
                    )}
                    {recentNetworkSearchType !== PROGRAM_ATTRIBUTES_NETWORK_SEARCH_TYPES.ID && (
                        <GNStationTypeahead
                            handleChangeCountry={(item) => handleFormChange(PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION.VALIDATION_LABEL_COUNTRY, item)}
                            handleChangeStation={(item) => {
                                handleFormChange(PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION.VALIDATION_LABEL_FULL, item);
                                handleFormChange(PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION.VALIDATION_LABEL_ID, item[0]?.id.toString() || '');
                            }}
                            isCallSignSearch={
                                recentNetworkSearchType === PROGRAM_ATTRIBUTES_NETWORK_SEARCH_TYPES.CALLSIGN
                            }
                            multipleStations={false}
                            placeholder={getNetworksLabel(false)}
                            useEntitledCountries={false}
                            selectedCountry={values[PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION.VALIDATION_LABEL_COUNTRY]}
                            selectedStations={values[PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION.VALIDATION_LABEL_FULL]}
                        />
                    )}
                    {recentNetworkSearchType === PROGRAM_ATTRIBUTES_NETWORK_SEARCH_TYPES.ID && (
                        <GNInput
                            label={getNetworksLabel(false)}
                            placeholder={getNetworksLabel(false)}
                            handleChange={(item) =>
                                handleFormChange(PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION.VALIDATION_LABEL_ID, item)
                            }
                            validateInput={() => true}
                            value={values[PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION.VALIDATION_LABEL_ID]}
                        />
                    )}
                </div>
                <div className='programAttributesSections__network-search-container'>
                    <GNRadioGroup
                        default={originalNetworkSearchType}
                        label={ADVANCED_PROGRAM_SEARCH_LABEL}
                        list={Object.values(PROGRAM_ATTRIBUTES_NETWORK_SEARCH_TYPES)}
                        onSelect={(type) => {
                            handleOriginalNetworkRadioButton(type);
                        }}
                        value={originalNetworkSearchType}
                    />
                    {originalNetworkSearchType !== PROGRAM_ATTRIBUTES_NETWORK_SEARCH_TYPES.ID && (
                        <div className='programAttributes__network-search-label'>{getNetworksLabel(true)}</div>
                    )}
                    {originalNetworkSearchType !== PROGRAM_ATTRIBUTES_NETWORK_SEARCH_TYPES.ID && (
                        <GNStationTypeahead
                            handleChangeCountry={(item) => handleFormChange(PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION.VALIDATION_LABEL_COUNTRY, item)}
                            handleChangeStation={(item) => {
                                handleFormChange(PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION.VALIDATION_LABEL_FULL, item);
                                handleFormChange(PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION.VALIDATION_LABEL_ID, item[0]?.id.toString() || '');
                            }}
                            isCallSignSearch={
                                originalNetworkSearchType === PROGRAM_ATTRIBUTES_NETWORK_SEARCH_TYPES.CALLSIGN
                            }
                            multipleStations={false}
                            placeholder={getNetworksLabel(true)}
                            useEntitledCountries={false}
                            selectedCountry={values[PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION.VALIDATION_LABEL_COUNTRY]}
                            selectedStations={values[PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION.VALIDATION_LABEL_FULL]}
                        />
                    )}
                    {originalNetworkSearchType === PROGRAM_ATTRIBUTES_NETWORK_SEARCH_TYPES.ID && (
                        <GNInput
                            label={getNetworksLabel(true)}
                            placeholder={getNetworksLabel(true)}
                            handleChange={(item) =>
                                handleFormChange(PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION.VALIDATION_LABEL_ID, item)
                            }
                            validateInput={() => true}
                            value={values[PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION.VALIDATION_LABEL_ID]}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

NetworksFilter.propTypes = {
    handleFormChange: PropTypes.func.isRequired,
    values: PropTypes.object
};

export default NetworksFilter;
