import React, { useState } from 'react';
import ErrorBoundary from '../../components/common/errorBoundary/ErrorBoundary';
import "./ReportAnIssue.scss";
import confirmationRocketship from '../../images/confirmationrocketship_2x.png';
import {
    FEEDBACK_ABOUT_OPTIONS_LABELS,
    REPORT_AN_ISSUE_BUTTON_TEXT,
    REPORT_AN_ISSUE_SECTIONS,
    REPORT_AN_ISSUE_TITLE,
    REPORT_AN_ISSUE_UNGENT_CONTACT,
    SEND_BUTTON_TOOLTIP
} from '../../constants/ReportAnIssue';
import gnViewLogo from '../../images/GN_View_logo.png';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Formik } from 'formik';
import get from 'lodash.get';
import {
    DESCRIPTION_CHAR_LIMIT,
    MAX_FILE_SIZE,
    REPORT_AN_ISSUE_FIELDS,
    REPORT_AN_ISSUE_INITIAL_TOUCHED,
    REPORT_AN_ISSUE_INITIAL_VALUES,
    REPORT_AN_ISSUE_VALIDATION_SCHEMA,
    VALID_FILE_TYPES
} from '../../constants/ReportAnIssueValidation';
import {
    REPORT_AN_ISSUE_FAILURE_MESSAGE,
    REPORT_AN_ISSUE_LABELS,
    REPORT_AN_ISSUE_PLACEHOLDERS,
    REPORT_AN_ISSUE_SUCCESS_MESSAGE,
    REPORT_AN_ISSUE_SUCCESS_MESSAGE_THANKS
} from '../../constants/ReportAnIssue';
import GNInput from '../../components/common/gnInput/GNInput';
import GNDropdown from '../../components/common/gnDropdown/GNDropdown';
import GNDatePickerWithYear from '../../components/common/gnDatePickerWithYear/GNDatePickerWithYear';
import GNNotification from '../../components/common/gnNotification/GNNotification';
import { useDispatch, useSelector } from 'react-redux';
import {
    gvauthSelEmail,
    gvauthSelGroup,
    gnviewSelLastURLVisited
} from '../../reducers/GNVAuthReducer';
import {
    gnviewSelProgramAiringsQueryObj
} from '../../reducers/ProgramAiringsReducer';
import {
    gnviewSelProgramAvailQueryObj
} from '../../reducers/ProgramAvailabilityReducer';
import {
    gnviewReportAnIssue,
    gnviewUploadAttachment
} from '../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../services/GeneralService';
import queryString from 'query-string';
import { ROUTES } from '../../config/Routes';
import { getIdFromURL, hasEmojiText, replaceNextLineCharWithSalesforceNextLineChar } from '../../utils/GeneralUtils';
import FileUpload from '../../components/common/fileUpload/FileUpload';
import LoadingSpinner from '../../components/common/loadingSpinner/LoadingSpinner';

export const ReportAnIssue = () => {
    const dispatch = useDispatch();
    const email = useSelector(gvauthSelEmail);
    const lastLocation = useSelector(gnviewSelLastURLVisited);
    let url = lastLocation?.href || lastLocation;
    const pathname = lastLocation?.pathname;
    const entitlementGroup = useSelector(gvauthSelGroup);
    const airingsQueryObject = useSelector(gnviewSelProgramAiringsQueryObj);
    const availQueryObject = useSelector(gnviewSelProgramAvailQueryObj);
    let searchCriteria = {};
    let stationId = '';
    let tmsId = '';
    const [base64Files, setBase64Files] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    if (url?.includes(ROUTES.SCHEDULES)) {
        const urlParsed = queryString.parseUrl(url);
        stationId = Array.isArray(urlParsed?.query?.station_ids) ? urlParsed?.query?.station_ids[0] : urlParsed?.query?.station_ids;
    } else if (url?.includes(ROUTES.STATION_DETAILS)) {
        stationId = getIdFromURL(pathname, ROUTES.STATION_DETAILS)
    } else if (url?.includes(ROUTES.PROGRAM_DETAILS)) {
        tmsId = getIdFromURL(pathname, ROUTES.PROGRAM_DETAILS)
    } else if (url?.includes(ROUTES.DATA_ALERTS)) {
        const urlParsed = queryString.parseUrl(url);
        stationId = Array.isArray(urlParsed?.query?.station_ids) ? urlParsed?.query?.station_ids[0] : urlParsed?.query?.station_ids;
        url = `${urlParsed.url}?range=${urlParsed?.query?.range}`;
    }

    /**
     * Search Criteria will be conditionally added to the description of the issue
     **/
    if (url?.includes(ROUTES.PROGRAM_AIRINGS)) {
        searchCriteria = airingsQueryObject;
    } else if (url?.includes(ROUTES.PROGRAM_AVAILABILITIES)) {
        searchCriteria = availQueryObject;
    }

    const initialFields = {
        ...REPORT_AN_ISSUE_INITIAL_VALUES,
        [REPORT_AN_ISSUE_FIELDS.CONFLICT_DATE]: new Date(),
        [REPORT_AN_ISSUE_FIELDS.STATION_ID]: stationId || "",
        [REPORT_AN_ISSUE_FIELDS.TMS_ID]: tmsId || "",
        [REPORT_AN_ISSUE_FIELDS.USER_EMAIL]: email,
        [REPORT_AN_ISSUE_FIELDS.URL]: url
    };

    const handleFormSubmit = async (reportFields, actions) => {
        setIsLoading(true);
        const validatedReportFields = {...reportFields, description: replaceNextLineCharWithSalesforceNextLineChar(reportFields?.description)}
        await (actions.validateForm()).then((errors) => {
            if (Object.keys(errors).length === 0) {
                dispatch(gnviewReportAnIssue(validatedReportFields, entitlementGroup?.id, searchCriteria))
                    .then((res) => {
                        if (base64Files.length > 0) {
                            submitFileUploads(res.result?.id);
                        } else {
                            setIsLoading(false);
                            setShowSuccess(true);
                        }
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        setShowNotification(true);
                        dispatch(gnviewSendLogMessage(`gnviewReportAnIssue error: ${err.message}`, err));
                    });
            }
        })
    };

    const submitFileUploads = (id) => {
        const uploadAttachmentRequests = base64Files.map(f => dispatch(gnviewUploadAttachment(id, f))
            .catch((error) => {
                dispatch(gnviewSendLogMessage(`gnviewUploadAttachment error: ${error.message}`, error));
                return error?.response?.data ? {result: error.response.data} : null;
            }));
        Promise.all(uploadAttachmentRequests).then(() => {
            setIsLoading(false);
            setShowSuccess(true);
        }).catch((err) => {
            setIsLoading(false);
            setShowNotification(true);
            dispatch(gnviewSendLogMessage(`gnviewUploadAttachment error: ${err.message}`, err));
        });
    };

    const renderValue = (valueProps, ref, selectedValue) => (
        <input {...valueProps} {...ref} className={selectedValue} />
    );

    const FEEDBACK_ABOUT_OPTIONS = FEEDBACK_ABOUT_OPTIONS_LABELS.map((labels) => ({
        value: labels.value,
        name: (
            <div className='feedback-options'>
                <span className='feedback-options__value'>{labels.value}</span>
                <span className='feedback-options__description'>{labels.description}</span>
            </div>
        )
    }));

    return (
        <ErrorBoundary>
            <div className="gnview-report-an-issue-container">
                <div className="header">
                    <img src={gnViewLogo} className='gn-logo' alt='Gracenote View Logo' />
                    <div className="header-title">{REPORT_AN_ISSUE_TITLE}</div>
                    <div className='header-contact'>
                        <div className='header-contact__numbers'>
                            <span>{REPORT_AN_ISSUE_UNGENT_CONTACT.FOR_URGENT_PROBLEMS_CALLS}</span>
                            <span >{REPORT_AN_ISSUE_UNGENT_CONTACT.FOR_URGENT_PROBLEMS_CALLS_NUMBER_1}</span>
                            <span>{REPORT_AN_ISSUE_UNGENT_CONTACT.OR}</span>
                            <span >{REPORT_AN_ISSUE_UNGENT_CONTACT.FOR_URGENT_PROBLEMS_CALLS_NUMBER_2}</span>
                        </div>
                        <div className='header-contact__hours'>
                            <span>{REPORT_AN_ISSUE_UNGENT_CONTACT.HOURS}</span>
                            <span>{REPORT_AN_ISSUE_UNGENT_CONTACT.DATE_TIME_TIMEZONE}</span>
                        </div>
                    </div>
                </div>
                {showSuccess &&
                    <div className="gnview-report-an-issue-success-container">
                        <img src={confirmationRocketship} alt="confirmation-page" className="confirmation-rocketship-image" />
                        <span className="success-thanks">{REPORT_AN_ISSUE_SUCCESS_MESSAGE_THANKS}</span>
                        <span className="success-message">{REPORT_AN_ISSUE_SUCCESS_MESSAGE}</span>
                    </div>
                }
                {!showSuccess &&
                    <React.Fragment>
                        {isLoading &&
                            <div className="gnview-report-an-issue-spinner-container">
                                <LoadingSpinner />
                            </div>
                        }
                        <Formik
                            initialValues={initialFields}
                            initialTouched={REPORT_AN_ISSUE_INITIAL_TOUCHED}
                            validationSchema={REPORT_AN_ISSUE_VALIDATION_SCHEMA}
                            onSubmit={(values, actions) => handleFormSubmit(values, actions)}>
                            {({ dirty, errors, handleSubmit, isSubmitting, isValid, setFieldTouched, setFieldValue, touched, values }) => {
                                const validateForm = (formName) =>
                                    isSubmitting || get(touched, formName) ? !get(errors, formName) : true;

                                const handleFormChange = (formName, value) => {
                                    // If user tries to type emojis - do nothing
                                    if ((formName !== REPORT_AN_ISSUE_FIELDS.CONFLICT_DATE) && hasEmojiText(value)) {
                                        return;
                                    }
                                    if ((formName === REPORT_AN_ISSUE_FIELDS.CONFLICT_DATE) && !(value instanceof Date)) {
                                        value = new Date();
                                    }
                                    if (!touched[formName]) {
                                        setFieldTouched(formName, true);
                                    }
                                    setFieldValue(formName, value);
                                };
                                return (
                                    <Form onSubmit={handleSubmit}>
                                        <div className="report-an-issue-form-container">
                                            <div className="section">
                                                <div className="section-title">{REPORT_AN_ISSUE_SECTIONS.CONTACT_INFO}</div>
                                                <div className="section-content">
                                                    <GNInput
                                                        disabled={true}
                                                        label={REPORT_AN_ISSUE_LABELS.USER_EMAIL}
                                                        handleChange={(item) => handleFormChange(REPORT_AN_ISSUE_FIELDS.USER_EMAIL, item)}
                                                        isRequired={true}
                                                        validateInput={() => validateForm(REPORT_AN_ISSUE_FIELDS.USER_EMAIL)}
                                                        validationText={errors[REPORT_AN_ISSUE_FIELDS.USER_EMAIL]}
                                                        value={values[REPORT_AN_ISSUE_FIELDS.USER_EMAIL]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="section">
                                                <div className="section-title">{REPORT_AN_ISSUE_SECTIONS.OVERVIEW}</div>
                                                <div className="section-content">
                                                    <div className="form-row">
                                                        <GNDropdown
                                                            handleChange={(item) => handleFormChange(REPORT_AN_ISSUE_FIELDS.FEEDBACK_ABOUT, item)}
                                                            isRequired={true}
                                                            label={REPORT_AN_ISSUE_LABELS.FEEDBACK_ABOUT}
                                                            options={FEEDBACK_ABOUT_OPTIONS}
                                                            placeholder={REPORT_AN_ISSUE_PLACEHOLDERS.FEEDBACK_ABOUT}
                                                            renderValue={renderValue}
                                                            showError={Boolean(errors[REPORT_AN_ISSUE_FIELDS.FEEDBACK_ABOUT])}
                                                            validateInput={() => validateForm(REPORT_AN_ISSUE_FIELDS.FEEDBACK_ABOUT)}
                                                            value={values[REPORT_AN_ISSUE_FIELDS.FEEDBACK_ABOUT]}
                                                        />
                                                        <div className='gndatepicker-main-container'>
                                                            <GNDatePickerWithYear
                                                                handleChange={(item) => handleFormChange(REPORT_AN_ISSUE_FIELDS.CONFLICT_DATE, item)}
                                                                isRequired={true}
                                                                isClearable={false}
                                                                label={REPORT_AN_ISSUE_LABELS.CONFLICT_DATE}
                                                                validateYear={() => validateForm(REPORT_AN_ISSUE_FIELDS.CONFLICT_DATE)}
                                                                validationText={errors[REPORT_AN_ISSUE_FIELDS.CONFLICT_DATE]}
                                                                value={values[REPORT_AN_ISSUE_FIELDS.CONFLICT_DATE]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <GNInput
                                                        className="description-field-wrapper"
                                                        label={REPORT_AN_ISSUE_LABELS.DESCRIPTION}
                                                        handleChange={(item) => handleFormChange(REPORT_AN_ISSUE_FIELDS.DESCRIPTION, item)}
                                                        isRequired={true}
                                                        requiredText={REPORT_AN_ISSUE_LABELS.DESCRIPTION_REQUIRED}
                                                        placeholder={REPORT_AN_ISSUE_PLACEHOLDERS.DESCRIPTION}
                                                        textAreaMax={DESCRIPTION_CHAR_LIMIT}
                                                        textAreaRows={5}
                                                        type="textarea"
                                                        validateInput={() => validateForm(REPORT_AN_ISSUE_FIELDS.DESCRIPTION)}
                                                        validationText={errors[REPORT_AN_ISSUE_FIELDS.DESCRIPTION]}
                                                        value={values[REPORT_AN_ISSUE_FIELDS.DESCRIPTION]}
                                                    />
                                                    <FileUpload
                                                        setBase64Files={setBase64Files}
                                                        acceptTypes={VALID_FILE_TYPES}
                                                        maxSize={MAX_FILE_SIZE}
                                                        maxTotalFiles={3}
                                                    />
                                                </div>
                                            </div>
                                            <div className="section">
                                                <div className="section-title">{REPORT_AN_ISSUE_SECTIONS.ADDITIONAL_INFO}</div>
                                                <div className="section-content additional-info">
                                                    <GNInput
                                                        label={REPORT_AN_ISSUE_LABELS.URL}
                                                        handleChange={(item) => handleFormChange(REPORT_AN_ISSUE_FIELDS.URL, item)}
                                                        isRequired={true}
                                                        placeholder={REPORT_AN_ISSUE_LABELS.URL}
                                                        validateInput={() => validateForm(REPORT_AN_ISSUE_FIELDS.URL)}
                                                        validationText={errors[REPORT_AN_ISSUE_FIELDS.URL]}
                                                        value={values[REPORT_AN_ISSUE_FIELDS.URL]}
                                                    />
                                                    <GNInput
                                                        label={REPORT_AN_ISSUE_LABELS.STATION_ID}
                                                        handleChange={(item) => handleFormChange(REPORT_AN_ISSUE_FIELDS.STATION_ID, item)}
                                                        placeholder={REPORT_AN_ISSUE_LABELS.STATION_ID}
                                                        validateInput={() => validateForm(REPORT_AN_ISSUE_FIELDS.STATION_ID)}
                                                        validationText={errors[REPORT_AN_ISSUE_FIELDS.STATION_ID]}
                                                        value={values[REPORT_AN_ISSUE_FIELDS.STATION_ID]}
                                                    />
                                                    <GNInput
                                                        label={REPORT_AN_ISSUE_LABELS.TMS_ID}
                                                        handleChange={(item) => handleFormChange(REPORT_AN_ISSUE_FIELDS.TMS_ID, item)}
                                                        placeholder={REPORT_AN_ISSUE_LABELS.TMS_ID}
                                                        validateInput={() => validateForm(REPORT_AN_ISSUE_FIELDS.TMS_ID)}
                                                        validationText={errors[REPORT_AN_ISSUE_FIELDS.TMS_ID]}
                                                        value={values[REPORT_AN_ISSUE_FIELDS.TMS_ID]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="button-container">
                                                {dirty && isValid ? (
                                                    <Button onClick={handleSubmit}>
                                                        {REPORT_AN_ISSUE_BUTTON_TEXT.SUBMIT}
                                                    </Button>
                                                ) : (
                                                    <OverlayTrigger
                                                        overlay={<Tooltip>{SEND_BUTTON_TOOLTIP}</Tooltip>}
                                                        placement='top'
                                                    >
                                                        <div className='button-container__tooltip'>
                                                            <Button disabled>{REPORT_AN_ISSUE_BUTTON_TEXT.SUBMIT}</Button>
                                                        </div>
                                                    </OverlayTrigger>
                                                )}
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                        <GNNotification
                            handleShow={setShowNotification}
                            message={REPORT_AN_ISSUE_FAILURE_MESSAGE}
                            milliseconds={5000}
                            show={showNotification}
                            success={false}
                        />
                    </React.Fragment>
                }
            </div>
        </ErrorBoundary>
    );
}

export default ReportAnIssue;
