import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AsyncTypeaheadFilter from '../asyncTypeaheadFilter/AsyncTypeaheadFilter';
import { Highlighter } from 'react-bootstrap-typeahead';
import { gnviewSearchStations, gnviewGetUserStationCountries } from '../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import PropTypes from 'prop-types';
import GNBadge from '../gnBadge/GNBadge';
import './GNStationTypeahead.scss';
import moment from 'moment-timezone'
import GNDropdown from '../gnDropdown/GNDropdown';
import { gnviewSelUserSettings, gvauthSelEntitledCountries } from '../../../reducers/GNVAuthReducer';
import { convertCountryCodes } from '../../../utils/GeneralUtils';
import { GLOBAL_COUNTRY_DEFAULT } from '../../../constants/AccountSettings.js';
import { useEffect } from 'react';
import isEmpty from 'lodash.isempty';

export const GNStationTypeahead = ({
    showTypeAhead = true,
    handleChangeCountry,
    handleChangeStation,
    isCallSignSearch = false,
    multipleStations,
    placeholder,
    useEntitledCountries = true,
    selectedCountry,
    selectedStations
}) => {
    const dispatch = useDispatch();
    const userSettings = useSelector(gnviewSelUserSettings);
    const entitledCountries = useSelector(gvauthSelEntitledCountries);
    // Station typeahead
    const [stationSearchResultOptions, setStationSearchResultOptions] = useState([]);
    const [isSearchStationsLoading, setIsSearchStationsLoading] = useState(false);
    const [searchString, setSearchString] = useState(null);
    const [currentStations, setCurrentStations] = useState(selectedStations || []);
    // Country dropdown
    const initialCountry =
        entitledCountries?.length === 1
            ? entitledCountries[0]
            : userSettings?.station_country || GLOBAL_COUNTRY_DEFAULT;
    const [countryFilter, setCountryFilter] = useState(initialCountry);
    const [countryList, setCountryList] = useState([]);
    const [countryListIsLoading, setCountryListIsLoading] = useState(true);

    const typeAheadRef = useRef(null);

    const getCountryList = useCallback(() => {
        if (useEntitledCountries) {
            const options = formatCountryFilterOptions(entitledCountries);
            setCountryList(options);
            setCountryListIsLoading(false);
        } else {
            dispatch(gnviewGetUserStationCountries())
                .then((response) => {
                    if (response.result) {
                        const options = formatCountryFilterOptions(response.result);
                        setCountryList(options);
                        setCountryListIsLoading(false);
                    }
                })
                .catch((error) => {
                    dispatch(gnviewSendLogMessage(`gnviewGetUserStationCountries error: ${error.message}`, error));
                    setCountryListIsLoading(false);
                });
        }
    }, [dispatch, entitledCountries, useEntitledCountries]);

    // If selectedStations or selectedCountry is cleared
    useEffect(() => {
        const stations = selectedStations || [];
        setCurrentStations(stations);
        if (isEmpty(selectedCountry) && isEmpty(countryFilter)) {
            setCountryFilter(initialCountry);
        }
    }, [countryFilter, initialCountry, selectedCountry, selectedStations]);

    useEffect(() => {
        if (countryList?.length === 0) {
            getCountryList();
        }
    }, [countryList?.length, getCountryList]);

    /**
     * When the placeholder text changes, we want to clear any values from the input,
     * but keep the selectedStations. This is done because the context of the input
     * was changed. Unfortunately the AsyncTypeahead component clears the rendered
     * list of stations until the next re-render, so we need to force a re-render.
     */
    useEffect(() => {
        typeAheadRef.current.clear();
        forceRedraw();
    }, [placeholder, forceRedraw]);

    const forceRedraw = useCallback(() => {
        const stations = currentStations;
        setTimeout(() => {
            setCurrentStations([]);
            setCurrentStations(stations);
        }, 0);
    }, [currentStations]);

    const handleStationSearch = useCallback((searchStr) => {
        if (searchStr) {
            setStationSearchResultOptions([]);
            setIsSearchStationsLoading(true);
            setSearchString(searchStr);
            // The country filter dropdown doesn't accept null as a valid value, so the "All Countries" option needs to be an empty string when selected, and will be converted to null upon making the request
            const countryFilter2 = countryFilter?.length > 0 ? countryFilter : null;
            dispatch(gnviewSearchStations(searchStr, isCallSignSearch, countryFilter2)).then(
                (response) => {
                    const selectedStationsIds = Array.isArray(selectedStations) ? selectedStations?.map(station => parseInt(station.id)) : [];
                    const options = selectedStationsIds ? response?.result?.filter(station => !selectedStationsIds.includes(station.id)) : response.result;
                    setStationSearchResultOptions(options);
                    setIsSearchStationsLoading(false);
                }, (error) => {
                    dispatch(gnviewSendLogMessage(`gnviewSearchStations error: ${error.message}`, error, { searchStr }));
                    setIsSearchStationsLoading(false);
                }
            );
        }
    }, [countryFilter, dispatch, isCallSignSearch, selectedStations]);

    /* eslint-disable react/prop-types */
    const renderChildrenForStation = (item, props, index) => {
        return (
            <div className="station-typeahead-container" key={index}>
                {isCallSignSearch && <span key="name" search={props.text}>{item.name}</span>}
                {!isCallSignSearch && <Highlighter key="name" search={props.text}>{item.name}</Highlighter>}
                {isCallSignSearch && <span className="sub-title-dark">(<Highlighter key="callsign" search={props.text}>{item.call_sign}</Highlighter>)</span>}
                {!isCallSignSearch && <span className="sub-title-dark">(<span key="callsign" search={props.text}>{item.call_sign}</span>)</span>}
                <GNBadge type={null} subtype={item.type} color='station-type-black' />
                <span className="dd-sub-title">{item.timezone}</span>
                <span className="dd-sub-title">{moment.tz(item.timezone).zoneAbbr()}</span>
                <span>
                    {item.country_codes.slice(0, 3).map((country, i) => (
                        <GNBadge type={null} subtype={country} color='country-code-gray' key={i} />
                    ))}
                    {item.country_codes.length > 3 && <span className="dd-sub-title">{`+${item.country_codes.length - 3} more`}</span>}
                </span>
            </div>
        );
    }
    /* eslint-enable react/prop-types */

    const formatCountryFilterOptions = (countries) => {
        const result = convertCountryCodes(countries).map(countryObj => ({ name: countryObj.name, value: countryObj.code }));
        return [
            GLOBAL_COUNTRY_DEFAULT,
            ...result
        ];
    }

    const handleCountryFilterChange = (item) => {
        setCountryFilter(item);
        if (handleChangeCountry) {
            handleChangeCountry(item);
        }
        handleStationSearch(searchString);
    }

    return (
        <div className="gnview-station-typeahead">
            <AsyncTypeaheadFilter
                className="station-search"
                disabled={!showTypeAhead}
                filterLabel=''
                isLoading={isSearchStationsLoading}
                labelKey={(option) => `${option.name} (${option.call_sign})`}
                multiple={multipleStations}
                onChange={(item) => {
                    setCurrentStations(item);
                    handleChangeStation(item);
                }}
                onSearch={handleStationSearch}
                options={stationSearchResultOptions}
                placeholder={placeholder || " "}
                renderMenuItemChildren={renderChildrenForStation}
                selected={currentStations}
                ref={typeAheadRef}
            />
            {(!countryListIsLoading && countryList?.length > 1 && showTypeAhead) && <GNDropdown
                handleChange={(item) => handleCountryFilterChange(item)}
                isRequired={false}
                label={'Country Filter'}
                options={countryList}
                value={countryFilter} />}
        </div>
    )
}

GNStationTypeahead.propTypes = {
    showTypeAhead: PropTypes.bool,
    handleChangeCountry: PropTypes.func,
    handleChangeStation: PropTypes.func.isRequired,
    isCallSignSearch: PropTypes.bool,
    multipleStations: PropTypes.bool.isRequired,
    placeholder: PropTypes.string,
    useEntitledCountries: PropTypes.bool,
    selectedCountry: PropTypes.string,
    selectedStations: PropTypes.oneOfType([
        PropTypes.array
    ])
};

export default GNStationTypeahead;
