import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import { Col, Form } from 'react-bootstrap';
import StreamingCatalog from './StreamingCatalog';
import "./ProgramAvailabilitySearchStep2.scss";
import { LEARN_MORE_LINK } from '../../../constants/ContactUs';
import { SERVICE_TYPE_LABEL, COUNTRY_LABEL } from '../../../constants/ProgramAvailability';
import { GNRadioGroup } from '../../../components/common/gnRadioGroup/GNRadioGroup';
import { TypeaheadFilter } from '../../../components/common/typeaheadFilter/TypeaheadFilter';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';

export class ProgramAvailabilitySearchStep2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryNamesList: []
        }
    }
    uniqueCatalogNames = [];
    componentDidUpdate(prevProps) {
        if (prevProps.countriesList !== this.props.countriesList) {
            const countryNamesList = this.props.countriesList.map(country => country.name);
            this.setState({ countryNamesList });
        }
    }

    render() {
        this.uniqueCatalogNames = [];
        return (
            <ErrorBoundary>
                {this.props.allStreamingCatalogs?.length > 0 && !isEmpty(this.props.filterTypes) && this.props.countriesList.length > 0 && <div className="gnview-program-availability-step-2">
                    <div className="catalog-label">Streaming Catalogs to include:</div>
                    <Form className="catalogs-container">
                        {/* For custom checkboxes you need the prop 'custom', label, and a unique id */}
                        <Form.Check
                            custom
                            id="select-all-catalogs"
                            className="select-all-catalogs"
                            type="checkbox"
                            defaultChecked={false}
                            label="Select all catalogs"
                            onChange={(e) => this.props.handleSelectAllCatalogs(e)}/>
                        <div className='filters-header'>Filters:</div>
                        <div className='filters-container'>
                            <TypeaheadFilter
                                filterLabel={COUNTRY_LABEL}
                                multiple={false}
                                list={this.state.countryNamesList}
                                placeholder=''
                                handleChange={(item) => this.props.handleCountryChange(item[0]) } />
                            <GNRadioGroup label={SERVICE_TYPE_LABEL} list={[...new Set(Object.values(this.props.filterTypes))]} default={this.props.filterTypes.all} onSelect={this.props.onSelectFilterTypes}/>
                        </div>
                        <Form.Row>
                            {this.props.allStreamingCatalogs.map((catalog) => {
                                if (catalog && catalog.show && catalog.name && !this.uniqueCatalogNames.includes(catalog.name)) {
                                    this.uniqueCatalogNames.push(catalog.name);
                                    return (
                                        <Col xs="4" key={catalog.name} className="catalog-wrapper">
                                            <StreamingCatalog catalog={catalog} handleCheckbox={this.props.handleSelectOneCatalog} />
                                        </Col>
                                    )
                                } else {
                                    return null;
                                }
                            })}

                        </Form.Row>
                    </Form>
                </div>}
                {this.props.allStreamingCatalogs?.length === 0 && <div className="gnview-error-msg">You are not entitled to any streaming video catalogs. Contact your Customer Care rep to upgrade your account or submit a request <a target="_blank" rel="noreferrer" href={LEARN_MORE_LINK}>here</a></div>}
            </ErrorBoundary>
        )
    }
}

ProgramAvailabilitySearchStep2.propTypes = {
    allStreamingCatalogs: PropTypes.array.isRequired,
    countriesList: PropTypes.array,
    filterTypes: PropTypes.object,
    filteredStreamingCatalogs: PropTypes.array,
    handleCountryChange: PropTypes.func,
    handleSelectAllCatalogs: PropTypes.func,
    handleSelectOneCatalog: PropTypes.func,
    onSelectFilterTypes: PropTypes.func
}

const mapStateToProps = () => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramAvailabilitySearchStep2);
