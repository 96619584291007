import React from 'react';
import GNBadge from '../components/common/gnBadge/GNBadge';
import { Link } from 'react-router-dom';
import { GNImage } from '../components/common/gnImage/GNImage';
import { convertLanguageFromCode } from '../utils/GeneralUtils';
import { ROUTES } from '../config/Routes';
import { getImageURLH } from '../utils/ImageUtils';
import { getProgramImageByRatio } from '../utils/ProgramUtils';
import StationInfo from '../components/common/stationInfo/StationInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const ADVANCED_PROGRAM_SEARCH = 'Advanced Program Search';
export const ADVANCED_PROGRAM_SEARCH_LABEL = 'Search by';
export const ADVANCED_PROGRAM_SEARCH_PROGRAM_ATTRIBUTES = 'Program Attributes';
export const ADVANCED_PROGRAM_SEARCH_ROOT_ID = 'Root ID';
export const ADVANCED_PROGRAM_SEARCH_SERIES_ID = 'Series ID';
export const ADVANCED_PROGRAM_SEARCH_NETWORK_LABEL = 'Networks';
export const ADVANCED_PROGRAM_SEARCH_PRODUCTION_COMPANIES_LABEL = 'Production Companies';
export const ADVANCED_PROGRAM_ALL_LANGUAGES = 'All Languages';
export const ADVANCED_PROGRAM_NO_DATA_MESSAGE_HEADER = 'Sorry, no results.';
export const ADVANCED_PROGRAM_NO_DATA_MESSAGE = 'Try updating your search with adjusted search criteria.';
export const APPLIES_TO = 'Applies to:';
export const HEADER_BUTTON_COLLAPSE = 'Collapse';
export const HEADER_BUTTON_MODIFY_SEARCH = 'Modify Search';
export const ADVANCED_PROGRAM_SEARCH_RESULTS_LIMIT = 20;

export const SUBTYPE_POPULATE_RULE = 'This combination will only show Series results.';

export const ADVANCED_PROGRAM_SEARCH_TYPES = {
    programAttributes: ADVANCED_PROGRAM_SEARCH_PROGRAM_ATTRIBUTES,
    rootId: ADVANCED_PROGRAM_SEARCH_ROOT_ID,
    seriesId: ADVANCED_PROGRAM_SEARCH_SERIES_ID
};

// Program Attributes
export const PROGRAM_ATTRIBUTES_SECTION_HEADERS = {
    UNIVERSAL: {
        title: 'Universal Criteria',
        description: 'These filters will apply across all program types. Use these filters in isolation to return multiple program types.'
    },
    PROGRAM_TYPE: {
        title: 'Program Type Criteria',
        description: 'These filters will narrow your search to a specific type. Use in conjunction with universal filters to get to a specific program type.'
    }
};

export const PROGRAM_ATTRIBUTES_TITLE_SECTION = {
    LABEL: 'Title',
    NAME: 'Title',
    PLACEHOLDER: 'Program Name',
    VALIDATION_LABEL: 'title'
};

export const PROGRAM_ATTRIBUTES_GENRES_SECTION = {
    LABEL: 'Genres',
    NAME: 'Genres',
    PLACEHOLDER: 'All Genres',
    VALIDATION_LABEL: 'genres',
    ANY_SELECTED: 'Any Selected Genre',
    ALL_SELECTED: 'All Selected Genres',
    IS_ANY_SELECTED: 'isAnyGenreSelected',
    DEFAULT_RADIO_BUTTON: 'isAnyGenreSelected',
    PROGRAM_FIELD_VALUES: 'genres'
};

export const PROGRAM_ATTRIBUTES_RELEASE_YEAR_SECTION = {
    LABEL: 'Year',
    MAXLEN: 4,
    NAME: 'Release Year',
    PLACEHOLDER: 'YYYY',
    VALIDATION_LABEL: 'releaseYear'
};

export const PROGRAM_ATTRIBUTES_NETWORK_SEARCH_TYPES = {
    NAME: 'Network Name',
    CALLSIGN: 'Network Call Sign',
    ID: 'Network ID'
};

export const PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION = {
    VALIDATION_LABEL_COUNTRY: 'mostRecentNetworkCountry',
    VALIDATION_LABEL_FULL: 'mostRecentNetworkFull',
    VALIDATION_LABEL_ID: 'mostRecentNetworkId',
    VALIDATION_LABEL_RADIO: 'mostRecentNetworkRadio'
};

export const PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION = {
    VALIDATION_LABEL_COUNTRY: 'originalNetworkCountry',
    VALIDATION_LABEL_FULL: 'originalNetworkFull',
    VALIDATION_LABEL_ID: 'originalNetworkId',
    VALIDATION_LABEL_RADIO: 'originalNetworkRadio'
};

export const PROGRAM_ATTRIBUTES_LANGUAGE_SECTION = {
    NAME: 'Languages',
    TITLE_LANG: {
        LABEL: 'Title',
        PLACEHOLDER: 'All Title Languages',
        VALIDATION_LABEL: 'titleLanguage'

    },
    DESCRIPTION_LANG: {
        LABEL: 'Description',
        PLACEHOLDER: 'All Description Languages',
        VALIDATION_LABEL: 'descriptionLanguage'
    },
    AUDIO_LANG: {
        LABEL: 'Audio',
        PLACEHOLDER: 'All Audio Languages',
        VALIDATION_LABEL: 'audioLanguage'
    }
};

export const PROGRAM_ATTRIBUTES_TYPES = {
    NAME: 'Types',
    PROGRAM_TYPES: {
        LABEL: 'Program Types',
        PLACEHOLDER: 'All Program Types',
        VALIDATION_LABEL: 'programTypes'
    },
    SUBTYPES: {
        LABEL: 'Subtypes',
        PLACEHOLDER: 'All Subtypes',
        VALIDATION_LABEL: 'programSubtypes'
    }
};

export const PROGRAM_ATTRIBUTES_SPORTS = {
    NAME: 'Sports',
    BADGE_TYPE: 'Sports event',
    PROGRAM_FIELD: 'genres',
    PROGRAM_TYPES: ['Sports event', 'Sports non-event'],
    SPORTS_TYPE: {
        LABEL: 'Sports Type',
        PLACEHOLDER: 'All Sports Types',
        VALIDATION_LABEL: 'sportsType'
    },
    TOOLTIP: 'Must have Program Type "Sports event" or "Sports non-event" selected to use'
};

export const PROGRAM_ATTRIBUTES_PRODUCTION_COMPANIES_SECTION = {
    VALIDATION_LABEL: 'productionCompanies',
    ANY_SELECTED: 'Any Selected Production Company',
    ALL_SELECTED: 'All Selected Production Companies',
    IS_ANY_SELECTED: 'isAnyProductionCompanySelected',
    DEFAULT_RADIO_BUTTON: 'isAnyProductionCompanySelected'
};

export const PROGRAM_ATTRIBUTES_BUTTONS = {
    RESET: 'Reset',
    SEARCH: 'Search',
    UPDATE: 'Update'
};

export const PROGRAM_ATTRIBUTES_DEFAULT_PLACEHOLDERS = {
    LANGUAGE: { name: ADVANCED_PROGRAM_ALL_LANGUAGES, value: '' },
    PROGRAM_TYPES: { name: PROGRAM_ATTRIBUTES_TYPES.PROGRAM_TYPES.PLACEHOLDER, value: '' },
    PROGRAM_SUBTYPES: { name: PROGRAM_ATTRIBUTES_TYPES.SUBTYPES.PLACEHOLDER, value: '' },
    SPORT: { name: PROGRAM_ATTRIBUTES_SPORTS.SPORTS_TYPE.PLACEHOLDER, value: '' }
};

export const REGULAR_EXPRESSION_ONLY_DIGITS = new RegExp('^[0-9]+$');
export const REGULAR_EXPRESSION_DIGITS_LETTERS = new RegExp('^[a-zA-Z0-9]*$');

export const ROOT_SERIES_ID_CHARACTER_LIMIT = 100;
export const ROOT_SERIES_ID_VALIDATION_TEXT = 'Maximum of 100 characters';

export const ROOT_ID_ID_SECTION = {
    LABEL: ADVANCED_PROGRAM_SEARCH_TYPES.rootId,
    NAME: ADVANCED_PROGRAM_SEARCH_TYPES.rootId,
    PLACEHOLDER: ADVANCED_PROGRAM_SEARCH_TYPES.rootId,
    VALIDATION_LABEL: 'rootId'
};

export const ID_LANGUAGE_SECTION = {
    NAME: 'Languages',
    TITLE_LANG: {
        LABEL: 'Title',
        PLACEHOLDER: 'All Title Languages',
        VALIDATION_LABEL: 'titleLanguage'

    },
    DESCRIPTION_LANG: {
        LABEL: 'Description',
        PLACEHOLDER: 'All Description Languages',
        VALIDATION_LABEL: 'descriptionLanguage'
    }
};

export const ID_DEFAULT_PLACEHOLDERS = {
    LANGUAGE: { name: ADVANCED_PROGRAM_ALL_LANGUAGES, value: '' }
};

export const ID_BUTTONS = {
    RESET: 'Reset',
    SEARCH: 'Search',
    UPDATE: 'Update'
};

export const SERIES_ID_ID_SECTION = {
    LABEL: ADVANCED_PROGRAM_SEARCH_TYPES.seriesId,
    NAME: ADVANCED_PROGRAM_SEARCH_TYPES.seriesId,
    PLACEHOLDER: ADVANCED_PROGRAM_SEARCH_TYPES.seriesId,
    VALIDATION_LABEL: 'seriesId'
};

/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
// `accessor` should match the sort_field for these columns: title, title_language, language, release_year in order for sorting to work
export const ADVANCED_SEARCH_RESULTS_COLUMN_HEADERS = (showCopyTmsId, onClickCopyTmsId) => [
    { accessor: 'images', Header: '', Cell: ({row}) => {
        return <GNImage className='program-image' url={getImageURLH(getProgramImageByRatio(row.original, '16:9'), '?h=100')} /> || null
    }, disableSortBy: true},
    { accessor: 'title', Header: 'Title', Cell: ({row}) => {
        return <Link to={`${ROUTES.PROGRAM_DETAILS}/${row?.original?.tms_id}`} target='_blank' rel='noreferrer'>{row?.values.title || row?.original?.titles?.[0]?.text}</Link> || null
    }},
    { accessor: 'tms_id', Header: 'TMS ID', disableSortBy: true, Cell: ({row}) => {
        return <span className='tms-id-row'>{row?.original?.tms_id}{showCopyTmsId && <OverlayTrigger overlay={<Tooltip id="copy-tms-id">Copy and paste this TMS ID into your Self-linked TMS ID field</Tooltip>}>
            <div className='tooltip-container'>
                <FontAwesomeIcon icon='copy' onClick={() => onClickCopyTmsId(row?.original?.tms_id)} />
            </div>
        </OverlayTrigger>}
        </span>
    }},
    { accessor: 'subtype', Header: 'Subtype', Cell: ({row}) => {
        return <GNBadge type={row?.original?.type} subtype={row?.original?.subtype} /> || null
    }, disableSortBy: true},
    { accessor: 'genres', Header: 'Genres', Cell: ({row}) => {
        return row?.original?.genres?.join(', ') || null
    }, disableSortBy: true},
    { accessor: 'title_language', Header: 'Title Language', Cell: ({row}) => {
        const engLangName = convertLanguageFromCode(row?.original?.title_language) || null;
        return engLangName?.length > 0 ? `${engLangName} [${row?.original?.title_language}]` : null;
    }},
    { accessor: 'language', Header: 'Description Language', Cell: ({row}) => {
        const engLangName = convertLanguageFromCode(row?.original?.language) || null;
        return engLangName?.length > 0 ? `${engLangName} [${row?.original?.language}]` : null;
    }},
    { accessor: 'original_audio_language', Header: 'Audio Language', Cell: ({row}) => {
        return convertLanguageFromCode(row?.original?.original_audio_language) || null
    }, disableSortBy: true},
    { accessor: 'release_year', Header: 'Release Year' },
    { accessor: 'production_companies', Header: 'Production Companies', Cell: ({row}) => {
        return row?.original?.production_companies?.join(', ') || null
    }, disableSortBy: true},
    { accessor: 'last_original_station_id', Header: 'Most Recent Network', Cell: ({row}) => {
        return row?.original?.last_original_station_id ? <StationInfo stationId={row?.original?.last_original_station_id} /> : null;
    }, disableSortBy: true},
    { accessor: 'original_station_id', Header: 'Original Network', disableSortBy: true, Cell: ({row}) => {
        return row?.original?.original_station_id ? <StationInfo stationId={row?.original?.original_station_id} /> : null;
    }},
    { accessor: 'root_id', Header: 'Root ID', disableSortBy: true},
    { accessor: 'series_info', Header: 'Series ID', Cell: ({row}) => {
        return row?.original?.seriesid || null
    }, disableSortBy: true}
];
/* eslint-enable react/prop-types */
/* eslint-enable react/display-name */
