import {
    GNVIEW_ADD_RECENT_STATION,
    GNVIEW_ADD_SELECTED_STATION,
    GNVIEW_CLEAR_SELECTED_STATION
} from '../actions/ActionTypes';

export const STATION_DETAILS_STORE = 'stationDetails';

const MAX_RECENT_STATIONS = 10;

/**
 * Initial State
 */
export const initialState = {
    recentStations: [],
    selectedStation: null
};

/**
 * Reducer
 */
export const StationDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
    case GNVIEW_ADD_RECENT_STATION: {
        if (action.payload.stationId) {
            const recentStations = [...state.recentStations].filter(id => id !== action.payload.stationId);
            // Add new station to beginning of list, since we want the most recent first
            recentStations.unshift(action.payload.stationId);
            // When we reach the max, remove the oldest
            if (recentStations.length > MAX_RECENT_STATIONS) {
                recentStations.pop();
            }
            return { ...state, recentStations };
        }
        return state;
    }
    case GNVIEW_ADD_SELECTED_STATION: {
        return { ...state, selectedStation: action.payload.selectedStation };
    }
    case GNVIEW_CLEAR_SELECTED_STATION: {
        return { ...state, selectedStation: null };
    }
    default:
        return state;
    }
};

/**
 * Selectors
 */
export const gnviewSelStationDetailsRecentStations = state => state[STATION_DETAILS_STORE].recentStations;
export const gnviewSelSelectedStation = state => state[STATION_DETAILS_STORE].selectedStation;