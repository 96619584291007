import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './AdvancedSearchHeader.scss';
import GNRadioGroup from '../../../components/common/gnRadioGroup/GNRadioGroup';
import {
    ADVANCED_PROGRAM_SEARCH,
    ADVANCED_PROGRAM_SEARCH_LABEL,
    ADVANCED_PROGRAM_SEARCH_TYPES,
    HEADER_BUTTON_COLLAPSE,
    HEADER_BUTTON_MODIFY_SEARCH,
    PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION,
    PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION
} from '../../../constants/AdvancedSearch';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ProgramAttributes from '../../../components/advancedSearch/ProgramAttributes/ProgramAttributes';
import { PROGRAM_ATTRIBUTES_INITIAL_VALUES } from '../../../constants/AdvancedSearchValidation';
import ClassNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
    gnviewSelAdvancedSearchProgramAttributesFields,
    gnviewSelAdvancedSearchRootIdFields,
    gnviewSelAdvancedSearchSeriesIdFields,
    gnviewSelAdvancedSearchType
} from '../../../reducers/AdvancedSearchReducer';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../config/Routes';
import { gnviewAdvancedSearchFieldsClear, gnviewAdvancedSearchUpdateSearchBy } from '../../../actions/GNViewActions';
import IDSearch from '../../../components/advancedSearch/IDSearch/IDSearch';

const AdvancedSearchHeader = ({ isResultsView = false }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [showSearch, setShowSearch] = useState(false);
    const searchType = useSelector(gnviewSelAdvancedSearchType);
    const searchFields = useSelector(gnviewSelAdvancedSearchProgramAttributesFields);
    const searchRootIdFields = useSelector(gnviewSelAdvancedSearchRootIdFields);
    const searchSeriesIdFields = useSelector(gnviewSelAdvancedSearchSeriesIdFields);
    const handleSearchBtn = () => setShowSearch((prevShowSearch) => !prevShowSearch);

    const completedInputSearchFields = () => {
        const enteredSearchCriteria = {};
        let selectedSearchFields = {};
        if (searchType === ADVANCED_PROGRAM_SEARCH_TYPES.programAttributes) {
            selectedSearchFields = searchFields;
        } else if (searchType === ADVANCED_PROGRAM_SEARCH_TYPES.rootId) {
            selectedSearchFields = searchRootIdFields;
        } else if (searchType === ADVANCED_PROGRAM_SEARCH_TYPES.seriesId) {
            selectedSearchFields = searchSeriesIdFields;
        }

        if (Object.keys(selectedSearchFields).length === 0) {
            return enteredSearchCriteria;
        }

        for (const [key, val] of Object.entries(selectedSearchFields)) {
            if (
                val === PROGRAM_ATTRIBUTES_INITIAL_VALUES[key] ||
                (typeof val === 'object' && val.length === PROGRAM_ATTRIBUTES_INITIAL_VALUES[key].length)
            ) {
                continue;
            }
            enteredSearchCriteria[key] = val;
        }
        return enteredSearchCriteria;
    };

    const GET_INPUT_SEARCH_TOOL_TIP = () => {
        const fields = completedInputSearchFields();
        const terms = Object.keys(fields).map((key) => {
            const val = fields[key];
            if (key === PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION.VALIDATION_LABEL_FULL ||
                key === PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION.VALIDATION_LABEL_FULL) {
                const networks = val.map((network) => network.call_sign);
                return `[${networks.join(", ")}]`;
            }
            if (key === PROGRAM_ATTRIBUTES_RECENT_NETWORK_SECTION.VALIDATION_LABEL_RADIO ||
                key === PROGRAM_ATTRIBUTES_ORIGINAL_NETWORK_SECTION.VALIDATION_LABEL_RADIO) {
                return null;
            }
            return `[${val}]`;
        });
        return `Current Search Criteria: ${terms.join(' ')}`;
    }

    const handleHeaderContainer = () => {
        const headerContainer = document.querySelector('.advanced-search-header-container');
        headerContainer.scrollTop = 0;
    };

    const SEARCH_VIEW = () => (
        <div className='gnview-header-search-view'>
            <div className='gnview-header-title'>{ADVANCED_PROGRAM_SEARCH}</div>
            <div className='gnview-header-title__searchBy'>
                <GNRadioGroup
                    label={ADVANCED_PROGRAM_SEARCH_LABEL}
                    list={Object.values(ADVANCED_PROGRAM_SEARCH_TYPES)}
                    onSelect={(type) => {
                        dispatch(gnviewAdvancedSearchFieldsClear());
                        dispatch(gnviewAdvancedSearchUpdateSearchBy(type));
                    }}
                    default={searchType}
                    value={searchType}
                />
            </div>
        </div>
    );

    const RESULT_VIEW = () => (
        <div className='gnview-header-result-view'>
            <div className='results-header-breacrumb-container'>
                <div className='results-advanced-search-header' onClick={() => history.push(ROUTES.ADVANCED_SEARCH)}>
                    {ADVANCED_PROGRAM_SEARCH}
                </div>
                <i className='fas fa-angle-right gnview-breadcrumbs-separator' />
                <div>Results</div>
            </div>
            <div className='results-advanced-search-header-button-container'>
                {!showSearch ? (
                    <OverlayTrigger overlay={<Tooltip>{GET_INPUT_SEARCH_TOOL_TIP()}</Tooltip>} placement='right'>
                        <Button variant={`results-advanced-search-header-button-modify`} onClick={handleSearchBtn}>
                            <i className='fas fa-pencil' />
                            {HEADER_BUTTON_MODIFY_SEARCH}
                            <i className='fas fa-angle-down' />
                        </Button>
                    </OverlayTrigger>
                ) : (
                    <Button variant={`results-advanced-search-header-button-collapse`} onClick={handleSearchBtn}>
                        <i className='fas fa-pencil' />
                        {HEADER_BUTTON_COLLAPSE}
                        <i className='fas fa-angle-up' />
                    </Button>
                )}
            </div>
            <div
                className={ClassNames(
                    { 'results-header-advanced-search-menu-show': showSearch },
                    { 'results-header-advanced-search-menu-close': !showSearch }
                )}
            >
                {searchType === ADVANCED_PROGRAM_SEARCH_TYPES.programAttributes && (
                    <ProgramAttributes searchFromHeader={true} />
                )}
                {searchType === ADVANCED_PROGRAM_SEARCH_TYPES.rootId && (
                    <IDSearch searchFromHeader={true} isRootId={true} />
                )}
                {searchType === ADVANCED_PROGRAM_SEARCH_TYPES.seriesId && (
                    <IDSearch searchFromHeader={true} isRootId={false} />
                )}
            </div>
        </div>
    );

    return (
        <div ref={handleHeaderContainer} className='filter-bar-container advanced-search-header-container'>
            {!isResultsView ? SEARCH_VIEW() : RESULT_VIEW()}
        </div>
    );
};

AdvancedSearchHeader.propTypes = {
    isResultsView: PropTypes.bool.isRequired
};

export default AdvancedSearchHeader;
