export const ACCOUNT_TYPES = {
    TRIAL: 'trial',
    BASIC: 'basic',
    STANDARD: 'standard',
    PREMIUM: 'premium',
    LDS: 'LDS'
};

export const LOOKUP_MAX_HEADER = "You've reached your search limit for this week.";
export const LOOKUP_MAX_TEXT = "Return next Monday for more searches or upgrade to premium.";
export const LOOKUP_MAX_UPGRADE_TEXT = "Upgrade by reaching out to";
export const UPGRADE_EMAIL = "gracenoteview@nielsen.com";