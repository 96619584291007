import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { gnviewSelProgramDetails } from '../../../../reducers/ProgramDetailsReducer';
import { gnviewGetStation } from '../../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../../services/GeneralService';
import ErrorBoundary from '../../../../components/common/errorBoundary/ErrorBoundary';
import GNCard from '../../../../components/common/gnCard/GNCard';
import GNClientTable from '../../../../components/common/gnClientTable/GNClientTable';
import { PROGRAM_ALTERNATE_EPISODES_COLUMN_HEADERS } from '../../../../constants/Program';
import isEmpty from 'lodash.isempty';

export class AlternateEpisodesTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            alternateEpisodes: [],
            isLoading: true
        }
    }

    componentDidMount() {
        const stationRequests = this.props.programDetails?.episode_info?.alternate_episodes?.map(episode => this.props.gnviewGetStation(episode.station_id).catch(() => null));
        Promise.all(stationRequests).then(stationData => {
            const alternateEpisodes = stationData.map((station, idx) => {
                if (station) {
                    const currentAlternateEpisode = this.props.programDetails.episode_info.alternate_episodes[idx];
                    return {
                        ...currentAlternateEpisode,
                        station_name: station.result.name,
                        station_call_sign: station.result.call_sign
                    }
                } else {
                    return null;
                }
            }).filter(episode => !isEmpty(episode));
            this.setState({ alternateEpisodes, isLoading: false });
        }).catch(error => {
            this.setState({ isLoading: false });
            this.props.gnviewSendLogMessage(`gnviewGetStation error: ${error.message}`, error);
        });
    }

    render() {
        return (
            <ErrorBoundary>
                <div className='gnview-alternate-episodes-tab'>
                    <GNCard title='Alternate Episodes' headerOutsideOfCard={true}>
                        {!this.state.isLoading && this.state.alternateEpisodes.length > 0 && <GNClientTable
                            columns={PROGRAM_ALTERNATE_EPISODES_COLUMN_HEADERS}
                            data={this.state.alternateEpisodes}
                            isLoading={false} />}
                        {!this.state.isLoading && !this.state.alternateEpisodes.length && <span className='no-alternate-episodes-message'>No Alternate Episodes</span>}
                    </GNCard>
                </div>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        programDetails: gnviewSelProgramDetails(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewGetStation,
        gnviewSendLogMessage
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AlternateEpisodesTab);

AlternateEpisodesTab.propTypes = {
    gnviewGetStation: PropTypes.func,
    gnviewSendLogMessage: PropTypes.func,
    programDetails: PropTypes.object
}