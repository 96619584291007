import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import {
    gvauthHasUSAEntitlements,
    gvauthSelEntitlements,
    gvauthSelInternalUser,
    gvauthSelToken,
    gvauthSelModulesPermitted
} from '../../../reducers/GNVAuthReducer';
import { ROUTES } from '../../../config/Routes';
import { shouldRenderComponent, shouldRenderGNViewTeaser } from '../../../constants/PrivateRoute';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';

export const PrivateRoute = ({ component: InnerComponent, token, entitlements, hasUSAEntitlements, internalUser, modulesPermitted, ...rest }) => {
    const isLoggedIn = !isEmpty(token);
    if (isLoggedIn) {
        if (shouldRenderComponent(entitlements, rest.path, hasUSAEntitlements, modulesPermitted, internalUser)) {
            return <Route {...rest} render={(props) => (<InnerComponent {...props} />)} />;
        } else if (shouldRenderGNViewTeaser(entitlements, rest.path)) {
            return <Redirect to={ROUTES.GNVIEW_TEASER} />;
        } else if (!shouldRenderComponent(entitlements, rest.path, hasUSAEntitlements, modulesPermitted, internalUser)) {
            return <Redirect to={`${ROUTES.ACCESS_DENIED}?page=${rest.path}`} />;
        } else {
            return <Redirect to={ROUTES.CONTACT_US} />;
        }
    } else {
        return <Redirect to={ROUTES.LANDING} />;
    }
};

PrivateRoute.propTypes = {
    component: PropTypes.node,
    entitlements: PropTypes.object,
    hasUSAEntitlements: PropTypes.bool,
    internalUser: PropTypes.bool,
    modulesPermitted: PropTypes.array,
    token: PropTypes.string
}

const mapStateToProps = state => {
    return {
        token: gvauthSelToken(state),
        entitlements: gvauthSelEntitlements(state),
        hasUSAEntitlements: gvauthHasUSAEntitlements(state),
        internalUser: gvauthSelInternalUser(state),
        modulesPermitted: gvauthSelModulesPermitted(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));