import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { gnviewGetProgramDetails } from '../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import { gvauthSelEntitlements } from '../../../reducers/GNVAuthReducer';
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import PropTypes from 'prop-types';
import './ProgramAvailabilityProgramCard.scss';
import GNImage from '../../../components/common/gnImage/GNImage';
import { getImageURLFromObj } from '../../../utils/ImageUtils';
import {
    getProgramImageHorizontal,
    getProgramTitle,
    getProgramTitleAltText,
    getProgramRating,
    getReleaseYear,
    getProgramDescription
} from '../../../utils/ProgramUtils';
import { convertLanguageCodeList } from '../../../utils/GeneralUtils';
import GNBadge from '../../../components/common/gnBadge/GNBadge';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../config/Routes';
import LoadingSpinner from '../../../components/common/loadingSpinner/LoadingSpinner';
import ProgramAvailabilityTable from './ProgramAvailabilityTable';

export class ProgramAvailabilityProgramCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            program: null,
            isEpisode: null,
            hasError: false
        }
    }

    componentDidMount() {
        this.props.gnviewGetProgramDetails(this.props.tmsid).then((response) => {
            const program = response.result;
            program.mainTitle = getProgramTitle(program, true);
            program.progTitleAlt = getProgramTitleAltText(program);
            program.progImageURL = getProgramImageHorizontal(program);
            program.prog_rating = getProgramRating(program);
            program.releaseYear = getReleaseYear(program);
            const isEpisodic = program.tmsid !== program.parent_tms_id;
            this.setState({ isLoading: false, program, isEpisode: isEpisodic })
        }).catch((error) => {
            const tmsid = this.props.tmsid;
            this.setState({ isLoading: false, hasError: true });
            this.props.gnviewSendLogMessage(`gnview_getProgramDetails error: ${error.message}`, error, {tmsid});
        })
    }

    exportProgramAvailabilityCard = () => {}

    render() {
        const entitledLanguages = convertLanguageCodeList(this.props.entitlements?.programs?.include?.languages).join(', ');
        return (
            <ErrorBoundary>
                <div className='gnview-program-availability-program-card'>
                    {this.state.isLoading && <LoadingSpinner />}
                    {!this.state.isLoading && !this.state.hasError && <div className='gnview-prog-avail-program-card-content'>
                        <div className='gnview-prog-avail-program-card-header'>
                            <GNImage className='program-image' url={getImageURLFromObj(this.state.program, 'progImageURL')} text={this.state.program.progTitleAlt} />
                            <div className='info-left-section'>
                                <div className='title'>{this.state.program.mainTitle}{this.state.isEpisode && <span>: {this.state.program.progTitleAlt}</span>}</div>
                                {this.state.isEpisode && this.state.program?.episode_info?.season && <div className='episode-info'>Season {this.state.program.episode_info.season} Episode {this.state.program.episode_info.number}</div>}
                                <div className='tmsid'>{this.state.program.parent_tms_id}{this.state.isEpisode && <span> : {this.state.program.tmsid}</span>}</div>
                                <div className='sub-section'>
                                    { this.state.program?.prog_rating && <span className='rating'>{this.state.program.prog_rating}</span> }
                                    { this.state.program?.prog_rating && <span className={'section-spacer'}>&#8226;</span> }
                                    <GNBadge type={this.state.program?.type} subtype={this.state.program?.subtype} />
                                    { this.state.program?.duration && <span className={'section-spacer'}>&#8226;</span> }
                                    { this.state.program?.duration && <span className='duration'>{this.state.program.duration} mins</span> }
                                    { this.state.program?.releaseYear && <span className={'section-spacer'}>&#8226;</span> }
                                    { this.state.program?.releaseYear && <span className='release-year'>{this.state.program.releaseYear}</span> }
                                </div>
                            </div>
                            <div className='info-right-section'>
                                <div className='header-section'>Root ID: <span className='text-section'>{this.state.program.rootid}</span></div>
                                <div className='header-section'>Description: <span className='text-section'>{getProgramDescription(this.state.program)}</span></div>
                            </div>
                            <div className='button-section'>
                                <Link to={`${ROUTES.PROGRAM_DETAILS}/${this.state.program.parent_tms_id}`} className='view-program-details-button'>View {this.state.isEpisode ? 'Series' : 'Program'} Details</Link>
                                {this.state.isEpisode && <Link to={`${ROUTES.PROGRAM_DETAILS}/${this.state.program.tmsid}`} className='view-program-details-button'>View Episode Details</Link>}
                            </div>
                        </div>
                        <ProgramAvailabilityTable tmsid={this.props.tmsid} isLoading={this.state.isLoading} />
                    </div>}
                    {!this.state.isLoading && this.state.hasError && <div className='gnview-prog-avail-program-card-unentitled'>
                        <span className='unentitled-header'>Access Denied</span>
                        <span className='tmsid'>TMS ID: {this.props.tmsid}</span>
                        <br/>
                        <span className='unentitled-message'>You are not entitled to view details for this Program. You are only allowed to view details for Programs that have the following languages:</span>
                        <span className='entitled-languages'>{entitledLanguages}</span><br/>Please contact your Customer Care rep for further information.
                    </div>}
                </div>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = state => {
    return {
        entitlements: gvauthSelEntitlements(state)
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewGetProgramDetails,
        gnviewSendLogMessage
    }, dispatch);
}

ProgramAvailabilityProgramCard.propTypes = {
    entitlements: PropTypes.object,
    gnviewGetProgramDetails: PropTypes.func.isRequired,
    gnviewSendLogMessage: PropTypes.func,
    tmsid: PropTypes.string.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgramAvailabilityProgramCard);