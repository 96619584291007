import React, { useEffect, useState } from 'react';
import { Form, Popover } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import './GNInput.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

export const GNInput = (props) => {
    const value = props?.value || '';
    const [inputValue, setValue] = useState(value);
    useEffect(() => {
        if (inputValue !== value) {
            setValue(value);
        }
    }, [inputValue, value]);

    const infoPopover = (
        <Popover>
            <Popover.Content>{props?.infoMessage}</Popover.Content>
        </Popover>
    );

    return (
        <div className={ClassNames('gnInput-main-container', { [props.className]: props.className })}>
            <InputGroup>
                {props?.label && (
                    <Form.Label>
                        {props.label}
                        {props?.infoMessage && (
                            <OverlayTrigger trigger='hover' placement='top' overlay={infoPopover} delay={500}>
                                <i className='fas fa-info-circle' />
                            </OverlayTrigger>
                        )}
                    </Form.Label>
                )}
                <Form.Control
                    as={props.type}
                    placeholder={props?.placeholder}
                    disabled={props?.disabled || false}
                    value={inputValue}
                    onChange={(e) => {
                        props.handleChange(e.target.value);
                        setValue(e.target.value);
                    }}
                    // disable Last Pass icon
                    data-lpignore={true}
                    {...(props?.textAreaRows && { rows: props.textAreaRows })}
                    {...(!props?.validateInput(inputValue) && { className: 'invalid' })}
                />
                {inputValue.length > 0 && !props.disabled && (
                    <i
                        className={ClassNames('fas fa-xmark', { 'has-label': props?.label })}
                        onClick={() => {
                            props.handleChange('');
                            setValue('');
                        }}
                    />
                )}
                <div className={ClassNames('validation-text', { invalid: !props?.validateInput(inputValue) })}>
                    <Form.Text className='required-text'>
                        {props?.isRequired && props?.requiredText}
                        {props?.validationText}
                    </Form.Text>
                    {props.type === 'textarea' && (
                        <Form.Text className='textarea-max'>{`${inputValue.length}/${props.textAreaMax}`}</Form.Text>
                    )}
                </div>
            </InputGroup>
        </div>
    );
};

GNInput.defaultProps = {
    className: '',
    isRequired: false,
    requiredText: '*Required ',
    type: 'input'
};

GNInput.propTypes = {
    disabled: PropTypes.bool,
    className: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    infoMessage: PropTypes.string,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    requiredText: PropTypes.string,
    textAreaMax: PropTypes.number,
    textAreaRows: PropTypes.number,
    type: PropTypes.string,
    validateInput: PropTypes.func.isRequired,
    validationText: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default GNInput;
