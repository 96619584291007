import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { gnviewSelProgramDetails } from '../../../../reducers/ProgramDetailsReducer';
import GNCard from '../../../../components/common/gnCard/GNCard';
import GNClientTable from '../../../../components/common/gnClientTable/GNClientTable';
import { getProgramSummaryFields, getTeamInfo, getOverviewSubMenuTabs } from '../../../../utils/ProgramUtils';
import {
    PROGRAM_TITLE_COLUMN_HEADERS,
    PROGRAM_DESC_COLUMN_HEADERS,
    SPORTS_TEAMS_COLUMN_HEADERS,
    TAB_NAMES
} from '../../../../constants/Program';
import './OverviewTab.scss'
import GNRadioGroup from '../../../../components/common/gnRadioGroup/GNRadioGroup';
import ReleasesTab from './ReleasesTab';
import RatingsTab from './RatingsTab';
import AwardsTab from './AwardsTab';
import GNSummary from '../../../../components/common/gnSummary/GNSummary';

export class OverviewTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subtabSelected: TAB_NAMES.SUMMARY
        };
    }

    renderProgramTitles = (programDetails) => {
        if (programDetails.episode_info?.titles?.length) {
            return <GNClientTable columns={PROGRAM_TITLE_COLUMN_HEADERS} data={this.props.programDetails.episode_info.titles} isLoading={false}/>;
        } else if (!programDetails.episode_info && programDetails.titles?.length) {
            return <GNClientTable columns={PROGRAM_TITLE_COLUMN_HEADERS} data={this.props.programDetails.titles} isLoading={false}/>;
        } else {
            return <div className="no-titles-message">No titles available</div>;
        }
    }

    onSubTabSelect = (tab) => {
        this.setState({subtabSelected: tab});
    }

    render() {
        const summaryFields = getProgramSummaryFields(this.props.programDetails);
        const teamInfo = getTeamInfo(this.props.programDetails?.sports_info?.teams);
        const overviewTabs = [...getOverviewSubMenuTabs(this.props.programDetails)];
        return (
            <div className="gnview-overview-tab-content">
                <GNRadioGroup style="flat" list={overviewTabs} onSelect={this.onSubTabSelect} default={overviewTabs[0]}/>
                {this.state.subtabSelected === TAB_NAMES.SUMMARY && <GNCard>
                    <GNSummary fields={summaryFields} data={this.props.programDetails} columns={3} />
                </GNCard>}
                {this.state.subtabSelected === TAB_NAMES.TEAMS && <GNCard>
                    {teamInfo?.length > 0 && <GNClientTable columns={SPORTS_TEAMS_COLUMN_HEADERS} data={teamInfo} isLoading={false}/>}
                    {(!teamInfo || teamInfo?.length === 0) && <div className="no-team-info-message">No Team Information available</div>}
                </GNCard>}
                {this.state.subtabSelected === TAB_NAMES.TITLES && <GNCard>
                    {this.renderProgramTitles(this.props.programDetails)}
                </GNCard>}
                {this.state.subtabSelected === TAB_NAMES.DESCRIPTIONS && <GNCard>
                    {this.props.programDetails?.descriptions?.length > 0 && <GNClientTable className="description-table" columns={PROGRAM_DESC_COLUMN_HEADERS} data={this.props.programDetails.descriptions} isLoading={false}/>}
                    {(!this.props.programDetails?.descriptions || this.props.programDetails.descriptions.length === 0) && <div className="no-descriptions-message">No descriptions available</div>}
                </GNCard>}
                {this.state.subtabSelected === TAB_NAMES.RELEASES && <ReleasesTab />}
                {this.state.subtabSelected === TAB_NAMES.RATINGS && <RatingsTab />}
                {this.state.subtabSelected === TAB_NAMES.AWARDS && <AwardsTab />}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        programDetails: gnviewSelProgramDetails(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewTab);

OverviewTab.propTypes = {
    programDetails: PropTypes.object.isRequired
}