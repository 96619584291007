import React from 'react';
import "./GNImage.scss";
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const GNImage = (props) => {
    return (
        <React.Fragment>
            { props.url && <img className={props.className} src={props.url} onClick={props.onClick} alt={props.text} />}
            { !props.url && <div className={ClassNames('blank-image', props.className)} onClick={props.onClick}><FontAwesomeIcon icon="file-image" /></div>}
        </React.Fragment>
    )
}

GNImage.propTypes = {
    url: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string
};

export default GNImage;