import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import range from 'lodash.range';
import get from 'lodash.get';
import './GNSummary.scss';
import ClassNames from 'classnames';

export const GNSummary = (props) => {
    const numRows = Math.ceil(props.fields.length / props.columns);
    return (
        <div className='gnSummary-main-container'>
            {range(props.columns).map((col) => {
                const rowIndices = range(col * numRows, col * numRows + numRows);
                const isLastCol = col === props.columns - 1;
                return (
                    <div className={ClassNames('gnSummary-table-container', {'lastCol': isLastCol})} key={col}>
                        <Table size='sm'>
                            <tbody>
                                {rowIndices.map(index => {
                                    const field = props?.fields[index];
                                    if (field) {
                                        const value = get(props.data, field.prop, '');
                                        const extra = Array.isArray(field.extra) ? field.extra.reduce(function(acc, cur) {
                                            acc[cur] = get(props.data, cur, '');
                                            return acc;
                                        }, {}) : {};
                                        if (field.render) {
                                            return (
                                                <tr className='summary-label' key={index}>
                                                    <td className='field-label'>{field.label}</td>
                                                    <td>{value ? field.render(value, extra) : ''}</td>
                                                </tr>
                                            )
                                        } else {
                                            return (
                                                <tr className='summary-label' key={index}>
                                                    <td className='field-label'>{field.label}</td>
                                                    <td>{Array.isArray(value) ? value.join(', ') : value}</td>
                                                </tr>
                                            )
                                        }
                                    } else {
                                        return null;
                                    }
                                })}
                            </tbody>
                        </Table>
                    </div>
                )
            })}
        </div>
    )
};

GNSummary.propTypes = {
    fields: PropTypes.array,
    data: PropTypes.object,
    columns: PropTypes.number
};

export default GNSummary;