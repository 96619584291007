import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment-timezone';
import StatusBadge from '../components/common/statusBadge/StatusBadge';

export const REPORT_AN_ISSUE_TITLE = "Report an Issue";
export const SEND_BUTTON_TOOLTIP = "All required fields must be completed.";
export const REPORT_AN_ISSUE_FAILURE_MESSAGE = "Sorry, there was an error in submitting your report. Please try again in a few minutes.";
export const REPORT_AN_ISSUE_SUCCESS_MESSAGE_THANKS = "Thanks!";
export const REPORT_AN_ISSUE_SUCCESS_MESSAGE = "We'll work on this issue and get back to you.";
export const TECHNICAL_PLATFORM_ISSUE = "Technical Platform Issue";
export const REPORT_ISSUE_STATUS_TABLE_HEADER = "Status";
export const REPORT_ISSUE_STATUS_NO_DATA_MESSAGE_HEADER = "No issues submitted.";
export const REPORT_ISSUE_STATUS_NO_DATA_MESSAGE = "Once an issue is submitted, you will see the status of the case here.";

export const REPORT_AN_ISSUE_UNGENT_CONTACT = {
    DATE_TIME_TIMEZONE: "Monday - Friday, 8AM - 5:30PM ET",
    FOR_URGENT_PROBLEMS_CALLS: "For urgent problems, call:",
    FOR_URGENT_PROBLEMS_CALLS_NUMBER_1: "(800) 833-9581",
    FOR_URGENT_PROBLEMS_CALLS_NUMBER_2: "(518) 792-9914",
    HOURS: "Hours:",
    OR: "or"
};

export const REPORT_AN_ISSUE_SECTIONS = {
    ADDITIONAL_INFO: "Additional Info",
    CONTACT_INFO: "Contact Info",
    OVERVIEW: "Overview"
};

export const REPORT_AN_ISSUE_BUTTON_TEXT = {
    SUBMIT: "Submit"
};

export const REPORT_AN_ISSUE_LABELS = {
    CONFLICT_DATE: "Conflict Date",
    DESCRIPTION: "Description",
    DESCRIPTION_REQUIRED: "*Required (Maximum of 1000 characters)",
    FEEDBACK_ABOUT: "Feedback About",
    STATION_ID: "Station ID",
    TMS_ID: "TMS ID",
    URL: "URL",
    USER_EMAIL: "Email",
    USER_FIRST_NAME: "First Name",
    USER_LAST_NAME: "Last Name"
};

export const REPORT_AN_ISSUE_PLACEHOLDERS = {
    DESCRIPTION: "What piece of data is wrong and where are you seeing it?",
    FEEDBACK_ABOUT: "Select an issue"
};

export const FEEDBACK_ABOUT_OPTIONS_LABELS = [
    {
        value: "Data is not updated",
        description: "For data on the site that shows old update timestamps or is not reflecting data sent over to GN"
    },
    {
        value: "Image Issue",
        description: "For any image related issue that requires Rich Media support"
    },
    {
        value: "Linear Schedule Issue",
        description: "For problems with linear schedules requiring Ingest support"
    },
    {
        value: "Metadata Issue",
        description: "Any problem that requires Editorial support to adjust values"
    },
    {
        value: "Non-linear Schedule Issue",
        description: "For problems with non-linear issues that require Ingest support"
    },
    {
        value: "Technical Platform Issue",
        description: "For problems related to site functionality"
    },
    {
        value: "Other",
        description: "Any problem that does not fall within one of the above options"
    }
];

/* eslint-disable react/prop-types */
export const CASE_STATUS_TABLE_COLUMN_HEADERS = [
    { accessor: 'status', Header: 'Status', Cell: ({value}) => {
        return <StatusBadge value={value} />
    }},
    { accessor: 'discrepancyCategory', Header: 'Feedback About', Cell: ({row}) => {
        return <OverlayTrigger overlay={<Tooltip>{row?.original?.url}</Tooltip>} placement="bottom">
            <span className='status-feedback-about'>{row?.original?.discrepancyCategory}</span>
        </OverlayTrigger> || null;
    }},
    { accessor: 'subject', Header: 'Subject'},
    { accessor: 'createdDate', Header: 'Date Opened', Cell: ({value}) => moment(value).format('lll')},
    { accessor: 'lastModifiedDate', Header: 'Last Updated', Cell: ({value}) => moment(value).format('lll')}
];
/* eslint-enable react/prop-types */
