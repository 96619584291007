import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import ClassNames from 'classnames';
import "./DateCarousel.scss";
import moment from 'moment-timezone';
import { sportsSetStartDate } from '../../../actions/SportsActions';
import { sportsSelStartDate } from '../../../reducers/SportsReducer';
import { formatDateOptions } from '../../../utils/SportUtils';

export const DateCarousel = () => {
    const dispatch = useDispatch();
    const userSelectedDate = useSelector(sportsSelStartDate);
    const today = moment();
    const pastLimit = moment(today).subtract(365, 'days');
    const futureLimit = moment(today).add(365, 'days');
    const [firstDate, setFirstDate] = useState(moment(userSelectedDate).subtract(3, 'days'));
    const [dateOptions, setDateOptions] = useState(formatDateOptions(firstDate));

    useEffect(() => {
        setDateOptions(formatDateOptions(firstDate));
    }, [firstDate, setDateOptions]);

    const handleSelectDate = (date) => {
        if (moment(date).isBefore(pastLimit) || moment(date).isAfter(futureLimit)) {
            return;
        }
        setFirstDate(moment(date).subtract(3, 'days'));
        dispatch(sportsSetStartDate(moment(date).format('YYYY-MM-DD')));
    };

    const handlePrev = () => {
        setFirstDate(moment(firstDate).subtract(7, 'days'));
    };

    const handleNext = () => {
        setFirstDate(moment(firstDate).add(7, 'days'));
    };

    return (
        <ErrorBoundary>
            <div className="gnview-sports-date-carousel-container">
                {moment(firstDate).isAfter(pastLimit) && <div className="button-container" onClick={handlePrev}>
                    <FontAwesomeIcon className='prev arrow-icon' icon={'chevron-left'} size='lg'/>
                </div>}
                <div className="carousel-options-wrapper">
                    {dateOptions.map((option, index) => {
                        return <div key={index} className={ClassNames("carousel-option", { option_selected: moment(userSelectedDate).isSame(option, 'day') })} onClick={() => handleSelectDate(option)}>
                            {moment(option).isSame(today, 'day') ? 'TODAY' : moment(option).format('MMM D')}
                        </div>;
                    })}
                </div>
                {moment(firstDate).isBefore(futureLimit) && <div className="button-container" onClick={handleNext}>
                    <FontAwesomeIcon className='next arrow-icon' icon={'chevron-right'} size='lg'/>
                </div>}
            </div>
        </ErrorBoundary>
    )
}

export default DateCarousel;
