import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GNRadioGroup from '../gnRadioGroup/GNRadioGroup';
import GNDatePicker from '../gnDatePicker/GNDatePicker';
import { getFormattedDate } from '../../../utils/DateUtils';
import "./GNDateSelection.scss";
import PropTypes from 'prop-types';

export const GNDateSelection = (props) => {
    return (
        <div className="gnview-date-selection">
            <GNRadioGroup label={props.radioLabel} list={props.radioList} onSelect={props.onRadioSelect} default={props.defaultRadio}/>
            {props.isRelative && <div className="relative-date">
                <div className={'filter-label'}>Date:</div>
                <i className="fas fa-calendar-days" />{getFormattedDate(props.isRelative, props.dateTypeSelected, props.relativeStartDate, props.relativeEndDate, props.exactStartDate, props.exactEndDate)}
            </div>}
            {!props.isRelative && <GNDatePicker
                dailyOrWeekly={props.exactDailyOrWeekly}
                startDate={props.exactStartDate}
                endDate={props.exactEndDate}
                onDatesChange={props.onExactDatesChange}/>}
        </div>
    );
}

GNDateSelection.propTypes = {
    radioLabel: PropTypes.string.isRequired,
    radioList: PropTypes.array.isRequired,
    onRadioSelect: PropTypes.func.isRequired,
    defaultRadio: PropTypes.string.isRequired,
    isRelative: PropTypes.bool.isRequired,
    dateTypeSelected: PropTypes.string.isRequired,
    relativeStartDate: PropTypes.string.isRequired,
    relativeEndDate: PropTypes.string.isRequired,
    exactDailyOrWeekly: PropTypes.bool.isRequired,
    // Moment object
    exactStartDate: PropTypes.object.isRequired,
    // Moment object
    exactEndDate: PropTypes.object.isRequired,
    onExactDatesChange: PropTypes.func.isRequired
};

const mapStateToProps = () => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GNDateSelection);