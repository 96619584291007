import PropTypes from 'prop-types'
import React from 'react';
import ErrorBoundary from '../../../../components/common/errorBoundary/ErrorBoundary';
import { Container } from "react-bootstrap";
import GNCard from '../../../../components/common/gnCard/GNCard';
import GNListGroup from '../../../../components/common/gnListGroup/GNListGroup';
import GNClientTable from '../../../../components/common/gnClientTable/GNClientTable';
import LoadingSpinner from '../../../../components/common/loadingSpinner/LoadingSpinner';
import "./StationsOverviewTab.scss";
import {
    STATION_SUMMARY_FIELDS,
    UNENTITLED_STATION_SUMMARY_FIELDS,
    STATION_AFFILIATIONS_COLUMN_HEADERS,
    STATION_RELATIONSHIPS_COLUMN_HEADERS
} from '../../../../constants/Station';

export const StationsOverviewTab = (props) => {
    return (
        <ErrorBoundary>
            {!props.isLoading && <Container fluid className="station-details-container">
                <GNCard title="Summary">
                    {props.noError() && !props.unentitled && <GNListGroup fields={STATION_SUMMARY_FIELDS} data={props.selectedStation} />}
                    {props.noError() && props.unentitled && <GNListGroup fields={UNENTITLED_STATION_SUMMARY_FIELDS} data={props.selectedStation} />}
                </GNCard>
                {!props.unentitled && <GNCard title="Relationships">
                    {props.noError() && props.selectedStation?.relationships?.length > 0 && <GNClientTable columns={STATION_RELATIONSHIPS_COLUMN_HEADERS} data={props.selectedStation.relationships} isLoading={false}/>}
                    {props.noError() && (!props.selectedStation?.relationships || props.selectedStation.relationships?.length === 0) && <div className="no-titles-message">No Relationships</div>}
                </GNCard>}
                {!props.unentitled && <GNCard title="Affiliations">
                    {props.noError() && props.selectedStation?.affiliations?.length > 0 && <GNClientTable columns={STATION_AFFILIATIONS_COLUMN_HEADERS} data={props.selectedStation.affiliations} isLoading={false}/>}
                    {props.noError() && (!props.selectedStation?.affiliations || props.selectedStation.affiliations?.length === 0) && <div className="no-titles-message">No Affiliations</div>}
                </GNCard>}
                {props.invalidIDError && !props.isLoading && <div className="error-message">The entered Station ID might not exist. For any questions, please contact customer care.</div>}
                {props.serverError && !props.isLoading && <div className="error-message">Sorry, your report could not be completed. Please try again. If this problem continues, please contact customer care.</div>}
                {props.isLoading && <LoadingSpinner />}
            </Container>}
        </ErrorBoundary>
    )
};

export default StationsOverviewTab;

StationsOverviewTab.propTypes = {
    invalidIDError: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    noError: PropTypes.func.isRequired,
    selectedStation: PropTypes.object.isRequired,
    serverError: PropTypes.bool.isRequired,
    unentitled: PropTypes.bool.isRequired
}