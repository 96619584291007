import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { gnviewSelProgramDetails } from '../../../../reducers/ProgramDetailsReducer';
import GNCard from '../../../../components/common/gnCard/GNCard';
import GNClientTable from '../../../../components/common/gnClientTable/GNClientTable';
import {PROGRAM_AWARDS_COLUMN_HEADERS} from '../../../../constants/Program';
import PropTypes from 'prop-types';

export class AwardsTab extends Component {
    render() {
        return (
            <div>
                <GNCard>
                    {this.props.programDetails?.awards?.length > 0 && <GNClientTable columns={PROGRAM_AWARDS_COLUMN_HEADERS} data={this.props.programDetails.awards}/>}
                    {(!this.props.programDetails?.awards || this.props.programDetails.awards.length === 0) && <div className="no-awards-message">No awards data available</div>}
                </GNCard>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        programDetails: gnviewSelProgramDetails(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AwardsTab);

AwardsTab.propTypes = {
    programDetails: PropTypes.object.isRequired
}