import React from 'react';
import PropTypes from 'prop-types';
import { getStationImage } from '../../../utils/StationUtils';
import { getImageURL } from '../../../utils/ImageUtils';
import GNImage from '../../../components/common/gnImage/GNImage';
import { Form } from 'react-bootstrap';
import "./StreamingCatalog.scss";

export const StreamingCatalog = (props) => {
    const imageURL = getImageURL(getStationImage(props.catalog));
    return (
        <span className="gnview-streaming-catalog">
            {/* For custom checkboxes you need the prop 'custom', label, and a unique id */}
            {props.handleCheckbox && <Form.Check custom inline label="" id={`catalog-${props.catalog.name}`} type="checkbox" checked={props.catalog.selected} onChange={(e) => props.handleCheckbox(e, props.catalog)}/>}
            {imageURL && <GNImage className={'catalog-image'} url={imageURL} text={props.catalog.name} />}
            <span className="catalog-name">{props.catalog.name}</span>
        </span>
    )
}

StreamingCatalog.propTypes = {
    catalog: PropTypes.object,
    handleCheckbox: PropTypes.func
};

export default StreamingCatalog;