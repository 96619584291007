import {
    GNVIEW_ADVANCED_SEARCH_UPDATE_SEARCH_BY,
    GNVIEW_ADVANCED_SEARCH_PROGRAM_ATTRIBUTES_FIELDS_UPDATE,
    GNVIEW_ADVANCED_SEARCH_FIELDS_CLEAR,
    GNVIEW_ADVANCED_SEARCH_RESULT_TABLE_RESET,
    GNVIEW_ADVANCED_SEARCH_ROOT_ID_FIELDS_UPDATE,
    GNVIEW_ADVANCED_SEARCH_SERIES_ID_FIELDS_UPDATE
} from '../actions/ActionTypes';
import {
    PROGRAM_ATTRIBUTES_INITIAL_VALUES,
    ROOT_ID_INITIAL_VALUES,
    SERIES_ID_INITIAL_VALUES
} from '../constants/AdvancedSearchValidation';

export const ADVANCED_SEARCH_STORE = 'advancedSearch';

/**
 * Initial State
 */
export const initialState = {
    programAttributesFields: {
        ...PROGRAM_ATTRIBUTES_INITIAL_VALUES
    },
    rootIdFields: {
        ...ROOT_ID_INITIAL_VALUES
    },
    seriesIdFields: {
        ...SERIES_ID_INITIAL_VALUES
    },
    searchType: 'Program Attributes', // can't use constant here since it will be an cyclic error eslint import/no-cycle
    // Flag used to trigger unmounting the GNServerTable when Update/Search is clicked
    shouldResetResultTable: false
};

/**
 * Reducer
 */
export const AdvancedSearchReducer = (state = initialState, action) => {
    switch (action.type) {
    case GNVIEW_ADVANCED_SEARCH_UPDATE_SEARCH_BY:
        return {...state, searchType: action.payload.searchType};
    case GNVIEW_ADVANCED_SEARCH_PROGRAM_ATTRIBUTES_FIELDS_UPDATE:
        return { ...state, programAttributesFields: action.payload.programAttributesFields };
    case GNVIEW_ADVANCED_SEARCH_ROOT_ID_FIELDS_UPDATE:
        return { ...state, rootIdFields: action.payload.rootIdFields };
    case GNVIEW_ADVANCED_SEARCH_SERIES_ID_FIELDS_UPDATE:
        return { ...state, seriesIdFields: action.payload.seriesIdFields };
    case GNVIEW_ADVANCED_SEARCH_FIELDS_CLEAR:
        return { ...initialState, searchType: state.searchType };
    case GNVIEW_ADVANCED_SEARCH_RESULT_TABLE_RESET:
        return { ...state, shouldResetResultTable: action.payload.shouldReset }
    default:
        return state;
    }
};

/**
 * Selectors
 */
export const gnviewSelAdvancedSearchProgramAttributesFields = (state) => state[ADVANCED_SEARCH_STORE].programAttributesFields;
export const gnviewSelAdvancedSearchRootIdFields = (state) => state[ADVANCED_SEARCH_STORE].rootIdFields;
export const gnviewSelAdvancedSearchSeriesIdFields = (state) => state[ADVANCED_SEARCH_STORE].seriesIdFields;
export const gnviewSelAdvancedSearchType = (state) => state[ADVANCED_SEARCH_STORE].searchType;
export const gnviewSelAdvancedSearchResultTableReset = (state) => state[ADVANCED_SEARCH_STORE].shouldResetResultTable;

export default AdvancedSearchReducer;
