import { GNVIEW_CATALOGS_IN_PROGRESS, GNVIEW_CATALOGS_SUCCESS, GNVIEW_CATALOGS_FAIL, GNVIEW_CATALOGS_CLEAR } from '../actions/ActionTypes';

export const GNVIEW_STREAMING_CATALOGS = 'gnvcatalogs';

/**
 * Initial State
 */
export const initialState = {
    entitledCatalogs: [],
    entitledCatalogsLoading: false
};

/**
 * Reducer
 */
export const GNVCatalogsReducer = (state = initialState, action) => {
    switch (action.type) {
    case GNVIEW_CATALOGS_IN_PROGRESS:
        return { ...state, entitledCatalogs: [], entitledCatalogsLoading: true };
    case GNVIEW_CATALOGS_SUCCESS:
        if (action.payload.shouldStore) {
            return { ...state, entitledCatalogs: action.payload.catalogs, entitledCatalogsLoading: false };
        }
        return { ...state, entitledCatalogsLoading: false };
    case GNVIEW_CATALOGS_FAIL:
        return { ...state, entitledCatalogs: action.payload.catalogs, entitledCatalogsLoading: false };
    case GNVIEW_CATALOGS_CLEAR:
        return { ...state, entitledCatalogs: [] };

    default:
        return state;
    }
};


/**
 * Selectors
 */
export const gnviewSelEntitledCatalogs = state => state[GNVIEW_STREAMING_CATALOGS].entitledCatalogs;
export const gnviewSelEntitledCatalogsLoading = state => state[GNVIEW_STREAMING_CATALOGS].entitledCatalogsLoading;


/**
 * Default export
 */
export default GNVCatalogsReducer;