import React, { useState } from 'react';
import { DATA_ALERTS, DATA_ALERTS_TYPES, DATA_ALERTS_TABS, DATA_ALERTS_UPDATED } from '../../constants/DataAlerts';
import SubscribedChannelsTab from './tabs/SubscribedChannelsTab';
import { SUBSCRIBE_TO_ALERTS_LABELS } from '../../constants/Station';
import GNRadioGroup from '../../components/common/gnRadioGroup/GNRadioGroup';
import { useDispatch, useSelector } from "react-redux";
import "./DataAlerts.scss";
import { Tooltip } from "react-bootstrap";
import GNButton from "../../components/common/gnButton/GNButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    gnviewGetUserSubscriptions
} from "../../actions/DataAlertsActions";
import { gvauthSelEmail } from "../../reducers/GNVAuthReducer";
import { gnviewSendLogMessage } from '../../services/GeneralService';
import AlertsTab from './tabs/AlertsTab';
import { gnviewSelDataAlertsSubscriptions } from '../../reducers/DataAlertsReducer';
import { SubscribeModal } from '../details/stations/Subscribe/SubscribeModal';
import GNNotification from '../../components/common/gnNotification/GNNotification';
import { DATA_ALERTS_MAX_CHANNELS_MESSAGE } from '../../constants/DataAlerts';
import { gnviewGetAlertsCount } from '../../actions/SubscriptionActions';
import { gnviewDataAlertsSetAlertsCountsThisWeek } from '../../actions/DataAlertsActions';

export const DataAlerts = () => {
    const subscriptions = useSelector(gnviewSelDataAlertsSubscriptions);
    const [selectedTab, setSelectedTab] = useState(DATA_ALERTS_TABS[DATA_ALERTS_TYPES.ALERTS]);
    const [showSubscribeModal, setShowSubscribeModal] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const email = useSelector(gvauthSelEmail);
    const dispatch = useDispatch();

    const onTabSelect = (tab) => {
        setSelectedTab(tab);
    };

    const openSubscribeModal = () => {
        setShowSubscribeModal(true);
    };

    const closeSubscribeModal = () => {
        setShowSubscribeModal(false);
    };

    const handleAfterSubscription = () => {
        setShowSubscribeModal(false);
        dispatch(gnviewGetUserSubscriptions(email))
            .catch((err) => {
                gnviewSendLogMessage('Retrieving subscriptions error:', err)
            });
        gnviewGetAlertsCount(email, 'this-week').then((response) => {
            const count = response?.result?.alert_count
            dispatch(gnviewDataAlertsSetAlertsCountsThisWeek(count))
        }).catch(error => {
            gnviewSendLogMessage(error)
        })
    };

    const handleErrorSubscription = () => {
        setNotificationMsg(SUBSCRIBE_TO_ALERTS_LABELS.SUBSCRIPTION_ERROR);
        setShowNotification(true);
    }

    return (
        <div className='gnview-data-alerts'>
            <div className='gnview-data-alerts-header-section'>
                <div className='gnview-header-title'>{DATA_ALERTS}</div>
                <div className="button-info-container">
                    <GNButton
                        tooltip={
                            <Tooltip id='request-publish-button-tooltip'>
                                {DATA_ALERTS_MAX_CHANNELS_MESSAGE}
                            </Tooltip>
                        }
                        buttonClass='register-button'
                        hasWriteAccess={true}
                        trigger={subscriptions.length >= 10 && 'hover'}
                        onClick={openSubscribeModal}
                        disabled={subscriptions.length >= 10}
                    >
                        <FontAwesomeIcon icon='bell' />
                        {"Track Channel"}
                    </GNButton>
                    <div className='gnview-data-alerts-info'>{DATA_ALERTS_UPDATED}</div>
                </div>
            </div>
            <div className='data-alerts-table-container'>
                <GNRadioGroup
                    style='flat'
                    list={Object.values(DATA_ALERTS_TABS)}
                    onSelect={onTabSelect}
                    default={selectedTab}
                />
                {selectedTab === DATA_ALERTS_TABS[DATA_ALERTS_TYPES.SUBSCRIBED_CHANNELS] && (
                    <div>
                        <SubscribedChannelsTab />
                    </div>
                )}
                {selectedTab === DATA_ALERTS_TABS[DATA_ALERTS_TYPES.ALERTS] &&
                    <AlertsTab email={email} />}
            </div>
            {showSubscribeModal && (
                <SubscribeModal
                    show={showSubscribeModal}
                    handleModal={closeSubscribeModal}
                    handleAfterSubscription={handleAfterSubscription}
                    handleErrorSubscription={handleErrorSubscription}
                    subscriptions={subscriptions}
                    trackChannel={true}
                />
            )}
            <GNNotification
                handleShow={setShowNotification}
                message={notificationMsg}
                milliseconds={5000}
                show={showNotification}
                success={false}
            />
        </div>
    );
};

export default DataAlerts;
