import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../config/Routes';
import './ProgramAvailabilityResult.scss';
import ProgramAvailabilityProgramCard from './ProgramAvailabilityProgramCard';
import { gnviewSelProgramAvailProgramIds, gnviewSelProgramAvailQueryObj, gnviewSelProgramAvailCatalogIds } from '../../../reducers/ProgramAvailabilityReducer';
import LazyLoad from 'react-lazyload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExportButton from '../../../components/common/exportButton/ExportButton';
import { gnviewGetExportProgramAvailabilities, gnviewGetSavedSearch, gnviewGetProgramAvailabilitiesLastUpdated } from '../../../actions/GNViewActions';
import { downloadExportFile, formatExportFileName } from '../../../utils/GeneralUtils';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import MySavedItemsButton from '../../../components/common/saveButton/MySavedItemsButton';
import queryString from 'query-string';
import { programAvailabilitySavedItemBodyFrom } from '../../../constants/MySavedItems';
import { gvauthSelEmail } from '../../../reducers/GNVAuthReducer';
import { getLocalGNVIEWIngestedLastupdated } from '../../../utils/ProgramUtils';
import { PROGRAM_AVAILABILITY_GNVIEW_LAST_UPDATED } from '../../../constants/ProgramAvailability';

export const ProgramAvailabilityResult = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const programTMSIds = useSelector(gnviewSelProgramAvailProgramIds);
    const selectedCatalogIds = useSelector(gnviewSelProgramAvailCatalogIds);
    const queryObj = useSelector(gnviewSelProgramAvailQueryObj);
    const email = useSelector(gvauthSelEmail);
    const [exportIsLoading, setExportIsLoading] = useState(false);
    const [savedItem, setSavedItem] = useState({});
    const [savedItemIsLoading, setSavedItemIsLoading] = useState(true);
    const [lastUpdated, setLastUpdated] = useState('');
    const searchId = queryString.parse(location?.search)['search_id'];

    useEffect(() => {
        if (searchId) {
            dispatch(gnviewGetSavedSearch(searchId)).then((response) => {
                setSavedItem(response?.result);
            }).catch(() => {
                // API throws an error if saved search does not exist or is not owned by user
                history.push(ROUTES.PROGRAM_AVAILABILITIES);
            });
        }
        dispatch(gnviewGetProgramAvailabilitiesLastUpdated())
            .then((response) => {
                if (response?.result?.length > 0) {
                    const localLastUpdated = getLocalGNVIEWIngestedLastupdated(response.result);
                    setLastUpdated(localLastUpdated);
                }
            })
            .catch((error) => {
                dispatch(
                    gnviewSendLogMessage(`gnviewGetProgramAvailabilitiesLastUpdated error: ${error.message}`, error)
                );
            });
        setSavedItemIsLoading(false);
        const wrapper = document.getElementsByClassName('gnview-main-container')[0];
        if (wrapper) {
            // scrollTop should be supported in IE versus scrollTo
            wrapper.scrollTop = 0;
        }
    }, [dispatch, history, searchId])

    const exportProgramAvailabilities = () => {
        setExportIsLoading(true);
        dispatch(gnviewGetExportProgramAvailabilities(programTMSIds, selectedCatalogIds)).then((response) => {
            downloadExportFile(response?.result, 'text/csv; charset=utf-8', formatExportFileName(response));
            setExportIsLoading(false);
        }).catch((error) => {
            dispatch(gnviewSendLogMessage(`gnviewGetExportProgramAvailabilities error: ${error.message}`, error));
            setExportIsLoading(false);
        });
    };

    const createSavedItemBody = () => {
        const title = savedItem?.title || '';
        const savedItemQueryObjCopy = JSON.stringify(savedItem?.query_obj);
        const reduxQueryObjCopy = JSON.stringify(queryObj);
        const catalogIds = savedItemQueryObjCopy === reduxQueryObjCopy ? savedItem?.queryObj?.catalog_ids : queryObj?.catalog_ids;
        const programIds = savedItemQueryObjCopy === reduxQueryObjCopy ? savedItem?.queryObj?.program_ids : queryObj?.program_ids;
        return programAvailabilitySavedItemBodyFrom(
            email,
            title,
            catalogIds,
            programIds
        );
    };

    const savedItemBody = createSavedItemBody();
    const selectedQueryObj = savedItem?.query_obj || queryObj;
    return (
        <ErrorBoundary>
            {programTMSIds?.length > 0 && <div className='gnview-program-availability-result'>
                <div className='program-avail-result-top-bar'>
                    <div className='gnview-breadcrumb'>
                        <Link to={ROUTES.PROGRAM_AVAILABILITIES}>Program Availability</Link>
                        <FontAwesomeIcon className="gnview-breadcrumbs-separator" icon="angle-right" />
                        <span className='program-count'>{programTMSIds?.length} {programTMSIds.length === 1 ? 'Program' : 'Programs'}</span>
                    </div>
                    <div className="button-container">
                        <ExportButton type='.csv' exportFunction={exportProgramAvailabilities} isLoading={exportIsLoading} />
                        {!savedItemIsLoading && <MySavedItemsButton
                            body={savedItemBody}
                            searchId={searchId}
                            selectedPrograms={selectedQueryObj?.program_ids?.map(program => ({ title: program.title, tms_id: program.parent_tms_id }))}
                            validationCallback={() => false}
                        />}
                    </div>
                </div>
                {lastUpdated?.length > 0 && (
                    <div className='program-avail-last-updated'>
                        <span className='program-avail-last-updated__label'>
                            {PROGRAM_AVAILABILITY_GNVIEW_LAST_UPDATED}
                        </span>
                        <span className='program-avail-last-updated__time'>{lastUpdated}</span>
                    </div>
                )}
                {programTMSIds.map((tmsid) => {
                    return <LazyLoad scroll={true} once overflow={true} scrollContainer={'gnview-program-availability-result'} offset={200} height={300} key={tmsid}>
                        <ProgramAvailabilityProgramCard tmsid={tmsid} />
                    </LazyLoad>
                })}
            </div>}
        </ErrorBoundary>
    )
}

export default ProgramAvailabilityResult;