import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { ROUTES } from './config/Routes';
import queryString from 'query-string';
import Menu from './components/common/menu/Menu';
import MarketsSearchView from './views/details/markets/MarketsSearch';
import MarketsDetailsView from './views/details/markets/MarketsDetails';
import StationsSearchView from './views/details/stations/StationsSearch';
import ProgramsSearchView from './views/details/programs/ProgramsSearch';
import ProgramsDetailsView from './views/details/programs/ProgramsDetails';
import AdvancedSearchView from './views/details/advancedSearch/AdvancedSearch';
import AdvancedSearchResultsView from './views/details/advancedSearch/AdvancedSearchResultsView';
import SportsSearch from './views/details/sports/SportsSearch';
import SportsResults from './views/details/sports/SportsResults';
import PrivateRoute from './components/common/privateRoute/PrivateRoute';
import { gvauthIsBasic, gvauthSelEmail, gvauthSelToken, gvauthSelEntitlements, gvauthSelLookupCount, gvauthSelLookupMax } from './reducers/GNVAuthReducer';
import MySavedItems from './views/mySavedItems/MySavedItems';
import ScheduleSearchView from './views/reports/schedule/ScheduleSearch';
import ScheduleResult from './views/reports/schedule/ScheduleResult';
import StationsDetailsView from "./views/details/stations/StationsDetails";
import ProgramAiringsSearchView from './views/reports/programAirings/ProgramAiringsSearch';
import ProgramAiringsResultView from './views/reports/programAirings/ProgramAiringsResult';
import ProgramAvailabilitySearchView from './views/streamingVideo/programAvailability/ProgramAvailabilitySearch';
import AccessDeniedView from './views/AccessDenied';
import GetStartedView from './views/getStarted/GetStarted';
import ContactUsView from './views/ContactUs';
import LookupMaxView from './views/LookupMax';
import NotFound404View from './views/NotFound404';
import GNViewTeaser from './views/GNViewTeaser';
import { GNGetHelp } from './components/common/gnGetHelp/GNGetHelp';
import ErrorBoundary from './components/common/errorBoundary/ErrorBoundary';
import ProgramAvailabilityResult from './views/streamingVideo/programAvailability/ProgramAvailabilityResult';
import PropTypes from "prop-types";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { config } from '@fortawesome/fontawesome-svg-core'
import ReportAnIssue from './views/reportAnIssue/ReportAnIssue';
import Status from './views/status/Status';
import { GNVIEW_AUTH, GNVIEW_SETTINGS } from './constants/LocalStorage';
import { gnviewAuthUpdate, gnviewLookupCountUpdate, gnviewGetLookupReset, gnviewUserSettingsUpdate } from './actions/GNViewAuthActions';
import ReactRouterPropTypes from "react-router-prop-types";
import { shouldRenderGNViewTeaser } from "./constants/PrivateRoute";
import { DataAlerts } from './views/dataAlerts/DataAlerts';
import { FEATURE_FLAGS } from './constants/App';
// https://stackoverflow.com/questions/48358529/failed-to-execute-removechild-on-node-with-fontawesome-in-react
config.autoReplaceSvg = "nest";
library.add(fas);

export class App extends Component {
    constructor(props) {
        super(props);
    }


    componentDidMount() {
        const serializedAuthState = localStorage.getItem(GNVIEW_AUTH);
        const authState = JSON.parse(serializedAuthState);
        const serializedUserSettingsState = localStorage.getItem(GNVIEW_SETTINGS);
        const userSettingsState = JSON.parse(serializedUserSettingsState);
        if (authState && (authState.email !== this.props.email)) {
            this.props.gnviewAuthUpdate(authState);
        }
        if (userSettingsState) {
            this.props.gnviewUserSettingsUpdate(userSettingsState);
        }
        if (!authState || !userSettingsState) {
            let pathAfterLogin;
            const params = queryString.parse(window.location.search);
            const redirectPath = params.redirectPath;
            if (redirectPath && redirectPath?.length > 0) {
                pathAfterLogin = redirectPath;
            } else {
                const currentUrl = () => {
                    if (window.location.search?.length > 0) {
                        return `${window.location.pathname}${window.location.search}`;
                    } else {
                        return `${window.location.pathname}`;
                    }
                };
                pathAfterLogin = currentUrl();
            }
            this.props.history.push(`${ROUTES.LANDING}?redirectPath=${pathAfterLogin}`);
        }
        return;
    }

    componentDidUpdate() {
        // If user is basic accountType and they are over lookupMax, check to see if we should reset their lookupCount
        if (this.props.isBasic && this.props.lookupCount > this.props.lookupMax) {
            this.props.gnviewGetLookupReset(this.props.email)
                .then((response) => {
                    if (response?.result?.shouldBeReset === true) {
                        this.props.gnviewLookupCountUpdate({
                            userLookupCount: 0,
                            userLastUpdated: new Date()
                        });
                        this.props.history.push(ROUTES.SCHEDULES);
                    }
                });
        }
    }

    canShowDataAlertsTab() {
        return this.props.entitlements?.global?.include?.featureFlags?.includes(FEATURE_FLAGS.CHANNEL_ALERTS)
    }

    render() {
        const shouldShowSideMenu = this.props.token
            && (this.props.location.pathname !== ROUTES.GNID_DISTRIBUTION)
            && (this.props.location.pathname !== ROUTES.REPORT_AN_ISSUE)
            && (this.props.location.pathname !== ROUTES.TRIAL_FORM)
            && !shouldRenderGNViewTeaser(this.props.entitlements, this.props.location.pathname);
        return (
            <div className="gnview-app-container">
                {this.props.token && <div className='gnview-inner-container'>
                    {shouldShowSideMenu && <ErrorBoundary><Menu /></ErrorBoundary>}
                    <ErrorBoundary>
                        <div className="gnview-main-container">
                            <Switch>
                                {/* Market Details */}
                                <PrivateRoute key="markets" exact path={ROUTES.MARKET_DETAILS} component={MarketsSearchView} />
                                <PrivateRoute key="markets/id" path={`${ROUTES.MARKET_DETAILS}/:marketId`} component={MarketsDetailsView} />

                                {/* Program Details */}
                                <PrivateRoute key="programs" exact path={ROUTES.PROGRAM_DETAILS} component={ProgramsSearchView} />
                                <PrivateRoute key="programs/tmsid" path={`${ROUTES.PROGRAM_DETAILS}/:tmsId`} component={ProgramsDetailsView} />

                                {/* Sports */}
                                <PrivateRoute key="sports" exact path={ROUTES.SPORTS} component={SportsSearch} />
                                <PrivateRoute key="sports/result" exact path={ROUTES.SPORTS_RESULTS} component={SportsResults} />

                                {/* Advanced Search */}
                                <PrivateRoute key="advancedSearch/result" exact path={`${ROUTES.ADVANCED_SEARCH}/result`} component={AdvancedSearchResultsView} />
                                <PrivateRoute key="advancedSearch" exact path={ROUTES.ADVANCED_SEARCH} component={AdvancedSearchView} />

                                {/* Station Details */}
                                <PrivateRoute key="stations" exact path={ROUTES.STATION_DETAILS} component={StationsSearchView} />
                                <PrivateRoute key="stations/id" path={`${ROUTES.STATION_DETAILS}/:stationId`} component={StationsDetailsView} />

                                {/* Saved Searches */}
                                <PrivateRoute key="savedSearches" path={ROUTES.MY_SAVED_ITEMS} component={MySavedItems} />
                                {/* Data Alerts */}
                                {this.canShowDataAlertsTab() && <PrivateRoute key="dataAlerts" path={ROUTES.DATA_ALERTS} component={DataAlerts} />}

                                {/* Schedules */}
                                <PrivateRoute key="schedules/result" path={`${ROUTES.SCHEDULES}/result`} component={ScheduleResult} />
                                <PrivateRoute key="schedules" path={ROUTES.SCHEDULES} component={ScheduleSearchView} />

                                {/* Program Airings */}
                                <PrivateRoute key="programAirings/result" path={`${ROUTES.PROGRAM_AIRINGS}/result`} component={ProgramAiringsResultView} />
                                <PrivateRoute key="programAirings" path={ROUTES.PROGRAM_AIRINGS} component={ProgramAiringsSearchView} />

                                {/* Streaming Video - Program Availability */}
                                <PrivateRoute key="programAvailabilities/result" path={`${ROUTES.PROGRAM_AVAILABILITIES}/result`} component={ProgramAvailabilityResult} />
                                <PrivateRoute key="programAvailabilities" path={ROUTES.PROGRAM_AVAILABILITIES} component={ProgramAvailabilitySearchView} />

                                {/* Report an Issue */}
                                <PrivateRoute key="reportAnIssue" path={ROUTES.REPORT_AN_ISSUE} component={ReportAnIssue} />
                                {/* Status */}
                                <PrivateRoute key="status" path={ROUTES.STATUS} component={Status} />

                                <PrivateRoute key="accessDenied" exact path={ROUTES.ACCESS_DENIED} component={AccessDeniedView} />
                                <Route key="getStarted" exact path={ROUTES.GET_STARTED} component={GetStartedView} />
                                <PrivateRoute key="contactUs" exact path={ROUTES.CONTACT_US} component={ContactUsView} />

                                <PrivateRoute key="lookupMax" exact path={ROUTES.LOOKUP_MAX} component={LookupMaxView} />

                                {/* GNView Teaser */}
                                <PrivateRoute key="gnViewTeaser" exact path={ROUTES.GNVIEW_TEASER} component={GNViewTeaser} />

                                {/*
                                    404 - Not found - has no path on purpose so we can catch-all invalid routes, all non-matching routes will go here.
                                    If you want the user to navigate here use ROUTES.NOT_FOUND (so we have a nice URL name, but we still are catch-alling here)
                                */}
                                <Route key="404" component={NotFound404View} />
                            </Switch>
                        </div>
                        <GNGetHelp />
                    </ErrorBoundary>
                </div>}
            </div>
        )
    }
}

App.propTypes = {
    gnviewAuthUpdate: PropTypes.func,
    gnviewGetLookupReset: PropTypes.func,
    gnviewLookupCountUpdate: PropTypes.func,
    gnviewUserSettingsUpdate: PropTypes.func,
    history: ReactRouterPropTypes.history,
    location: PropTypes.object,
    token: PropTypes.string,
    entitlements: PropTypes.object,
    email: PropTypes.string,
    isBasic: PropTypes.bool,
    lookupCount: PropTypes.number,
    lookupMax: PropTypes.number
};

const mapStateToProps = state => {
    return {
        token: gvauthSelToken(state),
        email: gvauthSelEmail(state),
        entitlements: gvauthSelEntitlements(state),
        isBasic: gvauthIsBasic(state),
        lookupCount: gvauthSelLookupCount(state),
        lookupMax: gvauthSelLookupMax(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewAuthUpdate,
        gnviewGetLookupReset,
        gnviewLookupCountUpdate,
        gnviewUserSettingsUpdate
    }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
