import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import App from './App';
import { history, store } from './services/GeneralService';
import './css/all.scss';
import moment from 'moment-timezone';
import "@fortawesome/fontawesome-free/js/all.js";
import singleSpaReact from "single-spa-react";
import singleSpaCss from 'single-spa-css';

moment.locale('en', { week: { dow: 1 } });

const Root = () => (
    <AppContainer>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App history={history} />
            </ConnectedRouter>
        </Provider>
    </AppContainer>
);

const cssLifecycles = singleSpaCss({
    // optional: defaults to false. This controls whether extracted CSS files from Webpack
    // will automatically be loaded. This requires using the ExposeRuntimeCssAssetsPlugin,
    // which is documented below.
    webpackExtractedCss: true,

    // optional: defaults to true. Indicates whether the <link> element for the CSS will be
    // unmounted when the single-spa microfrontend is unmounted.
    shouldUnmount: true,

    // optional: defaults to 5000. The number of milliseconds to wait on the <link> to load
    // before failing the mount lifecycle.
    timeout: 5000,

    // optional: defaults to a standard <link rel="stylesheet" href="/main.css"> element
    // Customize the creation of the link element that is used by single-spa-css by providing a
    // function. For example, for setting the cross-origin or other HTML attributes on the <link>
    createLink(url) {
        const linkEl = document.createElement('link');
        linkEl.rel = 'stylesheet';
        linkEl.href = url;
        return linkEl;
    }
});

const reactLifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary(err, info, props) {
        console.error('Error in GNVIEW:', err, info, props)
        // Customize the root error boundary for your microfrontend here.
        return null;
    }
});

// Export an array of lifecycles to integrate with a framework's
// single-spa lifecycles. The order matters.
export const bootstrap = [
    cssLifecycles.bootstrap,
    reactLifecycles.bootstrap
]

export const mount = [
    // The CSS lifecycles should be before your framework's mount lifecycle,
    // to avoid a Flash of Unstyled Content (FOUC)
    cssLifecycles.mount,
    reactLifecycles.mount
]

export const unmount = [
    // The CSS lifecycles should be after your framework's unmount lifecycle,
    // to avoid a Flash of Unstyled Content (FOUC)
    reactLifecycles.unmount,
    cssLifecycles.unmount
]