import React from 'react';
import './NoDataMessage.scss';
import PropTypes from 'prop-types';

export const NoDataMessage = ({ isStatusTable = false, header, message }) => {
    return (
        <div className='no-data-message-container'>
            {isStatusTable && <i className="no-data-icon fas fa-list" />}
            <span className='no-data-header'>{header}</span>
            <span className='no-data-message'>{message}</span>
        </div>
    )
}

NoDataMessage.propTypes = {
    // isStatusTable: no data message will display in case status table
    isStatusTable: PropTypes.bool,
    // header: header of no data component
    header: PropTypes.string.isRequired,
    // message: message of no data component
    message: PropTypes.string.isRequired
};

export default NoDataMessage;