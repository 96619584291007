import React from 'react';
import accessDeniedDog from '../images/access_denied.png';
import lockIcon from '../images/Lock.png';
import {Redirect} from 'react-router-dom';
import { ROUTES } from '../config/Routes';

import { ACCESS_DENIED_HEADER, ACCESS_DENIED_SECTION_CONTENT } from '../constants/AccessDenied';
import "./AccessDenied.scss";

export const AccessDeniedView = () => {
    const queryParameters = new URLSearchParams(location.search)
    const page = queryParameters.get("page")
    let sectionData = null;
    switch (page) {
    case ROUTES.ADVANCED_SEARCH:
        sectionData = ACCESS_DENIED_SECTION_CONTENT.ADVANCED_SEARCH;
        break;
    case ROUTES.PROGRAM_AIRINGS:
        sectionData = ACCESS_DENIED_SECTION_CONTENT.PROGRAM_AIRINGS;
        break;
    case ROUTES.SPORTS:
        sectionData = ACCESS_DENIED_SECTION_CONTENT.SPORTS;
        break;
    case ROUTES.STATION_DETAILS:
        sectionData = ACCESS_DENIED_SECTION_CONTENT.STATIONS;
        break;
    case ROUTES.MARKET_DETAILS:
        sectionData = ACCESS_DENIED_SECTION_CONTENT.US_MARKETS;
        break;
    case ROUTES.MY_SAVED_ITEMS:
        sectionData = ACCESS_DENIED_SECTION_CONTENT.MY_SAVED_ITEMS;
        break;
    case ROUTES.PROGRAM_AVAILABILITIES:
        sectionData = ACCESS_DENIED_SECTION_CONTENT.PROGRAM_AVAILABILITY;
        break;
    default:
        break;
    }

    if (!sectionData) {
        return <Redirect to="/not-found" />
    }

    return (
        <div className='gnview-access-denied-main-container'>
            <img src={accessDeniedDog} alt="access-denied" className="access-denied-image" />
            <div className="access-denied-title"><img src={lockIcon} alt="access-denied" className="access-denied-title-icon" />{ sectionData.header || ACCESS_DENIED_HEADER }</div>
            <div className="access-denied-section wrapper">
                <div className="access-denied-section info">
                    <h4 className="info-header">
                        <span className='section-icon'><i className={sectionData.icon} /> </span>
                        <strong>{sectionData.title}</strong> allows you to:</h4>
                    <ul>
                        {sectionData.features.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                    <hr className="short" />
                    <p className="access-denied-upgrade-text">To access, reach out to <a href ="mailto:gracenoteview@nielsen.com">gracenoteview@nielsen.com</a></p>
                    <div id="gnview-try-premium"></div>
                </div>
                <div className="access-denied-section demo-image">
                    <img src={ sectionData.image } className="access-denied-section-image" />
                </div>
            </div>
        </div>
    )
}

export default AccessDeniedView;
