/* eslint react/prop-types: 0 */
/* eslint react/display-name: 0 */
import React from 'react';
import GNImage from '../components/common/gnImage/GNImage';
import { getImageURL } from '../utils/ImageUtils';
import GNBadge from '../components/common/gnBadge/GNBadge';

export const PROGRAM_AVAILABILITY = 'Program Availability';
export const PROGRAM_AVAILABILITY_GNVIEW_LAST_UPDATED = 'GNVIEW Last Updated: ';

export const PROGRAM_AVAILABILITY_PROGRAM_MAXIMUM = 10;
export const PROGRAM_AVAILABILITY_STEPS = {
    STEP_1: "Step 1: Program selection",
    STEP_2: "Step 2: Streaming Catalogs"
};
export const PROGRAM_AVAILABILITY_PROGRESS_BAR = {
    STEP_1: "1. Programs",
    STEP_2: "2. Streaming Catalogs"
};
export const PROGRAM_AVAILABILITY_BUTTON_TEXT = {
    STEP_1: "Next - Step 2",
    STEP_2_PREV: "Prev - Step 1",
    STEP_2_NEXT: "Next - View Results"
};

export const PROGRAM_AVAILABILITY_FORM_VALIDATIONS = {
    PROGRAM_MINIMUM: 'Please select at least one program.',
    PROGRAM_MAXIMUM: `A maximum of ${PROGRAM_AVAILABILITY_PROGRAM_MAXIMUM} programs is allowed per Report.`,
    PROGRAM_INVALID_TMSID: ' is not a valid TMS ID.',
    PROGRAM_UNAUTHORIZED: 'You do not have access to view this program: ',
    SAVED_SEARCH_UNAUTHORIZED: 'Sorry, you do not own this Saved Search',
    PROGRAM_DUPLICATE: 'This program is already selected - Please use a different TMS ID.',
    EPISODE_TMSID_SEARCH: 'Episode lookups are currently not supported. Please use the series TMS ID: ',
    STREAMING_CATALOGS_MINIMUM: 'Please select at least one catalog.'
};

export const PROGRAM_AVAILABILITY_TABLE_COLUMN_HEADERS = [
    { accessor: 'catalog', Header: 'Catalog Name', Cell: ({row}) => {
        const catalog = row.values.catalog;
        return <div className='catalog-name'>
            {catalog.images[0] && <GNImage url={getImageURL(catalog.images[0].image)} />}
            {catalog.name} ({catalog.id})
            <GNBadge type={null} subtype={catalog.country_codes[0]} color='country-code-gray' />
        </div>;
    }},
    { accessor: 'provider_id', Header: 'Provider ID' },
    { accessor: 'urls', Header: 'URLs', Cell: ({row}) => {
        return row.values.urls.map((url, idx) => {
            return <div className='table-urls' key={idx}>{url.typecode}: <span className='url-value'>{url.link}<a href={url.link} target='_blank' rel="noreferrer"><i className='fas fa-link'/></a></span></div>
        });
    }},
    { accessor: 'purchase_options', Header: 'Viewing Options', Cell: ({row}) => {
        return row.values.purchase_options.map((purchOpt, idx) => {
            return <div className='viewing-options' key={idx}>{purchOpt.typecode}{purchOpt.price ? ':' : ''} <span className='viewing-options-value'>{purchOpt?.currencycode} {purchOpt?.price}</span></div>
        });
    }},
    { accessor: 'video_qualities', Header: 'Video Quality', Cell: ({row}) => {
        return row.values.video_qualities.map(qual => qual.typecode);
    }},
    { accessor: 'formatted_dates', Header: 'Availability', Cell: ({row}) => {
        const startDate = row.values?.formatted_dates?.start_date;
        const endDate = row.values?.formatted_dates?.end_date;
        return (startDate || endDate) ? <div className='availability'>{startDate ? startDate : 'Now'} - {endDate ? endDate : 'Now'}</div> : <div className='availability'>Now</div>;
    }},
    { accessor: 'provider_data', Header: 'Provider Data', Cell: ({row}) => {
        return row.values.provider_data.map((provider, idx) => {
            return <div className='provider-data' key={idx}>{provider.key}: {provider.key === 'ovd_image_uri' ? <a href={provider.value} target='_blank' rel="noreferrer" className='provider-data-value'> {provider.value}</a> : <span className='provider-data-value'>{provider.value}</span>}</div>
        });
    }},
    { accessor: 'id', Header: 'Availability ID' }
];

export const TABLE_PAGE_SIZE = 20;

export const STEP_2_FILTER_TYPES = {
    all: 'All',
    free: 'Free',
    subscription: 'SVOD',
    authentication: 'TVE',
    purchase: 'TVOD',
    rental: 'TVOD'
};

export const SERVICE_TYPE_LABEL = 'Service Type';
export const COUNTRY_LABEL = 'Countries';