import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Highlighter } from "react-bootstrap-typeahead";
import GNImage from '../gnImage/GNImage';
import {getImageURL, getImageURLH} from '../../../utils/ImageUtils';
import { numberWithCommas } from '../../../utils/GeneralUtils';
import {
    getProgramImageHorizontal,
    getProgramImageVertical,
    getProgramSeasonAndEpisodeHeaders
} from '../../../utils/ProgramUtils';
import { filterEpisodesBySeason } from '../../../utils/SeasonUtils';
import LazyLoad from 'react-lazyload';
import "./GNProgramCard.scss";
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import GNClientTable from '../gnClientTable/GNClientTable';
import GNCard from '../gnCard/GNCard';
import GNBadge from '../gnBadge/GNBadge';
import StepCount from './StepCount';
import { NO_SEASON } from '../../../constants/Program';

export class GNProgramCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseAll: false,
            seasonsSelected: {}
        }
    }

    setSeasonsSelected = () => {
        if (this.props.program?.uniqueSeasons?.length > 0) {
            const seasonsSelected = this.props.program.uniqueSeasons.reduce((acc, season) => {
                let episodes = this.props.program.seasonsAndEpisodes[season];
                if ((!episodes) && this.props.program.seasonsAndEpisodes[NO_SEASON]) {
                    episodes = filterEpisodesBySeason(season, this.props.program.seasonsAndEpisodes[NO_SEASON]);
                }
                const episodesSelected = episodes.filter((ep) => ep.isSelected).length;
                acc[season] = {selected: true, collapsed: true, episodesSelected};
                return acc;
            }, {});
            this.setState({seasonsSelected});
        }
    }

    componentDidMount() {
        if (this.props.program?.uniqueSeasons?.length > 0 && this.props.program?.seasonsAndEpisodes) {
            this.setSeasonsSelected();
        }
    }

    // This is here to update the checkbox background after the view is rendered, since the checkboxes don't exist until after the initial render
    componentDidUpdate(prevProps, prevState) {
        if (this.props.isDataFromSavedSearch && (this.state.seasonsSelected !== prevState.seasonsSelected)) {
            Object.keys(this.state.seasonsSelected).forEach(season => {
                const numEpisodesSelected = this.state.seasonsSelected[season].episodesSelected;
                const totalEpisodesNum = this.props.program.seasonsAndEpisodes[season].length;
                if (numEpisodesSelected !== 0 && (numEpisodesSelected < totalEpisodesNum)) {
                    const seasonCbxWrapper = document.getElementsByClassName(`${this.props.program.tmsid}-select-season-${season}`)[0];
                    const cb = seasonCbxWrapper.querySelector('input');
                    cb.data = 'indeterminate'
                    this.forceUpdate();
                }
            });
        }
    }

    toggleSeasonCollapse = (season) => {
        if (season) {
            const seasonsSelectedCopy = {...this.state.seasonsSelected};
            seasonsSelectedCopy[season].collapsed = !seasonsSelectedCopy[season].collapsed;
            this.setState({seasonsSelected: seasonsSelectedCopy});
        }
    }

    toggleCollapseAll = () => {
        const seasonsSelectedCopy = {...this.state.seasonsSelected};
        const seasons = Object.keys(seasonsSelectedCopy);
        seasons.forEach((season) => {
            seasonsSelectedCopy[season].collapsed = this.state.collapseAll;
        });
        this.setState((prevState) => ({seasonsSelected: seasonsSelectedCopy, collapseAll: !prevState.collapseAll}));
    }

    getSeasonEpisodeCount = (season) => {
        let episodes = this.props.program.seasonsAndEpisodes[season];
        if ((!episodes) && this.props.program.seasonsAndEpisodes[NO_SEASON]) {
            episodes = filterEpisodesBySeason(season, this.props.program.seasonsAndEpisodes[NO_SEASON]);
        }
        const shouldShowEpisodeCount = this.state.seasonsSelected[season].episodesSelected > 0 && this.state.seasonsSelected[season].episodesSelected !== episodes.length;
        const seasonCbxWrapper = document.getElementsByClassName(`${this.props.program.tmsid}-select-season-${season}`)[0];
        if (seasonCbxWrapper) {
            const cb = seasonCbxWrapper.querySelector('input');
            cb.data = shouldShowEpisodeCount ? 'indeterminate' : null;
            cb.checked = this.state.seasonsSelected[season].episodesSelected === episodes.length;
            cb.indeterminate = shouldShowEpisodeCount;
        }
        return shouldShowEpisodeCount ? <StepCount count={this.state.seasonsSelected[season].episodesSelected.toString()} text={this.state.seasonsSelected[season].episodesSelected > 1 ? "programs" : "program"} /> : null;
    }

    updateSeasonEpisodeCount = (season) => {
        // Update one season
        if (season) {
            const seasonsSelectedCopy = {...this.state.seasonsSelected};
            let episodes = this.props.program.seasonsAndEpisodes[season];
            if ((!episodes) && this.props.program.seasonsAndEpisodes[NO_SEASON]) {
                episodes = filterEpisodesBySeason(season, this.props.program.seasonsAndEpisodes[NO_SEASON]);
            }
            seasonsSelectedCopy[season].episodesSelected = episodes.filter((ep) => ep.isSelected).length;
            this.setState({seasonsSelected: seasonsSelectedCopy});
        } else { // Update all seasons
            this.setSeasonsSelected();
        }
    }

    handleSelectAllSeries = (e) => {
        this.props.seriesCheckboxHandler(e, this.props.program);
        this.updateSeasonEpisodeCount();
    }

    handleSelectSeason = (e, season) => {
        this.props.seasonCheckboxHandler(e, this.props.program, season);
        this.updateSeasonEpisodeCount(season);
    }

    handleSelectEpisode = (e, row, season) => {
        this.props.episodeCheckboxHandler(e, row);
        this.updateSeasonEpisodeCount(season);
    }

    render() {
        return (
            <div className="gnview-program-card">
                <div className="top-section">
                    <div className="left-section">
                        { this.props.typeahead && this.props.program?.title && <LazyLoad scroll={true} scrollContainer={'gnview-program-card'} overflow={true} once><GNImage className={'program-image'} url={getImageURL(getProgramImageVertical(this.props.program))} text={this.props.program.title} /></LazyLoad>}
                        { !this.props.typeahead && this.props.program?.title && !this.props.horizontalImage && <GNImage className={'program-image'} url={getImageURL(getProgramImageVertical(this.props.program))} text={this.props.program.title} />}
                        { !this.props.typeahead && this.props.program?.title && this.props.horizontalImage && <GNImage className={'program-image horizontal'} url={getImageURLH(getProgramImageHorizontal(this.props.program), '?h=250')} text={this.props.program.title} />}
                        <div className="program-data">
                            { this.props.typeahead && this.props.program?.title && <span className="program-title"><Highlighter key="title" search={this.props.typeaheadText}>{this.props.program.title}</Highlighter></span>}
                            { !this.props.typeahead && this.props.program?.title && <span className="program-title">{this.props.program.title}</span>}
                            { this.props.program?.release_year && <span className="dd-sub-title">({this.props.program.release_year})</span>}
                            { this.props.program?.tmsid && <div className='program-tmsid'>{this.props.program.tmsid}</div>}
                            <div className="section">
                                { this.props.program?.type && this.props.program?.subtype && <GNBadge type={this.props.program.type} subtype={this.props.program.subtype} />}
                                { this.props.program?.duration && <span className='program-duration'>{this.props.program.duration + ' mins'}</span>}
                                { this.props.program?.origin_countries?.length > 0 && <span className="countries">{this.props.program.origin_countries.join(', ')}</span>}
                            </div>
                            { this.props.program?.top_cast?.length > 0 && <div className="section">{this.props.program.top_cast.slice(0, 3).join(', ')}</div>}
                        </div>
                    </div>
                    { this.props.additionalSeriesInfo && <div className="right-section">
                        { this.props.program?.uniqueSeasons?.length > 0 && <div className="total-seasons">Total Seasons: {this.props.program.uniqueSeasons.length}</div>}
                        { this.props.program?.episodeCount && <div className="total-episodes">Total Episodes: {numberWithCommas(this.props.program.episodeCount)}</div>}
                    </div>}
                    { this.props.handleProgramRemove && <div className="program-delete" onClick={() => this.props.handleProgramRemove(this.props.program.tmsid)}><i className="fas fa-trash-can" /></div>}
                </div>
                { this.props.additionalSeriesInfo && (this.props.program?.episodeCount > 0) && <div className="series-section">
                    {/* For custom checkboxes you need the prop 'custom', label, and a unique id */}
                    <Form.Check
                        custom
                        id={`select-all-${this.props.program.tms_id}`}
                        className="select-all"
                        type="checkbox"
                        label={'Select entire series'}
                        defaultChecked={!this.props.isDataFromSavedSearch}
                        onChange={(e) => this.handleSelectAllSeries(e)}/>
                    <span className="expand-collapse-all" onClick={this.toggleCollapseAll}>{this.state.collapseAll ? 'Collapse All' : 'Expand All'}</span>
                    {Object.keys(this.state.seasonsSelected).length !== 0 && this.props.program?.uniqueSeasons?.length > 0 && this.props.program.uniqueSeasons.map((season) => {
                        let episodes = this.props.program.seasonsAndEpisodes[season];
                        if ((!episodes) && this.props.program.seasonsAndEpisodes[NO_SEASON]) {
                            episodes = filterEpisodesBySeason(season, this.props.program.seasonsAndEpisodes[NO_SEASON]);
                        }
                        const allEpisodesAreSelectedInSeason = episodes.filter(episode => !episode.isSelected).length === 0;
                        return (
                            <div className="season-table-wrapper" key={season}>
                                {/* For custom checkboxes you need the prop 'custom', label, and a unique id */}
                                <Form.Check
                                    custom
                                    id={`season-${season}-checkbox-${this.props.program.tms_id}`}
                                    label=""
                                    className={`${this.props.program.tmsid}-select-season-${season} select-season-wrapper`}
                                    type="checkbox"
                                    defaultChecked={this.props.isDataFromSavedSearch ? allEpisodesAreSelectedInSeason : true}
                                    onChange={(e) => this.handleSelectSeason(e, season)}/>
                                <GNCard
                                    title={season !== NO_SEASON ? 'Season ' + season : season}
                                    color="black"
                                    handleToggle={() => this.toggleSeasonCollapse(season)}
                                    isCollapsed={this.state.seasonsSelected[season].collapsed}
                                    subtitle={this.getSeasonEpisodeCount(season)}/>
                                {!this.state.seasonsSelected[season].collapsed && <GNClientTable columns={getProgramSeasonAndEpisodeHeaders()} data={episodes} checkboxes={true} rowCheckboxHandler={(e, row) => this.handleSelectEpisode(e, row, season)} />}
                            </div>
                        )
                    })}
                </div>}
            </div>
        );
    }
}

GNProgramCard.defaultProps = {
    additionalSeriesInfo: false,
    horizontalImage: false,
    typeahead: false
};

GNProgramCard.propTypes = {
    additionalSeriesInfo: PropTypes.bool,
    episodeCheckboxHandler: (props, propName) => {
        if ((props['additionalSeriesInfo'] === true && (props[propName] === undefined || typeof (props[propName]) !== 'function'))) {
            return new Error('Please provide a episodeCheckboxHandler function!');
        }
        return null;
    },
    handleProgramRemove: PropTypes.func,
    horizontalImage: PropTypes.bool,
    isDataFromSavedSearch: PropTypes.bool,
    program: PropTypes.object.isRequired,
    seasonCheckboxHandler: (props, propName) => {
        if ((props['additionalSeriesInfo'] === true && (props[propName] === undefined || typeof (props[propName]) !== 'function'))) {
            return new Error('Please provide a seasonCheckboxHandler function!');
        }
        return null;
    },
    seriesCheckboxHandler: (props, propName) => {
        if ((props['additionalSeriesInfo'] === true && (props[propName] === undefined || typeof (props[propName]) !== 'function'))) {
            return new Error('Please provide a seriesCheckboxHandler function!');
        }
        return null;
    },
    typeahead: PropTypes.bool,
    typeaheadText: PropTypes.string
};

const mapStateToProps = () => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GNProgramCard);