import React from 'react';
import SelectSearch from 'react-select-search';
import PropTypes from 'prop-types';
import './GNDropdown.scss';
import ClassNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const GNDropdown = (props) => (
    <div className='webclient-gndropdown-main-container'>
        <div className='gndropdown-label'>{props?.label}</div>
        <SelectSearch
            className={props?.className}
            onChange={(item) => props?.handleChange(item)}
            options={props?.options}
            disabled={props?.disabled}
            emptyMessage={props?.emptyMessage}
            // Initial value for GNDropdown, only will update with onChange
            value={props?.value}
            search={true}
            getOptions={(inputValue, options) => {
                if (inputValue) {
                    return options.filter((item) =>
                        typeof item?.name === 'string'
                            ? item.name.toLowerCase().includes(inputValue.toLowerCase())
                            : item.value.toLowerCase().includes(inputValue.toLowerCase())
                    );
                } else {
                    return options;
                }
            }}
            // disable Last Pass icon
            data-lpignore={true}
            {...(props.renderValue && { renderValue: props.renderValue })}
            {...(props.placeholder && { placeholder: props.placeholder })}
            {...(props.renderOption && { renderOption: props.renderOption })}
        />
        {props?.showClearIcon && props?.handleClear && <FontAwesomeIcon icon='xmark' className={ClassNames({ 'has-label': props?.label })} onClick={props.handleClear} />}
        <FontAwesomeIcon icon='angle-down' className={ClassNames({ 'has-label': props?.label })} />
        {props.isRequired && <div className={ClassNames('required-text', { 'show-error': props?.showError })}>*Required</div>}
    </div>
);

GNDropdown.defaultProps = {
    className: 'select-search',
    disabled: false,
    emptyMessage: 'Not Found',
    isRequired: false,
    showError: false,
    showClearIcon: true
};

GNDropdown.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    emptyMessage: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    handleClear: PropTypes.func,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    renderValue: PropTypes.func,
    renderOption: PropTypes.func,
    showError: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    showClearIcon: PropTypes.bool
};

export default GNDropdown;
