import { API_ENDPOINTS } from '../config/Api';
import apiService from '../services/ApiService';
import { GNVIEW_DATA_ALERTS_SET_ALERTS, GNVIEW_DATA_ALERTS_SET_SUBSCRIPTIONS, GNVIEW_DATA_ALERTS_SET_PRG_SVC_IDS, GNVIEW_DATA_ALERTS_SET_ALERTS_COUNTS, GNVIEW_DATA_ALERTS_SET_SELECTED_WINDOW, GNVIEW_DATA_ALERTS_SET_ALERTS_COUNTS_THIS_WEEK } from './ActionTypes';

export const gnviewDataAlertsSetAlerts = (alerts) => ({
    type: GNVIEW_DATA_ALERTS_SET_ALERTS,
    payload: { alerts }
});

export const gnviewDataAlertsSetSubscriptions = (subscriptions) => ({
    type: GNVIEW_DATA_ALERTS_SET_SUBSCRIPTIONS,
    payload: { subscriptions }
});

export const gnviewDataAlertsSetPrgSvcIDs = (prgSvcIDs) => ({
    type: GNVIEW_DATA_ALERTS_SET_PRG_SVC_IDS,
    payload: { prgSvcIDs }
});

export const gnviewDataAlertsSetAlertsCounts = (alertsCounts) => ({
    type: GNVIEW_DATA_ALERTS_SET_ALERTS_COUNTS,
    payload: { alertsCounts }
});

export const gnviewDataAlertsSetAlertsCountsThisWeek = (alertsCount) => ({
    type: GNVIEW_DATA_ALERTS_SET_ALERTS_COUNTS_THIS_WEEK,
    payload: { alertsCount }
});

export const gnviewDataAlertsSetSelectedWindow = (window) => ({
    type: GNVIEW_DATA_ALERTS_SET_SELECTED_WINDOW,
    payload: { window }
});

export const gnviewGetUserSubscriptions = (userEmail) => {
    return (dispatch) => {
        return apiService.makeApiCall(`${API_ENDPOINTS.SUBSCRIPTIONS}?username=${userEmail}`, {}, 'get').then(
            (response) => {
                dispatch(gnviewDataAlertsSetSubscriptions(response.result.subscriptions));
                dispatch(gnviewDataAlertsSetPrgSvcIDs(response.result.subscriptions.map(
                    (subscription) => {
                        return subscription.station_id;
                    }
                )))
                return response.result.subscriptions;
            },
            (error) => {
                throw error;
            }
        );
    }
};

export const gnviewGetUserAlerts = (prgSvcIDs, userEmail, windowStart, windowEnd) => {
    return (dispatch) => {
        return apiService.makeApiCall(`${API_ENDPOINTS.SUBSCRIPTIONS}/${API_ENDPOINTS.ALERTS}?program_service_ids[]=${prgSvcIDs}&username=${userEmail}&window_start=${windowStart}&window_end=${windowEnd}`, {}, 'get').then(
            (response) => {
                dispatch(gnviewDataAlertsSetAlerts(response.result));
                return response;
            },
            (error) => {
                throw error;
            }
        );
    }
};

export const gnviewPostReviewedAlerts = (alertId, userEmail) => {
    return () => {
        const body = {
            username: userEmail,
            alertId
        };
        return apiService.makeApiCall(`${API_ENDPOINTS.SUBSCRIPTIONS}/${API_ENDPOINTS.ALERTS}/${API_ENDPOINTS.REVIEWED_ALERTS}`, body, 'post');
    };
}

export const gnviewDeleteReviewedAlerts = (reviewedAlertId) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.SUBSCRIPTIONS}/${API_ENDPOINTS.ALERTS}/${API_ENDPOINTS.REVIEWED_ALERTS}/${reviewedAlertId}`, {}, 'delete');
    }
}
